import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {version} from '../../environments/version';
import { SettingsService } from '../settings.service';
import { Utils } from '../common/utils';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit,OnDestroy {

    @Input() hasBackground = false;
    @Input() isBasic = false;
    @Input() isAdvanced = false;

    isJsApi =  Utils.isJsApi;

    brandingYear: number = new Date().getFullYear();

    get link(): string {
        return (this.settings.languageCountry.domain && this.settings.languageCountry.domain !== '(null)') ? this.settings.languageCountry.domain : 'dhl.com';
    }

    version = version;

    constructor(public settings: SettingsService) {
    }

    ngOnDestroy(): void {
        // do nothing
    }

    ngOnInit() {
    }
}
