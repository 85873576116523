import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, NgModule, APP_INITIALIZER, CSP_NONCE} from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgSelectModule} from '@ng-select/ng-select';
import {HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { MatAutocompleteModule as MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule as MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule as MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule as MatInputModule } from '@angular/material/input';
import { MatRadioModule as MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule as MatSnackBarModule } from '@angular/material/snack-bar';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SearchPageComponent} from './search-page/search-page.component';
import {ResultsPageComponent} from './results-page/results-page.component';
import {FooterComponent} from './footer/footer.component';
import {HeaderComponent} from './header/header.component';
import {BasicSearchControlsComponent} from './basic-search-controls/basic-search-controls.component';
import {AdvSearchCriteriaComponent} from './adv-search-criteria/adv-search-criteria.component';
import {SpMapComponent} from './sp-map/sp-map.component';
import {ResultListComponent} from './result-list/result-list.component';
import {ResultItemComponent} from './result-item/result-item.component';
import {CountrySelectComponent} from './country-select/country-select.component';
import {AddressInputComponent} from './address-input/address-input.component';
import {CapabilitySelectComponent} from './capability-select/capability-select.component';
import {AdvSearchPageComponent} from './adv-search-page/adv-search-page.component';
import {LangSelectorComponent} from './lang-selector/lang-selector.component';
import {CapabilityDetailComponent} from './capability-detail/capability-detail.component';
import {BingMapComponent} from './bing-map/bing-map.component';
import {OnlyNumberDirective} from './directives/only-number';
import {PrintPageComponent} from './print-page/print-page.component';
import {DirectionsPageComponent} from './directions-page/directions-page.component';
import {NoopAnimationsModule} from '@angular/platform-browser/animations';
import {CookieService} from 'ngx-cookie-service';
import {SpMapLightComponent} from './sp-map-light/sp-map-light.component';
import {CookieConsentComponent} from './cookie-consent/cookie-consent.component';
import {AdvSearchTipComponent} from './adv-search-tip/adv-search-tip.component';
import {LegacyURLComponent} from './legacy-url/legacy-url.component';
import {SettingsService} from './settings.service';
import {NotFoundComponent} from './not-found/not-found.component';
import {GlobalErrorHandler} from './global-error-handler';
import {ErrorComponent} from './error/error.component';
import 'string-includes-polyfill';
import 'compatibility-array-includes';
import { BackendService } from './backend.service';
import {from, Observable} from 'rxjs';
import {AdvSearchHintComponent} from './adv-search-hint/adv-search-hint.component';
import { CountryPromotionComponent } from './country-promotion/country-promotion.component';
import { SpPromotionComponent } from './sp-promotion/sp-promotion.component';
import { CanFreezeDirective } from './directives/can-freeze';
import { CanHideDirective } from './directives/can-hide';
//import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { GoogleMapComponent } from './google-map/google-map.component';
import { SpDetail2Component } from './sp-detail2/sp-detail2.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UnsupportedBrowsersComponent } from './unsupported-browsers/unsupported-browsers.component';
import { FloatNumberDirectiveDirective } from './directives/float-number';
import { CssSwitchDirective } from './directives/css-switch';
import { CanFreezeElementDirective } from './directives/can-freeze-element';
import {UrlSerializer} from '@angular/router';
import { CustomUrlSerializer } from './custom-url-serializer';
import { NoCacheHeadersInterceptor } from './common/no-cache-headers-interceptor';
import { NavigationModalComponent } from './navigation-modal/navigation-modal.component';
import { DigitOnlyDirective } from './common/digit-only.directive';
import { CanFreezeReadonlyDirective } from './directives/can-freeze-readonly';
import { CanFreezeAnchorDirective } from './directives/can-freeze-anchor';
import { LightSelectorComponent } from './light-selector/light-selector.component';
import { LangListComponent } from './lang-list/lang-list.component';
import { SpDetailTooltipComponent } from './sp-detail-tooltip/sp-detail-tooltip.component';
import { LangCountryComponent } from './lang-country/lang-country.component';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import { LangCountryMobileComponent } from './lang-country-mobile/lang-country-mobile.component';
import { LoggingInterceptor } from './common/Logging-interceptor';
import { TempCloseComponent } from './temp-close/temp-close.component';
import { RedirectToActiveComponent } from './redirect-to-active/redirect-to-active.component';
//import { AgmMarkerClustererModule } from '@agm/markerclusterer';
import { AlertMessageComponent } from './alert-message/alert-message.component';
import { LoggerService } from './logger/logger.service';
import { CookieMapConsentComponent } from './cookie-map-consent/cookie-map-consent.component';
import { CommonModule } from '@angular/common';

declare const System: any;

let nonce = (document.querySelector('meta[name="CSP_NONCE"]') as HTMLMetaElement)?.content;
//console.log("nonce = ",nonce);
@NgModule({
    declarations: [
        AppComponent,
        SearchPageComponent,
        ResultsPageComponent,
        FooterComponent,
        HeaderComponent,
        BasicSearchControlsComponent,
        AdvSearchCriteriaComponent,
        SpMapComponent,
        ResultListComponent,
        ResultItemComponent,
        CountrySelectComponent,
        AddressInputComponent,
        AdvSearchHintComponent,
        ErrorComponent,
        CapabilitySelectComponent,
        AdvSearchPageComponent,
        LangSelectorComponent,
        CapabilityDetailComponent,
        BingMapComponent,
        OnlyNumberDirective,
        PrintPageComponent,
        DirectionsPageComponent,
        SpMapLightComponent,
        CookieConsentComponent,
        AdvSearchTipComponent,
        LegacyURLComponent,
        NotFoundComponent,
        CountryPromotionComponent,
        SpPromotionComponent,
        CanFreezeDirective,
        CanHideDirective,
        CssSwitchDirective,
        CanFreezeElementDirective,
        CanFreezeReadonlyDirective,
        CanFreezeAnchorDirective,
        GoogleMapComponent,
        SpDetail2Component,
        UnsupportedBrowsersComponent,
        FloatNumberDirectiveDirective,
        NavigationModalComponent,
        DigitOnlyDirective,
        LightSelectorComponent,
        LangListComponent,
        SpDetailTooltipComponent,
        LangCountryComponent,
        MobileMenuComponent,
        LangCountryMobileComponent,
        TempCloseComponent,
        RedirectToActiveComponent,
        AlertMessageComponent,
        CookieMapConsentComponent
        //,TranslatePipe
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgbModule,
        NgSelectModule,
        HttpClientModule,
        MatAutocompleteModule,
        MatInputModule,
        MatCheckboxModule,
        MatIconModule,
        MatRadioModule,
        MatExpansionModule,
        MatDialogModule,
        MatSnackBarModule,
        NoopAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        //AgmCoreModule.forRoot(),
        //AgmMarkerClustererModule,
        //DeviceDetectorModule.forRoot()
        
    ],
    exports : [DigitOnlyDirective],
    providers: [
        CookieService,
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        LoggerService,
        //GoogleMapsAPIWrapper,
        LoggingInterceptor,
        { provide: APP_INITIALIZER, useFactory: initializeAppService, deps: [ SettingsService ], multi: true },
        { provide: UrlSerializer, useClass: CustomUrlSerializer },
        { provide: HTTP_INTERCEPTORS, useClass: NoCacheHeadersInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true },
        {provide: CSP_NONCE, useValue: nonce}
    ],
    bootstrap: [AppComponent]/*,
    entryComponents: [AdvSearchTipComponent, AdvSearchHintComponent, NavigationModalComponent]*/
})
export class AppModule {
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
    return new GsplHttpLoader(httpClient, BackendService.restV3PathFragment + '/translations?locale=');
}

export class GsplHttpLoader extends TranslateHttpLoader {

    english = null;

    constructor(http: HttpClient, prefix?: string) {
        super(http, prefix, '');
    }

    getTranslation(lang: string): Observable<any> {

        try {
            const remote = super.getTranslation(lang);
            const remapped = remote.toPromise().then((raw: any) => {
                if (raw && raw['translationList']) {
                    //const translations = javaMapToObject(raw['translationList']);
                    const translations = raw['translationList'];
                    return translations;
                }
            });

            return from(remapped);
        } catch {
            console.error('Failed to fetch translations', lang);
        }
        return from(System.import(`../assets/i18n/en-GB.json`));
    }
}

export function initializeAppService(settings: SettingsService) {
    return () => new Promise<void>((resolve, reject) => {
        (window as any).gspl = { params: { isLabelFree: true } };
        settings.initialize().then(() => resolve())
            .catch(() => resolve()); // settings.isError === true
    });
}
