import {AfterViewInit, ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import { Utils } from '../common/utils';
import { SettingsService } from '../settings.service';

@Component({
    selector: 'app-search-page',
    templateUrl: './search-page.component.html',
    styleUrls: ['./search-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchPageComponent implements OnInit, AfterViewInit {

    constructor(public settings:SettingsService) {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        window.document.body.style.backgroundColor = 'white';
    }


    isJsApi(){
        return Utils.isJsApi();
    }
}
