import {Component, OnInit, NgZone, AfterViewInit, OnDestroy, Optional, Renderer2, Inject} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BingMapsLoader} from '../bing-maps-loader';
import {PrintData} from '../common/PrintData';
import {Utils} from '../common/utils';
import { EncodeDecode } from '../common/encode-decode';
import { SettingsService } from '../settings.service';
import { JsApiComponentBase } from '../common/jsapi-component-base';
import { BackendService } from '../backend.service';
import { TranslateService } from '@ngx-translate/core';
import { GoogleDirectionMapsLoader } from '../google-direction-maps-loader';
import { ConsentService } from '../consent.service.ts.service';
import { Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';

declare const Microsoft: any;
declare const window: any;
declare const OneTrust;

@Component({
    selector: 'app-print-page',
    templateUrl: './print-page.component.html',
    styleUrls: ['./print-page.component.scss']
})
export class PrintPageComponent extends JsApiComponentBase implements OnInit, AfterViewInit,OnDestroy {

  data = new PrintData();
  map: any;
  directionsManager: any;
  directionsErrorEventObj: any;
  directionsUpdatedEventObj: any;
  isJsApi = Utils.isJsApi;
  firstLoad: boolean = true;
  isMapConsentGiven: boolean = false;
  private subscriptions: Array<Subscription> = [];
  
  constructor(private route: ActivatedRoute, @Optional() public settings: SettingsService, 
  public ngZone: NgZone, private backend:BackendService, private translateService:TranslateService, 
  private renderer: Renderer2, private consentService: ConsentService, @Inject(DOCUMENT) private document: Document) {
    super();
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnInit() {
    this.isMapConsentGiven = this.settings.selectedCountry.mapType.toLowerCase() == 'google' ? this.settings.checkShowMapConsent('google') : this.settings.checkShowMapConsent('bing');
    if (this._isOpen) {
      this.initialize();
    }
  }


  onOpen() {
    this.initialize();
  }

  private initialize() {
    /*if (this.settings.isBannerSuppresedClient(this.route.snapshot.queryParams)) {
        this.renderer.addClass(document.body, 'hide-onetrust-elements');
    }*/
    if (Utils.isJsApi()) {
        this.loadScript(this.settings.appConfig.cookiesDataDomainUrl).then(() => {
            // Do something after the script is loaded
        }).catch(error => {
            console.error('Failed to load script:', error);
        });
    }
    if (Utils.isJsApi()) {
      if (window.gspl.params.printEncodeData) {
        if (window.gspl.printData) {
          this.data = JSON.parse(EncodeDecode.decode(window.gspl.printData));
        }
      } else {
        if (this.settings.printData) {
          this.data = this.settings.printData;
        }
      }
    } else {
      this.data = JSON.parse(EncodeDecode.decode(this.route.snapshot.queryParams.data));
    }

    //var newLang = this.data && this.data.lang ? this.data.lang : 'en';
    this.document.documentElement.lang = this.data.otCode;
    try {
      OneTrust.changeLanguage(this.data.otCode);
    } catch (Error) {
      //console.log("error in catch", Error);
    }//cookie settings changes
    
    this.settings.isParentPageDirections = true;
    this.translateService.use(this.data.langTrans?this.data.langTrans:'en-GB$Latn');

    setTimeout(() => {
      this.isMapConsentGiven = this.settings.checkShowMapConsent(this.data.mapType);
      if(this.isMapConsentGiven){
        this.mapLoader();
      }
    }, 500);

    /*setTimeout(() => {
      try{
        if (OneTrust) {
          OneTrust.OnConsentChanged(() => { 
            this.isMapConsentGiven = this.settings.checkShowMapConsent(this.data.mapType);
            if(this.isMapConsentGiven){
               this.mapLoader();
            }
          });
        }
      } catch(error){
        console.log(error);
      }
    }, 200);*/

  }

  mapLoader() {
    if (!this.data.mapType || this.data.mapType.toLowerCase() === 'bing') {
      BingMapsLoader.load(this.data.lang, this.data.langCtry, this.settings.appConfig.bingLoadUrl).then(result => {
        if (typeof Microsoft !== 'undefined') {
          setTimeout(() => {
            this.loadMap(); 
          }, 100);
        }
      }
      );
    } else {
      this.loadGoogleDirectionsMap();
    }
  }

  loadGoogleDirectionsMap() {
    var googleMapKey: string = '';
    if (Utils.isJsApi() && !this.settings.appConfig.jsapiv3Usegsplwsbkey) {
      googleMapKey = this.backend.googleKey;;
    } else {
      googleMapKey = this.settings.appConfig.googleMapsKey;
    }//573005
    GoogleDirectionMapsLoader.loadgoogleDirectionsScript(this.data.lang, this.data.langCtry, this.settings.appConfig.googleDirectionMapLoadUrl, googleMapKey).then(() => {
      setTimeout(() => {
        this.initMap();
      }, 100);

    });
  }

  loadMap() {
    const centerLocation = new Microsoft.Maps.Location(this.data.location.latitude, this.data.location.longitude);
    this.map = new Microsoft.Maps.Map(document.getElementById('map-direction'), {
      credentials: (Utils.isJsApi() && !this.settings.appConfig.jsapiv3Usegsplwsbkey) ? this.backend.bingKey : this.settings.appConfig.bingMapsKey,
      navigationBarMode: Microsoft.Maps.NavigationBarMode.compact
    });

    if (!this.directionsManager) {
      Microsoft.Maps.loadModule('Microsoft.Maps.Directions', { callback: this.createDrivingRoute.bind(this) });
    } else {
      this.createDrivingRoute();
    }


  }

  createDirectionsManager() {
    if (!this.directionsManager) {
      this.directionsManager = new Microsoft.Maps.Directions.DirectionsManager(this.map);
    }
    this.directionsManager.clearAll();
    this.directionsErrorEventObj = Microsoft.Maps.Events.addHandler(this.directionsManager, 'directionsError', (arg) => {
      //                alert(arg.message)
    });
    this.directionsUpdatedEventObj = Microsoft.Maps.Events.addHandler(this.directionsManager, 'directionsUpdated', () => {
      //                alert('Directions updated')
    });
  }

  createDrivingRoute() {
    if (!this.directionsManager) {
      this.createDirectionsManager();
    }
    this.directionsManager.clearAll();


    const unitOfLength = 'km'; // TODO pass this from main page
    let distanceUnit;
    if (unitOfLength === 'km') {
      distanceUnit = Microsoft.Maps.Directions.DistanceUnit.kilometers;
    } else {
      distanceUnit = Microsoft.Maps.Directions.DistanceUnit.miles;
    }


    // Set Route Mode to driving
    this.directionsManager.setRequestOptions({
      routeMode: Microsoft.Maps.Directions.RouteMode.driving,
      distanceUnit
    });
    var startWaypoint = new Microsoft.Maps.Directions.Waypoint({
      location: new Microsoft.Maps.Location(this.data.location.latitude - 0.0000001, this.data.location.longitude - 0.0000001)
    });
    if (this.data.searchLocation  && this.data.searchLocation.suggestion) { // RTC - 555425
      startWaypoint = new Microsoft.Maps.Directions.Waypoint({
        location: new Microsoft.Maps.Location(this.data.searchLocation.suggestion.latitude, this.data.searchLocation.suggestion.longitude)
      });
    }

    this.directionsManager.addWaypoint(startWaypoint);

    const endWaypoint = new Microsoft.Maps.Directions.Waypoint({
      location: new Microsoft.Maps.Location(this.data.location.latitude, this.data.location.longitude)
    });

    this.directionsManager.addWaypoint(endWaypoint);
    // Set the element in which the itinerary will be rendered
    this.directionsManager.setRenderOptions({ itineraryContainer: document.getElementById('map-itinerary') });
    this.directionsManager.calculateDirections();
    Microsoft.Maps.Events.addOne(this.map, 'viewrendered', () => {
        setTimeout(()=>{
          this.print();
        },2000)
    });
  }

  print() {
    window.print();
  }

  back() {
    if (!Utils.isJsApi()) {
      //this.location.back();
      window.close();
    }
  }

   initMap() {
    const directionsRenderer = new google.maps.DirectionsRenderer();
    const directionsService = new google.maps.DirectionsService();
    const map = new google.maps.Map(
      document.getElementById("map-direction") as HTMLElement,
      {
        zoom: 7,
        center: { lat: 41.85, lng: -87.65 },
        disableDefaultUI: true,
      }
    );

    directionsRenderer.setMap(map);
    
    directionsRenderer.setPanel(
      document.getElementById("map-itinerary") as HTMLElement
    );
    this.calculateAndDisplayRoute(directionsService, directionsRenderer);
    google.maps.event.addListenerOnce(map, 'tilesloaded', function(){
      window.print();
    });
  }

  calculateAndDisplayRoute( directionsService: google.maps.DirectionsService, directionsRenderer: google.maps.DirectionsRenderer ) {
    var start = { lat: this.data.location.latitude - 0.0000001, lng: this.data.location.longitude - 0.0000001 };
    const end = { lat: this.data.location.latitude, lng: this.data.location.longitude };
    if (this.data.searchLocation && this.data.searchLocation.suggestion) {
      start = { lat: this.data.searchLocation.suggestion.latitude, lng: this.data.searchLocation.suggestion.longitude };
    }
    var unitSystem: any = google.maps.UnitSystem.METRIC;
    if (this.data && this.data.uom && this.data.uom === 'mi') {
      unitSystem = google.maps.UnitSystem.IMPERIAL
    }
    directionsService
      .route({
        origin: start,
        destination: end,
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: unitSystem
      }, function (result, status) {
        directionsRenderer.setDirections(result);
      })

      
  }

  ngAfterViewInit() {
    if (Utils.isJsApi() && window.gspl.printAfterViewInit) {
      window.gspl.printAfterViewInit();
    } 
    this.consentService.initialize();
    this.subscriptions.push(this.consentService.consentChange$.subscribe(() => {
      this.isMapConsentGiven = this.settings.checkShowMapConsent(this.data.mapType);
      if (this.isMapConsentGiven) {
        if (this.data.mapType == 'BING') {
          this.directionsManager = undefined;
        }
        this.mapLoader();
      }
    }));
  }
}
