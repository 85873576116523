import { Component, OnInit, HostListener, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { BackendService, ServicePoint, Promotion } from '../backend.service';
import { SettingsService } from '../settings.service';
import { Utils } from '../common/utils';
import { ActivatedRoute } from '@angular/router';

declare const window: any;
declare const $: any;

@Component({
    selector: 'app-sp-promotion',
    templateUrl: './sp-promotion.component.html',
    styleUrls: ['./sp-promotion.component.scss']
})
export class SpPromotionComponent implements OnInit {

    @Input() set servicePoint(servicePoint: ServicePoint) {
        if (servicePoint.promotion) {
            this.promotion = servicePoint.promotion;
        } else if (servicePoint.partner && servicePoint.partner.promotion) {
            this.promotion = servicePoint.partner.promotion;
        } else {
            this.promotion = null;
        }
        
        if (servicePoint.svpStatus && servicePoint.svpStatus == 'A') {
            this.displayStatus = true;
        } else {
            this.displayStatus = false;
        }
    }

    promotion: Promotion;
    displayStatus: boolean = false;

    @Output()
    promotionClosed = new EventEmitter<string>();

    @ViewChild('spromotionSection', {static:true, read: ElementRef}) spromotionSection: ElementRef;

    constructor(private backendService: BackendService, public settings: SettingsService, private utils: Utils, private route: ActivatedRoute) {
        if (!window.gsplInternal) {
            window.gsplInternal = {};
        }
        if (!window.gsplInternal.openExternalLinks) {
            window.gsplInternal.openExternalLinks = (url) => {
                this.utils.openExternalLinks(url, this.route);
            };
        }
    }

    handlePromotion(){
        let event;
        if(typeof(Event) === 'function') {
            event = new Event('close-promotion');
        }else{
            event = document.createEvent('Event');
            event.initEvent('close-promotion', true, true);
        }
        window.dispatchEvent(event);
        $('.svpPromotion').hide();
    }

    openPromotion(){
        window.gsplInternal.openExternalLinks(this.promotion.hyperlink);
    }

    ngOnInit() {
    }

    @HostListener('window:close-promotion', ['$event'])
    closePromotion(event: any) {
        //RTC 623764 
        /*this.backendService.closePromotion('svp').then(closeAtSession => {
            this.settings.filteredSearchResult.servicePoints.forEach(servicePoint => {*/
                /*if (servicePoint.partner && servicePoint.partner.promotion) {
                    servicePoint.partner.promotion.isClose = true;
                }
                if (servicePoint.promotion) {
                    servicePoint.promotion.isClose = true;
                }*/
           /* });
            this.settings.notifySvpPromotionClosed();
            this.promotionClosed.emit();
        });*/
        if(this.promotion){
            this.promotion.isClose = true;
        }
        this.settings.notifySvpPromotionClosed();
        this.promotionClosed.emit();
    }
}
