import { Component, OnInit, Input,EventEmitter, Output } from '@angular/core';
import { SettingsService } from '../settings.service';
import {CountryInfo} from '../model/CountryInfo';

@Component({
  selector: 'app-lang-country',
  templateUrl: './lang-country.component.html',
  styleUrls: ['./lang-country.component.scss']
})
export class LangCountryComponent implements OnInit {

  @Input() lang;
  @Input() expandUp = false;
  //@Input() toggleLanguage;
  showCountryList = false;
  countryList: Array<CountryInfo>;


  @Output() onLangOrCountrySelected = new EventEmitter<Boolean>();

  constructor(public settings: SettingsService) { }

  ngOnInit() {
  }

  onLangSelect() {
    this.showCountryList = false;
    this.settings.language = this.lang;
    this.settings.toReloadResultsPage = true;
    this.onLangOrCountrySelected.emit(true);
  }

  showSelectedCountryList(){
    this.countryList = this.lang.countryInfos;
    this.showCountryList = !this.showCountryList
  }

  onCountrySelect(countryInfo: CountryInfo) {
    this.showCountryList = false;
    this.settings.language = this.lang;
    this.settings.languageCountry = countryInfo;
    this.settings.toReloadResultsPage = true;
    this.onLangOrCountrySelected.emit(true);
  }

  modalBackdropClick() {
    this.showCountryList = false;
  }

}
