<div *ngIf="_isOpen" [dir]="dir" [ngClass]="textAlignRtl" class="col h-100 map-container">
    <!-- servicePoints.servicePoints && servicePoints.servicePoints.length!=0) is added below for RTC 566028-->
    <app-country-promotion *ngIf="!mobile && (servicePoints && servicePoints.servicePoints && servicePoints.servicePoints.length!=0)" 
        #countryPromotion class="row" 
        [mobile]="false" 
        (heightChange)="promotionHeightChanged($event)"
        (promotionClosed)="promotionClosed()">
    </app-country-promotion>
    <app-alert-message class="row" #alertMessage  (heightChange)="promotionHeightChanged($event)" (promotionClosed)="promotionClosed()"></app-alert-message>
    <div #mapDiv class="row map-item">
        <app-google-map [ngStyle]="{'height': '100%'}" *ngIf="mapType === 'Google'" 
            class="col px-0" 
            [servicePoints]="servicePoints" 
            [selectedServicePoint]="selectedServicePoint" 
            [mobile]="mobile"
            (servicePointSelected)="setSelectedServicePoint($event)"
            [show_share_svp_detail]="showShareButtonSvpDetail"
            (mapMoveOrZoomEvent)="mapMoveOrZoom($event)"
            (onDirections)="onDirections.emit($event)"
            (onPrint)="onPrint.emit($event)"
            (onWebLink)="onWebLink.emit($event)"
            (onFindActiveServicePoints)="onFindActiveServicePoints.emit($event)">
        </app-google-map>
        <!--app-google-map style="height: 100%;" style="width: 100%;"></app-google-map-->
        <app-bing-map [ngStyle]="{'height': '100%'}" *ngIf="mapType === 'Bing'" 
            class="col px-0" 
            [servicePoints]="servicePoints" 
            [selectedServicePoint]="selectedServicePoint" 
            [mobile]="mobile" 
            (servicePointSelected)="setSelectedServicePoint($event)"
            [show_share_svp_detail]="showShareButtonSvpDetail" 
            (mapMoveOrZoomEvent)="mapMoveOrZoom($event)"
            (onDirections)="onDirections.emit($event)"
            (onPrint)="onPrint.emit($event)"
            (onWebLink)="onWebLink.emit($event)">
        </app-bing-map>
    </div>
    <div class="loading-wrap" *ngIf="loading && mobile">
        <div class="ng-spinner-loader loading"></div>
    </div>
    <div *ngIf="isJsApi() && !settings.isOneTrustLoaded()" class="row d-none d-md-flex" [ngStyle]="{'height': '30px'}">
        
    </div>
    
</div>
<div *ngIf="!isJsApi() && settings.mobileHiding" class="col-12 d-md-flex" [ngStyle]="{'height': '30px', 'text-decoration-line': 'underline', 'background-color': '#EBEBEB'}">
    <div class="col" >
        <a id="ot-sdk-btn" [ngClass]="'mt-2 font-weight-bold float-right'" [ngStyle]="{'margin-right': '-20px'}" class="ot-sdk-show-settings">Consent Settings</a>
    </div>
</div>

