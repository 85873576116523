import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SearchPageComponent} from './search-page/search-page.component';
import {ResultsPageComponent} from './results-page/results-page.component';
import {AdvSearchPageComponent} from './adv-search-page/adv-search-page.component';
import {SearchResolver} from './search-resolver';
import {PrintPageComponent} from './print-page/print-page.component';
import {DirectionsPageComponent} from './directions-page/directions-page.component';
import {LegacyURLComponent} from './legacy-url/legacy-url.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {ErrorComponent} from './error/error.component';
import { AppResolver } from './app-resolver';

const routes: Routes = [
    {path: '', component: SearchPageComponent, resolve: {results: AppResolver}},
    {path: 'results', component: ResultsPageComponent, resolve: {results: SearchResolver}, runGuardsAndResolvers: 'always'},
    {path: 'advanced-search', component: AdvSearchPageComponent, resolve: {results: AppResolver}},
    {path: 'print', component: PrintPageComponent, resolve: {results: AppResolver}},
    {path: 'directions', component: DirectionsPageComponent, resolve: {results: AppResolver}},
    {path: 'ServicePointLocator', component: LegacyURLComponent, resolve: {results: AppResolver}},
    {path: 'ServicePointLocator/index.jsp', component: LegacyURLComponent, resolve: {results: AppResolver}},
    {path: 'error', component: ErrorComponent},
    {path: 'not-found', component: NotFoundComponent},
    {path: '**', redirectTo: 'not-found'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
