<div class="customized">
    <mat-form-field [ngStyle]="!(isBasic || isAdvanced) ? {'height': '36px'} : {'height': '52px'}" *canHide="'yourLocation'" [floatLabel]="(isBasic || isAdvanced)?'':'never'">
        <mat-label [ngStyle]="{'text-align': settingService?.isRTL() ? 'right' : 'left', 'padding-right': settingService?.isRTL() ? (isBasic ? '18px' : '5px') : '0px'}" *ngIf="isBasic || isAdvanced" [ngClass]="{'address-present': isFloating, 'address-empty': !isFloating}"> {{ isDirection ? ('spl.search.directions.address_default' | translate) : ('spl.search.input_hint' | translate) }}</mat-label>
        <input aria-label="Enter the address or Zipcode or ServicePointId prefixed by ID:" *canHide="'yourLocation'" canFreeze="yourLocation" id="addressInput"
               type="text" [ngStyle]="{'text-align': settingService?.isRTL() ? 'right' : 'left'}"
               matInput (focus)="onFocus()" (blur)="onBlur()"
               (keydown.enter)="enterPressed($event)"
               [formControl]="address"
               [matAutocomplete]="auto"
               aria-controls="addressInput"
               title="{{address.value}}" [placeholder]="(isBasic || isAdvanced)? '' : ('spl.search.input_hint' | translate)">
        <div *ngIf="loading" class="ng-spinner-loader loading"></div>
        <span *ngIf="!isBasic && isValueSelected && !abbreviated" [ngStyle]="{'float': 'right','width': '20px','position': 'relative','padding-top': (isAdvanced || isDirection) ? '5px' : (settingService?.isRTL() ? '8px' : '8px'), 'padding-right' : (isAdvanced || isDirection) && settingService?.isRTL() ? '12px' : '0px'}">
            <img alt="Address selected" [src]="settingService.backendUrl + '/assets/blank.gif'" [ngStyle]="{'width': '20px', 'margin-top': '-5px', 'background-image': 'url('+settingService.backendUrl+'/assets/green-checked.svg)'}" [ngClass]="'greenchecklarge'+(isJSApi()?' top-margin-4':'')">
        </span>
        <span *ngIf="!isBasic && !isValueSelected && !abbreviated" [ngStyle]="{'float':'right','width':'20px','height':'17px','padding-top': (isAdvanced || isDirection) ? '5.5px' : '4.5px'}" matSuffix>
            <img alt="Address not selected" [src]="settingService.backendUrl + '/assets/blank.gif'" [ngStyle]="{'width': '15px', 'height': '11px', 'vertical-align': 'top', 'background': 'url(' + settingService.backendUrl + '/assets/grey-flag.svg) no-repeat'}">
        </span>
        <span *ngIf="!isBasic && isValueSelected && abbreviated" [ngStyle]="{'float': 'right','width': '20px','position': 'relative','padding-top': (isAdvanced || isDirection) ? '5px' : (settingService?.isRTL() ? '8px' : '8px'), 'padding-right' : (isAdvanced || isDirection) && settingService?.isRTL() ? '12px' : '0px'}">
            <img alt="Address selected" [src]="settingService.backendUrl + '/assets/blank.gif'" [ngStyle]="{'width': '20px', 'margin-top': '2px', 'background-image': 'url('+settingService.backendUrl+'/assets/green-checked.svg)'}" [ngClass]="'greenchecklarge'+(isJSApi()?' top-margin-2':'')">
        </span>
        <span *ngIf="!isBasic && !isValueSelected && abbreviated" [ngStyle]="{'float':'right','width':'20px','height':'17px','padding-top': (isAdvanced || isDirection) ? '5.5px' : '4.5px'}" matSuffix>
            <img alt="Address not selected" [src]="settingService.backendUrl + '/assets/blank.gif'" [ngStyle]="{'width': '15px', 'height': '11px', 'vertical-align': 'text-bottom', 'background': 'url(' + settingService.backendUrl + '/assets/grey-flag.svg) no-repeat'}" >
        </span>
        <!--!isClearPerformed?'clear-input':'clear-input close-x-button'-->
        <button [canFreezeElement]="'yourLocation'" aria-label="clear place or zip code" mat-button [ngClass]="'clear-input'" *ngIf="containsValue()" matSuffix mat-icon-button
                (click)="clearInput()"> 
                <mat-icon class="mat-icon-font-feat">close</mat-icon>
        </button>
        <mat-autocomplete [class]="customAutocomplete?'custom-autocomplete':''" #auto="matAutocomplete" autoActiveFirstOption
                (optionSelected)="addressSelected($event.option.value)">
            <mat-option *ngFor="let item of addresses" [value]="item.label">
                {{item.label}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</div>
