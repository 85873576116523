<a [attr.aria-label]="lang.languageEnglish" data-test_id="link_lang_select" (click)="showSelectedCountryList()">{{lang.languageNative}}</a>
<span data-test_id="link_lang_country_dropdown1" (click)="showSelectedCountryList()" [class]="showCountryList ? 'up' : ''" >
    <span data-test_id="link_lang_country_dropdown2" class="caret caret-right" [ngStyle]="{background: 'url(' + settings.backendUrl + '/assets/sprite2.png) no-repeat 0px -22px'}"></span>
</span>
<ul [ngClass]="'dropdown-menu show dropdown-country '+(expandUp?'upper':'under')" id="dropdown-country"
    aria-labelledby="countryLabel" *ngIf="showCountryList">
    <li *ngFor="let country of countryList">
        <a tabindex="0" data-test_id="link_lang_country_select" [ngStyle]="{'cursor': 'pointer'}" (click)="onCountrySelect(country)">{{country.countryName}}</a>
    </li>
    
</ul>
<div class="modal-backdrop" *ngIf="showCountryList" (click)="modalBackdropClick()"></div>
