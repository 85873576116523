import {Component, EventEmitter, HostListener, Input, NgZone, OnDestroy, OnInit, Optional, Output, AfterViewInit, Inject} from '@angular/core';
import {BackendService, SearchServicePointsResult, ServicePoint} from '../backend.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Utils} from '../common/utils';
import {SettingsService} from '../settings.service';
import {debounceTime} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { ConsentService } from '../consent.service.ts.service';
import { ElementRef, Renderer2 } from '@angular/core';

declare const Microsoft: any;
declare const window: any;
declare const $: any;
declare const OneTrust: any;
const lockerType = 'TWENTYFOURSEVEN';
const nonce = (document.querySelector('meta[name="CSP_NONCE"]') as HTMLMetaElement)?.content;

@Component({
    selector: 'app-bing-map',
    templateUrl: './bing-map.component.html',
    styleUrls: ['./bing-map.component.scss']
})
export class BingMapComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() mobile: boolean;
    @Input() lightVersion = false;

    map: any;
    pinSelected: any;
    arrayLocations = [];
    arrayPushPins = [];
    clusterLayer: any;
    selectedInfobox: any;
    firstData = false;
    repositioned = false;
    originPoint = null;
    zoomOnlyTriggered = false;
    pinLayer: any;
    firstMapZoomChange = true;
    isOnInit = false;
    private isMapZoomed: boolean = false;
    zoomValue: number = 0;

    private iconPoint: string;
    private iconLocker: string;
    private iconPointSel: string;
    private iconLockerSel: string;
    private iconClusterImage: string;
    private iconLocation: string;
    private _moveEvents = new EventEmitter<any>();
    private hoverServicePoint: ServicePoint;
    private hoverInfobox: any;
    private subscriptions: Array<Subscription> = [];
    private isScriptLoaded: boolean = false;
    private filterSelected: boolean = false;
    private isMapMoved: boolean = false;

    @Output() servicePointSelected = new EventEmitter<ServicePoint>();
    @Output() mapMoveOrZoomEvent = new EventEmitter<any>();

    constructor(@Optional() private router: Router, @Optional() private route: ActivatedRoute, @Optional() private ngZone: NgZone,
                @Optional() public settings: SettingsService, 
                public backend:BackendService, @Inject(DOCUMENT) private document: Document, private consentService: ConsentService,
                private elementRef: ElementRef, private renderer: Renderer2) {
        this.iconPoint = this.settings.backendUrl + Utils.getIconForPins('marker_not_selected');
        this.iconLocker = this.settings.backendUrl + Utils.getIconForPins('locker_marker_not_selected');
        this.iconPointSel = this.settings.backendUrl + Utils.getIconForPins('marker_selected');
        this.iconLockerSel = this.settings.backendUrl + Utils.getIconForPins('locker_marker_selected');
        this.iconClusterImage = this.settings.backendUrl + Utils.getIconForPins('cluster_marker');
        this.iconLocation = this.settings.backendUrl + Utils.getIconForPins('icon_location');
    }

    _servicePoints: SearchServicePointsResult;
    @Input()
    set servicePoints(servicePoints: SearchServicePointsResult) {
        let addrChanged = false;
        if (servicePoints && servicePoints.servicePoints && this._servicePoints) {
            addrChanged = this._servicePoints.searchAddress !== servicePoints.searchAddress ||
                (!this._servicePoints.searchAddress && this._servicePoints.servicePoints &&
                    this._servicePoints.servicePoints[0].facilityId !== servicePoints.servicePoints[0].facilityId);
            if (addrChanged) {
                this.originPoint = servicePoints.searchAddress;
            }
        }
        
        this._servicePoints = servicePoints;
        if (this.isOnInit) {
            setTimeout(() => { 
                this.resolveServicePoints(addrChanged);
            }, 100);
            if (!this.mobile && this._servicePoints.isShowInfoBox) {
                this.showInfoBox();
            }
        }
    }

    private resolveServicePoints(addrChanged: boolean) {
        if (this._servicePoints && this._servicePoints.mapType !== 'Bing') {
            return;
        }

        if (addrChanged && !this._servicePoints.isShowInfoBox) {
            this.closeInfoBox();
        }

        if (this.firstData) {
            if (this.clusterLayer) {
                this.arrayLocations = [];
                this.arrayPushPins = [];
                if ((addrChanged && this._servicePoints.searchLocation) || (this._servicePoints.searchLocation && this.isMapZoomed)) {//2nd condition is for GSPL-201
                    this.map.entities.clear();
                    const currentLocation = this.makeLocation(this._servicePoints.searchLocation.latitude,
                        this._servicePoints.searchLocation.longitude);
                    this.markCenterLocation(currentLocation);
                }else{
                    this.map.entities.clear();
                    const currentLocation = this.makeLocation(this.map.getCenter().latitude, this.map.getCenter().longitude);
                    if(!this._servicePoints.servicePoints || (this._servicePoints.servicePoints && this._servicePoints.servicePoints.length > 1)){
                        this.markCenterLocation(currentLocation);
                    }
                    if(this.settings.isMapMovedToSea){//GSPL-196
                        this._servicePoints.searchAddress = null;
                    }
                }

                //let recenter = addrChanged || Utils.isJsApi() || this._servicePoints.plainAddress;
                let recenter = false;

                if (this.zoomOnlyTriggered) {
                    recenter = false;
                    this.zoomOnlyTriggered = false;
                }

                if (this._servicePoints.plainAddress && !this.isMapZoomed) {//2nd condition is for GSPL-208
                    this.repositioned = true;
                }

                if (addrChanged){
                    recenter = true;
                }

                if(this.isMapMoved){
                    recenter = false;
                }

                this.loadServicePoints(recenter);

                this.clusterLayer.clear();
                this.clusterLayer.setPushpins(this.arrayPushPins);
            }
        } else {
            if (this._servicePoints && this._servicePoints.searchAddress) {
                this.originPoint = this._servicePoints.searchAddress;
            }
            this.firstData = true;
        }
    }

    _selectedServicePoint: ServicePoint;
    get selectedServicePoint() { return this._selectedServicePoint }
    @Input() set selectedServicePoint(selectedServicePoint: ServicePoint) {
        if (selectedServicePoint && selectedServicePoint.selectType === Utils.LIST_SELECT_TYPE) {
            this.showSetSelectedServicePoint(selectedServicePoint);
        }
    }

    private showSetSelectedServicePoint(selectedServicePoint: ServicePoint) {
        if (selectedServicePoint && this.map) {
            const selectedPin = this.arrayPushPins.find(pin => pin.catId === selectedServicePoint.facilityId);
            this.setDataintoInfoPointAndDisplay(selectedPin, selectedServicePoint, true);
        }
        this._selectedServicePoint = selectedServicePoint;
    }

    private loadMap() {
        let bingKey: string = (Utils.isJsApi() && !this.settings.appConfig.jsapiv3Usegsplwsbkey) ? this.backend.bingKey : this.settings.appConfig.bingMapsKey;
        /*const parentDiv = document.getElementById('map');
        const newElement = document.createElement('div');
        if (this._servicePoints && this._selectedServicePoint) {
            newElement.classList.add('col', 'px-0');
        } else {
            newElement.classList.add('col-12', 'px-0');
        }
        newElement.id = "map-canvas";
        parentDiv.appendChild(newElement);*/
        this.map = new Microsoft.Maps.Map(document.getElementById('map-canvas'), {
            credentials: bingKey,
            navigationBarMode: Microsoft.Maps.NavigationBarMode.compact,
            disableStreetside: true //3.1 - BR5
        });
    }



    private clearActivePins() {
        if (this.pinLayer && this.pinLayer.getPrimitives() != null) {
            this.pinLayer.getPrimitives().forEach(entity => {
                if (entity && entity.getIcon().includes('selected')) { // If there's active pin change its icon
                    entity.setOptions({icon: entity.getIcon().includes('locker') ? this.iconLocker : this.iconPoint, width: 53, height: 61});
                }
            });
        }
    }

    closeInfoBox() {
        this.settings.isSpDetail2Active = false;
        this.ngZone.run(() => {
            if (!this.selectedInfobox) {
                return;
            }
            this.selectedInfobox.setOptions({
                visible: false,
                width: 53,
                height: 61
            });
            this.pinSelected = null;
            if (this._selectedServicePoint) {
                this._selectedServicePoint.isSelected = false;
                this._selectedServicePoint = null;
            }
            if(!this.mobile){
                this.servicePointSelected.emit(null);
                this.clearActivePins();
            }
        });
        if (!this.settings.checkShowMapConsent('bing')) {
            this._selectedServicePoint = null;//to close the sp-detail, because pin is not avilable//GSPL-220
            this.servicePointSelected.emit(null);
        }
    }

    /*private addContentToInfoBox(id: string) {
        $('#infoBoxPlaceholder').html($('#' + id).html());

        const elem2 = window.document.querySelector('#icon-label' + id);
        Utils.shrinkText(elem2, 10, 42);

        const elemBreak = window.document.querySelector('#pay-with' + id);
        Utils.determineWordBreaking(elemBreak);

        const detailTab =  window.document.querySelector("div.tab-detail");
        Utils.makeVisible(detailTab);
        
        const holidayTab = window.document.querySelector("div.tab-holidays");
        Utils.makeNotVisible(holidayTab);
    }*/

    private setDataintoInfoPointAndDisplay(pin: any, servicePoint: ServicePoint, calledFromLeftPanel: boolean) {
        this.clearActivePins();

        this.pinSelected = servicePoint.facilityId;

        if (!pin) {
            const pinLocation = this.makeLocationFromSP(servicePoint);
            pin = this.makePin(servicePoint, pinLocation);
            this.arrayPushPins.push(pin);
            this.arrayLocations.push(pinLocation);
            this.addClickHandler(pin, servicePoint);
        }

        pin.setOptions({width: 64, height: 75, icon: servicePoint.servicePointType === lockerType ? this.iconLockerSel : this.iconPointSel});

        //BR11 - start
        /*if (!this.mobile && this.selectedInfobox) {
            this.selectedInfobox.setOptions({
                location: pin.getLocation(),
                visible: true,
                width: 360,
                height: 500,
                offset: new Microsoft.Maps.Point(-180, 565),
                htmlContent: '<div id="infoBoxPlaceholder"></div>'
            });
            this.addContentToInfoBox(servicePoint.facilityId);
        }*/
        //BR11 - end

        // Entire below block is commented for BR11
        // Do adjustment below only if to call from left panel because we need to break potential cluster.
        // If a particular SP is clicked then we need just move the map
        /*if (calledFromLeftPanel) {
            const locs = [];
            locs.push(pin.getLocation());
            this.repositioned = true;
            this.map.setView({zoom: 19});
        }*/

        // Move the map a bit down to display the whole info box
        // Cannot be done together with map.setView({ bounds: bounds, padding: 300 }); for some reason
        /*const pinPositionPixel = this.map.tryLocationToPixel(pin.getLocation());
        const offset = new Microsoft.Maps.Point(0, (-300) * ((800 / window.innerHeight) - 0.15));
        pinPositionPixel.y += offset.y;
        const newLocation = this.map.tryPixelToLocation(pinPositionPixel);
        this.repositioned = true;
        this.map.setView({center: newLocation});*/
        //this.applyNonceForAllStyles();
    }

    /*private applyNonceForAllStyles(){
        let components = $('[id^="component-"]');
        components.each(function() {
            // Find all <style> and <link rel="stylesheet"> tags within this component
            let styleTags = $(this).find('style');
            let linkTags = $(this).find('link[rel="stylesheet"]');
        
            // Add nonce to each <style> tag
            styleTags.each(function() {
                $(this).attr('nonce', nonce);
            });
        
            // Add nonce to each <link rel="stylesheet"> tag
            linkTags.each(function() {
                $(this).attr('nonce', nonce);
            });
        
            // Optionally, inject the modified HTML into a target element
            // This step depends on your specific needs, you can either
            // modify in place or re-inject the HTML elsewhere.
            // Example: $('#spHolder').html($(this).html());
        });
    }*/

    private makeLocation(lat, lng) {
        return new Microsoft.Maps.Location(lat, lng);
    }

    private makeLocationFromSP(servicePoint: ServicePoint) {
        return this.makeLocation(servicePoint.geoLocation.latitude, servicePoint.geoLocation.longitude);
    }

    private makePin(servicePoint, location) {
        return new Microsoft.Maps.Pushpin(location, {
            icon: servicePoint.servicePointType === lockerType ? this.iconLocker : this.iconPoint,
            width: 53,
            height: 61,
            draggable: false,
            anchor: {x: 32, y: 50},
            catId: servicePoint.facilityId
        });
    }

    private addClickHandler(pin: any, selectedServicePoint: ServicePoint) {
        Microsoft.Maps.Events.addHandler(pin, 'click', (e) => {
                this.ngZone.run(() => {
                    if (this._selectedServicePoint) {
                        this._selectedServicePoint.isSelected = false;
                    }
                    if (selectedServicePoint) {
                        this._selectedServicePoint = selectedServicePoint;
                        this._selectedServicePoint.selectType = Utils.PIN_SELECT_TYPE;
                        this._selectedServicePoint.isSelected = true;
                    }
                    this.servicePointSelected.emit(this._selectedServicePoint);
                    if (!this.mobile) {
                        this.setDataintoInfoPointAndDisplay(pin, this._selectedServicePoint, false);
                    }
                    this.makeHoverInvisible();
                });
        });

        Microsoft.Maps.Events.addHandler(pin, 'mouseover', (e) => {
            if (!this.hoverServicePoint && !this._selectedServicePoint) {
                const selectedPin = this.arrayPushPins.find(pin => pin.catId === selectedServicePoint.facilityId);
                if (selectedPin) {
                    this.hoverServicePoint = selectedServicePoint;
                    if (this.hoverInfobox) {
                        this.hoverInfobox.setLocation(selectedPin.getLocation());
                        
                        $('#hoverPlaceholder').html($('#sp-detail-tooltip-' + this.hoverServicePoint.facilityId).html());
                        /*let styleTags = $('#sp-detail-tooltip-' + this.hoverServicePoint.facilityId).querySelectorAll('style');
                            styleTags.forEach((styleTag) => {
                            styleTag.setAttribute('nonce', nonce);
                        });*/
                        /*const hoverPlaceholder = this.elementRef.nativeElement.querySelector('#hoverPlaceholder');
                        const spDetailTooltip = this.elementRef.nativeElement.querySelector('#sp-detail-tooltip-' + this.hoverServicePoint.facilityId);

                        if (hoverPlaceholder && spDetailTooltip) {
                            this.renderer.setAttribute('nonce',nonce);
                            this.renderer.setProperty(hoverPlaceholder, 'innerHTML', spDetailTooltip.innerHTML);
                        }*/
                        const pinLocation = this.map.tryLocationToPixel(pin.getLocation(), Microsoft.Maps.PixelReference.control);
                        setTimeout(() => {
                            this.hoverInfobox.setOptions({visible: true});
                            this.positionPopover(pinLocation.y);
                        }, 0);
                    } else {
                        const htmlContent = '<div id="hoverPlaceholder">' + $('#sp-detail-tooltip-' + this.hoverServicePoint.facilityId).html() + '</div>';
                        this.hoverInfobox = new Microsoft.Maps.Infobox(selectedPin.getLocation(), {  
                            //visible: true,
                            offset: new Microsoft.Maps.Point(-180, 50),
                            htmlContent: htmlContent
                        });
                        const pinLocation = this.map.tryLocationToPixel(pin.getLocation(), Microsoft.Maps.PixelReference.control);
                        setTimeout(() => {
                            this.positionPopover(pinLocation.y);
                        }, 0);
                        this.hoverInfobox.setMap(this.map);
                    }
                }
            }
        });

        Microsoft.Maps.Events.addHandler(pin, 'mouseout', (e) => {
            this.makeHoverInvisible();
        });
    }

    positionPopover(top:number) {
        const parent = document.getElementById('map-canvas') as HTMLElement;
        const checkElement = this.document.getElementById("hoverPlaceholder") as HTMLElement;
        const wrapperElement = document.getElementById('tooltipouter') as HTMLElement;
        const popover: HTMLElement = this.findSixthLevelParent(checkElement);
        if(parent && popover) {
            const parentRect = parent.getBoundingClientRect();
            const popoverRect = popover.getBoundingClientRect();
            var offsetX = -180;
            var offsetY = 50;
            if(parentRect.left > popoverRect.left) {
                offsetX = (parentRect.left - popoverRect.left)-175;
            } else if(popoverRect.right > parentRect.right){
                offsetX = (parentRect.right - popoverRect.right)-185;
            }
            if((popoverRect.bottom - popoverRect.height) < parentRect.top) {
                offsetY = -(popoverRect.height)- (13) ;
            }
            this.hoverInfobox.setOptions({offset: new Microsoft.Maps.Point(offsetX, offsetY)});
            wrapperElement.style.opacity='unset';

        } 
    }

    findSixthLevelParent(element: HTMLElement): HTMLElement | null {
        let currentElement: HTMLElement | null = element;
      
        for (let i = 0; i <= 3; i++) {
          if (currentElement && currentElement.parentNode instanceof HTMLElement) {
            currentElement = currentElement.parentNode;
          } else {
            // If there are less than 6 levels, return null or handle accordingly
            return null;
          }
        }
        return currentElement;
      }

    makeHoverInvisible(){
        if (this.hoverInfobox) {
            this.hoverInfobox.setOptions({offset: new Microsoft.Maps.Point(-180, 30)});
            this.hoverInfobox.setOptions({visible: false});
        }
        this.hoverServicePoint = null;
    }

    private processSP(servicePoint) {
        const pinLocation = this.makeLocationFromSP(servicePoint);
        const pin = this.makePin(servicePoint, pinLocation);

        if (servicePoint.facilityId === this.pinSelected && pin) {
            pin.setOptions({
                width: 64, height: 75,
                icon: servicePoint.servicePointType === lockerType ? this.iconLockerSel : this.iconPointSel
            });
        }
        this.arrayLocations.push(pinLocation);
        this.pinLayer.add(pin);
        this.arrayPushPins.push(pin);

        this.addClickHandler(pin, servicePoint);
        if((this.isMapMoved || this.isMapZoomed) && !this.mobile){
            this.clearActivePins();
        }
    }

    private clusterClicked(e) {
        if (e.target.containedPushpins) {
            const locs = [];
            e.target.containedPushpins.forEach(pin =>
                locs.push(pin.getLocation()));

            // Create a bounding box for the pushpins.
            const bounds = Microsoft.Maps.LocationRect.fromLocations(locs);

            // Zoom into the bounding box of the cluster.
            // Add a padding to compensate for the pixel area of the pushpins.
            this.repositioned = true;
            this.map.setView({bounds, padding: 100});
        }
    }

    private createCustomClusteredPin(cluster) {
        if (!cluster) {
            return;
        }
        cluster.setOptions({
            icon: this.iconClusterImage, anchor: {x: 16, y: 37}
        });

        Microsoft.Maps.Events.addHandler(cluster, 'click', this.clusterClicked.bind(this));
    }

    private loadServicePoints(recenter = false) {

        if (!this._servicePoints.servicePoints) {
            return; // nothing found
        }


        this._servicePoints.servicePoints.forEach(sp =>
            this.processSP(sp));

        this.zoomValue = this.map.getZoom();
        if (this.arrayLocations && this.arrayLocations.length > 0) {// !this.mobile &&
            const viewBoundaries = Microsoft.Maps.LocationRect.fromLocations(this.arrayLocations);
            if (recenter) {
                this.map.setView({bounds: viewBoundaries});
                // if(this.isMapMoved && !this.isMapZoomed){
                //     this.map.setView({zoom: this.zoomValue});
                // } //to stop zoom out on its own when moved the map
            }
        } else if (this.mobile && this.selectedServicePoint) {
            const zoomOn = this.makeLocationFromSP(this.selectedServicePoint);
            this.map.setView({center: zoomOn, zoom: 19});
        }
        this.isMapMoved = false;
        this.isMapZoomed = false;
    }

    ngOnInit() {
        this.initializeSubscriptions();
        this.initialize();
        if (this.settings && this.ngZone) {
            this.subscriptions.push(this.settings.languageCountryChanged.subscribe((lc) => {
                this.ngZone.run(() => {
                    const queryParams = this.settings.getParams(this.route.snapshot.queryParams);
                    const newParams = Object.assign({},
                        queryParams,
                        {
                            languageCode: this.settings.language.iso2Language,
                            languageCountryCode: lc.countryCode2,
                            languageScriptCode: this.settings.language.languageScript,
                            resultUom: this.settings.uom
                        });

                    this.router.navigate(['/results'], {
                        queryParams: newParams
                    });
                });
            }));

            this.subscriptions.push(this.settings.uomChanged.subscribe((uom) => {
                this.closeInfoBox();
                this.ngZone.run(() => {
                    const queryParams = this.settings.getParams(this.route.snapshot.queryParams);
                    this.router.navigate(['/results'], {
                        queryParams: Object.assign({}, queryParams, {resultUom: uom.code})
                    });
                });
            }));
        }
    }

    private initializeSubscriptions() {
        this.subscriptions.push(this.settings.servicePointsFilterTriggered.subscribe(servicePoints => {
            if (!this.mobile) {
                this._servicePoints.servicePoints = servicePoints;
                this.servicePoints = this._servicePoints;
            }
        }));

        this.subscriptions.push(this.settings.bingMapLoaded.subscribe(value => {
            this.isScriptLoaded = value;
            if(this.isScriptLoaded && !this.isOnInit) {//last condition is to stop loading of zoom multiple times
                this.initialize();
            }
        }));

        this.subscriptions.push(this.consentService.consentChange$.subscribe(consent => {
            let isConsentProvided = this.setElementsDisplay();
            if(this._servicePoints && (this._servicePoints.servicePoints.length > 1 || (this._servicePoints.servicePoints.length == 1 && !isConsentProvided))){
                this._selectedServicePoint = null;
             }
            //to close the sp-detail when loading map or consent
            setTimeout(() => {
                this.showSetSelectedServicePoint(this._selectedServicePoint);
            }, 500);
        }));

        this.subscriptions.push(this.settings.qrCodePromotionChanged.subscribe(() => {
            this.filterSelected = true;
            this.isMapMoved = false;
            this.extendToMaximumResults();
        }));

        this.subscriptions.push(this.settings.resultListSearched.subscribe(() => {
            this.isMapMoved = false;
        }));
 
    }

    private initialize(){
        if (typeof Microsoft !== 'undefined') {
            this.setElementsDisplay();
            this.resolveServicePoints(false);
            this.loadMap();

            this.pinLayer = new Microsoft.Maps.Layer();

            if (this._servicePoints && this._servicePoints.servicePoints) {
                if (this._servicePoints.searchLocation) {// !this.mobile && 
                    const currentLocation = this.makeLocation(this._servicePoints.searchLocation.latitude,
                        this._servicePoints.searchLocation.longitude);
                    this.markCenterLocation(currentLocation);
                }
                this.loadServicePoints(true);
            }

            Microsoft.Maps.loadModule('Microsoft.Maps.Clustering', () => {
                this.clusterLayer = new Microsoft.Maps.ClusterLayer(this.arrayPushPins, {
                    clusteredPinCallback: this.createCustomClusteredPin.bind(this),
                    gridSize: 80
                });
                this.map.layers.insert(this.clusterLayer);

                //if (this._servicePoints && this._servicePoints.servicePoints) { //GSPL-197
                    if (this._servicePoints.searchLocation) {

                        const currentLocation = this.makeLocation(this._servicePoints.searchLocation.latitude,
                            this._servicePoints.searchLocation.longitude);

                        this.markCenterLocation(currentLocation);

                        if (!this._servicePoints.servicePoints) {
                            this.map.setView({center: currentLocation});
                        }


                    } else if (this._servicePoints.servicePoints[0]) {
                        if (!this.selectedInfobox) {
                            const currentLocation = this.makeLocation(this._servicePoints.servicePoints[0].geoLocation.latitude,
                                this._servicePoints.servicePoints[0].geoLocation.longitude);

                            this.selectedInfobox = new Microsoft.Maps.Infobox(currentLocation, {visible: false});
                            this.selectedInfobox.setMap(this.map);
                        }
                    }
                //}
                            
                Microsoft.Maps.Events.addHandler(this.map, 'viewchangeend', (e) => {
                    //if (this._servicePoints && this._servicePoints.servicePoints && this._servicePoints.searchLocation) { //!this.mobile && 
                        if(e.cause === 1 ){
                            this.isMapZoomed = true;
                        }else{// if( e.location)
                            this.isMapZoomed = false;
                            this.isMapMoved = true;
                        }
                        
                        if (this.repositioned) {
                            this.repositioned = false;
                        } else {
                            if (e.cause === 1 || // zoom
                                e.location // drag or move
                                ) {
                                this._moveEvents.emit(e);
                            }
                        }
                    //}
                });

                Microsoft.Maps.Events.addHandler(this.map, 'mousewheel', (e) => {
                    e.handled = true;
                    this.isMapZoomed = true;
                    return true;
                });

                Microsoft.Maps.Events.addHandler(this.map, 'maptypechanged', (o) => {
                    if ('x' === o.newMapTypeId) {
                        if (this.selectedInfobox) {
                            const opts = this.selectedInfobox.getOptions();
                            if (opts.visible) {
                                this.selectedInfobox.setOptions({visible: false});
                            }
                        }
                    }
                });

                this._moveEvents.pipe(debounceTime(250)).subscribe((event) => {
                    /*const queryParams = this.settings.getParams(this.route.snapshot.queryParams);
                    let alteredParams = Object.assign(Object.assign({}, queryParams), {
                        servicePointResults: 50
                    });

                    if (!event.zoomOnly) {
                        alteredParams = Object.assign(alteredParams, {
                            latitude: this.map.getCenter().latitude,
                            longitude: this.map.getCenter().longitude
                        });
                    } else if (queryParams.servicePointResults === '50') {
                        return;
                    } else {
                        this.zoomOnlyTriggered = true;
                    }*/
                    this._selectedServicePoint = null;
                    //this.servicePointSelected.emit(null);
                    this.clearActivePins();
                    //if(Utils.isJsApi()){
                        if(this.firstMapZoomChange && event.cause === 1){
                            this.firstMapZoomChange = false;
                            this.extendToMaximumResults();
                        } else if(event.location) {
                            this.firstMapZoomChange = false;
                            this.extendToMaximumResults();
                        }
                    //} else {
                        //this.extendToMaximumResults();
                    //}
                });
            });
            this.isOnInit = true;
        }
    }

    extendToMaximumResults(){
        if(!Utils.isMobile()){
            this._selectedServicePoint = null;
            this.settings.notifyServicePointSelected(this._selectedServicePoint);
        }
        if (!Utils.isJsApi()) {
            const queryParams = this.settings.getParams(this.route.snapshot.queryParams);
            const params = Object.assign({}, queryParams);
            if (!params['servicePointResults']) {
                params['servicePointResults'] = this.settings.appConfig.maxResults;
                /*if (!this.isMapZoomed && this.isMapConsentGiven) {
                    params['latitude'] = this.map.getCenter().lat(); // Attention
                    params['longitude'] = this.map.getCenter().lng(); // Attention
                }*/
            }
            if(this.isMapMoved && !this.isMapZoomed) {
                params['latitude'] = this.map.getCenter().latitude;
                params['longitude'] = this.map.getCenter().longitude;
            }
            if (this.filterSelected) {
                this.settings.filterServicePoints();
            }
            this.mapMoveOrZoomEvent.emit(params);
            //BR11 - start
            this.settings.mapMovedonMobile.emit();

            //BR11 - end
            /*this.ngZone.run(() => {
                this.router.navigate(['/results'], {
                    queryParams: alteredParams
                });
            });*/
        } else {
            if (window.gspl.wsbSearch) {
                if (this._servicePoints.resultsParam) {
                    if (!window.gspl.params.servicePointResults) {
                        this._servicePoints.resultsParam.servicePointResults = this.settings.appConfig.maxResults;
                    } else {
                        this._servicePoints.resultsParam.servicePointResults = window.gspl.params.servicePointResults;
                    }
                    if(!this.isMapZoomed) {
                        this._servicePoints.resultsParam.latitude = this.map.getCenter().latitude;
                        this._servicePoints.resultsParam.longitude = this.map.getCenter().longitude;
                    }        
                    this.mapMoveOrZoomEvent.emit(this._servicePoints.resultsParam);
                } 
            } else {//GSPL-200 for js-api-result
                if (this.settings.jsApiPageParam) {
                    if (!window.gspl.params.servicePointResults) {
                        this.settings.jsApiPageParam['servicePointResults'] = this.settings.appConfig.maxResults;
                    } else {
                        this.settings.jsApiPageParam['servicePointResults'] = window.gspl.params.servicePointResults;
                    }
                    if(window.gspl.params.capability){
                        this.settings.jsApiPageParam['capability'] = window.gspl.params.capability;  
                    }

                    if (!this.isMapZoomed){
                        this.settings.jsApiPageParam['latitude'] = this.map.getCenter().latitude;
                        this.settings.jsApiPageParam['longitude'] = this.map.getCenter().longitude;
                    }
                    this.mapMoveOrZoomEvent.emit(this.settings.jsApiPageParam);
                }
            }
        }
    }

    ngAfterViewInit() {
        
        //this.initialize();
        if (!this.mobile && this._servicePoints.isShowInfoBox) {
            this.showInfoBox();
        }
        setTimeout(() => {
            this.consentService.initialize();
        }, 100);
        this.setElementsDisplay();
    }

    setElementsDisplay() {
        let isConsentProvided = this.settings.checkShowMapConsent('bing');
        if (isConsentProvided) {
            if (document.getElementById("map-canvas") && document.getElementById("content")) {
                document.getElementById("map-canvas").style.display = '';
                document.getElementById("content").style.display = 'none';
            }
        } else {
            if (document.getElementById("map-canvas") && document.getElementById("content")) {
                document.getElementById("content").style.display = '';
                document.getElementById("map-canvas").style.display = 'none';
            }
        }
        return isConsentProvided;
    }

    private showInfoBox() {
        setTimeout(() => {
            this._selectedServicePoint = this._servicePoints.servicePoints[0];
            this._selectedServicePoint.selectType = Utils.PIN_SELECT_TYPE;
            this._selectedServicePoint.isSelected = true;
            this.showSetSelectedServicePoint(this._selectedServicePoint);
            this.servicePointSelected.emit(this._selectedServicePoint);
        }, 100);
    }

    @HostListener('window:close-infobox', ['$event'])
    outsideCloseEvent(event) {
        this.closeInfoBox();
    }

    private markCenterLocation(currentLocation) {
        const pushPin = new Microsoft.Maps.Pushpin(currentLocation, {
            icon: this.iconLocation,
            width: 42,
            height: 42,
            draggable: false
        });
        this.arrayLocations.push(currentLocation);

        this.map.entities.push(pushPin);
        if (!this.selectedInfobox) {
            this.selectedInfobox = new Microsoft.Maps.Infobox(currentLocation, {visible: false});
            this.selectedInfobox.setMap(this.map);
        }

    }

    private markCenterLocation1(currentLocation) {
        const pushPin = new Microsoft.Maps.Pushpin(currentLocation, {
            icon: this.iconClusterImage,
            width: 42,
            height: 42,
            draggable: false
        });
        this.arrayLocations.push(currentLocation);

        this.map.entities.push(pushPin);
        if (!this.selectedInfobox) {
            this.selectedInfobox = new Microsoft.Maps.Infobox(currentLocation, {visible: false});
            this.selectedInfobox.setMap(this.map);
        }

    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    // JsApi
    @Input('show_share_svp_detail') showShareButtonSvpDetail = 'Y';
    @Output() onDirections = new EventEmitter<any>();
    @Output() onPrint = new EventEmitter<any>();
    @Output() onWebLink = new EventEmitter<any>();
    @Output() onFindActiveServicePoints = new EventEmitter<any>();
}
