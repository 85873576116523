<div *ngIf="promotion && !promotion.isClose && !promotion.isHidden" class="col px-2 py-2 bgcolor"><!--3.1 - br1   && !settings.isMyDHLPlusOrDHLE()-->
    <div class="row mx-0">
        <div class="col-auto pl-0 pr-2">
            <img [src]="settings.backendUrl + '/assets/promotion-icon.png'" alt="Selected a country with promotion">
        </div>
        <div class="col px-0">
            <div class="row mx-0">
                <div class="col px-0 text-14" [innerHTML]="promotion.text"></div>
                <div class="col-auto pl-2 pr-0 close">
                    <i class="material-icons close text-20" (click)="closePromotion()">close</i>
                </div>
            </div>
            <div *ngIf="promotion.hyperlink" class="row mx-0 mt-2">
                <div class="col px-0"></div>
                <div class="col-auto px-0">
                    <button type="button" class="promotion-button text-14" (click)="goToPromotionHyperlink(promotion.hyperlink)">{{promotion.buttonText}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
