<div *ngIf="!settings?.isRTL()" >
    <a [ngStyle]="{'display': 'inline-block'}" (click)="onLangSelect()">{{lang.languageNative}}</a>
    <span (click)="showSelectedCountryList()" [ngClass]="showCountryList?'caret-down':'caret-right'" [ngStyle]="{'display': 'inline-block', 'background': 'url(' + settings.backendUrl + '/assets/sprite2.png) no-repeat'}"></span>
</div>
<div *ngIf="settings?.isRTL()" >
    <span (click)="showSelectedCountryList()" [ngClass]="showCountryList?'caret-down':'caret-right'" [ngStyle]="{'display': 'inline-block', 'background': 'url(' + settings.backendUrl + '/assets/sprite2.png) no-repeat'}"></span>
    <a [ngStyle]="{'display': 'inline-block'}" (click)="onLangSelect()">{{lang.languageNative}}</a>
</div>
<ul [ngClass]="'show dropdown-country'" id="dropdown-country"
    aria-labelledby="countryLabel" *ngIf="showCountryList">
    <li *ngFor="let country of countryList" [ngClass]="settings?.isRTL()?'margin-left-rtl':''">
        <a  [ngStyle]="{'cursor': 'pointer'}" (click)="onCountrySelect(country)">{{country.countryName}}</a>
    </li>
</ul>
<!--span (click)="showSelectedCountryList()" [class]="showCountryList ? 'up' : ''" style="display: inline-block; width: 20px;">
    <span class="caret caret-right" [ngStyle]="{background: 'url(' + settings.backendUrl + '/assets/sprite2.png) no-repeat 0px -22px'}"></span>
</span-->
<!--div class="modal-backdrop" *ngIf="showCountryList" (click)="modalBackdropClick()"></div-->

