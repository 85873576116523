<div class="col">
    <div *ngIf="servicePoint.svpStatus && servicePoint.svpStatus=='A'">
        <div class="row py-2 result-item" (click)="onSelected.emit(servicePoint);">
            <div class="col mb-auto pl-2 pr-0 text-14 max-width-result-item-ls">
                <!-- style="width: calc(100% - 120px);" -->
                <div class="row">
                    <div class="col">
                        <span [innerHTML]="servicePoint.servicePointNameFormatted"></span>
                        <br />
                        <span class="font-weight-bold">{{servicePoint.localName}}</span>
                        <div>
                            <span [ngStyle]="{'word-wrap':'break-word', 'overflow-wrap': 'anywhere'}"
                                *ngIf="servicePoint.address.addressLine1">{{servicePoint.address.addressLine1}}</span>
                            <span [ngStyle]="{'word-wrap':'break-word', 'overflow-wrap': 'anywhere'}"
                                *ngIf="servicePoint.address.addressLine2">&nbsp;{{servicePoint.address.addressLine2}}</span>
                            <span [ngStyle]="{'word-wrap':'break-word', 'overflow-wrap': 'anywhere'}"
                                *ngIf="servicePoint.address.addressLine3">&nbsp;{{servicePoint.address.addressLine3}}</span>
                            <br />
                            <span *ngIf="servicePoint.address.zipCode">{{servicePoint.address.zipCode}}</span>
                            <span
                                *ngIf="servicePoint.address.countryDivisionCode">&nbsp;{{servicePoint.address.countryDivisionCode}},</span>
                            <span *ngIf="servicePoint.address.city">&nbsp;{{servicePoint.address.city}}</span>
                        </div>
                    </div>
                </div>
                <div *ngIf="locationButton === 'Y'" class="row pt-2">
                    <div class="col" [ngStyle]="{'height': '24px'}"></div>
                </div>
            </div>
            <div class="col-auto mb-auto width-result-item-rs"> <!-- style="width: 120px;" -->
                <div class="row pl-3 pr-2 align-top" [ngStyle]="{'height': '32px'}">
                    <div class="col-auto px-0 mx-auto"></div>
                    <div *ngIf="lightVersion" class="col-auto px-0 number-image-container"><img [ngStyle]="{'height': '34px', 'width': '26px', 'margin-top': '-2px'}"
                            [src]="'assets/lightVersion/marker_small_push_pin.png'" alt="Light version pins">
                            <div [ngStyle]="{'font-size': '10px'}" class="number-text"> {{lightVersionOrder}}</div>
                    </div>
                    <!--*ngIf="(servicePoint.partner?.promotion && !servicePoint.partner?.promotion.isClose) || (servicePoint.promotion && !servicePoint.promotion.isClose)"-->
                    <!--*ngIf="(servicePoint.partner?.promotion && !servicePoint.partner?.promotion.isClose) || (servicePoint.promotion && !servicePoint.promotion.isClose)"-->
                    <!--Removed from below two lines for RTC 623764-->
                    <div *ngIf="((servicePoint.partner?.promotion) || (servicePoint.promotion))"
                        class="col-auto px-1"></div><!--3.1 - BR1  && !settings.isMyDHLPlusOrDHLE()-->
                    <div *ngIf="((servicePoint.partner?.promotion) || (servicePoint.promotion))"
                        class="col-auto px-0"> <img [ngStyle]="{'height': '32px', 'width': '32px'}"
                            [src]="settings.backendUrl + '/assets/promotion-icon.png'"
                            title="{{'spl.result.promotion.title' | translate}}"></div><!--3.1 - BR1  && !settings.isMyDHLPlusOrDHLE()-->
                    <div *ngIf="servicePoint.servicePointCapabilities.labelFree" class="col-auto px-1"></div>
                    <div *ngIf="servicePoint.servicePointCapabilities.labelFree" class="col-auto px-0"><img
                        [ngStyle]="{'height': '26px', 'width': '26px', 'margin-top': '4px'}"
                            [src]="settings.backendUrl + '/assets/qr-icon.svg'"
                            title="{{'spl.result.labelfree.title' | translate}}"></div>
                </div>
                <div *ngIf="servicePoint.distance" class="row mt-1 pl-3 pr-2 align-top">
                    <div class="col-auto px-0 mx-auto"></div>
                    <div class="col-auto px-0"><img [ngStyle]="{'height': '20px', 'width': '20px'}"
                            [src]="settings.backendUrl + '/assets/distance-icon.svg'" alt="Displays a distance icon"></div>
                    <div class="col-auto px-1"></div>
                    <div class="col-auto px-0 text-nowrap">{{servicePoint.distance}}</div>
                </div>
                <div class="row mt-1 pl-3 pr-2 align-top">
                    <div class="col-auto px-0 mx-auto"></div>
                    <div class="col-auto px-0 detail">{{'spl.search.detail' | translate}}</div>
                    <div class="col-auto px-1"></div>
                    <div class="col-auto px-0" [ngClass]="settings.isRTL() ? 'detail-icon-rtl' : 'detail-icon'"
                        [ngStyle]="{background: 'url(' + settings.backendUrl + '/assets/sprite.png) no-repeat'}">&nbsp;
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="servicePoint.svpStatus && servicePoint.svpStatus!='A'">
        <div class="row py-2 result-item" [ngStyle]="{'flex-direction': 'column'}" (click)="onSelected.emit(servicePoint);">
            <div class="col pl-2 pr-0 text-14 min-height-resultitem">
                <span [innerHTML]="servicePoint.servicePointNameFormatted"></span>
                <span class="temp-close"> &nbsp;- &nbsp;{{'spl.servicepoint.temp_closed' | translate}}</span> <br />
            </div>
            <div class="col pl-3 pr-3 min-height-resultitem">
                <div class="row">
                    <div class="col mb-auto pl-2 pr-0 text-14 max-width-result-item-ls">
                        <!-- style="width: calc(100% - 120px);" -->
                        <div class="row">
                            <div class="col">
                                <span class="font-weight-bold">{{servicePoint.localName}}</span>
                                <div>
                                    <span [ngStyle]="{'word-wrap':'break-word', 'overflow-wrap': 'anywhere'}"
                                        *ngIf="servicePoint.address.addressLine1">{{servicePoint.address.addressLine1}}</span>
                                    <span [ngStyle]="{'word-wrap':'break-word', 'overflow-wrap': 'anywhere'}"
                                        *ngIf="servicePoint.address.addressLine2">&nbsp;{{servicePoint.address.addressLine2}}</span>
                                    <span [ngStyle]="{'word-wrap':'break-word', 'overflow-wrap': 'anywhere'}"
                                        *ngIf="servicePoint.address.addressLine3">&nbsp;{{servicePoint.address.addressLine3}}</span>
                                    <br />
                                    <span *ngIf="servicePoint.address.zipCode">{{servicePoint.address.zipCode}}</span>
                                    <span
                                        *ngIf="servicePoint.address.countryDivisionCode">&nbsp;{{servicePoint.address.countryDivisionCode}},</span>
                                    <span *ngIf="servicePoint.address.city">&nbsp;{{servicePoint.address.city}}</span>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="locationButton === 'Y'" class="row pt-2">
                            <div class="col" [ngStyle]="{'height': '24px'}"></div>
                        </div>
                    </div>
                    <div class="col-auto mb-auto width-result-item-rs"> <!-- style="width: 120px;" -->
                        <div class="row pl-3 pr-2 align-top" [ngStyle]="{'height': '11px'}">
                            <div class="col"></div>
                        </div>
                        <div *ngIf="servicePoint.distance" class="row mt-1 pl-3 pr-2 align-top">
                            <div class="col-auto px-0 mx-auto"></div>
                            <div class="col-auto px-0"><img [ngStyle]="{'height': '20px', 'width': '20px'}"
                                    [src]="settings.backendUrl + '/assets/distance-icon.svg'" alt="Displays a distance icon"></div>
                            <div class="col-auto px-1"></div>
                            <div class="col-auto px-0 text-nowrap">{{servicePoint.distance}}</div>
                        </div>
                        <div class="row mt-1 pl-3 pr-2 align-top">
                            <div class="col-auto px-0 mx-auto"></div>
                            <div class="col-auto px-0 detail">{{'spl.search.detail' | translate}}</div>
                            <div class="col-auto px-1"></div>
                            <div class="col-auto px-0" [ngClass]="settings.isRTL() ? 'detail-icon-rtl' : 'detail-icon'"
                                [ngStyle]="{background: 'url(' + settings.backendUrl + '/assets/sprite.png) no-repeat'}">
                                &nbsp;
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <button *ngIf="locationButton === 'Y'"
        [ngClass]="'select-button ' + (servicePoint.isSelectedLocation ? 'selected' : 'not-selected')"
        [disabled]="servicePoint.isSelectedLocation"
        (click)="onSelectedLocation.emit(servicePoint);">{{servicePoint.isSelectedLocation ? ('spl.result.selected' |
        translate) : ('spl.result.select' | translate)}}
    </button>
</div>