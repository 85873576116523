<!--div *ngIf="_isOpen" [dir]="dir" [ngClass]="textAlignRtl" class="container d-block">
    <div class="head-loc">
        <div class="row">
            <div class="col-9">
                <h2>{{data.spName}}</h2>
                <h3>{{data.localName}}</h3>
                <div>
                    <span *ngIf="data.address.addressLine1">{{data.address.addressLine1}}</span>
                    <span *ngIf="data.address.addressLine2"> {{data.address.addressLine2}}</span>
                    <span *ngIf="data.address.addressLine3"> {{data.address.addressLine3}}</span>
                    <br/>
                    <span *ngIf="data.address.zipCode">{{data.address.zipCode}}</span>
                    <span *ngIf="data.address.countryDivisionCode"> {{data.address.countryDivisionCode}},</span>
                    <span *ngIf="data.address.city"> {{data.address.city}}</span>
                </div>
            </div>
            <div class="col-3">
                <a class="text-button-l" (click)="print()">
                    <span><div class="iconed-big print" [ngStyle]="{background: 'url(' + settings.backendUrl + '/assets/sprite.png)'}"></div></span>
                    <span>{{'spl.result.button.print' | translate}}</span>
                </a></div>
        </div>
    </div>


    <div class="direct">
        <div class="row">
            <div id="print_map"></div>
        </div>
    </div>

</div-->

<app-header *ngIf="!data.hideMobile" [showLangSelector]="false"></app-header>
<div *ngIf="_isOpen" [dir]="dir" [ngClass]="textAlignRtl" class="container d-block">
    <div class="back no-print d-md-none d-lg-none d-xl-none" (click)="back()">{{"spl.result.back" | translate}}</div>
    <!--div class="logo-line"><img src="../../assets/logo.png" class="logo"></div-->
    <div class="head-loc">
        <div class="row">
            <div class="col-12 col-sm-5">
                <h2>A: {{'spl.search.your_location' | translate}}</h2>
                <div>{{data.yourLocation}}</div>
            </div>
            <div class="col-12 col-sm-5">
                <h2>B: {{data.spName}}</h2>
                <h3>{{data.localName}}</h3>
                <div>
                    <span *ngIf="data.address.addressLine1">{{data.address.addressLine1}}</span>
                    <span *ngIf="data.address.addressLine2"> {{data.address.addressLine2}}</span>
                    <span *ngIf="data.address.addressLine3"> {{data.address.addressLine3}}</span>
                    <br/>
                    <span *ngIf="data.address.zipCode">{{data.address.zipCode}}</span>
                    <span *ngIf="data.address.countryDivisionCode"> {{data.address.countryDivisionCode}},</span>
                    <span *ngIf="data.address.city"> {{data.address.city}}</span>
                </div>
            </div>
            <div class="col-sm-2 d-none d-sm-block no-print">
                <a *ngIf="isMapConsentGiven" class="text-button-l" (click)="print()">
                    <span><div class="iconed-big print" [ngStyle]="{background: 'url(' + settings.backendUrl + '/assets/sprite.png)'}"></div></span>
                    <span>{{'spl.result.button.print' | translate}}</span>
                </a></div>
            </div>
    </div>


    <div class="direct">
        <div class="row" *ngIf="isMapConsentGiven">
            <div class="col-xs-12 col-12 col-md-4 col-sm-12 col-lg-4 col-xl-4">
                <div id="map-itinerary"></div>
            </div>
            <div class="col-xs-12 col-12 cold-md-8 col-sm-12 col-lg-8 col-xl-8">
                <div aria-labelledby="Loads and initiates the map directions" id="map-direction"></div>
            </div>
        </div>
        <div class="row" [ngStyle]="{'min-height': '60vh', 'font-size': '18px', 'display': 'flex', 'justify-content': 'center', 'align-items': 'center'}" *ngIf="!isMapConsentGiven">
            <app-cookie-map-consent [page]="'directions'" [mapType]="data.mapType"></app-cookie-map-consent>
        </div>
    </div>
</div>
<app-footer class="no-print row margin-top" [hasBackground]="true"></app-footer>
