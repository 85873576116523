import { Component, EventEmitter, Input, OnInit, Output, ViewChild, AfterViewInit, Renderer2, ElementRef, OnDestroy, NgZone, ChangeDetectorRef, HostListener, ViewRef } from '@angular/core';
import { SearchServicePointsResult, ServicePoint, BackendService, ResultsParameter, ResultLoadType } from '../backend.service';
import { Utils } from '../common/utils';
import { SettingsService } from '../settings.service';
import { CountryPromotionComponent } from '../country-promotion/country-promotion.component';
import { SpMapBase } from './sp-map-base';
import { BingMapsLoader } from '../bing-maps-loader';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
//import { ResultsPageComponent } from '../results-page/results-page.component';
import { media } from '../media';
import { AlertMessageComponent } from '../alert-message/alert-message.component';
import { LocalCacheService } from '../local-cache.service';
import { ConsentService } from '../consent.service.ts.service';

declare const window: any;
declare const OneTrust: any;

@Component({
    selector: 'app-sp-map',
    templateUrl: './sp-map.component.html',
    styleUrls: ['./sp-map.component.scss']
})
export class SpMapComponent extends SpMapBase implements OnInit, AfterViewInit, OnDestroy {

    @Input() resultsPage: boolean = false;

    servicePoints: SearchServicePointsResult;
    selectedServicePoint: ServicePoint;

    mobile: boolean;
    mapType: string;
    isAfterOnInit = false;
    isAfterViewInit = false;
    prevMapType: string;
    loading = false;
    isBingMapLoaded: boolean = false;

    @ViewChild('countryPromotion', {static: false}) countryPromotionComponent: CountryPromotionComponent;
    @ViewChild('mapDiv', {static: false}) mapDivElementRef: ElementRef;
    @ViewChild('alertMessage', {static: false}) alertMsgComponent: AlertMessageComponent;

    private resultLoadType: ResultLoadType = ResultLoadType.DEFAULT;
    private subscriptions: Array<Subscription> = [];

    constructor(public settings: SettingsService, public renderer: Renderer2, private router: Router, 
        private backend: BackendService, public ngZone: NgZone, private cd: ChangeDetectorRef,
        private cache: LocalCacheService, private consentService: ConsentService, public route: ActivatedRoute) {
        super();
    }

    ngOnInit() {
        this.initializeSubscriptions();
        if(!this.isJsApi() && this.settings.mobileHiding){
            this.settings.cookieSettingForLang();//cookie settings changes
        }
        if (this._isOpen) {
            this.initialize();
        }
        this.isAfterOnInit = true;
    }

    onOpen() {
        this.initialize();
    }

    private initializeSubscriptions() {
        this.subscriptions.push(media('(orientation: landscape)').subscribe((matches) => {
            if ((window.innerHeight >= window.innerWidth && window.innerWidth > 768 && this.resultsPage) ||
                (window.innerHeight >= 1024 && window.innerWidth >= 1024) || matches) {
                this.mobile = false;
            } else {
                this.mobile = true;
            }
        }));
        this.subscriptions.push(this.settings.searchResultLoaded.subscribe(() => {
            this.initialize();
        }));
        this.subscriptions.push(this.settings.servicePointSelected.subscribe(selectedServicePoint => {
            if (selectedServicePoint && selectedServicePoint.selectType === Utils.LIST_SELECT_TYPE) {
                this.selectedServicePoint = selectedServicePoint;
            }
        }));
        this.subscriptions.push(this.settings.resultListSearched.subscribe(() => {
            this.resultLoadType = ResultLoadType.DEFAULT;
        }));
        this.subscriptions.push(this.settings.shareButtonDisabled.subscribe(data => {
            this.showShareButtonSvpDetail = data;
        }));

        this.subscriptions.push(this.consentService.consentChange$.subscribe(consent => {
            if(this.servicePoints && this.servicePoints.mapType == 'Bing') {
                if(this.settings.checkShowMapConsent('bing') && !this.isBingMapLoaded){
                    this.loadBingMaps();
                    this.isBingMapLoaded = true;
                } else {
                    this.mapType = this.servicePoints?this.servicePoints.mapType:'Bing';
                }
            }
            this.selectedServicePoint = null;
        }));
        /*try{
            if (OneTrust) {
                OneTrust.OnConsentChanged(() => {
                    if(this.servicePoints.mapType == 'Bing') {
                        if(this.settings.checkShowMapConsent('bing') && !this.isBingMapLoaded){
                            this.loadBingMaps();
                            this.isBingMapLoaded = true;
                        } else {
                            this.mapType = this.servicePoints?this.servicePoints.mapType:'Bing';
                        }
                    }
                });
            }
        } catch(error){
            // ignore the error
        }*/
    }

    private initialize() {
        this.loading = false;
        if (Utils.isJsApi()) {
            if (!this.settings.searchResult) {
                this.settings.setSearchResultJsApi(window.gspl.mapServicePoints);
            }
            this.settings.initializeParamsJsApi(window.gspl.params);
        }

        this.mobile = (Utils.isMobile() && this.resultsPage) ? true : false;
        if (this.settings.searchResult) {
            this.servicePoints = this.settings.filteredSearchResult;
            this.servicePoints.resultLoadType = this.resultLoadType;
            if (this.resultLoadType !== ResultLoadType.MAP_PAN_OR_ZOOM) {
                this.selectedServicePoint = this.settings.selectedServicePoint;
            }
            this.setMapType();
        }
        /*if (this.settings.isBannerSuppresedClient(this.route.snapshot.queryParams)) {
            this.renderer.addClass(document.body, 'hide-onetrust-elements');
        }*/
        if (Utils.isJsApi()) {
            this.loadScript(this.settings.appConfig.cookiesDataDomainUrl).then(() => {
                // Do something after the script is loaded
            }).catch(error => {
                console.error('Failed to load script:', error);
            });
        }
    }

    ngAfterViewInit() {
        this.isAfterViewInit = true;
        /*this.consentService.initialize();
        console.log("After view init sp-map",new Date());*/
        if (Utils.isJsApi() && window.gspl.mapAfterViewInit) {
            window.gspl.mapAfterViewInit();
        }

        setTimeout(() => {
            this.consentService.initialize();
        }, 100);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    loadBingMaps() {
        if(this.settings.checkShowMapConsent('bing')){
            BingMapsLoader.load(this.settings ? this.settings.language.isoLanguage : 'en',
                this.settings ? this.settings.languageCountry.countryCode2 : 'US', this.settings.appConfig.bingLoadUrl).then(() => {//573005
                    this.mapType = 'Bing';
                    if (Utils.isJsApi && window.gspl.wsbSearch) {
                        setTimeout(() => {
                            if (this.cd && !(this.cd as ViewRef).destroyed) {
                                this.cd.detectChanges();
                            }
                        });
                    }
                    this.isBingMapLoaded = true;
                    this.settings.bingMapLoaded.emit(this.isBingMapLoaded);
                }
            );
        } else {
            this.mapType = this.servicePoints?this.servicePoints.mapType:'Bing';
        }
    }

    setSelectedServicePoint(selectedServicePoint: ServicePoint) {
        this.selectedServicePoint = selectedServicePoint;
        this.onServicePointSelected.emit(selectedServicePoint);
        this.settings.notifyServicePointSelected(selectedServicePoint);
    }

    mapMoveOrZoom(params?: any) {
        this.settings.isUserInputLocationNeeded = false;
        this.settings.notifyMapMovedOrZoomed(true);
        this.loading = true;
        if (!Utils.isJsApi()) {
            this.cache.set("mapMoved",true,1);//795587
            if (params) {
                this.prevMapType = this.mapType;
                this.resultLoadType = ResultLoadType.MAP_PAN_OR_ZOOM; // to do: simplify...
                if(params.latitude && params.longitude && !this.settings.isFilterSelected) {
                    this.backend.getDetailsByLatLong(this.constructDetailsByLatLongParam(params), this.settings).then((data) => {
                        if(data && data.countryCode){
                           // if((this.mapType != "Bing") || (this.mapType == "Bing" && this.settings.isMapMovedToSea)){//zoom issue is fixed//GSPL-200
                                this.settings.selectedCountry = this.settings.countries.countryList.find((country)=>country.value.toLowerCase() === data.countryCode.toLowerCase());
                                params.countryCode = data.countryCode;
                                params.address = data.value;
                           // }   
                            this.settings.isMapMovedToSea = false;
                        }//GSPL - 55
                        else if(data && !data.countryCode){
                            this.settings.isMapMovedToSea = true;
                            params.countryCode = null;
                        }
                        this.router.navigate(['/results'], { queryParams: params }).then(succeeds => {
                            if(this.prevMapType == 'Bing' && this.mapType == 'Google' && this.servicePoints){
                                this.servicePoints.resultLoadType = ResultLoadType.DEFAULT;
                            }
                            if(!Utils.isMobile()){
                                this.settings.servicePointSelected.emit(null);
                            }
                            this.settings.mapMovedonMobile.emit();
                            if (!succeeds) { 
                                this.settings.notifyMapMovedOrZoomed(false);
                            }
                            this.loading = false;
                            this.cd.detectChanges();
                        });
                   });
                } else {                    
                    this.router.navigate(['/results'], { queryParams: params }).then(succeeds => {
                        if(!Utils.isMobile()){
                            this.settings.servicePointSelected.emit(null);
                        }
                        this.settings.mapMovedonMobile.emit();
                        if (!succeeds) {
                            this.settings.notifyMapMovedOrZoomed(false);
                        }
                        this.loading = false;
                        this.cd.detectChanges();
                    });
                }
                
                //this.moveEvents2.emit();
                //this.moveEvents.emit($event)
            } else {
                // Bing not passing uRLSearchParams yet
            }
        } else {
            if (params) {
                this.loading = true; // Google & Bing pass params
                const resultsParam = <ResultsParameter>params;
                this.prevMapType = this.mapType;
                this.resultLoadType = ResultLoadType.MAP_PAN_OR_ZOOM; // to do: simplify...
                if (window.gspl.wsbSearch) {                    
                    if(params.latitude && params.longitude && !this.settings.isFilterSelected) {
                        this.backend.getDetailsByLatLong(this.constructDetailsByLatLongParam(params), this.settings).then((data) => {
                            if(data && data.countryCode) {
                               // if ((this.mapType != "Bing") || (this.mapType == "Bing" && this.settings.isMapMovedToSea)) {
                                    this.settings.selectedCountry = this.settings.countries.countryList.find((country) => country.value.toLowerCase() === data.countryCode.toLowerCase());
                                    params.countryCode = data.countryCode;
                                    params.address = data.value;
                               // }
                                this.settings.isMapMovedToSea = false;
                            }
                            else if (data && !data.countryCode) {
                                this.settings.isMapMovedToSea = true;
                                params.countryCode = null;
                            }
                            this.backend.searchServicePoints(resultsParam, this.settings).then(searchResult => {                                
                                this.ngZone.run(()=>{
                                    this.settings.mapMovedonMobile.emit();
                                    if(!Utils.isMobile()){
                                        this.settings.servicePointSelected.emit(null);
                                    }
                                    this.settings.notifyMapMovedOrZoomed(false);
                                    this.settings.searchResult = searchResult;
                                    this.settings.notifySearchResultLoaded();
                                    this.onPanOrZoom.emit(searchResult);
                                    if(this.prevMapType == 'Bing' && this.mapType == 'Google' && this.servicePoints){
                                        this.servicePoints.resultLoadType = ResultLoadType.DEFAULT;
                                    }
                                    this.loading = false;
                                });
                            });
                        });
                    } else {
                        this.backend.searchServicePoints(resultsParam, this.settings).then(searchResult => {                                
                            this.ngZone.run(()=>{
                                if(!Utils.isMobile()){
                                    this.settings.servicePointSelected.emit(null);
                                }
                                this.settings.notifyMapMovedOrZoomed(false);
                                this.settings.searchResult = searchResult;
                                this.settings.notifySearchResultLoaded();
                                this.onPanOrZoom.emit(searchResult);
                                if(this.prevMapType == 'Bing' && this.mapType == 'Google' && this.servicePoints){
                                    this.servicePoints.resultLoadType = ResultLoadType.DEFAULT;
                                }
                                this.loading = false;
                            });
                        });
                    }
                } else {
                    this.backend.getDetailsByLatLong(this.constructDetailsByLatLongParam(params), this.settings).then((data) => {
                        if (data && data.countryCode) {
                            this.settings.selectedCountry = this.settings.countries.countryList.find((country) => country.value.toLowerCase() === data.countryCode.toLowerCase());
                            resultsParam.countryCode = data.countryCode;
                            resultsParam.address = data.value;
                            window.gspl.params.searchAddress = data.value;
                            this.settings.isMapMovedToSea = false;
                        }
                        else if (data && !data.countryCode) {
                            this.settings.isMapMovedToSea = true;
                            resultsParam.countryCode = null;
                        }
                        if(!Utils.isMobile()){
                            this.settings.servicePointSelected.emit(null);
                        }
                        if (this.prevMapType == 'Bing' && this.mapType == 'Google' && this.servicePoints) {
                            this.servicePoints.resultLoadType = ResultLoadType.DEFAULT;
                        }
                    }); 
                    this.onPanOrZoom.emit(resultsParam);
                    //this.settings.notifyMapMovedOrZoomed(false);
                    this.loading = false;
                }
            }
        }
        
    }

    promotionClosed() {
        this.settings.notifyPromotionBannerClosed();
    }

    constructDetailsByLatLongParam(params: any) {
        return {latitude: params.latitude, longitude: params.longitude, bingculture: this.settings.languageCountry.bingCulture2, clientAppCode: this.backend.clientAppCode, sessionToken: this.settings.sessionToken}
    }

    isJsApi(){
        return Utils.isJsApi();
    }
    
    // JsApi
    @Input('show_share_svp_detail') showShareButtonSvpDetail = 'Y';
    @Input() get loadServicePoints() {
        return () => {
            this.ngZone.run(() => {
                this.settings.setSearchResultJsApi(window.gspl.mapServicePoints, true);
            });
        };
    }

    @Output() onServicePointSelected = new EventEmitter<ServicePoint>();
    @Output() onPanOrZoom = new EventEmitter<any>();
    @Output() onDirections = new EventEmitter<any>();
    @Output() onPrint = new EventEmitter<any>();
    @Output() onWebLink = new EventEmitter<any>();
    @Output() onFindActiveServicePoints = new EventEmitter<any>();
}
