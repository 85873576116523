import { Component, OnInit, Input, NgZone, Optional, OnDestroy, Inject } from '@angular/core';
import { SettingsService } from '../settings.service';
//import { LangListComponent } from '../lang-list/lang-list.component';
import { LanguageInfo } from '../model/LanguageInfo';
import { CountryInfo } from '../model/CountryInfo';
import { Uom } from '../model/Uom';
import { Subscription } from 'rxjs';
//import { Utils } from '../common/utils';
import { DOCUMENT } from '@angular/common';

declare const OneTrust: any;

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss']
})
export class MobileMenuComponent implements OnInit {

  //@Input() langList: LangListComponent;
  @Input() expandUp = false;

  toggleUom = false;
  showCountryList = false;
  toggleLanguage = false;
  isNavbarCollapsed = true;
  uoms: Array<Uom>;
  selectedLanguage: LanguageInfo;
  countryInfos: Array<CountryInfo>;
  selectedLanguageCountry: CountryInfo;
  selectedUom: Uom;
  onClickDisplay: boolean = false;

  private subscriptions: Array<Subscription> = [];

  get mobileLink(): string {
    return (this.settings.languageCountry.mobileDomain && this.settings.languageCountry.mobileDomain !== '(null)') ? this.settings.languageCountry.mobileDomain : 'dhl.com';
  }

  constructor(public settings: SettingsService, @Optional() private ngZone: NgZone, @Inject(DOCUMENT) private document: Document) {
    //, @Optional() private cdr: ChangeDetectorRef
    this.uoms = this.settings.uoms;
    this.selectedLanguage = this.settings.language;
    this.countryInfos = this.selectedLanguage.countryInfos;
    this.selectedLanguageCountry = this.settings.languageCountry;
    this.selectedUom = this.settings.uom;
  }

  ngOnInit(): void {
    this.subscriptions.push(this.settings.languageChanged.subscribe(newLang => this.ngZone.run(() => {
      this.selectedLanguage = newLang;
      this.countryInfos = newLang.countryInfos;
      if (this.countryInfos && this.countryInfos.length > 0) {
        this.settings.languageCountry = this.countryInfos[0];
        this.selectedLanguageCountry = this.settings.languageCountry;
      }
      //this.cdr.detectChanges();
    })));

    this.subscriptions.push(this.settings.languageCountryChanged.subscribe(newLc => this.ngZone.run(() => {
      this.selectedLanguageCountry = newLc;
      var oneTrustCode: string = this.settings.getOneTrustCode(this.selectedLanguage, newLc);
      this.document.documentElement.lang = oneTrustCode;
      try{
          OneTrust.changeLanguage(oneTrustCode);
      } catch (Error){
          console.log("error in catch", Error);
      }
      //this.cdr.detectChanges();
    })));

    this.subscriptions.push(this.settings.uomChanged.subscribe(newUom => this.ngZone.run(() => {
      this.selectedUom = this.uoms.find(uom => uom.code === newUom.code);
      //this.cdr.detectChanges();
    })));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }



  onUomClick() {
    this.toggleUom = !this.toggleUom;
    this.showCountryList = false;
    this.toggleLanguage = false;
  }

  onUomSelect(uom: Uom): void {
    this.toggleUom = false;
    this.settings.uom = uom;
  }

  onLangClick() {
    this.toggleUom = false;
    this.showCountryList = false;
    this.toggleLanguage = !this.toggleLanguage;
  }

  onLangPlateClick() {
    this.toggleLanguage = !this.toggleLanguage;
    this.toggleUom = false;
  }

  modalBackdropClick(){
    this.toggleLanguage = false;
    this.toggleUom = false;
    this.showCountryList = false;
    this.isNavbarCollapsed = true;
    this.onClickDisplay = false;
  }

  onClick(){
    this.isNavbarCollapsed = !this.isNavbarCollapsed;
    if(this.isNavbarCollapsed){
      this.onClickDisplay = false;
    }else{
      this.onClickDisplay = true;
    }
  }

}
