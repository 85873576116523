import { animate, AnimationBuilder, style } from '@angular/animations';
import { Component, OnInit, Input, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ServicePoint } from '../backend.service';
import { SettingsService } from '../settings.service';

@Component({
    selector: 'app-sp-detail-tooltip',
    templateUrl: './sp-detail-tooltip.component.html',
    styleUrls: ['./sp-detail-tooltip.component.scss']
})
export class SpDetailTooltipComponent implements OnInit,AfterViewInit {

    @Input() servicePoint: ServicePoint;

    private hrFormat24: boolean;
    isBlinking = false;

    blinkElementStyle: string = "blinker 1s linear 2";
    @ViewChild('blinkElement',{static: false}) blinkElement: ElementRef;
    //safeAnimationStyle: SafeStyle;
    constructor(public settings: SettingsService, private animationBuilder: AnimationBuilder, private sanitizer: DomSanitizer) {
        this.hrFormat24 = settings.languageCountry.timeFormat !== 'AMPM';
    }
    ngAfterViewInit(): void {
        this.applyBlinkAnimationClass();
    }

    ngOnInit() {
        this.initialize();
        if(this.settings.appConfig.svpdetailBlinkingText) {
            this.blinkElementStyle = this.settings.appConfig.svpdetailBlinkingText;
        }
    }

    private initialize() {
        if (this.servicePoint && this.servicePoint.shippingCutOffTime) {
            this.servicePoint.shippingCutOffTime = this.servicePoint.shippingCutOffTime.includes('000') ?
                this.formatHour(this.servicePoint.shippingCutOffTime) : this.servicePoint.shippingCutOffTime;
        }
    }

    private formatHour(time) {
        if (!time || time === '-') {
            return null;
        }
        
        if (this.hrFormat24) {
            const parts = time.split(':');
            if (parts.length > 1) {
                const hour = parts[0];
                const min = parts[1];
                return hour + ':' + min;
            }
        }
        else {
            if (!(time.includes('AM') || time.includes('PM'))) {
                const parts = time.split(':');
                const hour = parts[0];
                
                if (hour >= 12) {
                    return (hour === '12' ? hour : hour - 12) + ':' + parts[1] + ' PM';
                } else {
                    return Number(hour) + ':' + parts[1] + ' AM';
                }
            }
        }
        
        return time; 
    }

    /* This will also work. But commented out because in line style issues with bing map. Awaiting response from the support team*/
    applyBlinkAnimation() {
        if (this.blinkElement) {
            const nativeElement = this.blinkElement.nativeElement;
            // Remove and add the class to force the animation
            if (nativeElement && nativeElement.style) {
                nativeElement.style.animation = ''; 
                setTimeout(() => {
                    nativeElement.offsetHeight; // Trigger repaint
                    nativeElement.style.animation = this.blinkElementStyle;   
                }, 25);
               
            }
        }
    }

    /* This is added to fix the CSP w.r.t inline styles usage*/
    applyBlinkAnimationClass() {
        if (this.blinkElement) {
            this.isBlinking = false; // Remove the class
            setTimeout(() => {
              this.isBlinking = true; // Add the class back to force the animation
            }, 25);
          }
    }


    /*applyAnimation() {
        const animation = this.animationBuilder.build([
          style({ opacity: 0 }), // Initial style
          animate('2s', style({ opacity: 1 })) // Animation steps
        ]);
    
        const player = animation.create(this.blinkElement.nativeElement);
        player.play();
      }*/

}
