import { Injectable } from '@angular/core';
import { BackendService } from '../backend.service';
import { Meta } from '@angular/platform-browser';
import { Utils } from '../common/utils';

declare const window: any;

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  constructor(private backend: BackendService, private metaService: Meta) { }

  log(message: any) {
    //console.log("LOGGER",this.backend? this.backend.transcationId : 0, new Date(), JSON.stringify(message));
    this.saveLogs({
      id: this.backend? this.backend.transcationId : 0,
      date: new Date(),
      message: JSON.stringify(message)
    });
  }

  saveLogs(data) {
    return this.backend.saveLogs(data);
  }


  isEnableLogs() {
    return this.backend.enableLogs || this.getMetaContent('name=enableLogs') === 'true' || (Utils.isJsApi() && window.gspl.params.enableLogs);
  }

  getMetaContent(selector) {
    return this.metaService.getTag(selector) && this.metaService.getTag(selector).getAttribute('content');
  }
}
