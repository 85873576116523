<div id="tooltipouter" class="col tool-tip-outer" > <!--width: 360px; height: 250px;-->
    <div *ngIf="servicePoint.svpStatus && servicePoint.svpStatus=='A'">
        <div *ngIf="servicePoint.shippingCutOffTime"  class="row text-bg-red cut-off">
            <div #blinkElement class="col px-2 font-weight-bold text-white font-size-14" [class.blink]="isBlinking">{{('spl.result.ship_today' | translate | uppercase).replace('{0}', servicePoint.shippingCutOffTime)}}</div>
        </div>
    </div>
    <div id="tooltip" class="row px-2 py-2 detail-bg">  <!--style="bottom: 40px; position: absolute; width: 100%;"-->
        <div class="col">
            <div class="row">
                <div class="col">
                    <div *ngIf="servicePoint.svpStatus && servicePoint.svpStatus!='A'" class="row mb-2">
                        <app-temp-close></app-temp-close><br/>
                    </div>
                    <div class="row">
                        <div class="col pl-0 pr-2 mb-auto text-14">
                            <span>{{servicePoint.servicePointName}}</span><br>
                            <span class="font-weight-bold">{{servicePoint.localName}}</span>
                            <div>
                                <span *ngIf="servicePoint.address.addressLine1">{{servicePoint.address.addressLine1}}</span>
                                <span *ngIf="servicePoint.address.addressLine2">&nbsp;{{servicePoint.address.addressLine2}}</span>
                                <span *ngIf="servicePoint.address.addressLine3">&nbsp;{{servicePoint.address.addressLine3}}</span>
                                <br/>
                                <span *ngIf="servicePoint.address.zipCode">{{servicePoint.address.zipCode}}</span>
                                <span *ngIf="servicePoint.address.countryDivisionCode">&nbsp;{{servicePoint.address.countryDivisionCode}},</span>
                                <span *ngIf="servicePoint.address.city">&nbsp;{{servicePoint.address.city}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-auto mb-auto">
                    <div class="row height-28"></div>
                    <div class="row align-top height-32">
                        <div class="col-auto px-0 mx-auto"></div>
                        <div *ngIf="(servicePoint.partner?.promotion && !servicePoint.partner?.promotion.isClose) || (servicePoint.promotion && !servicePoint.promotion.isClose)" class="col-auto px-1"></div>
                        <span *ngIf="(servicePoint.partner?.promotion && !servicePoint.partner?.promotion.isClose) || (servicePoint.promotion && !servicePoint.promotion.isClose)" class="col-auto px-0"><img class="height-width-32" [src]="settings.backendUrl + '/assets/promotion-icon.png'"></span>
                        <div *ngIf="servicePoint.servicePointCapabilities.labelFree" class="col-auto px-1"></div>
                        <span *ngIf="servicePoint.servicePointCapabilities.labelFree" class="col-auto px-0"><img class="height-width-26 margin-top-4" [src]="settings.backendUrl + '/assets/qr-icon.svg'"></span>
                    </div>
                    <div *ngIf="servicePoint.distance" class="row mt-1 align-top">
                        <div class="col-auto px-0 mx-auto"></div>
                        <span class="col-auto px-0"><img class="height-width-20" [src]="settings.backendUrl + '/assets/distance-icon.svg'"></span>
                        <div class="col-auto px-1"></div>
                        <div class="col-auto px-0 text-nowrap">{{servicePoint.distance}}</div>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col">
                    <div class="row">
                        <span *ngIf="servicePoint.servicePointCapabilities.disabledAccess" class="col-auto px-0">
                            <img title="{{'spl.result.wheelchair.title'|translate}}" class="height-width-26" [src]="settings.backendUrl + '/assets/wheelchair.svg'">
                        </span>
                        <div *ngIf="servicePoint.servicePointCapabilities.disabledAccess" class="col-auto pl-1 pr-0"></div>
                        <span *ngIf="servicePoint.servicePointCapabilities.parkingAvailable" class="col-auto px-0">
                            <img title="{{'spl.result.parking.title'|translate}}" class="height-width-26" [src]="settings.backendUrl + '/assets/parking.svg'">
                        </span>
                        <div class="col">&nbsp;</div>
                    </div>
                </div>
                <div class="col-auto"></div>
            </div>
        </div>
    </div>
</div>