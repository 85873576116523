import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SettingsService } from '../settings.service';
import { SupportedBrowser } from '../backend.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: '[app-unsupported-browsers]',
  templateUrl: './unsupported-browsers.component.html',
  styleUrls: ['./unsupported-browsers.component.scss']
})
export class UnsupportedBrowsersComponent implements OnInit {

  supportedBrowsers: Array<SupportedBrowser>;

  @Output() closed = new EventEmitter<any>();

  constructor(public settings: SettingsService, private deviceDetectorService: DeviceDetectorService) { }

  ngOnInit() {
    this.supportedBrowsers = this.settings.appConfig.supportedBrowsers.filter(supportedBrowser =>
      (supportedBrowser.device === this.deviceDetectorService.device ||
      supportedBrowser.device === this.deviceDetectorService.os));
  }
}
