import { Directive, Input, ViewContainerRef, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Utils } from '../common/utils';
import { SettingsService } from '../settings.service';

@Directive({
    selector: '[canHide]'
})
export class CanHideDirective {

    @Input()
    set canHide(values: string) {
        const params = this.settings.getParams(Utils.isJsApi() ? this.settings.jsApiPageAttributes : this.route.snapshot.queryParams);
        const hide: string = params['hide'];
        const hides = hide ? hide.split(',') : null;
        if (hides && values && values.split(',').some(value => {
            if (value.includes('&')) {
                const value1 = value.replace(/\!/g, '');
                const valueAnds = value1.split('&');
                const and = valueAnds.every(valueAnd => hides.includes(valueAnd));
                return !value.startsWith('!') && and;
            } else {
                if (value.startsWith('*')) {
                    value = value.replace(/\*/g, '');
                    return value === hide;
                } else {
                    return hides.includes(value);
                }
            }
        })
        ) {
            this.viewContainerRef.clear();
        } else {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
    }

    constructor(private templateRef: TemplateRef<any>, private viewContainerRef: ViewContainerRef, private route: ActivatedRoute, private settings: SettingsService) { }
}
