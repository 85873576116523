import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LocalCacheService {

    constructor() {
    }

    set(key: string, value: any, expirationInMins: number) {
        if (value) {
            const expiration = Date.now() + (expirationInMins * 60000);
            localStorage.setItem('lc.' + key, JSON.stringify({expiration, value}));
        }
    }

    get(key: string) {
        const str = localStorage.getItem('lc.' + key);
        if (!str) {
            return null;
        }

        const item = JSON.parse(str);
        if (item.value && item.expiration && item.expiration > Date.now()) {
            return item.value;
        } else {
            return null;
        }
    }

    remove(key: string){
        localStorage.removeItem('lc.' + key);
    }
}
