import {Injectable, Injector, Optional, ElementRef} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Router, Params, ActivatedRoute} from '@angular/router';
import { SettingsService } from '../settings.service';
import { UntypedFormControl } from '@angular/forms';

declare const window: any;
declare const navigator: any;

const monthTranslationMap = [
    'spl.result.month_long_january',
    'spl.result.month_long_february',
    'spl.result.month_long_march',
    'spl.result.month_long_april',
    'spl.result.month_long_may',
    'spl.result.month_long_june',
    'spl.result.month_long_july',
    'spl.result.month_long_august',
    'spl.result.month_long_september',
    'spl.result.month_long_october',
    'spl.result.month_long_november',
    'spl.result.month_long_december'
];

@Injectable({
    providedIn: 'root'
})
export class Utils {

    public static readonly SERVICE_POINT_RESULTS: number = 10;
    public static readonly PIN_SELECT_TYPE: string = 'pin';
    public static readonly LIST_SELECT_TYPE: string = 'list';
    public static readonly POUNDS_TO_KG_FACTOR: number = 2.20462;
    public static readonly IN_TO_CM_FACTOR: number = 0.393701;
    static coordsMatcher = /[0-9]+\.*[0-9]*\s*,\s*[0-9]+\.*[0-9]*/;
    public static readonly ID_COLON: string= 'ID:';
    public static readonly IDF_COLON: string= 'IDF:';
    public static readonly MIN_CHARS: number=3;
    //private static queryString = require('query-string');

    public static readonly MARKER_NOT_SELECTED: string = '/assets/icon-marker.svg';
    public static readonly MARKER_SELECTED: string = '/assets/icon-marker-selected.svg';
    public static readonly LOCKER_MARKER_NOT_SELECTED: string = '/assets/icon-marker-locker.svg';
    public static readonly LOCKER_MARKER_SELECTED: string = '/assets/icon-marker-locker-selected.svg';
    public static readonly CLUSTER_MARKER: string = '/assets/icon-marker-cluster-good.png';
    public static readonly LIGHT_VERSION_MARKER: string = '/assets/lightVersion/marker_small_push_pin.png';
    public static readonly ICON_LOCATION: string = '/assets/icon-location.png';

    public static readonly scriptLanguages: string[] = ["CHINESE","JAPANESE","INDONESIAN","BULGARIAN","KOREAN","HUNGARIAN","ITALIAN","LITHUANIAN","ALBANIAN","THAI"];

    snackBarRefs = [];

    months: Promise<any> = null;
    
    constructor(private translate: TranslateService, private injector: Injector, @Optional() private router: Router) {
        router.events.subscribe(() => {
            this.snackBarRefs.forEach(sRef => {
                sRef.dismiss();
            });
        });
    }

    static isAddressAnID(address) {
        if (!address) {
            return false;
        }
        return address.toUpperCase().startsWith(Utils.ID_COLON) || address.toUpperCase().startsWith(Utils.IDF_COLON);
    }

    static isAddressAValidValue(address) {
        if (!address) {
            return false;
        }
        address = address.trim();
        if(address.toUpperCase().startsWith(Utils.ID_COLON) && (address.substring(Utils.ID_COLON.length, address.length).trim().length==6)){
            return true;
        }
        if(address.toUpperCase().startsWith(Utils.IDF_COLON) && (address.substring(Utils.IDF_COLON.length, address.length).trim().length==6)){
            return true;
        }
        if(!(address.toUpperCase().startsWith(Utils.ID_COLON) || address.toUpperCase().startsWith(Utils.IDF_COLON)) && address.length >= this.MIN_CHARS){
            return true;
        }
        return false;
    }

    static isAddressCoordinates(address) {
        if (!address) {
            return false;
        }
        return this.coordsMatcher.test(address);
    }

    static isJsApi() {
        return window.jsApi;
    }

    static isMobile() {
        return window.innerWidth <= 768;
    }

    static getQueryParams(settings: SettingsService): Params {
        //const params: Params = Utils.queryString.parse(window.location.search);
        //return settings.getParams(params);
        const search = window.location.search;
        const result = {};
        search.split('?').pop().split('&').forEach(pairStr => {
            const pair = pairStr.split('=');
            result[pair[0]] = pair[1];
        });
        //return result;
        return settings.getParams(result);
    }

    static shrinkText(elem, runLimit, heightLimit, count = 0) {
        if (!elem) {
            return;
        }
        count += 1;
        const fSize = elem.style.fontSize || '14px';
        const height = elem.clientHeight;

        if (height > heightLimit) {

            elem.style.fontSize = ((fSize.split('px')[0]) - 1) + 'px';
            if (count < runLimit) { // max recursions
                this.shrinkText(elem, runLimit, heightLimit, count);
            }
        }
    }


    static makeVisible(elem) {
        if (!elem) {
            return;
        }

        elem.style.display = 'block';
    }    

    static makeNotVisible(elem) {
        if (!elem) {
            return;
        }

        elem.style.display = 'none';
    }    


    static determineWordBreaking(element) {

        if (element && element.textContent && !element.textContent.includes(' ')) {
            // text does not contain spaces, pre-emtively enable word breaking
            element.style.overflowWrap = 'break-word';
            element.style.wordBreak = 'break-all';
        }
    }

    static isIOS() {
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    }

    static isWindowsDevice() {
        return (navigator.userAgent.indexOf('Windows Phone') !== -1);
    }
    static isAndroidDevice() {
        return /Android|android/.test(navigator.userAgent) && !window.MSStream;
    }




    clearFormControl(control: UntypedFormControl, value: string = null) {
        if (!control.disabled) {
            control.reset();
            if (value) {
                control.setValue(value);
            }
        }
    }

    clearFormControl2(control: UntypedFormControl, elementRef: ElementRef) {
        if (!this.isElementRefDisabled(elementRef)) {
            control.reset();
        }
    }

    isElementRefDisabled(elementRef: ElementRef): boolean {
        if (elementRef) {
            return elementRef.nativeElement.readOnly;
        }
        return false;
    }

    showTranslatedMsg(snackbar, message) {
        if (snackbar && message) {
            this.translate.get(message).toPromise().then(translation => {
                    const sRef = snackbar.open(translation, '✖', {
                        duration: 999 * 1000
                    });
                    this.snackBarRefs.push(sRef);
                }
            );
        }
    }

    getTranslatedMonths(): Promise<any> {
        if (!this.months) {
            this.months = Promise.all(monthTranslationMap.map(month => this.translate.get(month).toPromise()));
        }

        return this.months;
    }

    openExternalLinks(url: string, route: ActivatedRoute = null) {
        const eventIdentifier: string = route ? route.snapshot.queryParams['eventIdentifier'] : null;
        if (eventIdentifier) {
            if(eventIdentifier == 'inAppBrowser-'){
                window.open(url, '_system');
            } else {
                window.parent.postMessage(eventIdentifier + url, '*');
            }
        } else {
            window.open(url, '_blank');
        }
    }

    static checkScriptLanguage(currentLanguageSelected: string){
        var isScriptLanguage: boolean = false;
        this.scriptLanguages.forEach(element=> {
            if(currentLanguageSelected.indexOf(element)>=0){
                isScriptLanguage = true;
            }
        });
        return isScriptLanguage;
    }

    public static isOneTrustLoaded(): boolean{
        return typeof window.OneTrust != 'undefined';
    }

    static getIconForPins(parameter: string): string {
        if (parameter === 'marker_not_selected') {
            return this.MARKER_NOT_SELECTED;
        } else if (parameter === 'marker_selected') {
            return this.MARKER_SELECTED;
        } else if (parameter === 'locker_marker_not_selected') {
            return this.LOCKER_MARKER_NOT_SELECTED;
        } else if (parameter === 'locker_marker_selected') {
            return this.LOCKER_MARKER_SELECTED;
        } else if (parameter === 'cluster_marker') {
            return this.CLUSTER_MARKER;
        } else if (parameter === 'light_version_marker'){
            return this.LIGHT_VERSION_MARKER
        } else if (parameter === 'icon_location'){
            return this.ICON_LOCATION;
        }
    }
}
