import {Injectable} from '@angular/core';
import {BackendService} from './backend.service';
import { ActivatedRouteSnapshot } from '@angular/router';
import {SettingsService} from './settings.service';

@Injectable({
    providedIn: 'root'
})
export class SearchResolver  {

    constructor(private backend: BackendService, public settings: SettingsService) {
    }
    
    resolve(route: ActivatedRouteSnapshot): Promise<any> {
        return this.settings.languageResovled.then(() => {
            const queryParams = this.settings.getParams(route.queryParams);
            const resultsParam = this.backend.queryParamsToSearchParams(queryParams, this.settings);
            return this.backend.searchServicePoints(resultsParam, this.settings);
        });
    }
}
