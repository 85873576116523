export interface Capability {
    code: string;
    label: string;
}

export const sendSecondLevel: Array<Capability> = [
    {code: '80', label: 'spl.search.sl.send.ship_label'},
    {code: '89', label: 'spl.details.rs.do.without_shipment'},
    {code: '88', label: 'spl.search.sl.send.neither'},
];
export const allSendCode = sendSecondLevel.map(opt => opt.code).join(',');
export const allSend: Capability = {code: allSendCode, label: 'spl.search.all.above'}

export const collectSecondLevel: Array<Capability> = [
    {code: '78,79', label: 'spl.search.sl.collect_shipment'},    
    {code: '86,87', label: 'spl.search.sl.send_shipment'},
];
export const allCollectCode =  collectSecondLevel.map(opt => opt.code).join(',');
export const allCollect: Capability = {code: allCollectCode, label: 'spl.search.all.above'}

export const allCode = allSendCode + ',' + allCollectCode;
