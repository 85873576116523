import {Component, EventEmitter, Input, NgZone, OnInit, Optional, Output, AfterViewInit, OnDestroy, ViewChild, ChangeDetectorRef, ElementRef, HostListener, Inject, Renderer2} from '@angular/core';
import {AddressEvent, AddressEventType} from '../address-input/address-input.component';
import { CapabilitySelection, CapabilityCategory } from '../common/capability-selection';
import {ActivatedRoute, Router, Params} from '@angular/router';
import {UntypedFormControl} from '@angular/forms';
import {environment} from '../../environments/environment';
import {MatDialog as MatDialog} from '@angular/material/dialog';
import {MatSnackBar as MatSnackBar} from '@angular/material/snack-bar';
import {BackendService, Countries, Country, MessageDetail, PageParameter} from '../backend.service';
import {SettingsService} from '../settings.service';
import {Utils} from '../common/utils';
//import uuid from 'uuid/v4';
import { v4 as uuidv4 } from 'uuid';
import {AdvSearchHintComponent} from '../adv-search-hint/adv-search-hint.component';
import { NavigateService } from '../common/navigate.service';
import { Subscription } from 'rxjs';
import { Hide } from '../common/hide';
import { JsApiComponentBase } from '../common/jsapi-component-base';
import { LangListComponent } from '../lang-list/lang-list.component';
import { CountryInfoWithCode } from '../model/CountryInfo';
import { CookieService } from 'ngx-cookie-service';
import { DialogService } from '../common/dialog.service';
import { SeoService } from '../seo/seo.service';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { LanguageInfo } from '../model/LanguageInfo';

declare const window: any;
declare const OneTrust: any;

@Component({
    selector: 'app-basic-search-controls',
    templateUrl: './basic-search-controls.component.html',
    styleUrls: ['./basic-search-controls.component.scss'],
    // encapsulation: ViewEncapsulation.ShadowDom
})
export class BasicSearchControlsComponent extends JsApiComponentBase implements OnInit, AfterViewInit, OnDestroy {
    // TODO RTL languages

    @Input() spacingBelow = false;
    @ViewChild('langList', {static: false}) langList: LangListComponent;
    @ViewChild('iconRef', {static: false}) iconRef: ElementRef;

    countries: Countries;
    hide: Hide;
    selectedCapability: CapabilitySelection;
    placeId: string;
    sessionToken: string;
    selectedCountry = new UntypedFormControl();
    searchInProgress = false;
    address = new UntypedFormControl('');
    geoLoading = false;
    isMobile = Utils.isMobile;
    isJsApi = Utils.isJsApi;

    private suggestionPickedAddressEvent: AddressEvent;
    private sessionTokenAddressEvent: AddressEvent;
    private isUomChanged = false;
    private subscriptions: Array<Subscription> = [];
    private queryParams: Params;

    private isCountryPopulatedByAddress: boolean = false;

    expressMessage: MessageDetail;
    expressMessageWithLink: MessageDetail;

    get mobileLink(): string {
        return (this.settings.languageCountry.mobileDomain && this.settings.languageCountry.mobileDomain !== '(null)') ? this.settings.languageCountry.mobileDomain : 'dhl.com';
    }

    constructor(@Optional() private router: Router, @Optional() private snackBar: MatSnackBar,
                private backend: BackendService, @Optional() public settings: SettingsService,
                private utils: Utils, @Optional() private route: ActivatedRoute,
                public dialog: MatDialog, public ngZone: NgZone, private navigate: NavigateService, private ref: ChangeDetectorRef,
                private cookies: CookieService, private dialogService: DialogService, private seoService: SeoService, 
                private translateService: TranslateService, @Inject(DOCUMENT) private document: Document, private renderer: Renderer2) {
        super();
        this.countries = this.settings.countries;
        this.hide = new Hide();
        this.selectedCapability = new CapabilitySelection(this.settings, this.utils, this.hide, false);
    }

    ngOnInit() {
        this.initializeSubscriptions();
        //resolve();
        this.settings.cookieSettingForLang();//cookie settings changes
        this.initialize();
        /*if (this.settings.isBannerSuppresedClient(this.route.snapshot.queryParams)) {
            this.renderer.addClass(document.body, 'hide-onetrust-elements');
        }*/
        if (Utils.isJsApi()) {
            this.loadScript(this.settings.appConfig.cookiesDataDomainUrl).then(() => {
                // Do something after the script is loaded
            }).catch(error => {
                console.error('Failed to load script:', error);
            });
        }
    }

    setSeoFields() {
        this.seoService.setPageTitle(this.translateService.instant('spl.search.seo.page_title'));
        this.seoService.updateTags([{ name: 'description', content:  this.translateService.instant('spl.search.seo.meta_desc')},
        { name: 'keywords', content: this.translateService.instant('spl.search.seo.meta_keywords')}]);
    }

    onOpen() {
    }

    private initializeSubscriptions() {
        this.subscriptions.push(this.settings.uomChanged.subscribe(uom => {
            this.isUomChanged = true;
        }));

        this.subscriptions.push(this.settings.messageDetailFetched.subscribe(() => {
            this.ref.detectChanges();
        }));

        this.subscriptions.push(this.translateService.stream(['spl.search.seo.page_title','spl.search.seo.meta_desc','spl.search.seo.meta_keywords']).subscribe((translatedValue: string) => {
            this.setSeoFields();
        }));
    }

    private initialize() {
        //this.settings.suppressCookiesPanel();
        this.setSeoFields();
        if (Utils.isJsApi()) {
            this.queryParams = this.settings.jsApiPageAttributes;
            this.settings.initializeParamsJsApi(this.queryParams);
        } else {
            this.queryParams = this.settings.getParams(this.route.snapshot.queryParams);
            if(this.settings.cookieDisplayStatus){
                //this.settings.loadcookieBannerScript();
                //this.settings.cookieLinkDisplayStatus = true; //694365
            } else {
                //this.settings.cookieLinkDisplayStatus = false; //694365
            }
            if(this.settings.isParameterizedExtClientCallValidationFailed){
                this.router.navigate(['/error']);
            }
        }
        
        if (!this.queryParams.latitude && !this.queryParams.longitude){
            this.initializeCountryAndCapabilities();
            this.selectedCountry.setValue(this.resolveSelectedCountry());
        }       
        if (this.queryParams) {
            if (this.queryParams.zipcode) {
                this.address.setValue(this.queryParams.zipcode);
            } else if (this.queryParams.address) {
                this.address.setValue(this.queryParams.address);
            } else if (this.queryParams.servicePointID) {
                this.address.setValue('ID: ' + this.queryParams.servicePointID);
            } else if (this.queryParams.latitude && this.queryParams.longitude) {
                this.geoLoading = true;
                this.selectedCountry.setValue(null);
                this.showPosition(this.queryParams.latitude, this.queryParams.longitude);
            }
            if (this.queryParams.action) {
                // TODO open stuff
            }
            if(this.queryParams.svpStatus){
                this.settings.svpStatus = this.queryParams.svpStatus;
            }
            
        }

        if(Utils.isJsApi() && ((!window.gspl.params.wsbSearch) || ( window.gspl.params.wsbSearch && window.gspl.params.wsbSearch ==true))) {
            setTimeout(() => {
                this.ref.detectChanges();
            }, 300);
        }
        
        if (!this.queryParams.latitude && !this.queryParams.longitude){
            this.populateMessageDetails();
        }
    }

    initializeCountryAndCapabilities(){
        this.hide.initialize(this.queryParams);
        this.selectedCapability.initialize(this.queryParams);
        this.selectedCapability.setCapabilityOptions();
        this.selectedCapability.resolve(true);
    }

    populateMessageDetails(){
        var ppcArray: string[] = [];
        if(this.selectedCapability && this.selectedCapability.handling && this.selectedCapability.payment && this.selectedCapability.handling.value && this.selectedCapability.payment.value) {
            var multipleHandlingCodes: string[] = this.selectedCapability.handling.value.toString().split(",");
            if(multipleHandlingCodes.length > 1 && this.queryParams['capability'] &&
                !(this.queryParams['capability'].includes(multipleHandlingCodes[0]) &&
                this.queryParams['capability'].includes(multipleHandlingCodes[1]))) {
                ppcArray.push(this.selectedCapability.payment.value+","+multipleHandlingCodes[0]);
                ppcArray.push(this.selectedCapability.payment.value+","+multipleHandlingCodes[1]);
            } else{
                ppcArray.push(this.selectedCapability.payment.value+","+this.selectedCapability.handling.value);
            }
        }
        if(this.selectedCapability && this.selectedCapability.collect && this.selectedCapability.collect.value){
            ppcArray.push(this.selectedCapability.collect.value);
        }
        this.settings.ppcsArray = ppcArray;
        if (this.settings.language && this.settings.selectedCountry && this.settings.selectedCountry.secondLevel && this.backend.clientAppCode && this.settings.selectedCountry.showMessage && this.settings.appConfig.messageTypes) {
            this.settings.expressMessage=undefined;
            this.settings.expressMessageWithLink=undefined;
            this.settings.getMessageDetails(this.settings.selectedCountry.id, this.settings.language.id.toString(), this.settings.clientAppCode.toString(), this.settings.appConfig.messageTypes.join(",")).then(()=>{
                this.settings.getMessageDetailsByPriorityForExpressMessage(ppcArray);
                //setTimeout(() => {
                    this.ref.detectChanges(); 
                //}, 0);  
            });
        }
    }

    ngAfterViewInit() {
        if (Utils.isJsApi() && window.gspl.basicSearchAfterViewInit) {
            window.gspl.basicSearchAfterViewInit();
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    openDialog(){//565958
        this.dialogService.openDialog(this.dialog, this.iconRef);
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if(this.dialogService.isInfoDialogOpen) {
            this.dialogService.updateDialogPosition(this.dialogService.dialogInfoRef, this.dialogService.iconRef);
        }
    }

    isAddressLongEnough() {
        return this.address.value && this.address.value.length >= environment.minCharsToActivateUi;
    }

    onCountrySelect(country: Country) {
        if(!this.isCountryPopulatedByAddress && !this.settings.isSelectCountryFreezed()){
            this.address.setValue("");   
        } else {
            this.isCountryPopulatedByAddress = false;  
        }
      if(country && country.capabilityCustom && country.capabilityCustom.collects && country.capabilityCustom.collects){
            this.settings.handleCustomCapabilitiesOnCountryChange(country).then(()=>{this.onCountryChanged();});
        } else {
            this.settings.paymentOptionsCustom = [];
            this.settings.collectOptionsCustom = [];
            this.onCountryChanged();
        }
    }

    onCountryChanged(){
        this.selectedCapability.setCapabilityOptions();
        if (this.isAddressLongEnough() && !this.isID()) {
            this.selectedCapability.resolve(false);
        } else {
            this.selectedCapability.reset();
        }//678061
        this.settings.resolveUomOnCountrySelect();
    }

    onAddressEvent(event: AddressEvent) {
        switch (event.type) {
            case AddressEventType.SUGGESTION_PICKED:
                if (!this.selectedCountry.value) {
                    if (event.placeId) {
                        this.backend.getCountryByPlaceId(event.placeId, 
                            event.providerId, 
                        	this.settings ? this.settings.language.iso2Language : 'eng',
				            this.settings ? this.settings.languageCountry.bingCulture2 : 'en-US',				            
                            event.countryCode, event.sessionToken).then(countryCode => {
                                this.isCountryPopulatedByAddress = true;
                                const country = this.countries.countryList.find(country => country.value === countryCode);
	                            this.selectedCountry.setValue(country);
                                //this.cdr.detectChanges(); // not sure if needed
	                        });
                    }
                }
                this.suggestionPickedAddressEvent = event;
                break;
            case AddressEventType.INPUT_FILLED_ENOUGH:
            case AddressEventType.INPUT_INSUFFICIENT:
                this.placeId = event.placeId; // why not used ?
                this.sessionToken = event.sessionToken;
                this.suggestionPickedAddressEvent = null;
                if (this.isAddressLongEnough() && !this.isID()) {
                    this.selectedCapability.setCapabilityOptions();
                    this.selectedCapability.resolve(false);
                } else {
                    if(!this.address.value || this.isID()){
                        this.selectedCapability.reset();
                    }  
                }
                break;
            case AddressEventType.SUGGESTION_LOADED:
                this.sessionTokenAddressEvent = event;
                break;
        }
    }

    doSearch() {
        this.searchInProgress = true;
        this.settings.isResultReachedFromHomeLanding = true;
        this.settings.isUserInputLocationNeeded = true;
        this.settings.isQrCodeAccessed = false;
        const pageParam: PageParameter = {};
        pageParam.address = this.address.value;
        pageParam.capability = this.selectedCapability.getSelectedCodeValid1();
        this.setPieceLimitationPageParameter(pageParam, this.queryParams);
        this.setOpeningHoursPageParameter(pageParam, this.queryParams);

        if (this.suggestionPickedAddressEvent) {
            this.settings.placeId = this.suggestionPickedAddressEvent.placeId;
            this.settings.providerId = this.suggestionPickedAddressEvent.providerId;
        }
        if (this.sessionTokenAddressEvent) {
            this.settings.sessionToken = this.sessionTokenAddressEvent.sessionToken;
        }
        if (Utils.isJsApi()) {
            //window.gspl.selectedCountry = (<Country>this.selectedCountry.value).value;
            //window.gspl.capability = this.selectedCapability.getCode();
            const jsApiParams: Params = this.navigate.getJsApiResultParams(pageParam);
            const resultsParam = this.backend.queryParamsToSearchParams(jsApiParams, this.settings);
            this.settings.jsApiPageParam = resultsParam;
            this.settings.setJsApiPageAttributes({ capability: resultsParam.capability });
            if (window.gspl.wsbSearch) {
                this.backend.searchServicePoints(resultsParam, this.settings).then(searchResult => {
                    this.settings.searchResult = searchResult;
                    this.onSearch.emit(searchResult);
                });
            } else {
                this.onSearch.emit(resultsParam);
            }
        } else {
            if (!this.selectedCountry.value && !this.isID()) {
                if (!this.sessionToken) {
                    this.sessionToken = uuidv4();
                }
                this.backend.getAddressSuggestions(this.address.value, 
                    this.settings.language.iso2Language, 
                    this.settings.languageCountry.bingCulture2, 
                    null, null, false,
                    this.sessionToken).then(results => {
                        if (results && results.length > 0) {
                            return this.backend.getCountryByPlaceId(results[0].placeId, 
                            	results[0].providerId, 
                            	this.settings.language.iso2Language,
            					this.settings.languageCountry.bingCulture2,
                               	results[0].countryCode, this.sessionToken).then(countryCode => {
                                    pageParam.countryCode= countryCode;
                                    this.settings.placeId = results[0].placeId;
                                    this.settings.providerId = results[0].providerId;
                                    this.settings.sessionToken = this.sessionToken;
                                    this.navigate.toResult(this.settings.getParams(this.route.snapshot.queryParams), pageParam);                                   
                                });
                        } else {
                            this.router.navigate(['/not-found']);
                        }
                    });
            } else {
                this.navigate.toResult(this.queryParams, pageParam);
            }
        }
    }

    private setPieceLimitationPageParameter(pageParam: PageParameter, queryParams: Params) {
        const freeze: string = queryParams['freeze'];
        let freezeArray: string[] = [];
        let pieceLimitationIsFreeze = false;
        if (freeze) {
            freezeArray = freeze.split(',');
            pieceLimitationIsFreeze = freezeArray.includes('pieceLimitation');
        }

        const weight = queryParams['weight'];
        if (weight && (this.hide.pieceLimitation || pieceLimitationIsFreeze || freezeArray.includes('weight'))) {
            pageParam.weight = weight;
        }
        const weightUom = queryParams['weightUom'];
        if (weightUom && (this.hide.pieceLimitation || pieceLimitationIsFreeze || freezeArray.includes('weightUom'))) {
            pageParam.weightUom = weightUom;
        }
        const length = queryParams['length'];
        if (length && (this.hide.pieceLimitation || pieceLimitationIsFreeze || freezeArray.includes('length'))) {
            pageParam.length = length;
        }
        const width = queryParams['width'];
        if (width && (this.hide.pieceLimitation || pieceLimitationIsFreeze || freezeArray.includes('width'))) {
            pageParam.width = width;
        }
        const height = queryParams['height'];
        if (height && (this.hide.pieceLimitation || pieceLimitationIsFreeze || freezeArray.includes('height'))) {
            pageParam.height = height;
        }
        const dimensionsUom = queryParams['dimensionsUom'];
        if (dimensionsUom && (this.hide.pieceLimitation || pieceLimitationIsFreeze || freezeArray.includes('dimensionsUom'))) {
            pageParam.dimensionsUom = dimensionsUom;
        }
    }

    private setOpeningHoursPageParameter(params: PageParameter, queryParams: Params) {
        const freeze: string = queryParams['freeze'];
        let freezeArray: string[] = [];
        let openingHoursIsFreeze = false;
        if (freeze) {
            freezeArray = freeze.split(',');
            openingHoursIsFreeze = freezeArray.includes('openingHours');
        }

        const openBefore = queryParams['openBefore'];
        if (openBefore && (this.hide.openingHours || openingHoursIsFreeze || freezeArray.includes('openBefore'))) {
            params.openBefore = openBefore;
        }
        const openAfter = queryParams['openAfter'];
        if (openAfter && (this.hide.openingHours || openingHoursIsFreeze || freezeArray.includes('openAfter'))) {
            params.openAfter = openAfter;
        }
        const openDay = queryParams['openDay'];
        if (openDay && (this.hide.openingHours || openingHoursIsFreeze || freezeArray.includes('openDay'))) {
            params.openDay = openDay;
        }
    }

    private showPosition(latitude, longitude) {
        this.backend.getAddressSuggestions(latitude + ', ' + longitude,
        	this.settings ? this.settings.language.iso2Language : 'eng',
            this.settings ? this.settings.getCurrentLangCode() : 'en-US', null, null,
            true).then(result => {
            if (result && result[0]) {
                this.ngZone.run(() => {
                    this.isCountryPopulatedByAddress = true;
                    this.address['geoLocated'] = true;
                    this.address.setValue(result[0].label);
                    const country = this.countries.countryList.find(country => country.value === result[0].countryCode);
                    this.selectedCountry.setValue(country);
                    this.geoLoading = false;
                    setTimeout(() => {
                        this.initializeCountryAndCapabilities();
                        this.populateMessageDetails();
                    }, 100);
                });
            }
        });
    }

    getLocation() {
        
        const error = (err) => {
            // location fix
            if (err && err.code === 1) {
                this.utils.showTranslatedMsg(this.snackBar, 'spl.geoapi.no.permission');
            } else {
                this.utils.showTranslatedMsg(this.snackBar, 'spl.geoapi.no.geolocation');
            }
        };

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) =>
                    this.showPosition(position.coords.latitude, position.coords.longitude),
                error, {maximumAge: 600000, timeout: 10000, enableHighAccuracy: true});
        } else {
            this.utils.showTranslatedMsg(this.snackBar, 'spl.geoapi.not.supported');
        }

    }

    getSpacerClass() {
        if (this.selectedCapability) {
            if (this.selectedCapability.secondLevelEnabled &&
                [CapabilityCategory.COLLECT, CapabilityCategory.SEND].includes(this.selectedCapability.category)) {
                return ' bottom-spacer-1cap';
            } else if (this.selectedCapability.secondLevelEnabled && this.selectedCapability.category === CapabilityCategory.SEND_COLLECT) {
                return ' bottom-spacer-2cap';
            }
        }
        return '';
    }

    advancedSearchClick() {
        const pageParam: PageParameter = {
            address: this.address.value,
            capability: this.selectedCapability.getSelectedCode()
        };
        this.navigate.toAdvancedSearch(this.settings.getParams(this.route.snapshot.queryParams), pageParam, this.isUomChanged);
    }

    onAdvancedSearchClick() { // for JS api only
        this.onAdvancedSearchClicked.emit();
    }

    onMessageDetailClick(inputUrl: string) {
        let url: string = '';
        if (!/^http[s]?:\/\//.test(inputUrl)) {
            url += 'http://';
        }

        url += inputUrl;
        window.open(url,'_blank');
    }

    isCapabilityDisabled() {
        if(this.selectedCountry && this.selectedCountry.value){
            if(this.isAddressLongEnough() && !this.isID() && (this.selectedCapability && !this.selectedCapability.isFreeze)){
                return false;
            }else{
                if(this.address.value && !this.isID()&& (this.selectedCapability && !this.selectedCapability.isFreeze)){
                    return false;
                }else{
                    this.selectedCapability.clear();
                    return true;
                }
            }
        }else{
            return true;
        } 
        //return !this.isAddressLongEnough() || this.isID() || this.selectedCapability.isFreeze;
    }

    isCapabilitySelected(): boolean {
        /*return (!this.selectedCapability.secondLevelEnabled && this.selectedCapability.category !== CapabilityCategory.NONE) ||
            (this.selectedCapability.secondLevelEnabled && this.selectedCapability.getSelectedCode());*/
        return this.selectedCapability.getSelectedCodeValid1() ? true : false;
    }

    isID() {
        return this.address.value && Utils.isAddressAnID(this.address.value);
    }

    onSubmit() {
        this.doSearch();
    }

    private resolveSelectedCountry(): Country {
        const countryCodeFromUrl: string = this.queryParams['countryCode'];
        var selectedCountry = undefined;
        if (countryCodeFromUrl) { // CountryCode from URL
            selectedCountry = this.settings.countries.countryList.find(country => country.value === countryCodeFromUrl);
        } else { // CountryCode from Cookie
            const selectedCountryCookie = this.cookies.get('sc');
            if (selectedCountryCookie && selectedCountryCookie !== 'undefined') {
                const selectedCountryCookieObj = <CountryInfoWithCode>JSON.parse(selectedCountryCookie);
                selectedCountry = this.settings.countries.countryList.find(country => country.value === selectedCountryCookieObj.value);
            }
        }
        if(!selectedCountry) {
            selectedCountry = this.settings.selectedCountry;
        }
        return selectedCountry;
    }

    getClientAppCode() {
        return (this.backend.clientAppCode?(this.backend.clientAppCode.toLowerCase()==='gspl'?true:false):false);
    }

    /*setIsValidForExpressMessage(){
        if(this.settings.selectedCountry && this.settings.selectedCountry.secondLevel){
            this.settings.isValidForExpressMessage = true;
        } else {
            this.settings.isValidForExpressMessage = false;
        }
    }

    setIsValidForExpressLink(){
        if(this.settings.selectedCountry && this.settings.selectedCountry.secondLevel){
            this.settings.isValidForExpressLink = true;
        } else {
            this.settings.isValidForExpressLink = false;
        }
    }*/

    // JsApi

    @Input('show_advanced_search') showAdvancedSearch = true;

    @Output() onSearch = new EventEmitter<any>();
    @Output() onAdvancedSearchClicked = new EventEmitter<void>();
}
