import { Directive, Input, ElementRef, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Utils } from '../common/utils';
import { SettingsService } from '../settings.service';

@Directive({
    selector: '[canFreezeAnchor]'
})
export class CanFreezeAnchorDirective {

    @Input('canFreezeAnchor') value: string;

    constructor(private elementRef: ElementRef, private renderer: Renderer2, private route: ActivatedRoute, private settings: SettingsService) { }

    ngOnInit() {
        const params = this.settings.getParams(Utils.isJsApi() ? this.settings.jsApiPageAttributes : this.route.snapshot.queryParams);
        const freeze: string = params['freeze'];
        if (freeze && freeze.split(',').some(freezeParam => this.value.split(',').includes(freezeParam))) {
            this.renderer.addClass(this.elementRef.nativeElement, 'anchor-disabled');
        }
    }
}
