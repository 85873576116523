import { Component, Injector, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Utils } from '../common/utils';
import { SettingsService } from '../settings.service';
import { ActivatedRoute } from '@angular/router';
import { NavigationMap } from './../backend.service';

declare const window: any;

@Component({
  selector: 'app-navigation-modal',
  templateUrl: './navigation-modal.component.html',
  styleUrls: ['./navigation-modal.component.scss']
})
export class NavigationModalComponent implements OnInit {

  @Input() servicePoint;
  readonly activeModal: NgbActiveModal;
  private linkTarget: string;
  naviMapData: NavigationMap[];
  constructor(private injector: Injector, public settings: SettingsService, private route: ActivatedRoute, private utils: Utils) {
    this.activeModal = this.injector.get(NgbActiveModal);
  }

  ngOnInit(): void {
    this.naviMapData = this.settings.appConfig.navigateMap;
    // this.naviMapData = [
    //   {
    //     id: 1,
    //     title: 'Google',
    //     link: 'https://maps.google.com/?daddr={{lat}},{{long}}',
    //     country: 'All',
    //     imageName: 'google-maps.png',
    //     platform: 'All',
    //     language: 'All'
    //   },
    //   {
    //     id: 2,
    //     title: 'Waze',
    //     link: 'https://www.waze.com/ul?ll={{lat}},{{long}}&navigate=yes',
    //     country: 'All',
    //     imageName: 'waze-maps.png',
    //     platform: 'All',
    //     language : 'All'

    //   },
    //   {
    //     id: 3,
    //     title: 'Apple',
    //     link: 'http://maps.apple.com/?q={{lat}},{{long}}',
    //     country: 'All',
    //     imageName: 'apple-maps.png',
    //     platform: 'ios',
    //     language : 'All'

    //   },
    //   {
    //     id: 4,
    //     title: 'Bing',
    //     link: 'https://www.bing.com/maps/default.aspx?rtp=~pos.{{lat}}_{{long}}',
    //     country: 'All',
    //     imageName: 'bing-maps.png',
    //     platform: 'windows',
    //     language : 'All'
    //   }
    //   , {
    //     id: 5,
    //     title: '百度',
    //     link: 'http://api.map.baidu.com/marker?location={{lat}},{{long}}&coord_type=gcj02&output=html&src=webapp.baidu.openAPIdemo',
    //     country: 'CN',
    //     imageName: 'baidu-maps.png',
    //     platform: 'All',
    //     language: 'SIMP CHINESE'
    //   }
    // ];
    this.filterBasedOnCountry();

    if (Utils.isIOS()) {
      this.filterBasedOnDevice('ios');
    } else if (Utils.isWindowsDevice()) {
      this.filterBasedOnDevice('windows');
    } else if (Utils.isAndroidDevice()) {
      this.filterBasedOnDevice('android');
    }
  }
  filterBasedOnCountry() {
    const selectedCountry =  this.servicePoint.address.country;
    const countryBasedFilter = this.naviMapData.filter((x) =>
      (x.country === selectedCountry && x.language === this.settings.language.languageEnglish)
      || (x.language === 'All' && x.country === 'All') && (x.platform && x.platform.toLowerCase()) != 'desktop');
    this.naviMapData = countryBasedFilter;
  }
  filterBasedOnDevice(device: string) {
    const deviceBasedFilter = this.naviMapData.filter((x) => x.platform === device || x.platform === 'All');
    this.naviMapData = deviceBasedFilter;
  }

  openLinks(url: string) {
    const lat = /{{lat}}/gi;
    const long = /{{long}}/gi;
    let newURL = url.replace(lat, this.servicePoint.geoLocation.latitude);
    newURL = newURL.replace(long, this.servicePoint.geoLocation.longitude);
    if (!Utils.isJsApi()) {
      this.utils.openExternalLinks(newURL, this.route);
    } else {
      if (window.gspl.params.openWebLink) {
          this.utils.openExternalLinks(newURL);
      }
      this.settings.notifyNavigationLinkClicked(newURL);
    }
  }
}
