import {Component, OnInit} from '@angular/core';
import {MatDialogRef as MatDialogRef} from '@angular/material/dialog';
import { SettingsService } from '../settings.service';

@Component({
    selector: 'app-adv-search-hint',
    templateUrl: './adv-search-hint.component.html',
    styleUrls: ['./adv-search-hint.component.scss']
})
export class AdvSearchHintComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<AdvSearchHintComponent>, public settings: SettingsService) {
    }

    onCloseClick(): void {
        this.dialogRef.close();
    }

    ngOnInit() {
    }

}
