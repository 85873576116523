import { AfterViewInit, Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { SettingsService } from './settings.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { SupportedBrowser, BackendService } from './backend.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { filter } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { CookiesBannerLoader } from './cookies-banner-loader';
import { ConsentService } from './consent.service.ts.service';
import { Utils } from './common/utils';

declare let gtag: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit,AfterViewInit {

    showCookieConsent = false;
    showUnsupportedBrowser: boolean = false;

    constructor(public settings: SettingsService, private route: ActivatedRoute, private router: Router, private deviceDetectorService: DeviceDetectorService, 
        private backendService: BackendService,private renderer: Renderer2,@Inject(DOCUMENT) private _document: Document, private consentService: ConsentService) {
        //console.log("Initialized in appconfig",new Date());
        //this.consentService.initialize();
        // just making sure this service is inited for every screen regardless of direct usage (it has to init translations)
        if(this.settings.appConfig.googleAnalyticsTrackingId){
            this.createGoogleAnalyticsScriptTag();
        }
        this.settings.isDisplayCookiesbanner(this.route);
        this.route.queryParams.subscribe(qp => {
            if (qp.language || qp.L) {
                const languageCode = qp.language || qp.L;

            }
            if (qp.C) {
                // TODO country language
            }
            if (qp.U) {
                // TODO units mi/km
            }
        });
    }

    ngOnInit() {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
            this.settings.mobileHiding = (this.route.snapshot.queryParams.mobile && this.route.snapshot.queryParams.mobile.toLowerCase() === 'y') ? true : false;
            if (!this.settings.isError && (<any>event).url !== "/error") {
                this.showCookieConsent = true;
                this.backendService.statusSupportedBrowser().then(closeAtSession => {
                    const isUnsupportedBrowser = (!this.settings.mobileHiding) ? this.isUnsupportedBrowser() : false;
                    this.showUnsupportedBrowser = !(closeAtSession.isClose === 'true') && isUnsupportedBrowser;
                });
            }
            if ((<any>event).url !== "/error") {
                this.settings.validateForMandatoryParameters(this.route);
            }
        });
    }

    /*loadCookiesBannerDisplay(data:any){
        if(data){
            CookiesBannerLoader.loadCookiesBannerScript(data).then(() => {
                //this.consentService.initialize();
                this.settings.oneTrustScriptLoaded.emit();
            });
        }
    }*/

    ngAfterViewInit(){
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
            let loader = this.renderer.selectRootElement('#loader');
            this.renderer.setStyle(loader, 'display', 'none');
        });
        // OneTrust Banner reload issue
        if(!Utils.isJsApi()){
            this.backendService.loadCookiesBannerDisplay(this.settings.appConfig);
        }
        /*const scriptElements = document.querySelectorAll("script");
        scriptElements.forEach((scriptElement: HTMLScriptElement) => {
        const nonceValue = scriptElement.nonce;
        console.log(scriptElement.src + ` Nonce value: ${nonceValue}`);
        }); */
    }

    createGoogleAnalyticsScriptTag(){
        this.router.events.subscribe(event => {
            if(event instanceof NavigationEnd){
                try{
                    gtag('config', this.settings.appConfig.googleAnalyticsTrackingId, 
                        {
                            'page_path': event.urlAfterRedirects
                        }
                        );
                } catch(Error){
                    if((<String>Error.message).indexOf("gtag is not defined") >= 0){
                        // Supressing the error message here as this is expected
                    } else {
                        console.error(Error.message);
                    }
                    
                }
            }
          }
       )
    }

    /*checkIFrame() {
        if (window.self !== window.top || window.frameElement) {
            return true;
        }
        return false;
    }*/

    private isUnsupportedBrowser(): boolean {
        return !this.settings.appConfig.supportedBrowsers.some(supportedBrowser => {
            return (supportedBrowser.device === this.deviceDetectorService.device || 
                supportedBrowser.device === this.deviceDetectorService.os) && 
                this.isSupportedBrowserVersion(supportedBrowser);
        });
    }

    private isSupportedBrowserVersion(supportedBrowser: SupportedBrowser): boolean {
        const browsers = supportedBrowser.browser.split(',');
        const versions = supportedBrowser.version.split(',');
        if (browsers.length === versions.length) {
            let some = false;
            for (let index = 0; index < browsers.length; index++) {
                if (browsers[index] === this.deviceDetectorService.browser && this.isSupportedVersion(versions[index], supportedBrowser.id)) {
                    some = true;
                    break;
                }
            }
            return some;
        }
        return false;
    }

    private isSupportedVersion(version: string, id: number): boolean {
        const supportedVersionNo = Number(version);
        if (!isNaN(supportedVersionNo)) {
            const charArray = [...this.deviceDetectorService.browser_version.trim()];
            let browserVersion = '';
            for (let index = 0; index < charArray.length && charArray[index] !== '.'; index++) {
                const ch = charArray[index];
                if (!isNaN(Number(ch))) {
                    browserVersion = browserVersion.concat(ch);
                }
            }
            if (browserVersion.length > 0) {
                const browserVersionNo = Number(browserVersion);
                if (!isNaN(browserVersionNo)) {
                    return (browserVersionNo >= supportedVersionNo);
                }
            }
        } else {
            console.error('Invalid version (' + version + ') for Supported Browser ID=' + id);
        }
        return false;
    }

    closed() {
        this.backendService.closeUnSupportedBrowser().then(closeAtSession => {
            this.showUnsupportedBrowser = !(closeAtSession.isClose === 'true');
        });
    }
}


