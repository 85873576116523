<div #spromotionSection *ngIf="promotion && !promotion.isClose && displayStatus" class="col px-2 py-2 bgcolor"><!--3.1 - BR1 && !settings.isMyDHLPlusOrDHLE() -->
    <div class="row">
        <div class="col-auto pr-2">
            <img [src]="settings.backendUrl + '/assets/promotion-icon.png'" alt="ServicePoint with promotion">
        </div>
        <div class="col">
            <div class="row">
                <div class="col px-0 text-14" [innerHTML]="promotion.text"></div>
                <div class="col-auto pl-2 close">
                    <mat-icon class="close text-20 mat-icon-font-feat" (click)="handlePromotion()">close</mat-icon>
                </div>
            </div>
            <div *ngIf="promotion.hyperlink" class="row mt-2">
                <div class="col"></div>
                <div class="col-auto">
                    <button [ngStyle]="{'display':'inline-block'}" type="button" class="promotion-button text-14" (click)="openPromotion()">{{promotion.buttonText}}</button>
                    <!--input type="hidden" [value]="promotion.hyperlink"-->
                </div>
            </div>
        </div>
    </div>
</div>
