import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../settings.service';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

    constructor(public settings: SettingsService) {
    }

    ngOnInit() {
    }
}
