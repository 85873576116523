import { UntypedFormControl, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { Country, PageParameter } from '../backend.service';
import { Hide } from './hide';
import { ElementRef } from '@angular/core';
import { SettingsService } from '../settings.service';
import { Utils } from './utils';
import { Params } from '@angular/router';
import { CustomValidators } from './custom-validators';
import { Uom } from '../model/Uom';

export class AdvanceSearchCriteria {

    //isInitialize = false;
    //dropOff: FormControl;
    //collection: FormControl;
    //payment: FormControl;
    //handlingOptions: Array<Capability> = [];
    //handling: FormControl;
    //collect: FormControl;

    weight: UntypedFormControl;
    weightUom: UntypedFormControl;
    length: UntypedFormControl;
    width: UntypedFormControl;
    height: UntypedFormControl;
    dimensionsUom: UntypedFormControl;
    openBefore: UntypedFormControl;
    openAfter: UntypedFormControl;
    openDay: UntypedFormControl;

    selectedCountry: Country;
    //capabilitySelection: CapabilitySelectionAdvancedSearch;

    weightInput: ElementRef;
    lengthInput: ElementRef;
    widthInput: ElementRef;
    heightInput: ElementRef;

    private queryParams: Params;

    constructor(private settings: SettingsService, private utils: Utils, public hide: Hide, private isResultPage: boolean = false) {
        //this.payment = new FormControl('0');
        //this.handling = new FormControl('0');
        //this.handling.disable({ onlySelf: true });
        //this.collect = new FormControl('0');
        this.weight = new UntypedFormControl(null, [CustomValidators.number(), this.weightValidator()]);
        this.weightUom = new UntypedFormControl('kg');
        this.length = new UntypedFormControl(null, [CustomValidators.number(), this.lengthValidator()]);
        this.width = new UntypedFormControl(null, [CustomValidators.number(), this.widthValidator()]);
        this.height = new UntypedFormControl(null, [CustomValidators.number(), this.heightValidator()]);
        this.dimensionsUom = new UntypedFormControl('cm');
        this.openBefore = new UntypedFormControl(null);
        this.openAfter = new UntypedFormControl(null);
        this.openDay = new UntypedFormControl(null);
        //this.capabilitySelection = new CapabilitySelectionAdvancedSearch();
    }

    initialize(queryParams: Params) {
        this.queryParams = queryParams;
    }

    resolve() {
        //this.setDropOffAndCollection(this.queryParams);
        this.setPieceLimitation(this.queryParams);
        this.setOpeningHours(this.queryParams);
    }

    /*private setDropOffAndCollection(queryParams: Params) {
        this.capabilitySelection.resolveCapability(queryParams);
        if (this.isResultPage && !this.capabilitySelection.sendCode && !this.capabilitySelection.collectCode) {
            this.setDropOffValue(allSendCode);
            this.setCollectionValue(allCollectCode);
        } else {
            this.setDropOffValue(this.capabilitySelection.sendCode);
            this.setCollectionValue(this.capabilitySelection.collectCode);
        }

        if (this.capabilitySelection.isFreeze) {
            this.payment.disable({ onlySelf: true });
            this.handling.disable({ onlySelf: true });
            this.collect.disable({ onlySelf: true });
        }
    }

    private setDropOffValue(value: string) {
        if (!this.hide.dropOff) {
            this.payment.setValue(value);
        }
    }

    private setCollectionValue(value: string) {
        if (!this.hide.collection) {
            this.collect.setValue(value);
        }
    }*/

    private setPieceLimitation(queryParams: Params) {
        if (!this.hide.pieceLimitation && queryParams) {
            const weightUom = queryParams['weightUom'];
            if (weightUom) {
                if (weightUom === 'kg' || weightUom === 'lb') {
                    this.weightUom.setValue(weightUom);
                }
            } else {
                if (this.settings.uom.code === 'km') {
                    this.weightUom.setValue('kg');
                } else if (this.settings.uom.code === 'mi') {
                    this.weightUom.setValue('lb');
                }
            }

            this.setFormControlValue(this.weight, queryParams['weight']);
            this.setFormControlValue(this.length, queryParams['length']);
            this.setFormControlValue(this.width, queryParams['width']);
            this.setFormControlValue(this.height, queryParams['height']);

            const dimensionsUom = queryParams['dimensionsUom']
            if (dimensionsUom) {
                if (dimensionsUom === 'cm' || dimensionsUom === 'in') {
                    this.dimensionsUom.setValue(dimensionsUom);
                }
            } else {
                if (this.settings.uom.code === 'km') {
                    this.dimensionsUom.setValue('cm');
                } else if (this.settings.uom.code === 'mi') {
                    this.dimensionsUom.setValue('in');
                }
            }
        }
    }

    private setOpeningHours(queryParams: Params) {
        if (!this.hide.openingHours && queryParams) {
            this.setFormControlValue(this.openBefore, queryParams['openBefore']);
            this.setFormControlValue(this.openAfter, queryParams['openAfter']);
            this.setFormControlValue(this.openDay, queryParams['openDay']);
        }
    }

    private setFormControlValue(control: UntypedFormControl, value: string) {
        if (value) {
            control.setValue(value);
        }
    }

    /*get hasChanged() : boolean {
        return ( 
            (!this.hide.dropOff && !this.payment.disabled && this.payment.value && this.payment.value !== '0')
            || (!this.hide.collection && !this.collect.disabled && this.collect.value && this.collect.value !== '0')
            || this.hasChangedPieceLimitation()
            || this.hasChangedOpeningHours()
        );
    }

    private hasChangedPieceLimitation(): boolean {
        return !this.hide.pieceLimitation && (
            (!this.utils.isElementRefDisabled(this.weightInput) && this.weight.value)
            || this.weightUomHasChanged()
            || (!this.utils.isElementRefDisabled(this.lengthInput) && this.length.value)
            || (!this.utils.isElementRefDisabled(this.widthInput) && this.width.value)
            || (!this.utils.isElementRefDisabled(this.heightInput) && this.height.value)
            || this.dimensionsUomHasChanged()
        );
    }

    private hasChangedOpeningHours() {
        return !this.hide.openingHours && (
            (!this.openBefore.disabled && this.openBefore.value)
            || (!this.openAfter.disabled && this.openAfter.value)
            || (!this.openDay.disabled && this.openDay.value) 
        );
    }

    private weightUomHasChanged(): boolean {
        if (!this.weightUom.disabled) {
            if (this.settings.uom.code === 'km') {
                return this.weightUom.value === 'lb';
            } else if (this.settings.uom.code === 'mi') {
                return this.weightUom.value === 'kg';
            }
        }
        return false;
    }

    private dimensionsUomHasChanged(): boolean {
        if (!this.dimensionsUom.disabled) {
            if (this.settings.uom.code === 'km') {
                return this.dimensionsUom.value === 'in';
            } else if (this.settings.uom.code === 'mi') {
                return this.dimensionsUom.value === 'cm';
            }
        }
        return false;
    }*/

    get maxLimitWeight(): string {
        if ((this.weightUom && this.weightUom.value) && this.selectedCountry) {
                let weight = this.selectedCountry.maxLimitWeightKg;
                if (this.weightUom.value === 'lb') {
                    weight = weight * Utils.POUNDS_TO_KG_FACTOR;
                }
                return weight.toFixed(1).toString();
        }
        return '';
    }

    get maxLimitLength(): string {
        if ((this.dimensionsUom && this.dimensionsUom.value) && this.selectedCountry) {
                let length = this.selectedCountry.maxLimitLengthCm;
                if (this.dimensionsUom.value === 'in') {
                    length = length * Utils.IN_TO_CM_FACTOR;
                }
                return this.roundDown(length, 2).toString();
        }
        return '';
    }

    get maxLimitWidth(): string {
        if ((this.dimensionsUom && this.dimensionsUom.value) && this.selectedCountry) {
                let width = this.selectedCountry.maxLimitWidthCm;
                if (this.dimensionsUom.value === 'in') {
                    width = width * Utils.IN_TO_CM_FACTOR;
                }
                return this.roundDown(width, 2).toString();
        }
        return '';
    }

    get maxLimitHeight(): string {
        if ((this.dimensionsUom && this.dimensionsUom.value) && this.selectedCountry) {
                let height = this.selectedCountry.maxLimitHeightCm;
                if (this.dimensionsUom.value === 'in') {
                    height = height * Utils.IN_TO_CM_FACTOR;
                }
                return this.roundDown(height, 2).toString();
        }
        return '';
    }

    roundDown(number: number, decimalPlaces: number): number {
        const factor = Math.pow(10, decimalPlaces);
        return Math.floor(number * factor) / factor;
    }

    changeUom(uom: Uom) {
        if (uom.code === 'km') {
            this.setFormControlValueNotdisabled(this.weightUom, 'kg');
            this.setFormControlValueNotdisabled(this.dimensionsUom, 'cm');
        } else if (uom.code === 'mi') {
            this.setFormControlValueNotdisabled(this.weightUom, 'lb');
            this.setFormControlValueNotdisabled(this.dimensionsUom, 'in');
        }
    }

    private setFormControlValueNotdisabled(control: UntypedFormControl, value: string) {
        if (!control.disabled) {
            control.setValue(value);
        }
    }

    getPageParameter(): PageParameter {
        const params: PageParameter = {};
        /*if (this.hide.capability) {
            const capability = queryParams['capability'];
            if (capability) {
                params.capability = capability;
            }
        } else {
            //let capabilities: string[] = [];
            if (!this.hide.dropOff && this.payment.value !== '0') {
                capabilities = capabilities.concat(this.payment.value.split(','));
            }
            if (!this.hide.collection && this.collect.value !== '0') {
                capabilities = capabilities.concat(this.collect.value.split(','));
            }
            params.capability = capabilities.join(',');
        }*/
        if (!this.hide.pieceLimitation) {
            if (this.weight.value) {
                params.weight = this.weight.value;
            }
            if (this.weightUom.value) {
                params.weightUom = this.weightUom.value;
            }
            if (this.length.value) {
                params.length = this.length.value;
            }
            if (this.width.value) {
                params.width = this.width.value;
            }
            if (this.height.value) {
                params.height = this.height.value;
            }
            if (this.dimensionsUom.value) {
                params.dimensionsUom = this.dimensionsUom.value;
            }
        } else {
            this.setHidePieceLimitationPageParameter(params);
        }
        if (!this.hide.openingHours) {
            if (this.openBefore.value) {
                params.openBefore = this.openBefore.value;
            }
            if (this.openAfter.value) {
                params.openAfter = this.openAfter.value;
            }
            if (this.openDay.value) {
                params.openDay = this.openDay.value;
            }
        } else {
            this.setHideOpeningHoursPageParameter(params);
        }
        return params;
    }

    private setHidePieceLimitationPageParameter(params: PageParameter) {
        const weight = this.queryParams['weight'];
        if (weight) {
            params.weight = weight;
        }
        const weightUom = this.queryParams['weightUom'];
        if (weightUom) {
            params.weightUom = weightUom;
        }
        const length = this.queryParams['length'];
        if (length) {
            params.length = length;
        }
        const width = this.queryParams['width'];
        if (width) {
            params.width = width;
        }
        const height = this.queryParams['height'];
        if (height) {
            params.height = height;
        }
        const dimensionsUom = this.queryParams['dimensionsUom'];
        if (dimensionsUom) {
            params.dimensionsUom = dimensionsUom;
        }
    }

    private setHideOpeningHoursPageParameter(params: PageParameter) {
        const openBefore = this.queryParams['openBefore'];
        if (openBefore) {
            params.openBefore = openBefore;
        }
        const openAfter = this.queryParams['openAfter'];
        if (openAfter) {
            params.openAfter = openAfter;
        }
        const openDay = this.queryParams['openDay'];
        if (openDay) {
            params.openDay = openDay;
        }
    }

    clear() {
        //this.utils.clearFormControl(this.payment, '0');
        //this.utils.clearFormControl(this.collect, '0');
        this.utils.clearFormControl2(this.weight, this.weightInput);
        this.utils.clearFormControl2(this.length, this.lengthInput);
        this.utils.clearFormControl2(this.width, this.widthInput);
        this.utils.clearFormControl2(this.height, this.heightInput);
        this.utils.clearFormControl(this.openBefore);
        this.utils.clearFormControl(this.openAfter);
        this.utils.clearFormControl(this.openDay);
        if (this.settings.uom.code === 'km') {
            this.utils.clearFormControl(this.weightUom, 'kg');
            this.utils.clearFormControl(this.dimensionsUom, 'cm');
        } else if (this.settings.uom.code === 'mi') {
            this.utils.clearFormControl(this.weightUom, 'lb');
            this.utils.clearFormControl(this.dimensionsUom, 'in');
        }
    }

    updateValueAndValidity() {
        this.weight.updateValueAndValidity();
        this.length.updateValueAndValidity();
        this.width.updateValueAndValidity();
        this.height.updateValueAndValidity();
    }

    get isLimitationValid(): boolean {
        return this.weight.errors == null && this.length.errors == null && this.width.errors == null && this.height.errors == null;
    }

    private weightValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if ((this.weightUom && this.weightUom.value) && 
                (control.value && control.value.toString().length > 0) && this.selectedCountry) {
                    let weight = Number(control.value.toString().replace(',', '.'));
                    if (!isNaN(weight)) {
                        if (this.weightUom.value === 'lb') {
                            weight = weight / Utils.POUNDS_TO_KG_FACTOR;
                        }
                        if (weight > this.selectedCountry.maxLimitWeightKg) {
                            return { weightExceed: true };
                        }
                    }
            }
            return null;
        };
    }

    private lengthValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if ((this.dimensionsUom && this.dimensionsUom.value) && 
                (control.value && control.value.toString().length > 0) && this.selectedCountry) {
                    
                    let length = Number(control.value.toString().replace(',', '.'));
                    if (!isNaN(length)) {
                        if (this.dimensionsUom.value === 'in') {
                            length = length / Utils.IN_TO_CM_FACTOR;
                        }
                        if (length > this.selectedCountry.maxLimitLengthCm) {
                            return { lengthExceed: true };
                        }
                    }
            }
            return null;
        };
    }

    private widthValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if ((this.dimensionsUom && this.dimensionsUom.value) && 
                (control.value && control.value.toString().length > 0) && this.selectedCountry) {
                    let width = Number(control.value.toString().replace(',', '.'));
                    if (!isNaN(width)) {
                        if (this.dimensionsUom.value === 'in') {
                            width = width / Utils.IN_TO_CM_FACTOR;
                        }
                        if (width > this.selectedCountry.maxLimitWidthCm) {
                            return { widthExceed: true };
                        }
                    }
            }
            return null;
        };
    }

    private heightValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if ((this.dimensionsUom && this.dimensionsUom.value) && 
                (control.value && control.value.toString().length > 0) && this.selectedCountry) {
                    let height = Number(control.value.toString().replace(',', '.'));
                    if (!isNaN(height)) {
                        if (this.dimensionsUom.value === 'in') {
                            height = height / Utils.IN_TO_CM_FACTOR;
                        }
                        if (height > this.selectedCountry.maxLimitHeightCm) {
                            return { heightExceed: true };
                        }
                    }
            }
            return null;
        };
    }

    public isLimitationEntered(): boolean {
        return this.weight.value || this.length.value || this.width.value || this.height.value || this.openDay.value || this.openAfter.value || this.openBefore.value;
    }
}

/*export class CapabilitySelectionAdvancedSearch {

    sendCode: string;
    collectCode: string;
    isFreeze: boolean = false;
    //capabilityExceptions: string;

    resolveCapability(queryParams: Params) {
        this.sendCode = null;
        this.collectCode = null;
        this.isFreeze = false;

        let send: Capability = null;
        let collect: Capability = null;
        const capability: string = queryParams ? queryParams['capability'] : null;
        if (capability) {
            const capabilities: string[] = capability.split(',');
            if (allSend.code.split(',').every(code => capabilities.includes(code))) {
                send = allSend;
            } else {
                send = sendSecondLevel.find(send => capabilities.includes(send.code));
            }
            if (allCollect.code.split(',').every(code => capabilities.includes(code))) {
                collect = allCollect;
            } else {
                collect = collectSecondLevel.find(collect => collect.code.split(',').every(code => capabilities.includes(code)));
            }
        }

        const freeze: string = queryParams ? queryParams['freeze'] : null;
        let freezeArray: string[];
        if (freeze) {
            freezeArray = freeze.split(',');
        }
        if (freezeArray && freezeArray.find(freeze => ['capability','sendAndCollect','send','collect'].includes(freeze))) {
            const freezeArray = freeze.split(',');
            if (freezeArray.includes('capability')) {
                this.resolveCapabilityQueryParams(send, collect, true);
            } else if(freezeArray.includes('sendAndCollect')) {
                if (send && collect) {
                    this.setValues(send.code, collect.code, true);
                }
            } else if (freezeArray.includes('send')) {
                if (send) {
                    this.setValues(send.code, null, true);
                }
            } else if (freezeArray.includes('collect')) {
                if (collect) {
                    this.setValues(null, collect.code, true);
                }
            }
        } else {
            this.resolveCapabilityQueryParams(send, collect, false);
        }
    }

    private resolveCapabilityQueryParams(send: Capability, collect: Capability, isFreeze: boolean) {
        if (send) {
            this.setValues(send.code, null, isFreeze);
        }
        if (collect) {
            this.setValues(null, collect.code, isFreeze);
        }
    }

    private setValues(sendCode: string, collectCode: string, isFreeze: boolean) {
        if (sendCode) {
            this.sendCode = sendCode;
        }
        if (collectCode) {
            this.collectCode = collectCode;
        }
        this.isFreeze = isFreeze;
    }
}*/
