import { Injectable, EventEmitter } from '@angular/core';
import { Router, Params } from '@angular/router';
import { SettingsService } from '../settings.service';
import { PERMANENT_URL_PARAMS, PAGE_URL_PARAMS, PageParameter } from '../backend.service';
import { Utils } from './utils';
declare const window: any;

@Injectable({
    providedIn: 'root'
})
export class NavigateService {

    public headerIconClicked = new EventEmitter<void>();

    constructor(private router: Router, private settings: SettingsService) {
    }

    tolanding(queryParams: Params, pageParam: PageParameter) {
        const consolidatedParams: Params = this.getParams(queryParams, pageParam, false);
        this.router.navigate(['/'], { queryParams: consolidatedParams });
    }

    toAdvancedSearch(queryParams: Params, pageParam: PageParameter, isUomChanged: boolean) {
        const consolidatedParams: Params = this.getParams(queryParams, pageParam, true, isUomChanged);
        this.router.navigate(['/advanced-search'], { queryParams: consolidatedParams });
    }

    toResult(queryParams: Params, pageParam: PageParameter) {
        const resultsParams: Params = this.getParams(queryParams, pageParam, false);
        this.router.navigate(['/results'], { queryParams: resultsParams });
    }

    getJsApiResultParams(pageParam: PageParameter): Params {
        return this.getParams(null, pageParam, false);
    }

    private getParams(queryParams: Params, pageParam: PageParameter, usePageUrlParams: boolean, isUomChanged: boolean = false): Params {
        let returnParams: Params = {};
        this.setUrlParams(returnParams, PERMANENT_URL_PARAMS, queryParams);
        this.setSettingsParams(returnParams);
        if (usePageUrlParams) {
            this.setUrlParams(returnParams, PAGE_URL_PARAMS, queryParams, isUomChanged);
        }
        returnParams = Object.assign<Params, PageParameter>(returnParams, pageParam);
        return returnParams;
    }

    private setUrlParams(returnParams: Params, properties: string[], queryParams: Params, isUomChanged: boolean = false) {
        properties.forEach(property => {
            const queryParamValue = queryParams ? queryParams[property] : null;
            if (queryParamValue && 
                !(isUomChanged && (property === 'weightUom' || property === 'dimensionsUom'))
                ) {
                returnParams[property] = queryParamValue;
            }
        });
    }

    private setLanguageCountryCode() {
        let languageCountryCode = this.settings.languageCountry.countryCode2;
        if(Utils.isJsApi()) {
            const gsplParams = window.gspl.params;
            if(gsplParams.lc) {
                languageCountryCode = gsplParams.lc;      
            } else if (gsplParams.languageCountryCode) {
                languageCountryCode = gsplParams.languageCountryCode;
            }
        }
        return languageCountryCode;
    }

    private setSettingsParams(returnParams: Params) {
        returnParams['language'] = this.settings.language.isoLanguage;
        returnParams['languageCountryCode'] = this.setLanguageCountryCode();
        returnParams['resultUom'] = this.settings.uom.code;
        returnParams['light'] = this.settings.lightVersion ? 'y' : 'n';
        if (this.settings.selectedCountry) {
            returnParams['countryCode'] = this.settings.selectedCountry.value;
        }
        if(this.settings.clientAppCode){
            returnParams['clientAppCode'] = this.settings.clientAppCode;
        }
        if(this.settings.svpStatus){
            returnParams['svpStatus'] = this.settings.svpStatus;
        }
        if(this.settings.userCountryFromUrl){//userCountryFromUrl is called when userCountry is in url
            returnParams['userCountry'] = this.settings.userCountry.value;
        }
    }

    notifyHeaderIconClicked() {
        this.headerIconClicked.emit();
    }


    routeToSamePage(queryParams:any, route:any, paramName: string, paramValue: string){
        let paramObj = {};
        paramObj[paramName] = paramValue;
        let alteredParams = Object.assign(Object.assign({}, queryParams), paramObj);
        this.router.navigate(
            [], 
            {
            relativeTo: route,
            queryParams: alteredParams, 
            queryParamsHandling: 'merge', 
        });
    }
}
