<div class="modal-wrapper" cdkDrag>
  <!-- Modal -->
  <div [ngStyle]="{'height': '68.6667px'}" class="modal-header" cdkDragHandle>
    <h4 [ngStyle]="(settings?.isRTL())?{'width':'90%','font-size': '24px'}:{'font-size': '24px'}" class="modal-title">{{'spl.navigation.title' | translate}}</h4>
    <button [ngStyle]="(settings?.isRTL())?{'width':'10%','margin-left':'0%','outline':'0'}:{'outline':'0'}" type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <ng-container *ngFor="let data of naviMapData; let i= index">
        <div class="col-3 text-center">        
          <a href="javascript:void(0);" (click)="openLinks(data.link)">
            <img class="stw-button-image" alt="{{data.title}} Maps icon" [src]="settings.backendUrl + '/assets/map-icon/' + data.imageName">
            <br/><span>{{data.title}}</span>
          </a>
        </div>
        <ng-container *ngIf="(i+1)%4==0">
          <br><br><br>
        </ng-container>
    </ng-container>
    </div>  
  </div>


  <div class="modal-footer">
    <!-- Button trigger modal -->
    <!-- <button *ngIf="showCancel" class="btn btn-tertiary" (click)="cancel()">Cancel</button>
    <button class="btn btn-primary" (click)="ok()" ngbAutofocus>OK</button> -->
  </div>
</div>
