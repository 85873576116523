<div aria-disabled="true" (window:resize)="onResize($event)" [ngClass]="'col' + (settings?.isRTL()?' RTL':'')">
    <div *ngIf="!capability.secondLevelEnabled" [ngClass]="'row' + ((isaddressAnID || disablePPC) ? ' disabled' : ' enabled')">
        <div class="col">
            <div *canHide="'capability'" class="row"><div class="col px-0"><hr/></div></div>
            <div *canHide="'capability'" class="row">
                <div class="col px-1">
                    <div class="row mb-2">
                        <div class="col" id="select-service"><label>{{'spl.search.select.service' | translate }}</label></div>
                    </div>
                    <div class="row">
                        <div [ngClass]="'col'+(!compact ?' col-6':'')" [ngStyle]="{'width': '1px'}">
                            <!--[tabIndex]="capability.service.value!='0' ? '-1' : '0'"-->
                            <ng-select id="service_select" [tabIndex]="-1" [inputAttrs]="{'aria-labelledby':'serviceValue', 'autocomplete':'off'}" aria-labelledby="select-service" [ngClass]="(!capability.isCapabilitySelectedWithHide() && !isaddressAnID)?'capab-not-selected':''" [formControl]="capability.service" [items]="capability.serviceOptions"
                                [searchable]="false" bindValue="code" [clearable]="false"
                                (change)="onServiceChange()">
                                <!--ng-template ng-label-tmp let-item="item">
                                    <span style="width:0px">{{ item.code === '0' ? ('--- ' + (item.labelKey | translate) + ' ---') : (item.labelKey | translate) }}</span>
                                    <img *ngIf="checkCodeForValidationTickMark(item.code)" [src]="settings.backendUrl + '/assets/blank.gif'" [ngStyle]="{'background-image': 'url('+settings.backendUrl+'/assets/green-checked.svg)'}" style="margin-top: 4px; width: 22px;" class="greenchecklarge">
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item">
                                    <span [ngClass]="capability.service.value === item.code ? 'font-weight-bold' : ''" style="width:0px">{{ item.code === '0' ? ('--- ' + (item.labelKey | translate) + ' ---') : (item.labelKey | translate) }}</span>
                                </ng-template-->
                                <ng-template ng-label-tmp let-item="item">
                                    <div class="capability">
                                        <div tabindex=0 class="ng-value capab">
                                            <div [ngClass]="'capabplaceholder'" *ngIf="item.code!='0'">
                                                {{"spl.search.service" | translate}}
                                            </div>
                                            <div id="serviceValue" [ngClass]="item.code!='0'?'capabvalue':'capabvaluedefault'">
                                                {{item.labelKey | translate}}
                                            </div>
                                        </div>
                                        <div [ngClass]="'ng-value greencheck'">
                                            <img *ngIf="checkCodeForValidationTickMark(item.code)" alt="Enables the green check when the entered address is valid" [src]="settings.backendUrl + '/assets/blank.gif'" [ngStyle]="{'background-image': 'url('+settings.backendUrl+'/assets/green-checked.svg)'}" [ngClass]="'greenchecklarge' +(settings?.isRTL()?' right-margin-0':'')">
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item">
                                    <span [ngClass]="capability.service.value === item.code ? 'font-weight-bold' : ''" [ngStyle]="{'width':'0px'}">{{ item.code === '0' ? ('--- ' + (item.labelKey | translate) + ' ---') : (item.labelKey | translate) }}</span>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="capability.secondLevelEnabled" [ngClass]="'row' + ((isaddressAnID || disablePPC) ? ' disabled' : ' enabled')">
        <div class="col px-1">
            <div *canHide="'capability'" class="row"><div class="col"><hr/></div></div>
            <div *canHide="'capability'" class="row">
                <div [ngClass]="compact ? 'col' : 'col col-md-6 '+(settings?.isRTL()?'pl-md-3':'pr-md-3')" *ngIf="!searchCriteria.hide.dropOff">
                    <div class="row mb-2">
                        <label class="col">{{'spl.details.rs.drop_off' | translate}}
                            <!--span *ngIf="mandatoryIndicator === 'default'" class="required">*</span>
                            <span *ngIf="mandatoryIndicator === 'mydhl+' && !isCapabilitySelected" class="">*</span>
                            <i *ngIf="mandatoryIndicator === 'mydhl+' && isCapabilitySelected" class="material-icons text-success font-weight-bold validation-check">done</i-->
                        </label>
                    </div>
                    <div class="row mb-2">
                        <div class="col" [ngStyle]="{'width': '1px'}">
                            <!-- [tabIndex]="capability.payment.value!='0' ? '-1' : '0'"  -->
                            <ng-select id="payment_select" [tabIndex]="-1" [inputAttrs]="{'aria-labelledby':'paymentValue', 'autocomplete':'off'}" aria-label="Payment Option" [ngClass]="(!capability.isCapabilitySelectedWithHide() && !isaddressAnID)?'capab-not-selected':''" [formControl]="capability.payment" [items]="capability.paymentOptions"
                                [searchable]="false" bindValue="code" bindLabel="label" [clearable]="false"
                                (change)="capability.onPaymentChange($event)">
                                <!--ng-template ng-label-tmp let-item="item">
                                    <span style="width:0px">{{ item.code === '0' ? ('--- ' + (item.labelKey | translate) + ' ---') : (item.labelKey | translate) }}</span>
                                    <img *ngIf="checkCodeForValidationTickMark(item.code)" [src]="settings.backendUrl + '/assets/blank.gif'" [ngStyle]="{'background-image': 'url('+settings.backendUrl+'/assets/green-checked.svg)'}" style="margin-top: 4px; width: 22px;" class="greenchecklarge">
                                </ng-template-->
                                <!--ng-template ng-multi-label-tmp let-items="items">
                                    <div class="countryvalue">
                                        <div class="ng-value placeholder" *ngFor="let item of items">
                                            <span class="ng-value-label placeholder">{{"spl.search.choose_payment_option" | translate}}</span>
                                        </div>
                                        <div class="ng-value country" *ngFor="let item of items">
                                            <span style="width:0px">{{ item.code === '0' ? ('--- ' + (item.labelKey | translate) + ' ---') : (item.labelKey | translate) }}</span>
                                            <img *ngIf="checkCodeForValidationTickMark(item.code)" [src]="settings.backendUrl + '/assets/blank.gif'" [ngStyle]="{'background-image': 'url('+settings.backendUrl+'/assets/green-checked.svg)'}" style="margin-top: 0px; width: 22px;" class="greenchecklarge">
                                        </div>
                                    </div>
                                </ng-template-->
                                <!--ng-template ng-option-tmp let-item="item">
                                    <span [ngClass]="capability.service.value === item.code ? 'font-weight-bold' : ''" style="width:0px">{{ item.code === '0' ? ('--- ' + (item.labelKey | translate) + ' ---') : (item.labelKey | translate) }}</span>
                                </ng-template-->
                                <ng-template ng-label-tmp let-item="item">
                                    <div class="capability">
                                        <div tabindex=0 class="ng-value capab">
                                            <div [ngClass]="'capabplaceholder'" *ngIf="item.code!='0'">
                                                {{"spl.search.choose_payment_option" | translate}}
                                            </div>
                                            <div id="paymentValue" [ngClass]="item.code!='0'?'capabvalue':'capabvaluedefault'">
                                                {{item.labelKey | translate}}
                                            </div>
                                        </div>
                                        <div [ngClass]="'ng-value greencheck'">
                                            <img *ngIf="checkCodeForValidationTickMark(item.code)" alt="Enables the green check when the entered address is valid" [src]="settings.backendUrl + '/assets/blank.gif'" [ngStyle]="{'background-image': 'url('+settings.backendUrl+'/assets/green-checked.svg)'}" [ngClass]="'greenchecklarge' +(settings?.isRTL()?' right-margin-0':'')">
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item">
                                    <span [ngClass]="capability.service.value === item.code ? 'font-weight-bold' : ''" [ngStyle]="{'width':'0px'}">{{ item.code === '0' ? ('--- ' + (item.labelKey | translate) + ' ---') : (item.labelKey | translate) }}</span>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                    <div *ngIf="capability.isLabelFree" class="row">
                        <div class="col" [ngStyle]="{'width': '1px'}">
                            <ng-select id="handling_select" [tabIndex]="-1" [inputAttrs]="{'aria-labelledby':'handlingValue', 'autocomplete':'off'}" aria-label="Handling Option" [ngClass]="(!capability.isCapabilitySelectedWithHide() && !isaddressAnID)?'capab-not-selected':''" [formControl]="capability.handling" [items]="capability.handlingOptions"
                                [searchable]="false" bindValue="code" bindLabel="label" [clearable]="false">
                                <!--ng-template ng-label-tmp let-item="item">
                                    <span style="width:0px">{{ item.code === '0' ? ('--- ' + (item.labelKey | translate) + ' ---') : (item.labelKey | translate) }}</span>
                                    <img *ngIf="checkCodeForValidationTickMark(item.code)" [src]="settings.backendUrl + '/assets/blank.gif'" [ngStyle]="{'background-image': 'url('+settings.backendUrl+'/assets/green-checked.svg)'}" style="margin-top: 4px; width: 22px;" class="greenchecklarge">
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item">
                                    <span [ngClass]="capability.service.value === item.code ? 'font-weight-bold' : ''" style="width:0px">{{ item.code === '0' ? ('--- ' + (item.labelKey | translate) + ' ---') : (item.labelKey | translate) }}</span>
                                </ng-template-->
                                <ng-template ng-label-tmp let-item="item">
                                    <div class="capability">
                                        <div tabindex="0" class="ng-value capab">
                                            <div [ngClass]="'capabplaceholder'" *ngIf="item.code!='0'">
                                                {{"spl.search.choose_handling_option" | translate}}
                                            </div>
                                            <div id="handlingValue" [ngClass]="item.code!='0'?'capabvalue':'capabvaluedefault'">
                                                {{item.labelKey | translate}}
                                            </div>
                                        </div>
                                        <div [ngClass]="'ng-value greencheck'">
                                            <img *ngIf="checkCodeForValidationTickMark(item.code)" alt="Enables the green check when the entered address is valid" [src]="settings.backendUrl + '/assets/blank.gif'" [ngStyle]="{'background-image': 'url('+settings.backendUrl+'/assets/green-checked.svg)'}" [ngClass]="'greenchecklarge' +(settings?.isRTL()?' right-margin-0':'')">
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item">
                                    <span [ngClass]="capability.service.value === item.code ? 'font-weight-bold' : ''" [ngStyle]="{'width':'0px'}">{{ item.code === '0' ? ('--- ' + (item.labelKey | translate) + ' ---') : (item.labelKey | translate) }}</span>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div [ngClass]="compact ? 'w-100 mb-2' : 'w-100 mb-2 d-md-none'"></div>
                <div [ngClass]="compact ? 'col ' : 'col col-md-6 pl-md-2'" *ngIf="!searchCriteria.hide.collection">
                    <div class="row mb-2">
                        <label class="col">{{'spl.details.rs.collection' | translate}}</label>
                    </div>
                    <div class="row">
                        <div class="col" [ngStyle]="{'width': '1px'}">
                            <ng-select id="collect_select" [tabIndex]="-1" [inputAttrs]="{'aria-labelledby':'collectValue', 'autocomplete':'off'}" aria-label="Collect Option" [ngClass]="(!capability.isCapabilitySelectedWithHide() && !isaddressAnID)?'capab-not-selected':''" [formControl]="capability.collect" [items]="capability.collectOptions"
                                [searchable]="false" bindValue="code" bindLabel="label" [clearable]="false" (change)="capability.onCollectChange($event)">
                                <!--ng-template ng-label-tmp let-item="item">
                                    <span style="width:0px">{{ item.code === '0' ? ('--- ' + (item.labelKey | translate) + ' ---') : (item.labelKey | translate) }}</span>
                                    <img *ngIf="checkCodeForValidationTickMark(item.code)" [src]="settings.backendUrl + '/assets/blank.gif'" [ngStyle]="{'background-image': 'url('+settings.backendUrl+'/assets/green-checked.svg)'}" style="margin-top: 4px; width: 22px;" class="greenchecklarge">
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item">
                                    <span [ngClass]="capability.service.value === item.code ? 'font-weight-bold' : ''" style="width:0px">{{ item.code === '0' ? ('--- ' + (item.labelKey | translate) + ' ---') : (item.labelKey | translate) }}</span>
                                </ng-template-->
                                <ng-template ng-label-tmp let-item="item">
                                    <div class="capability">
                                        <div tabindex="0" class="ng-value capab">
                                            <div [ngClass]="'capabplaceholder'" *ngIf="item.code!='0'">
                                                {{"spl.search.choose_collection_option" | translate}}
                                            </div>
                                            <div id="collectValue" [ngClass]="item.code!='0'?'capabvalue':'capabvaluedefault'">
                                                {{item.labelKey | translate}}
                                            </div>
                                        </div>
                                        <div [ngClass]="'ng-value greencheck'">
                                            <img *ngIf="checkCodeForValidationTickMark(item.code)" alt="Enables the green check when the entered address is valid" [src]="settings.backendUrl + '/assets/blank.gif'" [ngStyle]="{'background-image': 'url('+settings.backendUrl+'/assets/green-checked.svg)'}" [ngClass]="'greenchecklarge' +(settings?.isRTL()?' right-margin-0':'')">
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item">
                                    <span [ngClass]="capability.service.value === item.code ? 'font-weight-bold' : ''" [ngStyle]="{'width':'0px'}">{{ item.code === '0' ? ('--- ' + (item.labelKey | translate) + ' ---') : (item.labelKey | translate) }}</span>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--div *canHide="'capability'" class="row">
        <ng-select *ngIf="!searchCriteria.hide.dropOff" (change)="onAnyChange()" [items]="sendOptionsTranslated"
                   [formControl]="searchCriteria.dropOff" [searchable]="false"
                   bindValue="code"
                   bindLabel="label" [clearable]="false">
        </ng-select>
        <ng-select *ngIf="!searchCriteria.hide.collection" (change)="onAnyChange()" [items]="collectOptionsTranslated"
                   [formControl]="searchCriteria.collection" [searchable]="false"
                   bindValue="code"
                   bindLabel="label" [clearable]="false">
        </ng-select>
    </div-->
    <!--div *canHide="'capability'"><div class="spacer2"></div></div--><!-- *ngIf="compact" -->
    <div class="row"><div class="col px-0"><hr/></div></div>
    <div [ngClass]="'row' + ((isaddressAnID || disabled) ? ' disabled' : ' enabled')">
        <div class="col px-1">
            <div class="row">
                <div [ngClass]="compact ? 'col' : 'col col-md-6 '+ (settings?.isRTL()?' pl-md-3':'pr-md-3')" *canHide="'pieceLimitation'">
                    <div class="row mb-2">
                        <label [ngClass]="'col-auto ' + (settings?.isRTL()?'pr-3 pl-0':'pr-0')">{{'spl.search.packaging_parameters' | translate}}</label>  <!-- BR11 - UXRedesign S4-->
                        <div [ngClass]="'col '+(settings?.isRTL()?'pr-0':'pl-2')">
                            <a tabindex="0" role="link" aria-label="View details about packaging parameters in the image" *ngIf="infoIcon === 'red'" class="hint" (click)="openDialog()" [ngStyle]="{background: 'url(' + settings.backendUrl + '/assets/red_info_icon.svg) no-repeat'}"></a>
                            <a tabindex="0" role="link" aria-label="View details about packaging parameters in the image" *ngIf="infoIcon === 'blue'" class="hint" (click)="openDialog()" [ngStyle]="{background: 'url(' + settings.backendUrl + '/assets/blue_info_icon.svg) no-repeat'}"></a>
                        </div>
                    </div>
                    <div class="row">            
                        <div ngClass="col-9">
                            <div class="row mb-2">
                                <div [ngClass]="'col-4 ' + (settings?.isRTL()?'pr-3 pl-1':'pr-1')">
                                    <input aria-label="Enter length" #lengthInput canFreezeReadonly="pieceLimitation,length" (keydown)="onAnyChange('length')" class="w-100"
                                        type="text" pattern="^(([0-9][0-9]*)|0)([.,][0-9]*){0,1}$" placeholder=" " inputmode="decimal" [formControl]="searchCriteria.length"
                                        [class.is-invalid]="searchCriteria.length.errors" onfocus="if(this.readOnly) this.blur();" (focus)="searchCriteria.length['isfocus'] = true" (blur)="searchCriteria.length['isfocus'] = false" (mouseenter)="searchCriteria.length['isfocus'] = true" (mouseleave)="searchCriteria.length['isfocus'] = false"/>
                                    <i *ngIf="searchCriteria.length.errors" [ngClass]="'material-icons-round invalid-limitation'+(settings?.isRTL()?' invalid-limitation-rtl':'')">warning</i>
                                    <span class="floating-label">{{'spl.search.abbr.length' | translate}}</span>
                                </div>
                                <div ngClass="col-4 px-1">
                                    <input aria-label="Enter width" #widthInput canFreezeReadonly="pieceLimitation,width" placeholder=" " (keydown)="onAnyChange('width')" class="w-100"
                                        type="text" pattern="^(([0-9][0-9]*)|0)([.,][0-9]*){0,1}$" inputmode="decimal" [formControl]="searchCriteria.width"
                                        [class.is-invalid]="searchCriteria.width.errors" onfocus="if(this.readOnly) this.blur();" (focus)="searchCriteria.width['isfocus'] = true" (blur)="searchCriteria.width['isfocus'] = false" (mouseenter)="searchCriteria.width['isfocus'] = true" (mouseleave)="searchCriteria.width['isfocus'] = false"/>
                                    <i *ngIf="searchCriteria.width.errors" [ngClass]="'material-icons-round invalid-limitation'+(settings?.isRTL()?' invalid-limitation-rtl':'')">warning</i>
                                    <span class="floating-label floating-label-middle">{{'spl.search.abbr.width' | translate}}</span>
                                </div>
                                <div ngClass="col-4 px-1">
                                    <input aria-label="Enter height" #heightInput canFreezeReadonly="pieceLimitation,height" placeholder=" " (keydown) ="onAnyChange('height')" class="w-100" 
                                        type="text" pattern="^(([0-9][0-9]*)|0)([.,][0-9]*){0,1}$" inputmode="decimal" [formControl]="searchCriteria.height"
                                        [class.is-invalid]="searchCriteria.height.errors" onfocus="if(this.readOnly) this.blur();" (focus)="searchCriteria.height['isfocus'] = true" (blur)="searchCriteria.height['isfocus'] = false" (mouseenter)="searchCriteria.height['isfocus'] = true" (mouseleave)="searchCriteria.height['isfocus'] = false"/>
                                    <i *ngIf="searchCriteria.height.errors" [ngClass]="'material-icons-round invalid-limitation'+(settings?.isRTL()?' invalid-limitation-rtl':'')">warning</i>
                                    <span class="floating-label floating-label-middle">{{'spl.search.abbr.height' | translate}}</span>
                                </div>
                                    <div *ngIf="searchCriteria.length.errors?.notANumber && searchCriteria.length['isfocus']" class="ml-3 is-invalid-message">{{'spl.search.piece.limitation.only.numeric.values' | translate}}</div>
                                    <div *ngIf="searchCriteria.length.errors?.lengthExceed && searchCriteria.length['isfocus']" class="ml-3 is-invalid-message">{{'spl.search.piece.length.max.value' | translate:{ 'unit' : searchCriteria.dimensionsUom.value, 'length': searchCriteria.maxLimitLength } }}</div>
                                    <div *ngIf="searchCriteria.width.errors?.notANumber && searchCriteria.width['isfocus']" class="ml-3 is-invalid-message">{{'spl.search.piece.limitation.only.numeric.values' | translate}}</div>
                                    <div *ngIf="searchCriteria.width.errors?.widthExceed && searchCriteria.width['isfocus']" class="ml-3 is-invalid-message">{{'spl.search.piece.width.max.value' | translate:{ 'unit' : searchCriteria.dimensionsUom.value, 'width': searchCriteria.maxLimitWidth } }}</div>                
                                    <div *ngIf="searchCriteria.height.errors?.notANumber && searchCriteria.height['isfocus']" class="ml-3 is-invalid-message">{{'spl.search.piece.limitation.only.numeric.values' | translate}}</div>
                                    <div *ngIf="searchCriteria.height.errors?.heightExceed && searchCriteria.height['isfocus']" class="ml-3 is-invalid-message">{{'spl.search.piece.height.max.value' | translate:{ 'unit' : searchCriteria.dimensionsUom.value, 'height': searchCriteria.maxLimitHeight } }}</div>
                            </div>
                        </div>
                        <div [ngClass]="'col-3 '+ (settings?.isRTL()?'pr-1':'pl-1')">
                            <ng-select id="length_select" [tabIndex]="-1" [inputAttrs]="{'aria-labelledby':'lengthValue', 'autocomplete':'off', 'aria-label':'Length Value'}" aria-label="Unit of Dimensions" canFreeze="pieceLimitation,dimensionsUom" (change)="onDimensionsUomChange()" [items]="dUoms"
                                    [formControl]="searchCriteria.dimensionsUom" [clearable]="false">
                                <ng-template ng-multi-label-tmp let-items="items">
                                    <div tabindex="0" class="openvalue">
                                        <div class="ng-value" *ngFor="let item of items">
                                            <span id="lengthValue" class="ng-value-label">{{item}}</span>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                    <div class="row">
                        <div [ngClass]="'col-6 '+ (settings?.isRTL()?'pl-1':'pr-1')">
                            <div class="row">
                                <div class="col-12">
                                    <input aria-label="Enter weight" #weightInput canFreezeReadonly="pieceLimitation,weight" placeholder=" " (keydown)="onAnyChange('weight')" class="weight"
                                        type="text" pattern="^(([0-9][0-9]*)|0)([.,][0-9]*){0,1}$" inputmode="decimal" [formControl]="searchCriteria.weight"
                                        [class.is-invalid]="searchCriteria.weight.errors" onfocus="if(this.readOnly) this.blur();" (focus)="searchCriteria.weight['isfocus'] = true" (blur)="searchCriteria.weight['isfocus'] = false" (mouseenter)="searchCriteria.weight['isfocus'] = true" (mouseleave)="searchCriteria.weight['isfocus'] = false" />
                                    <i *ngIf="searchCriteria.weight.errors" [ngClass]="'material-icons-round invalid-limitation'+(settings?.isRTL()?' invalid-limitation-rtl':'')">warning</i>
                                    <span class="floating-label">{{'spl.search.weight' | translate}}</span>
                                </div>
                                <div *ngIf="searchCriteria.weight.errors?.notANumber && searchCriteria.weight['isfocus']" class="ml-3 is-invalid-message">{{'spl.search.piece.limitation.only.numeric.values' | translate}}</div>
                                <div *ngIf="searchCriteria.weight.errors?.weightExceed && searchCriteria.weight['isfocus']" class="ml-3 is-invalid-message">{{'spl.search.piece.weight.max.value' | translate:{ 'unit' : searchCriteria.weightUom.value, 'weight': searchCriteria.maxLimitWeight } }}</div>
                            </div>
                        </div>
                        <div [ngClass]="'col-6 '+ (settings?.isRTL()?'pr-1':'pl-1')">
                            <ng-select id="weight_select" [tabIndex]="-1" [inputAttrs]="{'aria-labelledby':'weightValue', 'autocomplete':'off', 'aria-label':'Weight Value'}" aria-label="Unit of weight" canFreeze="pieceLimitation,weightUom" class="weight-uom" (change)="onWeightUomChange()" [items]="wUoms"
                                        [formControl]="searchCriteria.weightUom" [clearable]="false">
                                <ng-template ng-multi-label-tmp let-items="items">
                                    <div tabindex="0" class="openvalue">
                                        <div class="ng-value" *ngFor="let item of items">
                                            <span id="weightValue" class="ng-value-label">{{item}}</span>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div [ngClass]="compact ? 'w-100' : 'w-100 d-md-none'"></div>
                <div [ngClass]="compact ? 'col' : 'col col-md-6 pl-md-2'" *canHide="'openingHours'">
                    <div [ngClass]="compact ? 'row' : 'row d-md-none'"><div class="col"><hr/></div></div>
                    <div class="row mb-2">
                        <label class="col">{{'spl.details.open_on' | translate}}</label> <!-- BR11 - UXRedesign S4-->
                    </div>
                    <div class="row mx-0 mb-2 mr-1-negative">
                        <div class="pl-0 openday pr-1" [ngClass]="(searchCriteria.openDay.value === day.code) ? 'selected' : 'not-selected'" *ngFor="let day of days;index as i">
                            <a role="link" [attr.aria-label]="day.displayValue" tabindex="0" canFreezeAnchor="openingHours,openDay" (click)="searchCriteria.openDay.setValue(day.code)">
                                <div [ngStyle]="{'line-height': '35px'}"><label [ngStyle]="isScriptLanguage?{'font-size':'12px'}:{}">{{day.labelKey | translate}}</label></div>
                            </a>
                        </div>
                    </div>
                    <div class="row">
                        <div [ngClass]="'col '+ (settings?.isRTL()?'pl-1':'pr-1')">
                            <ng-select id="openbefore_select" [tabIndex]="searchCriteria.openBefore.value ? '-1' : '0'" [inputAttrs]="{'aria-labelledby':'openBeforeValue', 'autocomplete':'off', 'aria-label':'open before'}" aria-label="'Select open before timings'" [searchable]="false" canFreeze="openingHours,openBefore" (change)="onAnyChange()" [items]="hours" bindLabel="value" bindValue="code"
                                [formControl]="searchCriteria.openBefore"
                                placeholder="{{'spl.details.oh.open_before' | translate}}">
                                <ng-template ng-label-tmp let-item="item">
                                    <div tabindex="0" class="openvalue">
                                        <div class="ng-value placeholder">
                                            <span class="ng-value-label placeholder">{{'spl.details.oh.open_before' | translate}}</span>
                                        </div>
                                        <div class="ng-value open">
                                            <span id="openBeforeValue" class="ng-value-label open">{{item.value}}</span>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                        <div [ngClass]="'col '+ (settings?.isRTL()?'pr-1':'pl-1')">
                            <ng-select id="openafter_select" [tabIndex]="searchCriteria.openAfter.value ? '-1' : '0'" [inputAttrs]="{'aria-labelledby':'openAfterValue', 'autocomplete':'off', 'aria-label':'open after'}" aria-label="'Select open after timings'" [searchable]="false" canFreeze="openingHours,openAfter" (change)="onAnyChange()" [items]="hours" bindLabel="value" bindValue="code"
                                [formControl]="searchCriteria.openAfter"
                                placeholder="{{'spl.details.oh.open_after' | translate}}">
                                <!--BR11-->
                                <ng-template ng-label-tmp let-item="item">
                                    <div tabindex="0" class="openvalue">
                                        <div class="ng-value placeholder">
                                            <span class="ng-value-label placeholder">{{'spl.details.oh.open_after' | translate}}</span>
                                        </div>
                                        <div class="ng-value open">
                                            <span id="openAfterValue" class="ng-value-label open">{{item.value}}</span>
                                        </div>
                                    </div>
                                </ng-template>
                                <!--BR11-->
                            </ng-select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
        <!--ng-select canFreeze="openingHours,openDay" (change)="onAnyChange()" [items]="daysTranslated" bindLabel="value" bindValue="code"
                   [formControl]="searchCriteria.openDay" placeholder="{{'spl.details.oh.open_on' | translate}}">
        </ng-select-->

</div>
