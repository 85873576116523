<div *ngIf="_isOpen" [dir]="dir" [ngClass]="!isJsApi()?'hide-onetrust-elements textAlignRtl':'textAlignRtl'"  class="col h-100 mx-2" [ngClass]="'search-page-customization' + (settings?.isRTL() ? ' search-page-customization-rtl' : '')">
    <div class="row">
        <div *ngIf="!(hide.yourLocation && hide.selectCountry)" class="col text-center font-weight-bold text-red mb-1 mb-md-2">
            <h6 class="font-weight-bold" [ngStyle]="{'font-size': '1.2rem'}">{{'spl.search.select_your_location' | translate | uppercase }}</h6>
        </div>
    </div>
    <!--div class="d-none d-md-flex row basic-search-controls" dir="LTR">
        <div class="col-xl-2 col-lg-1 col-md-1 d-none d-sm-block"></div>
        <div *canHide="'selectCountry'" [cssSwitch]="'yourLocation,col-xl-8 col-11 col-less-padding,col-xl-3 col-lg-4 col-md-3 col-sm-4 col-3 col-less-padding'" 
            [dir]="settings?.isRTL()?'RTL':''">
            <label class="nowrap" for="countrySelect">{{'spl.search.your_country' | translate}}</label>
        </div>
        <div *canHide="'yourLocation'" [cssSwitch]="'selectCountry,col-xl-8 col-12 col-less-padding,col-xl-5 col-lg-6 col-md-7 col-sm-8 col-8 col-less-padding'" 
            [dir]="settings?.isRTL()?'RTL':''">
            <label for="addressInput">{{'spl.search.your_location' | translate}}</label>
            <a class="geolocation" (click)="getLocation()"
               title="{{'spl.search.location.click.title' | translate}}"></a>
            <a [ngClass]="'hint d-none d-md-inline-block '+(settings?.isRTL()?'hint-rtl':'')" [ngStyle]="{background: 'url(' + settings.backendUrl + '/assets/red_info_icon.svg) no-repeat'}" (click)="openDialog()"></a>
        </div>
        <div class="col-xl-2 col-lg-1 col-md-1 d-none d-sm-block"></div>
    </div-->
    <div class="row mb-3 mb-md-2" dir="LTR">
        <div class="col-md-3 d-none d-md-flex"></div>
        <div *canHide="'selectCountry'" [ngClass]="'col '+(hide.yourLocation?'col-md-6':'col-md-3')">
            <div class="row">
                <app-country-select aria-label="Select a country value" [ngClass]="'col pl-0 pl-md-0 pr-2 pr-md-3'+(hide.yourLocation?' selectCountry':'')" [selectedCountry]="selectedCountry" [countries]="countries" [enableRtl]="false"
                    (selected)="onCountrySelect($event)" [isBasic]="true" data-test_id="dropdown_country_basic"></app-country-select>
                <div class="col-auto px-0 d-md-none">
                    <a class="geolocation" [ngStyle]="{background: 'url(' + settings.backendUrl + '/assets/locationIcon2.svg) no-repeat'}" (click)="getLocation()" title="{{'spl.search.location.click.title' | translate}}"></a>
                </div>
            </div>
        </div>
        <div class="w-100 mb-3 d-md-none"></div>
        <div *canHide="'yourLocation'" [ngClass]="'col '+(hide.selectCountry?'col-md-6':'col-md-3')">
            <div class="row">
                <app-address-input [ngClass]="'col pl-0 pl-md-3 pr-2 pr-md-0'+(hide.selectCountry?' yourLocation':'')" [selectedCountry]="selectedCountry" (submit)="onSubmit()" [loading]="geoLoading"
                    (events)="onAddressEvent($event)" [address]="address" [isBasic]="true" data-test_id="textBox_address_basic"></app-address-input>
                <div class="col-auto px-0 pt-3 d-md-none" [ngStyle]="{'width': '52px'}">
                    <a [ngClass]="'hint mx-auto '+(settings?.isRTL()?'hint-rtl':'')" [ngStyle]="{background: 'url(' + settings.backendUrl + '/assets/red_info_icon.svg) no-repeat'}" (click)="openDialog()" data-test_id="link_redinfo"></a>
                </div>
            </div>
        </div>
        <div *ngIf="!hide.yourLocation" class="col-md-3 pl-2 d-none d-md-flex">
            <a canFreezeAnchor="yourLocation" tabindex="0" data-test_id="link_geolocation" class="geolocation" [ngStyle]="{background: 'url(' + settings.backendUrl + '/assets/locationIcon2.svg) no-repeat'}" (click)="getLocation()" title="{{'spl.search.location.click.title' | translate}}"></a>
        </div>
        <!--div class="col-xl-2 col-lg-1 col-md-1 d-none d-md-block"></div>
        <div *canHide="'selectCountry'" [cssSwitch]="'yourLocation,col-xl-8 col-11 small-gutters-mobile-left,col-xl-3 col-lg-4 col-md-3 col-sm-4 col-4 small-gutters-mobile-left'" 
             [dir]="settings?.isRTL()?'RTL':''">
            
        </div>
        <div *canHide="'yourLocation'" [cssSwitch]="'selectCountry,col-xl-8 col-12 address-container small-gutters-mobile-right,col-xl-5 col-lg-6 col-md-7 col-sm-8 col-8 address-container small-gutters-mobile-right'" 
             [dir]="settings?.isRTL()?'RTL':''">
            
        </div>
        <div class="col-xl-2 col-lg-1 col-md-1 d-none d-md-block"></div-->
    </div>
    <div class="row d-none d-md-flex">
        <div class="col-md-3"></div>
        <div class="col-md-3 pl-md-0 pr-md-3"></div>
        <div *ngIf="!hide.yourLocation" class="col-md-3 pl-md-3 pr-md-0">
            <a role="link" aria-label="Click for more information" tabindex="0" #iconRef [ngClass]="'hint float-right '+(settings?.isRTL()?'hint-rtl':'')" [ngStyle]="{background: 'url(' + settings.backendUrl + '/assets/red_info_icon.svg) no-repeat'}" (click)="openDialog()"></a>
        </div>
        <div class="col-md-3"></div>
    </div>
    <!--div *canHide="'capability'" class="row justify-content-center mb-2">
        <label [ngClass]="{'mobile' : isMobile()}">{{'spl.search.select.service' | translate | uppercase }}</label>
    </div-->
    <div *canHide="'capability'" class="row">
        <div class="col text-center font-weight-bold text-red mb-1 mb-md-2">
            <h6 class="font-weight-bold" [ngStyle]="{'font-size': '1.2rem'}">{{'spl.search.select.service' | translate | uppercase }}</h6>
        </div>
    </div>
    <div *canHide="'capability'" class="row">
        <app-capability-select class="col" [isaddressAnID]="isID()" [disabled]="isCapabilityDisabled()" [selectedCapability]="selectedCapability" data-test_id="button_capability_basic" [landingPage]="true"></app-capability-select>
    </div>
    <div class="row justify-content-center mt-3 mt-md-5 mb-3">
        <button class="rounded-lg" mat-button id="searchButton"
                [disabled]="searchInProgress ? true : (
                        (  
                            isID() || 
                            (hide.capability && isAddressLongEnough()) ||
                            (!hide.capability && isAddressLongEnough() && isCapabilitySelected())
                        ) ? false : true
                    )"
                (click)="doSearch()"
                [title]="!isAddressLongEnough()? (isID() ? '' : ('spl.search.insert_location' | translate)) : (isCapabilitySelected() ?'': (isID() ? '' : ('spl.search.select_actio' | translate)))" data-test_id="button_search_basic">
            <span>{{"spl.search.btn_text" | translate}}</span>
        </button>
    </div>
    <div class="row justify-content-center">
        <a *ngIf="!isJsApi()" tabindex="0" (click)="advancedSearchClick()" class="adv-search" data-test_id="link_advanced_basic">{{'spl.search.advance' | translate}}<i class="material-icons align-middle" [ngStyle]="{'width': '16px'}">navigate_next</i></a>
        <a *ngIf="isJsApi()" tabindex="0" (click)="onAdvancedSearchClick()" class="adv-search" data-test_id="link_advanced_basic">{{'spl.search.advance' | translate}}<i class="material-icons align-middle" [ngStyle]="{'width': '16px'}">navigate_next</i></a>
    </div>

    <div *ngIf="expressMessage || settings.expressMessage" class="spacer"></div>
    <div *ngIf="expressMessage || settings.expressMessage" class="row justify-content-center">
        <!--div *ngIf="expressMessage" class="adv-search" style="font-weight: normal !important; font-family: Delivery, sans-serif !important;">{{expressMessage.message}}</div-->
        <div *ngIf="settings.expressMessage && settings.expressMessage.url"><a (click)="onMessageDetailClick(settings.expressMessage.url)" class="adv-search" data-test_id="link_message_detail_basic1">{{settings.expressMessage.message}} <i class="material-icons align-middle" [ngStyle]="{'width': '16px'}">navigate_next</i></a></div>
        <div *ngIf="settings.expressMessage && !settings.expressMessage.url" class="adv-search" data-test_id="link_message_detail_basic2" [ngStyle]="{'font-weight': 'normal', 'font-family': 'Delivery, sans-serif'}">{{settings.expressMessage.message}}</div>
    </div>
    <div *ngIf="expressMessageWithLink || settings.expressMessageWithLink" class="row justify-content-center">
        <!--div *ngIf="expressMessageWithLink && expressMessageWithLink.url"><a (click)="onMessageDetailClick()" class="adv-search" data-test_id="link_message_detail_basic">{{expressMessageWithLink.message}} <i class="material-icons align-middle" style="width: 16px;">navigate_next</i></a></div>
        <div *ngIf="expressMessageWithLink && !expressMessageWithLink.url" class="adv-search" style="font-weight: normal !important; font-family: Delivery, sans-serif !important;">{{expressMessageWithLink.message}}</div-->
        <div *ngIf="settings.expressMessageWithLink && settings.expressMessageWithLink.url"><a (click)="onMessageDetailClick(settings.expressMessageWithLink.url)" class="adv-search" data-test_id="link_message_detail_basic_link1">{{settings.expressMessageWithLink.message}} <i class="material-icons align-middle" [ngStyle]="{'width': '16px'}">navigate_next</i></a></div>
        <div *ngIf="settings.expressMessageWithLink && !settings.expressMessageWithLink.url" class="adv-search" data-test_id="link_message_detail_basic_link2" [ngStyle]="{'font-weight': 'normal', 'font-family': 'Delivery, sans-serif'}">{{settings.expressMessageWithLink.message}}</div>
    </div>

    <div *ngIf="!isJsApi() && !settings.mobileHiding" class="row justify-content-center mt-3 d-md-none">
        <app-lang-selector  uniqueId="search-lan-label" [expandUp]="true" [langList]="langList" data-test_id="button_langSelect_basic"></app-lang-selector>
        <app-lang-list #langList [ngStyle]="{'position': 'relative'}" [expandUp]="true" data-test_id="button_langList_basic"></app-lang-list>
    </div>
    <div *ngIf="!isJsApi() && !settings.mobileHiding" class="row justify-content-center d-md-none">
        <span class="col-auto pr-2 ml-auto"><a href="http://www.dhl.com" [href]="'https://' + mobileLink" target="_blank">www.dhl.com</a></span>
        <app-light-selector class="col-auto pl-2 mr-auto" data-test_id="button_lightVersion_basic"></app-light-selector>
    </div>
    <div *ngIf="!isJsApi() && !settings.mobileHiding" class="row justify-content-center mt-1 d-md-none"> <!-- && settings.cookieLinkDisplayStatus //694365-->
        <span class="col-auto"><a id="ot-sdk-btn" class="ot-sdk-show-settings">Consent Settings</a></span>
    </div>
    <div class="spacer"></div>
    <div *ngIf="isJsApi() && (!settings.isOneTrustLoaded() || getClientAppCode())" class="row d-md-flex">
        <div class="col-md-3"></div>
        <div class="col-md-6" [ngClass]="(settings?.isRTL()?'pr-0':'pl-0')">
            <a id="ot-sdk-btn" [ngClass]="'text-link font-weight-bold '+ (settings?.isRTL()?'float-right':'float-left')" class="ot-sdk-show-settings">Consent Settings</a>
        </div>
        <div class="col-md-3"></div>
    </div>
</div>
