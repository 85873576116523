import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../settings.service';

@Component({
    selector: 'app-light-selector',
    templateUrl: './light-selector.component.html',
    styleUrls: ['./light-selector.component.scss']
})
export class LightSelectorComponent implements OnInit {

    constructor(public settings: SettingsService) { }

    ngOnInit() {
    }

}
