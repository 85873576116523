import { ElementRef, HostListener, Injectable, Input, NgZone, ViewChild } from '@angular/core';
import { SettingsService } from '../settings.service';
import { Utils } from './utils';
import { AdvSearchHintComponent } from '../adv-search-hint/adv-search-hint.component';
import { MatDialog as MatDialog } from '@angular/material/dialog';

@Injectable({
    providedIn: 'root'
})

export class DialogService {

    iconRef : ElementRef = undefined;
    isInfoDialogOpen: boolean;
    dialogInfoRef: any;
    
    openDialog(dialog: MatDialog, iconRef:ElementRef): void {
        this.iconRef = iconRef;
        if(Utils.isMobile()){
            dialog.open(AdvSearchHintComponent, {});
        }else{
            this.isInfoDialogOpen = true;
            this.dialogInfoRef = dialog.open(AdvSearchHintComponent);
            this.updateDialogPosition(this.dialogInfoRef, iconRef);
            this.dialogInfoRef.afterClosed().subscribe(() => {
                // Handle the event from the child component
                this.isInfoDialogOpen = false;
            }); 
        }  
    }

    updateDialogPosition(dialogRef, iconRef:ElementRef) {
        this.iconRef = iconRef;
        const iconPosition = iconRef.nativeElement.getBoundingClientRect();
        dialogRef.updatePosition({
            right: `${innerWidth - (iconPosition.left - 20)}px`,
            top: `${iconPosition.top + 10}px`
        })
    }

}