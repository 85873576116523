import { Utils } from "./common/utils";

declare const window: any;//573005

export class GoogleDirectionMapsLoader {

    private static promise;
    static newUrl: string;

    public static loadgoogleDirectionsScript(lang = 'en', region = 'US', url, key) {
        if (!this.promise) {

            this.promise = new Promise((resolve, reject) => {
                let nonce = (document.querySelector('meta[name="CSP_NONCE"]') as HTMLMetaElement)?.content;
                if(Utils.isJsApi()){
                    nonce = window.gsplNonce;
                }
                const node = document.createElement('script');
                node.setAttribute('nonce', nonce);
                node.src = window.location.protocol + url + "region=" + region + "&language=" + lang + "&key=" + key ;
                document.body.appendChild(node);
                    node.onload = resolve;
                    node.onerror = reject;
            });
        }

        return this.promise;
    }

}
