<!--div class="col" [ngClass]="'controls ' + (logoOnly?'logoOnly':'') + (compact?' compact':'')" dir="LTR"-->
<app-mobile-menu *ngIf="!isJsApi() && !this.settings.mobileHiding && (settings.isResultPage() || settings.isAdvSearchPage()) && isMobile()"></app-mobile-menu> <!--BR11-->
<div *ngIf="!isJsApi() && !settings.mobileHiding" class="col" dir="LTR">
    <div class="row d-none d-md-flex">
        <div class="col"></div>
        <app-lang-list #langList [ngStyle]="{'position': 'relative'}"></app-lang-list>
    </div>
    <div class="row py-2 px-3">
        <div [ngClass]="showCenterLogo ? 'col' : 'col-auto'">
            <div *ngIf="!showCenterLogo" class="row">
                <div class="col-auto px-0">
                    <a *ngIf="toLandingPageWithParams" (click)="toLandingPage()" class="logo-link" [style.pointerEvents]="isDirectionPage() ? 'none' : 'auto'"><img class="logo" [src]="settings.backendUrl + '/assets/logo.png'" alt="DHL"/></a>
                    <a *ngIf="!toLandingPageWithParams" href="/" class="logo-link"><img class="logo" [src]="settings.backendUrl + '/assets/logo.png'" alt="DHL"/></a>
                </div>
            </div>
        </div>
        <div *ngIf="showCenterLogo" class="col-auto py-md-2 mb-xx-1" [ngStyle]="{'text-align': 'center'}">
            <img id="big-logo" src="assets/logo.png" alt="DHL" [ngStyle]="{'width': '180px', 'height': '55px'}" />
        </div>
        <div *ngIf="!settings.isError" class="col" [ngStyle]="{'display': 'flex', 'flex-direction': 'row-reverse', 'padding-block-start' : (showCenterLogo)? 'inherit' : 'unset', 'align-items' : (showCenterLogo) ? '' : 'center'}">
            <div *ngIf="showLangSelector" class="row justify-content-end d-none d-md-flex" [ngStyle]="{ 'padding-top': showCenterLogo ? '0px' : '5px' }">
                <app-lang-selector uniqueId="header-lan-label" class="col-auto px-0" [langList]="langList" [ngStyle]="{'margin-top': '-3px'}"></app-lang-selector>
                <div class="col-auto pr-0 ml-4" [ngStyle]="{'height': 'fit-content', 'border-left': '1px solid #D9AE00', 'padding-left': '13px'}">
                    <app-light-selector></app-light-selector>
                </div>
            </div>
        </div>
    </div>
</div>

