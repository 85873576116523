import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Utils } from '../common/utils';
import { SettingsService } from '../settings.service';
import { CapabilityCategory, CapabilitySelection } from '../common/capability-selection';
import { BackendService } from '../backend.service';

@Component({
    selector: 'app-capability-select',
    templateUrl: './capability-select.component.html',
    styleUrls: ['./capability-select.component.scss']
})
export class CapabilitySelectComponent implements OnInit,AfterViewInit {

    //collectSecondLevel = collectSecondLevel;
    //sendSecondLevel = sendSecondLevel;
    categories = CapabilityCategory;

    @Input()
    landingPage: boolean = false;

    @Input()
    selectedCapability: CapabilitySelection;

    @Input() isaddressAnID : boolean;//700773

    _disabled = true;

    private _capabilityException: string;

    @Input() set disabled(disabled: boolean) {
        if (!this.selectedCapability || !this.selectedCapability.isFreeze) {
            if (disabled && this.selectedCapability) {
                this.selectedCapability.reset();
            }
        } else {
            disabled = true;
        }
        this._disabled = disabled;
    }

    get disabled() {
        return this._disabled;
    }

    selectCapability(capability: CapabilityCategory) {
        setTimeout(() => {
            this.handleAccessibilityForNGSelect();
        }, 100);
        
        if (this.disabled) {
            return true;
        }
        //if (this.landingPage) {
            if (capability !== this.selectedCapability.category && this.settings.language && this.settings.selectedCountry && this.settings.selectedCountry.secondLevel && this.backend.clientAppCode && this.settings.selectedCountry.showMessage && this.settings.appConfig.messageTypes) {
                this.settings.expressMessage=undefined;
                this.settings.expressMessageWithLink=undefined;
                this.settings.getMessageDetails(this.settings.selectedCountry.id, this.settings.language.id.toString(), this.backend.clientAppCode.toString(), this.settings.appConfig.messageTypes.join(","));
            }
        //}
        if (capability !== this.selectedCapability.category) {
            this.selectedCapability.category = capability;
            if (this.selectedCapability.secondLevelEnabled) {
                if (capability === CapabilityCategory.SEND) {
                    this.selectedCapability.collect.setValue('0');
                } else if (capability === CapabilityCategory.SEND_COLLECT) {
                    // do nothing...
                } else if (capability === CapabilityCategory.COLLECT) {
                    this.selectedCapability.payment.setValue('0');
                    this.selectedCapability.handling.setValue('0');
                }
            } else {
                if (capability === CapabilityCategory.SEND) {
                    this.selectedCapability.payment.setValue(this.selectedCapability.allPaymentCode.toString());
                    this.selectedCapability.collect.setValue('0');
                } else if (capability === CapabilityCategory.SEND_COLLECT) {
                    this.selectedCapability.payment.setValue(this.selectedCapability.allPaymentCode.toString());
                    this.selectedCapability.collect.setValue(this.selectedCapability.allCollectCode.toString());
                } else if (capability === CapabilityCategory.COLLECT) {
                    this.selectedCapability.payment.setValue('0');
                    this.selectedCapability.collect.setValue(this.selectedCapability.allCollectCode.toString());
                }
            }
        }
    }

    // Deprecated
    hover(capability: CapabilityCategory, showLabel: boolean) {
        /*if (!this.disabled && this.selectedCapability.category === CapabilityCategory.NONE) {
            if (capability === CapabilityCategory.SEND) {
                this.selectedCapability.showSendLabel = showLabel;
            } else if (capability === CapabilityCategory.SEND_COLLECT) {
                this.selectedCapability.showSendAndCollectLabel = showLabel;
            } else if (capability === CapabilityCategory.COLLECT) {
                this.selectedCapability.showCollectLabel = showLabel;
            }
        }*/
    }

    /*selectSecondLevelSend(sendCode: string) {
        this.selectedCapability.sendCode = sendCode;
    }

    selectSecondLevelCollect(collectCode: string) {
        this.selectedCapability.collectCode = collectCode;
    }*/

    constructor(public settings: SettingsService, public backend: BackendService) {
    }

    ngAfterViewInit(): void {
       this.handleAccessibilityForNGSelect();                       
    }

    handleAccessibilityForNGSelect() {
        let arrayVal: string[] = ['dropdown_payment_select','dropdown_payment_SEND_select','dropdown_handling_SEND_select','dropdown_collect_COLLECT_select',
                                    'dropdown_collect_SEND_COLLECT_select','dropdown_handling_SEND_COLLECT_select','dropdown_payment_SEND_COLLECT_select',
                                    'dropdown_handling_select','dropdown_collect_select'];
        for(const item of arrayVal){
            const selectElement:HTMLElement = document.getElementById(item);
            if(selectElement){
                selectElement.removeAttribute('role');
            }
        }

    }

    ngOnInit() {
    }

    isMobile() {
        return Utils.isMobile();
    }
}
