import {Component, HostListener, OnDestroy, OnInit, Optional, ViewChild, ElementRef, AfterViewInit, Renderer2, NgZone, Input} from '@angular/core';
import {BackendService, SearchServicePointsResult, ServicePoint} from '../backend.service';
import {environment} from '../../environments/environment';
import {SettingsService} from '../settings.service';
import {Utils} from '../common/utils';
import {Subscription} from 'rxjs';
import { CountryPromotionComponent } from '../country-promotion/country-promotion.component';
import { SpMapBase } from '../sp-map/sp-map-base';
import { AlertMessageComponent } from '../alert-message/alert-message.component';
import { ConsentService } from '../consent.service.ts.service';

declare const window: any;

@Component({
    selector: 'app-sp-map-light',
    templateUrl: './sp-map-light.component.html',
    styleUrls: ['./sp-map-light.component.scss']
})
export class SpMapLightComponent extends SpMapBase implements OnInit, AfterViewInit, OnDestroy {

    isMobile = Utils.isMobile;

    servicePoints: SearchServicePointsResult;
    selectedServicePoint: ServicePoint;

    @ViewChild('countryPromotion', {static: false})  countryPromotionComponent: CountryPromotionComponent;
    @ViewChild('mapDiv', {static: false}) mapDivElementRef: ElementRef;
    @ViewChild('alertMessage', {static: false}) alertMsgComponent: AlertMessageComponent;
    mapType: string;
    isAfterOnInit = false;
    isAfterViewInit = false;
    staticMapUrl = '';
    isMapConsentGiven: boolean = false;

    private subscriptions: Array<Subscription> = [];

    constructor(@Optional() public settings: SettingsService, public renderer: Renderer2, public ngZone: NgZone, public backend:BackendService, private consentService: ConsentService) {
        super();
    }

    ngOnInit() {
        this.isMapConsentGiven = this.settings.checkShowMapConsent('Bing');
        this.servicePoints = this.settings.filteredSearchResult;
        this.setMapType();
        this.subscriptions.push(this.settings.searchResultLoaded.subscribe(() => {
            this.servicePoints = this.settings.filteredSearchResult;
            this.setMapType();
            // this.resolveMapDivHeight();
        }));
        this.subscriptions.push(this.settings.servicePointSelected.subscribe(selectedServicePoint => {
            if (selectedServicePoint && selectedServicePoint.selectType === Utils.LIST_SELECT_TYPE) {
                this.selectedServicePoint = selectedServicePoint;
            }
        }));
        this.isAfterOnInit = true;
    }

    onOpen() {
    }

    ngAfterViewInit() {
        this.consentService.initialize();
        this.subscriptions.push(this.consentService.consentChange$.subscribe(consent => {
            if(this.mapType && this.mapType == 'Bing'){
                this.isMapConsentGiven = this.settings.checkShowMapConsent('Bing');
                this.loadBingMaps();
            }
            // Update UI based on consent change
        }));
        // this.resolveMapDivHeight();
        this.isAfterViewInit = true;

        setTimeout(() => {
            this.consentService.initialize();
        }, 100);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    setSelectedServicePoint(selectedServicePoint: ServicePoint) {
        this.selectedServicePoint = selectedServicePoint;
        this.settings.notifyServicePointSelected(selectedServicePoint);
    }

    @HostListener('window:close-infobox', ['$event'])
    outsideCloseEvent(event) {
        this.selectedServicePoint.isSelected = false;
        this.selectedServicePoint = null;
        this.settings.notifyServicePointSelected(null);
    }

    closed(){
        this.selectedServicePoint.isSelected = false;
        this.selectedServicePoint = null;
        this.settings.notifyServicePointSelected(null);
    }

    loadBingMaps() {
        this.mapType = 'Bing';
        if(this.isMapConsentGiven){
            
            
            // to mimic bootstrap sizing
            const mapWidth = (window.innerWidth - window.innerWidth / 4 - 10).toFixed();
            let mapHeight = window.innerHeight - 116;
            if(Utils.isJsApi()){
                mapHeight = window.innerHeight;
            }

            this.staticMapUrl = window.location.protocol + environment.bingUrl + `/Imagery/Map/Road?mapSize=${mapWidth},${mapHeight}&key=` +
            ((Utils.isJsApi() && !this.settings.appConfig.jsapiv3Usegsplwsbkey) ? this.backend.bingKey : this.settings.appConfig.bingMapsKey);

            // add language parameter
            if (this.settings) {
                this.staticMapUrl += '&c=' + this.settings.getCurrentLangCode();
            }
            // add pin at location of search address
            if (this.servicePoints &&
                (this.servicePoints.searchLocation || (this.servicePoints.servicePoints && this.servicePoints.servicePoints[0]))) {
                const coords = this.servicePoints.searchLocation ?
                    (this.servicePoints.searchLocation.latitude + ',' + this.servicePoints.searchLocation.longitude) :
                    (this.servicePoints.servicePoints[0].geoLocation.latitude + ',' + this.servicePoints.servicePoints[0].geoLocation.longitude);
                this.staticMapUrl += '&pp=' + coords + ';92;';
            }

            if (this.servicePoints && this.servicePoints.servicePoints) {

                this.servicePoints.servicePoints.forEach((sp, i) => {
                    this.staticMapUrl += '&pp=' + sp.geoLocation.latitude + ',' + sp.geoLocation.longitude + ';64;' + (i + 1);
                });
            }

            if (this.servicePoints && this.servicePoints.isShowInfoBox) {
                this.selectedServicePoint = this.servicePoints.servicePoints[0];
                this.selectedServicePoint.selectType = Utils.PIN_SELECT_TYPE;
                this.selectedServicePoint.isSelected = true;
                this.settings.notifyServicePointSelected(this.selectedServicePoint);
            } else {
                this.selectedServicePoint = null;
            }
        }
    }

    isJsApi(){
        return Utils.isJsApi();
    }

    @Input('show_share_svp_detail') showShareButtonSvpDetail = 'Y';
}
