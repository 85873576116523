<ul>
  <li tabindex="0" *ngIf="isResultsPage()"> <!-- ngIf is added for BR11 - UXRedesign S1-->
    <div class="dropdown sel-country">
      <a id="lenUnit" data-target="#"data-toggle="dropdown" role="button" aria-haspopup="true"
         aria-expanded="false" (click)="onUomClick()">{{'spl.search.'+ selectedUom.code | translate}}</a>
      <ul [ngClass]="'dropdown-menu show '+(expandUp?'upper':'under')" aria-labelledby="lenUnit" *ngIf="toggleUom">
        <li *ngFor="let uom of uoms">
          <a tabindex="0" (click)="onUomSelect(uom)">{{'spl.search.'+ uom.code | translate}}</a>
        </li>
      </ul>
    </div>
  </li>
  <!--li> Commented for BR11
    <div class="dropdown sel-country">
      <a id="countryLabel" class="dropdown-selected" data-target="#" data-toggle="dropdown"
         role="button" aria-haspopup="true" aria-expanded="false"
         (click)="onCountryClick()">{{selectedLanguageCountry?.countryCode2}}</a>
      <ul [ngClass]="'dropdown-menu show dropdown-country '+(expandUp?'upper':'under')" id="dropdown-country"
          aria-labelledby="countryLabel" *ngIf="toggleCountry">
        <li *ngFor="let country of countryInfos">
          <a (click)="onCountrySelect(country)">{{country.countryName}}</a>
        </li>
      </ul>
    </div>
  </li-->
  <li [ngStyle]="{'margin-right': '0px'}" tabindex="0">
    <div class="dropdown sel-language">
      <a [id]="uniqueId" class="dropdown-selected lan-label" data-target="#" data-toggle="dropdown"
         role="button" aria-haspopup="true" aria-expanded="false"
         (click)="onLangClick()">{{selectedLanguage ? selectedLanguage.languageNative : 'english'}}</a>
      <!--ul (click)="onLangPlateClick()" [ngClass]="'dropdown-menu show dropdown-language '+(expandUp?'upper':'under')" *ngIf="toggleLanguage">
        <li *ngFor="let lang of settings.languages | keyvalue">
          <a (click)="onLangSelect(lang.value)">{{lang.value.languageNative}}</a>
        </li>
      </ul-->
    </div>
  </li>
</ul>
<div class="modal-backdrop" *ngIf="toggleUom || toggleCountry || langList.toggleLanguage" (click)="modalBackdropClick()"></div>
