import { Component, Input, NgZone, OnInit, Optional, OnDestroy, Inject } from '@angular/core';
import {Uom} from '../model/Uom';
import {LanguageInfo} from '../model/LanguageInfo';
import {CountryInfo} from '../model/CountryInfo';
import {SettingsService} from '../settings.service';
import { Subscription } from 'rxjs';
import { LangListComponent } from '../lang-list/lang-list.component';
import { DOCUMENT } from '@angular/common';

declare const OneTrust: any;

@Component({
    selector: 'app-lang-selector',
    templateUrl: './lang-selector.component.html',
    styleUrls: ['./lang-selector.component.scss']
})

export class LangSelectorComponent implements OnInit, OnDestroy {

    @Input() expandUp = false;
    @Input() uniqueId: string = '';
    @Input() langList: LangListComponent;

    uoms: Array<Uom>;
    selectedLanguage: LanguageInfo;
    countryInfos: Array<CountryInfo>;
    selectedLanguageCountry: CountryInfo;
    selectedUom: Uom;

    toggleUom = false;
    //toggleLanguage = false;
    toggleCountry = false;

    private subscriptions: Array<Subscription> = [];

    constructor(public settings: SettingsService, @Optional() private ngZone: NgZone, @Inject(DOCUMENT) private document: Document) {
        //, @Optional() private cdr: ChangeDetectorRef
        this.uoms = this.settings.uoms;
        this.selectedLanguage = this.settings.language;
        this.countryInfos = this.selectedLanguage.countryInfos;
        this.selectedLanguageCountry = this.settings.languageCountry;
        this.selectedUom = this.settings.uom;
    }

    ngOnInit(): void {

        this.subscriptions.push(this.settings.languageChanged.subscribe(newLang => this.ngZone.run(() => {
            this.selectedLanguage = newLang;
            this.countryInfos = newLang.countryInfos;
            if (this.countryInfos && this.countryInfos.length > 0) {
                this.settings.languageCountry = this.countryInfos[0];
                this.selectedLanguageCountry = this.settings.languageCountry;
            }
            //this.cdr.detectChanges();
        })));

        this.subscriptions.push(this.settings.languageCountryChanged.subscribe(newLc => this.ngZone.run(() => {
            this.selectedLanguageCountry = newLc;
            // var oneTrustCode: string = this.settings.getOneTrustCode(this.selectedLanguage, newLc);
            // this.document.documentElement.lang = oneTrustCode;
            // try{
            //     OneTrust.changeLanguage(oneTrustCode);
            // } catch (Error){
            //     console.log("error in catch", Error);
            // }
            this.settings.cookieSettingForLang();
        })));

        this.subscriptions.push(this.settings.uomChanged.subscribe(newUom => this.ngZone.run(() => {
            this.selectedUom = this.uoms.find(uom => uom.code === newUom.code);
            //this.cdr.detectChanges();
        })));
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    onUomClick() {
        this.toggleUom = !this.toggleUom;
        this.toggleCountry = false;
        this.langList.toggleLanguage = false;
    }

    onCountryClick() {
        this.toggleUom = false;
        this.toggleCountry = !this.toggleCountry;
        this.langList.toggleLanguage = false;
    }

    onLangClick() {
        this.toggleUom = false;
        this.toggleCountry = false;
        this.langList.toggleLanguage = !this.langList.toggleLanguage;
    }

    onLangSelect(language: any) {
        this.langList.toggleLanguage = false;
        this.settings.language = language;
    }

    onCountrySelect(countryInfo: CountryInfo) {
        this.toggleCountry = false;
        this.settings.languageCountry = countryInfo;
    }

    onUomSelect(uom: Uom): void {
        this.toggleUom = false;
        this.settings.uom = uom;
    }

    onLangPlateClick() {
        if (this.expandUp) {
            this.langList.toggleLanguage = false;
        }
    }

    modalBackdropClick() {
        this.langList.toggleLanguage = this.toggleCountry = this.toggleUom = false;
    }

    /*Added for BR11 - UXRedesign S1 */
    isResultsPage(){
        return this.settings.isResultPage();
    }
}

