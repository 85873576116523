<div class="container-fluid h-100">
    <div class="row" [ngStyle]="settings.mobileHiding?{'min-height': 'calc(100vh - 34px)'}:{'min-height': 'calc(100vh - 3rem)'}">
        <div class="col">
            <app-header class="row" [showCenterLogo]="true"></app-header>
            <app-basic-search-controls class="row" spacingBelow="true"></app-basic-search-controls>
        </div>
    </div>
    <app-footer *ngIf="!settings.mobileHiding" class="row d-none d-md-flex"></app-footer>
    <app-footer *ngIf="!isJsApi() && settings.mobileHiding" [isBasic]="true" [ngClass]="'row d-md-flex' + (!settings.mobileHiding?' d-none':'')" [ngStyle]="{'background-color': '#EBEBEB'}"></app-footer>
</div>
