import { Component, OnInit, Input } from '@angular/core';
import { SettingsService } from '../settings.service';
import {CountryInfo} from '../model/CountryInfo';
import {LanguageInfo} from '../model/LanguageInfo';

@Component({
    selector: 'app-lang-list',
    templateUrl: './lang-list.component.html',
    styleUrls: ['./lang-list.component.scss']
})
export class LangListComponent implements OnInit {

    @Input() expandUp = false;
    toggleLanguage = false;
    countryInfos: Array<CountryInfo>;
    selectedLanguageCountry: CountryInfo;
    selectedLanguage: LanguageInfo;

    constructor(public settings: SettingsService) { 
        this.selectedLanguage = this.settings.language;
        this.countryInfos = this.selectedLanguage.countryInfos;
        this.selectedLanguageCountry = this.settings.languageCountry;
    }

    ngOnInit() {
    }

    onLangPlateClick() {
        // commented below block BR11
        /*if (this.expandUp) {
            this.toggleLanguage = false;
        }*/
    }

    onLangOrCountrySelectedAction(){
        this.populateMessageDetails();
        this.toggleLanguage = false;
    }

    modalBackdropClick() {
        this.toggleLanguage = false;
      }

      populateMessageDetails(){
        if(this.settings.ppcsArray && this.settings.ppcsArray.length > 0 && !this.settings.isAdvSearchPage() && !this.settings.isResultPage()){
            if (this.settings.selectedCountry && this.settings.selectedCountry.secondLevel && this.settings.clientAppCode && this.settings.selectedCountry.showMessage && this.settings.appConfig.messageTypes) {
                this.settings.expressMessage=undefined;
                this.settings.expressMessageWithLink=undefined;
                this.settings.getMessageDetails(this.settings.selectedCountry.id, this.settings.language.id.toString(), this.settings.clientAppCode.toString(), this.settings.appConfig.messageTypes.join(",")).then(() => {
                    this.settings.getMessageDetailsByPriorityForExpressMessage(this.settings.ppcsArray);
                    this.settings.messageDetailFetched.emit();
                });
            }
        }
    }

    // BR11 Removed lot of code from here as it is moved to lang-country component
}
