<!--*ngFor="let sp of _servicePoints?.servicePoints"-->
<div id="map" class="col mx-0 px-0 h-100" [ngStyle]="{'display':'flex', 'flex-direction':'row'}">
    <div class="col px-0 svp-detail-min-max-width" [ngStyle]="{'padding-left': '0px', 'padding-right': '0px'}" *ngIf="!mobile && _servicePoints && _selectedServicePoint">
        <app-sp-detail2 
        [servicePoint]="_selectedServicePoint"
        [yourLocation]="originPoint" 
        [searchLocation]="_servicePoints?.searchLocation" 
        [show_share_svp_detail]="showShareButtonSvpDetail"
        [hrFormat24]="settings?.languageCountry.timeFormat !== 'AMPM'"
        [hasPromotion]="_servicePoints.hasSpPromotion"
        (onDirections)="onDirections.emit($event)"
        (onPrint)="onPrint.emit($event)"
        (onWebLink)="onWebLink.emit($event)"
        (onFindActiveServicePoints)="onFindActiveServicePoints.emit($event)"
        (closed)="closeInfoBox()">
    </app-sp-detail2>
    </div>
    <div [ngClass]="_servicePoints && _selectedServicePoint?'col px-0':'col-12 px-0'" id="map-canvas" [ngStyle]="{'display': 'none'}"></div>
    <div id="content" [ngStyle]="{'display': 'none'}"><app-cookie-map-consent [page]="'bing'" [mapType]="'bing'" ></app-cookie-map-consent></div> <!--(dataEvent)="receiveData($event)"-->
</div>

<div *ngIf="!mobile && _servicePoints" [ngStyle]="{'display': 'none'}">
    <!--BR11 - not needed though-->
    <!--app-sp-detail 
        [id]="sp.facilityId" 
        *ngFor="let sp of _servicePoints?.servicePoints" 
        [servicePoint]="sp"
        [yourLocation]="originPoint" 
        [searchLocation]="_servicePoints?.searchLocation" 
        [mobile]="mobile"
        [rtl]="settings?.isRTL()"
        [hrFormat24]="settings?.languageCountry.timeFormat !== 'AMPM'"
        [show_share_svp_detail]="showShareButtonSvpDetail"
        (onDirections)="onDirections.emit($event)"
        (onPrint)="onPrint.emit($event)"
        (onWebLink)="onWebLink.emit($event)">
    </app-sp-detail-->
    <app-sp-detail-tooltip 
        [id]="'sp-detail-tooltip-' + servicePoint.facilityId" 
        *ngFor="let servicePoint of _servicePoints?.servicePoints" 
        [servicePoint]="servicePoint">
    </app-sp-detail-tooltip>
</div>
