<div class="col h-100 map-container">
    <app-country-promotion *ngIf="!isMobile()" 
        #countryPromotion class="row" 
        [mobile]="false"
        (heightChange)="promotionHeightChanged($event)">
    </app-country-promotion>
    <app-alert-message class="row" #alertMessage  (heightChange)="promotionHeightChanged($event)"></app-alert-message>
    <!--servicePoints?.promotion?{'height': 'calc(100% - 60px)'}:{'height': '100%'}-->
    <div #mapDiv class="row map-item">
        <app-google-map [ngStyle]="{'height': '100%'}" *ngIf="mapType === 'Google'"
            class="col-md-12 px-0"
            [lightVersion]="true"
            [servicePoints]="servicePoints"
            [show_share_svp_detail]="showShareButtonSvpDetail"
            [selectedServicePoint]="selectedServicePoint" 
            [mobile]="isMobile()"
            (servicePointSelected)="setSelectedServicePoint($event)">
        </app-google-map>
        <div class="col px-0 mx-0" [ngStyle]="{'display':'flex', 'flex-direction':'row', 'height': (isJsApi() ? '100%' : 'calc(100vh - 106px)')}">
            <div  class="col p2-1 mr-1 svp-detail-min-max-width" [ngStyle]="{'padding-left': '0px', 'padding-right': '0px'}" *ngIf="mapType === 'Bing' && !isMobile() && selectedServicePoint">
                <app-sp-detail2 *ngIf="selectedServicePoint && settings.lightVersion" 
                    [servicePoint]="selectedServicePoint"
                    [yourLocation]="servicePoints?.searchAddress" 
                    [searchLocation]="servicePoints?.searchLocation" 
                    [hrFormat24]="settings?.languageCountry.timeFormat !== 'AMPM'" 
                    [show_share_svp_detail]="showShareButtonSvpDetail"
                    (closed)="closed()">
                </app-sp-detail2>
            </div>
            <div *ngIf="mapType === 'Bing' && isMapConsentGiven" [ngClass]="'light-map-container '+(selectedServicePoint?'col':'col-12 px-0 mx-0')" [style.backgroundImage]="'url(' + staticMapUrl+')'"></div>
            <app-cookie-map-consent *ngIf="mapType === 'Bing' && !isMapConsentGiven" [mapType]="'Bing'"></app-cookie-map-consent>
        </div>
        
            <!--BR11-->
            <!--app-sp-detail *ngIf="selectedServicePoint" [id]="selectedServicePoint?.facilityId" [servicePoint]="selectedServicePoint"
                        [yourLocation]="servicePoints?.searchAddress" [fitScreen]="true"
                        [searchLocation]="servicePoints?.searchLocation" [lightVersion]="settings.lightVersion"
                        [rtl]="settings?.isRTL()"
                        [hrFormat24]="settings?.languageCountry.timeFormat !== 'AMPM'"
            ></app-sp-detail-->
    </div>
</div>