<div *ngIf="settings.isShowAlertMsg" class="col px-2 py-2 bgcolor">
  <div class="row mx-0">
    <div class="col-auto pl-0 pr-2">
      <img [src]="settings.backendUrl + '/assets/alerts/' + settings.alert.image">
    </div>
    <div class="col px-0">
      <div class="row mx-0">
        <div class="col px-0 text-14">{{settings.alert.message1 | translate}}</div>
        <div class="col-auto pl-2 pr-0 close">
          <i class="material-icons close text-20" (click)="close()">close</i>
        </div>
      </div>
      <div class="row mx-0">
        <div class="col px-0 text-14">{{settings.alert.message2 | translate}}</div>
      </div>
    </div>
  </div>
</div>