export class PrintData {
    location = {latitude : 0, longitude : 0};
    address = {
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        zipCode: '',
        city: '',
        state: '',
        countryDivisionCode: '',
        countryDivisionCodeType: ''
    };
    spName = '';
    localName = '';
    yourLocation = '';
    searchLocation: any;
    hideMobile = false;
    uom: any;
    mapType: any;
    lang: string;
    langCtry: string;
    langTrans: string;
    //userSearchLocation: any = {latitude : 0, longitude : 0};
    showTravelMode: string;
    defDriveMode: string;
    cookieConsent: boolean;
    countryCode: string;
    otCode: string;
}
