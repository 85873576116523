import { Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  constructor(private titleService: Title,
    private metaTagService: Meta, private translateService: TranslateService) {
    this.titleService.setTitle(this.translateService.instant('spl.search.seo.page_title'));
    this.metaTagService.addTags([
      {name: 'description', content: this.translateService.instant('spl.search.seo.meta_desc')},
      {name: "keywords", content: this.translateService.instant('spl.search.seo.meta_keywords')},
      { name: 'author', content: this.translateService.instant('spl.seo.meta_author') },
      { name: 'robots', content: this.translateService.instant('spl.seo.meta_robots') }]);
  }

  addMetaTags(tags: Array<MetaDefinition>) {
    this.metaTagService.addTags(tags);
  }

  setPageTitle(title: string) {
    this.titleService.setTitle(title);
  }

  updateTag(tag: MetaDefinition) {
    this.metaTagService.updateTag(tag);
  }

  updateTags(tags: Array<MetaDefinition> = []) {
    tags.map((tag) => {
      this.metaTagService.updateTag(tag);
    })
  }

  removeTag(name: string) {
    this.metaTagService.removeTag(name);
  }
}
