import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export class CustomValidators {

    private static regex: RegExp = new RegExp(/^(([0-9][0-9]*)|0)([.,][0-9]*){0,1}$/g);

    static number(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value && control.value.toString().length > 0) {
                const num = control.value.toString();
                if (!String(num).match(this.regex)) {
                    return { notANumber: true };
                }
            }
            return null;
        };
    }
}
