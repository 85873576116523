<div *ngIf="_isOpen" [dir]="dir" [ngClass]="textAlignRtl" id="main" class="container-fluid" (window:resize)="resizeNothing()"
    [style.height]="!isJsApi() ? 'calc(100vh)' : '100%'">
    <app-header class="row position-given"></app-header>
    <div class="row" [ngStyle]="{'background-color': 'white'}" [style.direction]="(settings?.isRTL() ? 'rtl':'ltr')"
        [style.height]="
            isMobile() ? 
            ((isJsApi() || settings.mobileHiding) ? '100%' : 'calc(100% - 58px)') : 
            (isAdvancedSearchOpen ? ((isJsApi() || settings.mobileHiding) ? '100%':'calc(100% - 106px)') : 
                ((isJsApi() || settings.mobileHiding) ? '100%' : 'calc(100% - 106px)')
            )"
        >
        <div class="col h-100">
            <div [ngClass]="'row d-md-none' + (isMobile() && mobileDisplay === MobileDisplay.SP_DETAIL ? ' d-none' : '')" [ngStyle]="{'height': '52px'}">
                <button type="button" [ngClass]="'col-6 text-white ' + (mobileDisplay === MobileDisplay.RESULT_LIST ? 'white-bg-color' : 'dhlred-bg-color-details')"
                    (click)="showMobileList()">{{'spl.result.tab.details' | translate}}</button>
                <button type="button" [ngClass]="'col-6 text-white ' + (mobileDisplay === MobileDisplay.MAP ? 'white-bg-color' : 'dhlred-bg-color-details')"
                    (click)="showMobileMap()">{{'spl.result.map' | translate}}</button>
            </div>
            <div [ngClass]="'row' + (isMobile() && mobileDisplay === MobileDisplay.SP_DETAIL ? ' d-none' : '')"
                [style.height]="isMobile() ? (settings.mobileHiding?'calc(100% - 82px)':'calc(100% - 52px)') : '100%'" > <!--[ngStyle]="!isMobile() ? {'display': 'flex'}:{}"-->
                <div [ngClass]="!isMobile() ? 'col-3 h-100 result-list-min-width' : (isMobile() && mobileDisplay === MobileDisplay.RESULT_LIST) ? 'col h-100' : 'd-none'" > <!--[ngStyle]="!isMobile() ? {'flex': '0 0 0'}:{} [ngStyle]="!isMobile() ? {'min-width': '315px'}:{}"-->
                    <app-country-promotion class="row d-md-none" [mobile]="true"></app-country-promotion>
                    <app-result-list #resultList class="row h-100" (onSearch)="_onSearch($event)" (onFilterSelected)="_onFilterSelected($event)"
                        [resultsPage]="true"
                        [mandatory_indicator]="mandatoryIndicator" 
                        [show_country_select]="showCountrySelect"
                        [show_address_input]="showAddressInput"
                        [show_filter]="showFilter"
                        [show_advanced_search]="showAdvancedSearch"
                        [show_search_button]="showSearchButton"
                        [show_share_svp_detail]="showShareButtonSvpDetail"
                        [show_qr_code_selected]="showDefaultQRCodeSelected"
                        (onToggleAdvancedSearch)="toggleAdvancedSearch($event)"
                        (onServicePointSelected)="onServicePointSelected.emit($event)"
                        (onSelectedLocation)="onSelectedLocation.emit($event);"
                        (shareButtonDisabled)="shareButtonDisable($event)">
                    </app-result-list>
                </div>
                <div *ngIf="!isMobile() ? true : isMapLoaded"
                    [ngClass]="!isMobile() ? 'col h-100' : (isMobile() && mobileDisplay === MobileDisplay.MAP) ? 'col h-100' : 'd-none'"> <!--This is modified for BR11 - UXRedesign S9 -> added confition col-9 and col-6 switch-->
                    <app-sp-map *ngIf="!settings?.lightVersion" class="row h-100"
                        [resultsPage]="true"
                        [show_share_svp_detail]="showShareButtonSvpDetail"
                        (onServicePointSelected)="onServicePointSelected.emit($event)"
                        (onDirections)="onDirections.emit($event)"
                        (onPrint)="onPrint.emit($event)"
                        (onWebLink)="onWebLink.emit($event)"
                        (onFindActiveServicePoints)="onFindActiveServicePoints.emit($event)"
                        (onPanOrZoom)="_onPanOrZoom($event)"
                        >
                    </app-sp-map>
                    <app-sp-map-light *ngIf="settings?.lightVersion" [show_share_svp_detail]="showShareButtonSvpDetail" class="row h-100"></app-sp-map-light>
                </div>
            </div>
            <app-country-promotion *ngIf="selectedServicePoint" class="row d-md-none" [mobile]="true"></app-country-promotion>
            <!--Below code is needed for BR11 - UXRedesign S9-->
            <app-sp-detail2 *ngIf="selectedServicePoint && isMobile()" class="row d-md-none" 
                [servicePoint]="selectedServicePoint" 
                [searchLocation]="settings.searchResult?.searchLocation"
                [yourLocation]="settings.searchResult?.searchAddress" 
                [standalone]="true" [rtl]="settings?.isRTL()" [mobile]="true"
                [hrFormat24]="settings?.languageCountry.timeFormat !== 'AMPM'"
                [show_share_svp_detail]="showShareButtonSvpDetail" 
                (closed)="spDetailClosed()"
                (onWebLink)="onWebLink.emit($event)"
                (onNavigationLink)="onNavigationLink.emit($event)"></app-sp-detail2>
        </div>
    </div>
    <app-footer *ngIf="!isMobile()" class="row" [hasBackground]="true"></app-footer>
</div>
    