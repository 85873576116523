import {Component, OnInit} from '@angular/core';
import {MatDialogRef as MatDialogRef} from '@angular/material/dialog';
import { SettingsService } from '../settings.service';

@Component({
  selector: 'app-adv-search-tip',
  templateUrl: './adv-search-tip.component.html',
  styleUrls: ['./adv-search-tip.component.scss']
})
export class AdvSearchTipComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AdvSearchTipComponent>, public settings: SettingsService) {
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

}
