import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {allCode} from '../common/capabilities';

@Component({
    selector: 'app-legacy-url',
    templateUrl: './legacy-url.component.html',
    styleUrls: ['./legacy-url.component.scss']
})
export class LegacyURLComponent implements OnInit {

    constructor(private router: Router, private route: ActivatedRoute) {
    }

    ngOnInit() {
        const qp = Object.assign({}, this.route.snapshot.queryParams);
        if ((!qp.mobile || qp.mobile.toLowerCase() !== 'y')
            && (qp.servicePointID || qp.zipcode || qp.address || (qp.latitude && qp.longitude))) {
            qp.capability = allCode;
            this.router.navigate(['results'], {
                queryParams: qp
            });
        } else {
            this.router.navigate([''], {
                queryParams: qp
            });
        }
    }

}
