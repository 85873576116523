import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { BackendService, Promotion } from '../backend.service';
import { SettingsService } from '../settings.service';
import { ActivatedRoute } from '@angular/router';
import { Utils } from '../common/utils';
import { Subscription } from 'rxjs';

declare const window: any;
declare const $: any;

@Component({
    selector: 'app-country-promotion',
    templateUrl: './country-promotion.component.html',
    styleUrls: ['./country-promotion.component.scss']
})
export class CountryPromotionComponent implements OnInit, OnDestroy {

    promotion: Promotion;

    @Input() mobile: boolean;

    private subscriptions: Array<Subscription> = [];

    @Output() heightChange = new EventEmitter<number>();
    @Output() promotionClosed = new EventEmitter<void>();

    constructor(private backendService: BackendService, public settings: SettingsService, private route: ActivatedRoute, private utils: Utils) {
    }

    ngOnInit() {
        this.initializeSubscriptions();
        this.initialize();
    }

    private initialize() {
        if (this.settings.searchResult) {
            this.promotion = this.settings.searchResult.promotion;
            this.showHide();
        }
    }

    private initializeSubscriptions() {
        this.subscriptions.push(this.settings.searchResultLoaded.subscribe(() => {
            this.initialize();
        }));
        this.subscriptions.push(this.settings.servicePointSelected.subscribe(selectedServicePoint => {
            this.showHide();
        }));
        this.subscriptions.push(this.settings.svpPromotionClosed.subscribe(() => {
            this.showHide();
        }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    goToPromotionHyperlink(hyperlink: string) {
        this.utils.openExternalLinks(hyperlink, this.route);
    }

    closePromotion() {
        // RTC 623764
        //this.backendService.closePromotion('country').then(closeAtSession => {
            this.promotion.isClose = true;
            this.promotionClosed.emit();
        //});
    }

    private showHide() {
        if (this.promotion) {
            const condition = (
                this.settings.selectedServicePoint && (
                    (
                        this.settings.selectedServicePoint.partner && 
                        (this.settings.selectedServicePoint.partner.promotion && !this.settings.selectedServicePoint.partner.promotion.isClose)
                    ) || 
                    (this.settings.selectedServicePoint.promotion && !this.settings.selectedServicePoint.promotion.isClose)
                )
            );
            this.promotion.isHidden = condition ? true : false;
        }
    }
}
