import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import {SettingsService} from './settings.service';
import { NavigateService } from './common/navigate.service';

@Injectable({
    providedIn: 'root'
})
export class AppResolver  {

    constructor(private settings: SettingsService, private router: Router, private navigate: NavigateService) { }

    resolve(route: ActivatedRouteSnapshot): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const queryParams = this.settings.getParams(route.queryParams);
            const doSearch: string = queryParams['doSearch'];
            if (route.routeConfig.path === '') {
                if (doSearch) {
                    if (doSearch === 'advancedSearch') {
                        this.navigate.toAdvancedSearch(queryParams, {}, false);
                    } else if (doSearch === 'landingPage') {
                        // do nothing.
                    } else if (['resultPage', 'detail', 'detailContacts', 'detailLimits', 'detailHolidays'].includes(doSearch)) {
                        // do nothing
                    }else {
                        this.router.navigate(['/not-found'], {});
                    }
                }
            } else if (route.routeConfig.path === 'ServicePointLocator' || route.routeConfig.path === 'ServicePointLocator/index.jsp') {
                if (doSearch) {
                    if (!['resultPage', 'detail', 'detailContacts', 'detailLimits', 'detailHolidays'].includes(doSearch)) {
                        this.router.navigate(['/not-found'], {});
                    }
                }
            }
            resolve(0);
        });
    }

}
