<div *ngIf="displayStatus" class="col px-2 py-2 bgcolor">
    <div class="row">
        <div class="col-auto pr-2"></div>
        <div class="col">
            <div class="row">
                <div class="col px-0 text-14">
                    <app-temp-close></app-temp-close>
                </div>
                <div class="col-auto pl-2 close">
                    <mat-icon class="close text-20 mat-icon-font-feat" (click)="handleRedirectButton()">close</mat-icon>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col"></div>
                <div class="col-auto">
                    <button [ngStyle]="{'display':'inline-block'}" type="button" class="button"
                        (click)="openRedirectToActive()">Find an open Service Point</button>
                    <!--input type="hidden" [value]="newUrl"-->
                </div>
            </div>
        </div>
    </div>
</div>