<!--div [ngClass]="(settings.isRTL() ? 'infobox-close-button-RTL' : 'infobox-close-button') + (lightVersion?' light-version':'')">
    <img [src]="settings.backendUrl + '/assets/close-btn.png'" (click)="close()">
</div>
<div #mainSection [ngClass]="('wrapper'+(standalone?' standalone':'')) + (rtl?' RTL':'')"-->
<!--div #mainSection class="col sp-detail-font-props" [ngStyle]="mobile ? {} : (!isJsApi?{'height':'calc(100vh - 60px)','overflow-x':'hidden','overflow-y':'hidden' }:{'height':'100%','overflow-x':'hidden','overflow-y':'hidden'})"-->
<!-- 'overflow-x':'hidden','overflow-y':'hidden' is added to avoid scroll bar in IE. 21-Jun-2021 sp-detail-font-props is added for BR11-->
<div #mainSection class="col sp-detail-font-props map-container" [ngStyle]="{'display': 'flex', 'flex-direction': 'column', 'height':'100%'}"><!-- 'overflow-x':'hidden','overflow-y':'hidden' is added to avoid scroll bar in IE. 21-Jun-2021 sp-detail-font-props is added for BR11-->
    <div *ngIf="!displayStatus && _servicePoint.shippingCutOffTime" class="row px-0 py-0" [ngStyle]="{'flex-direction': 'row', 'align-items': 'center', 'min-height': '35px'}">
        <div class="ship-until-wrapper text-bg-red">
            <div class="ship-until-inner">
                <div #blinkElement [style.animation]="blinkElementStyle" class="px-1 font-weight-bold text-white font-size-15">{{('spl.result.ship_today' | translate |  uppercase).replace('{0}', _servicePoint.shippingCutOffTime)}}</div>
            </div>
        </div>
        <div tabindex="0" class="svp-detail-close" role="button" aria-label="close service point detail button" [ngClass]="(settings.isRTL() ? 'infobox-close-button-RTL' : 'infobox-close-button') + (lightVersion?' light-version':'')">
            <mat-icon [ngClass]="'text-red text-20 mat-icon-font-feat ' + (settings.isRTL() ? 'pr-1':'pl-1')" (click)="close()">close</mat-icon>
        </div>
    </div>
    <div #detailSection class="row px-2 py-2 detail-bg">
        <div *ngIf="!_servicePoint.shippingCutOffTime" tabindex="0" role="button" aria-label="close service point detail button" [ngClass]="(settings.isRTL() ? 'infobox-close-button-RTL' : 'infobox-close-button') + (lightVersion?' light-version':'')">
            <mat-icon class="text-red text-20 mat-icon-font-feat" (click)="close()">close</mat-icon>
        </div>
        <div class="col">
            <div class="row">
                <div class="col">
                    <!--div *ngIf="!displayStatus">
                        <div *ngIf="_servicePoint.shippingCutOffTime" class="row mb-2">
                            <div class="col px-0 font-weight-bold text-red">{{('spl.result.ship_today' | translate |  uppercase).replace('{0}', _servicePoint.shippingCutOffTime)}}</div>
                        </div>
                    </div-->
                    <div *ngIf="displayStatus" class="row mb-2"><app-temp-close></app-temp-close><br /></div>
                    <div class="row">
                        <div class="col pl-0 pr-2 mb-auto text-14">
                            <span>{{_servicePoint.servicePointName}}</span><br>
                            <span class="font-weight-bold">{{_servicePoint.localName}}</span>
                            <div>
                                <span *ngIf="_servicePoint.address.addressLine1">{{_servicePoint.address.addressLine1}}</span>
                                <span *ngIf="_servicePoint.address.addressLine2">&nbsp;{{_servicePoint.address.addressLine2}}</span>
                                <span *ngIf="_servicePoint.address.addressLine3">&nbsp;{{_servicePoint.address.addressLine3}}</span>
                                <br />
                                <span *ngIf="_servicePoint.address.zipCode">{{_servicePoint.address.zipCode}}</span>
                                <span *ngIf="_servicePoint.address.countryDivisionCode">&nbsp;{{_servicePoint.address.countryDivisionCode}},</span>
                                <span *ngIf="_servicePoint.address.city">&nbsp;{{_servicePoint.address.city}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-auto mb-auto">
                    <div class="row" [ngStyle]="{'height': '28px'}"></div>
                    <div class="row align-top" [ngStyle]="{'height': '32px'}">
                        <div class="col-auto px-0 mx-auto"></div>
                        <div *ngIf="(_servicePoint.partner?.promotion && !_servicePoint.partner?.promotion.isClose) || (_servicePoint.promotion && !_servicePoint.promotion.isClose)" class="col-auto px-1"></div>
                        <div *ngIf="!displayStatus" [ngStyle]="{'display': 'flex'}">
                            <div *ngIf="(_servicePoint.partner?.promotion && !_servicePoint.partner?.promotion.isClose) || (_servicePoint.promotion && !_servicePoint.promotion.isClose)" class="col-auto px-0"><img [ngStyle]="{'height': '32px', 'width': '32px'}" alt="Selected a servicepoint with promotion" [src]="settings.backendUrl + '/assets/promotion-icon.png'" title="{{'spl.result.promotion.title' | translate}}"></div>
                            <div *ngIf="_servicePoint.servicePointCapabilities.labelFree" class="col-auto px-1"></div>
                            <div *ngIf="_servicePoint.servicePointCapabilities.labelFree" class="col-auto px-0"><img [ngStyle]="{'height': '26px', 'width': '26px', 'margin-top': '4px'}" [src]="settings.backendUrl + '/assets/qr-icon.svg'" title="{{'spl.result.labelfree.title' | translate}}">
                            </div>
                        </div>
                    </div>
                    <div *ngIf="_servicePoint.distance" class="row mt-1 align-top">
                        <div class="col-auto px-0 mx-auto"></div>
                        <div class="col-auto px-0"><img [ngStyle]="{'height': '20px', 'width': '20px'}" [src]="settings.backendUrl + '/assets/distance-icon.svg'" alt="Symbol for measuring distance"></div>
                        <div class="col-auto px-1"></div>
                        <div class="col-auto px-0 text-nowrap" [ngStyle]="{'padding-top': '2px', 'font-size': '13px'}">{{_servicePoint.distance}}</div>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col">
                    <div class="row">
                        <div *ngIf="_servicePoint.servicePointCapabilities.disabledAccess" class="col-auto px-0">
                            <img title="{{'spl.result.wheelchair.title'|translate}}" [ngStyle]="{'height': '26px', 'width': '26px'}" [src]="settings.backendUrl + '/assets/wheelchair.svg'">
                        </div>
                        <div *ngIf="_servicePoint.servicePointCapabilities.disabledAccess" class="col-auto pl-1 pr-0"></div>
                        <div *ngIf="_servicePoint.servicePointCapabilities.parkingAvailable" class="col-auto px-0">
                            <img title="{{'spl.result.parking.title'|translate}}" [ngStyle]="{'height': '26px', 'width': '26px'}" [src]="settings.backendUrl + '/assets/parking.svg'">
                        </div>
                        <div class="col">&nbsp;</div>
                    </div>
                </div>
                <div class="col-auto">
                    <div class="row" aria-disabled="true">
                        <div [attr.title]="(!isConsentProvided && !(settings.selectedCountry && settings.selectedCountry.dirExtRoute)) ? ('spl.details.button.no_consent_hover' | translate) :''">
                            <div [style.pointerEvents]="(displayStatus || (!isConsentProvided && !(settings.selectedCountry && settings.selectedCountry.dirExtRoute))) ? 'none' : 'auto'">
                                <div tabindex="0" id="directions" *ngIf="!mobile" class="col-auto px-0" [ngStyle]="{'cursor': 'pointer'}" (click)="openDirections()">
                                    <img [class]="'mx-auto'+((displayStatus || (!isConsentProvided && !(settings.selectedCountry && settings.selectedCountry.dirExtRoute))) ? ' grayscale' : ' auto')" [ngStyle]="{'display': 'block', 'height': '18px', 'width': '18px', 'cursor': 'pointer'}" [src]="settings.backendUrl + '/assets/direction.svg'" alt="direction icon redirects to direction page.">
                                    <label [class]="'font-weight-bold text-10' + ((displayStatus || (!isConsentProvided && !(settings.selectedCountry && settings.selectedCountry.dirExtRoute))) ? ' text-gray' : ' text-red')" [ngStyle]="{'text-decoration': 'underline', 'cursor': 'pointer'}">{{'spl.result.button.route' | translate}}</label>
                                </div>
                                <!--input type="hidden" [value]="'/directions?data=' + printDetails"-->
                                <div tabindex="0" *ngIf="mobile" class="col-auto px-0" [ngStyle]="{'cursor': 'pointer'}" (click)="openNavigate()">
                                    <img [class]="'mx-auto'+((displayStatus || (!isConsentProvided && !(settings.selectedCountry && settings.selectedCountry.dirExtRoute))) ? ' grayscale' : ' auto')" [ngStyle]="{'display': 'block', 'height': '18px', 'width': '18px', 'cursor': 'pointer'}" [src]="settings.backendUrl + '/assets/direction.svg'" alt="navigate icon.">
                                    <label [class]="'font-weight-bold text-10' + ((displayStatus || (!isConsentProvided && !(settings.selectedCountry && settings.selectedCountry.dirExtRoute))) ? ' text-gray' : ' text-red')" [ngStyle]="{'text-decoration': 'underline', 'cursor': 'pointer'}">{{'spl.result.button.navigate' | translate}}</label>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!mobile && !(displayID || (settings.selectedCountry && settings.selectedCountry.dirExtRoute)) " class="col-auto mx-2 px-0 divider"></div> 
                        <div [attr.title]="!isConsentProvided ? ('spl.details.button.no_consent_hover' | translate) :''">
                            <div *ngIf="!(displayID || (settings.selectedCountry && settings.selectedCountry.dirExtRoute))" [style.pointerEvents]="(displayStatus || !isConsentProvided) ? 'none' : 'auto'">
                                <div tabindex="0" *ngIf="!mobile" class="col-auto px-0" [ngStyle]="{'cursor': 'pointer'}"
                                    (click)="openPrint()">
                                    <img [class]="'mx-auto'+((displayStatus || !isConsentProvided) ? ' grayscale' : ' auto')"
                                    [ngStyle]="{'display': 'block', 'height': '18px', 'width': '18px', 'cursor': 'pointer'}"
                                        [src]="settings.backendUrl + '/assets/print.svg'" alt="print icon redirects to print page.">
                                    <label
                                        [class]="'font-weight-bold text-10' + ((displayStatus || !isConsentProvided) ? ' text-gray' : ' text-red')"
                                        [ngStyle]="{'text-decoration': 'underline', 'cursor': 'pointer'}">{{'spl.result.button.print' | translate}}</label>
                                </div>
                                <!--input type="hidden" [value]="'/print?data=' + printDetails"-->
                            </div>
                        </div>                       
                        <div *ngIf="showShareButtonSvpDetail==='Y'" class="col-auto mx-2 px-0 divider"></div>
                        <div *ngIf="showShareButtonSvpDetail==='Y'" class="col-auto px-0" [ngStyle]="{'cursor': 'pointer'}">
                            <a [href]="email"><img class="mx-auto" [ngStyle]="{'display': 'block', 'height': '18px', 'width': '18px', 'cursor': 'pointer'}" [src]="settings.backendUrl + '/assets/share.svg'" alt="clickable share icon redirects with all the required details.">
                            <label class="font-weight-bold text-red text-10" [ngStyle]="{'text-decoration': 'underline', 'cursor': 'pointer'}">{{'spl.details.share' | translate}}</label> </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <!--div class="pics">
            <div id="parking" class="where">
                <div class="mx-auto" style="flex: 0 0 auto;">&nbsp;</div>
                <div style="flex: 0 0 32px; max-width: 32px;">
                    <div *ngIf="_servicePoint.servicePointCapabilities.disabledAccess"
                        [ngStyle]="{background: 'url(' + settings.backendUrl + '/assets/sprite.png)'}"
                        class="iconed wheel" title="{{'spl.result.wheelchair.title'|translate}}"></div>
                </div>
                <div style="flex: 0 0 6px; max-width: 6px;">&nbsp;</div>
                <div style="flex: 0 0 32px; max-width: 32px;">
                    <div *ngIf="_servicePoint.servicePointCapabilities.parkingAvailable"
                        [ngStyle]="{background: 'url(' + settings.backendUrl + '/assets/sprite.png)'}"
                        class="iconed parking" title="{{'spl.result.parking.title'|translate}}"></div>
                </div>
            </div>
            <div id="directionDraw" class="where">
                <div class="links">
                    <div class="mx-auto" style="flex: 0 0 auto;">&nbsp;</div>
                    <div *ngIf="showButtons" class="img-btn center-text break-word">
                        <a class="text-button" onclick="window.gsplInternal.openDirections(this.nextSibling.value)">
                            <div class="iconed-big direction-2" [ngStyle]="{background: 'url(' + settings.backendUrl + '/assets/sprite.png)'}"></div>
                            <div class="icon-label" [id]="'icon-label' + _servicePoint.facilityId">{{'spl.result.button.route' | translate}}</div>
                        </a>
                        <input type="hidden" [value]="'/directions?data=' + printDetails">
                    </div>
                    <div class="divider-2"></div>
                    <div *ngIf="showButtons" class="img-btn break-word hidden-xs">
                        <a class="text-button" onclick="window.gsplInternal.openPrint(this.nextSibling.value)">
                            <div class="iconed-big print-2" [ngStyle]="{background: 'url(' + settings.backendUrl + '/assets/sprite.png)'}"></div>
                            <div class="icon-label" [id]="'icon-label' + _servicePoint.facilityId">{{'spl.result.button.print' | translate}}</div>
                        </a>
                        <input type="hidden" [value]="'/print?data='+this.printDetails">
                    </div>
                </div>
            </div>
        </div-->
        <!--div class="clear"></div-->
    </div>

    <app-redirect-to-active class="row" [servicePoint]="_servicePoint" *ngIf="displayDetailsStatus" (tempButtonClosed)="doTempButtonClosed()" (onFindActiveServicePoints)="onFindActiveServicePoints.emit($event)"></app-redirect-to-active>

    <app-sp-promotion #promotionSection class="row" [servicePoint]="_servicePoint" (promotionClosed)="doPromotionClosed()"></app-sp-promotion>

    <!--lower-panel--> <!-- Lot of changes were made in the following section for BR11-->
    <div #infotabsection class="row map-item" [ngStyle]="mobile ? {} : {flex: 1}">
        <div class="col" [ngStyle]="{'height':'100%'}">
            <div #tabsSection id="info-tabs" class="row">
                <a tabindex="0" role="button" aria-label="Click here to open the details section" [class]="'col ' + (infoTabType === 'detail' ? 'white-bg-color' : 'dhlred-bg-color-details')" (click)="infoTabType = 'detail';">{{'spl.result.tab.details'|translate}}</a>
                <a tabindex="0" role="button" aria-label="Click here to open the holiday section" [class]="'col ' + (infoTabType === 'holidays' ? 'white-bg-color' : 'dhlred-bg-color-details')" (click)="infoTabType = 'holidays';">{{'spl.result.tab.holidays'|translate}}</a>
            </div>
            <!-- 'row scroll4 info '+(fitScreen?'fit-screen':'')+(isJsApi?' fit-screen-jsapi':'')-->
            <div #infoSection [ngClass]="'row info scroll4'" tabindex="-1" [ngStyle]="mobile ? {} : (hasPromotion ? {'overflow-y': 'auto','overflow-x': 'hidden' }: {'overflow-y': 'auto','overflow-x': 'hidden'})"> <!-- 'overflow-x':'hidden' is added to avoid scroll bar in chrome-->
                <div class="overview" [ngStyle]="{'top': '0px', 'width':'100%', 'height':'100%'}">
        
                    <div tabindex="0" *ngIf="infoTabType === 'detail'">
                        <div class="dropping" id="openingHours">
                            <!-- added this below h4 for BR11 S11-->
                            <h4 tabindex="0" (click)="showOpeningHours = !showOpeningHours" [class]="showOpeningHours ? 'up' : ''">
                                <span class="caret caret-right" [ngStyle]="{background: 'url(' + settings.backendUrl + '/assets/sprite2.png) no-repeat 0px -22px'}"></span>&nbsp;{{'spl.details.opening_hours'|translate}}
                            </h4>
                            <!--h3 id="bubble_3lt" class="bubble_headline2">{{'spl.details.opening_hours'|translate}}</h3--><!-- Commented for BR11 S11-->
                            <div *ngIf="showOpeningHours && displayStatus" [ngStyle]="{'display': 'flex', 'flex-wrap': 'wrap'}">
                                <div [ngStyle]="{'flex': '0 0 10px', 'max-width': '10px'}">&nbsp;</div>
                                <div id="bubble_table" [ngStyle]="{'flex-grow': '1'}"> <app-temp-close></app-temp-close> </div>
                            </div>
                            <div *ngIf="showOpeningHours && !displayStatus" [ngStyle]="{'display': 'flex', 'flex-wrap': 'wrap'}"><!-- added this below h4 for BR11 S11-->
                                <div [ngStyle]="{'flex': '0 0 10px', 'max-width': '10px'}">&nbsp;</div>
                                <div id="bubble_table" [ngStyle]="{'flex-grow': '1'}">
                                    <table id="" class="bubble_timetable" summary="">
                                        <tbody>
                                            <tr *ngFor="let opening of openingHours">
                                                <td class="bubble_timetable_tl">
                                                    <span [ngStyle]="{'white-space': 'nowrap'}">{{'spl.result.weekday_short_' + opening.dayOfWeek.toLowerCase() | translate}}
                                                        <span *ngIf="opening.dayOfWeek2 && opening.dayOfWeek2 !== opening.dayOfWeek">
                                                            - {{'spl.result.weekday_short_' + opening.dayOfWeek2.toLowerCase() | translate}}
                                                        </span>
                                                    </span>
                                                </td>
                                                <td>
                                                    <div class="mx-3"></div>
                                                </td>
                                                <td class="bubble_timetable_tr"> <span 
                                                    *ngFor="let interval of opening.intervals" 
                                                    [ngStyle]="{'white-space': 'nowrap'}">{{this.formatHour(interval.openingTime)}}
                                                     - {{this.formatHour(interval.closingTime)}}<br /></span>
                                                </td>
                                                <td [ngStyle]="{'width': '99%'}"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div [ngStyle]="{opacity : (displayStatus)?'0.3':'1'}">
                            <div [style.pointerEvents]="displayStatus ? 'none' : 'auto'">
                                <div class="servicePointCapabilities dropping" [ngStyle]="{'display': 'flex', 'flex-wrap': 'wrap'}">
                                    <div [ngStyle]="{flex: '0 0 ' + ((paymentOptions.length > 0) ? '62%' : '100%') }">  
                                    <!-- changed from 62% to auto-->
                                    <!--div class="bubble_headline2">{{'spl.result.services_available' | translate}}</div-->
                                    <!-- Commented for BR11 S11-->
                                    <!-- added this below h4 for BR11 S11-->
                                        <h4 tabindex="0" (click)="onServicePointCapabilitiessExpand()" [class]="showServicePointCapabilities ? 'up' : ''">
                                            <span class="caret caret-right" [ngStyle]="{background: 'url(' + settings.backendUrl + '/assets/sprite2.png) no-repeat 0px -22px'}"></span> {{'spl.result.services_available' | translate}}
                                        </h4>
                                        <ul *ngIf="showServicePointCapabilities" id="ul_capabilities">
                                            <li *ngFor="let capability of capabilities" class="bubble3_li">{{capability | translate}}</li>
                                        </ul>
                                    </div>
                                    <div class="payment" *ngIf="paymentOptions.length > 0 && showServicePointCapabilities" [ngStyle]="{'flex': '0 0 auto'}">
                                        <div [id]="'pay-with' + _servicePoint.facilityId" [style.text-align]="settings.isRTL() ? 'left' : 'right'">{{'spl.result.sa.pay_with' | translate }}</div>
                                        <div [ngStyle]="{'display': 'flex', 'flex-wrap': 'wrap'}">
                                            <div class="mx-auto" [ngStyle]="{'flex': '0 0 auto'}">&nbsp;</div>
                                            <div class="payments">
                                                <div *ngFor="let pay of paymentOptions" title="{{pay.label|translate}}" [ngStyle]="{background: 'url(' + settings.backendUrl + '/assets/' + ((pay.code === 'paypal') ? 'pp-acceptance-small.png' : 'sprite.png') + ')'}" [ngClass]="'iconed '+pay.code"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="dropping" [style.pointerEvents]="displayStatus ? 'none' : 'auto'">
                                <h4 tabindex="0" (click)="onContactDetailsExpand()" [class]="showContactDetails ? 'up' : ''">
                                    <span class="caret caret-right" [ngStyle]="{background: 'url(' + settings.backendUrl + '/assets/sprite2.png) no-repeat 0px -22px'}"></span> {{'spl.result.telephone_contacts'|translate}}
                                </h4>
                                <div *ngIf="showContactDetails" class="caret-drop contactDetails ml-1">
                                    <!--div style="flex: 0 0 10px; max-width: 10px;">&nbsp;</div-->
                                    <div class="contacts-wrap">
                                        <div class="contact-item" *ngIf="_servicePoint.contactDetails.phoneNumber">
                                            <span>{{'spl.contactdetails.phone'|translate}}:</span>
                                            <div class="contact-value">{{_servicePoint.contactDetails.phoneNumber}} </div>
                                        </div>
                                        <div *ngIf="_servicePoint.contactDetails.email" class="contact-item">
                                            <span>{{'spl.contactdetails.email' | translate}}:</span>
                                            <div class="contact-value">
                                                <a id="mailTo" href="mailto: {{_servicePoint.contactDetails.email}}">{{'spl.contactdetails.send_mail' | translate}}</a>
                                            </div>
                                        </div>
                                        <div class="contact-item" *ngIf="_servicePoint.contactDetails.linkUri">
                                            <span>{{'spl.contactdetails.www'|translate}}:</span>
                                            <div class="contact-value">
                                                <a class="text-red-14-underline font-weight-bold" id="clickHereLink" (click)="openWeblink()">{{'spl.contactdetails.click_here' | translate}}</a>
                                                <input type="hidden" [value]="ensureProtocol(_servicePoint.contactDetails.linkUri)">
                                            </div>
                                        </div>
                                        <div class="contact-item" *ngIf="_servicePoint.facilityId">
                                            <span>{{'spl.contactdetails.servicepoint.id' | translate}}:</span>
                                            <div class="contact-value" id="id-detail">{{_servicePoint.facilityId}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="dropping" [style.pointerEvents]="displayStatus ? 'none' : 'auto'">
                                <h4 tabindex="0" (click)="onLimitationsExpand()" [class]="showLimitations ? 'up' : ''">
                                    <span class="caret caret-right" [ngStyle]="{background: 'url(' + settings.backendUrl + '/assets/sprite2.png) no-repeat 0px -22px'}"></span>
                                    {{'spl.result.shipment_packaging_parameters'|translate}}
                                </h4>
                                <div *ngIf="showLimitations" class="limitations caret-drop ml-2">
                                    <!--div style="flex: 0 0 14px; max-width: 14px;">&nbsp;</div-->
                                    <div [ngStyle]="{'flex-grow': '1'}">
                                        <div>
                                            <h4>{{'spl.result.shipment_piece_limitations' | translate}}</h4>
                                        </div>
                                        <div id="shipmentLimitationsByPiece" class="limitations-wrap">
                                            <div class="limitations-item">
                                                <div title="{{'spl.result.limit.size' | translate}}" [ngStyle]="{background: 'url(' + settings.backendUrl + '/assets/limitationsIcons.png)'}" class="iconed size"></div>
                                                <div class="vertical-value "><span>
                                                        {{roundToOne(_servicePoint.servicePointCapabilities.pieceDimensionsLimit.L)}}
                                                        x
                                                        {{roundToOne(_servicePoint.servicePointCapabilities.pieceDimensionsLimit.W)}}
                                                        x
                                                        {{roundToOne(_servicePoint.servicePointCapabilities.pieceDimensionsLimit.H)}}
                                                        {{_servicePoint.servicePointCapabilities.pieceDimensionsLimitUnit.toLowerCase()}}</span>
                                                </div>
                                            </div>
                                            <div class="limitations-item">
                                                <div title="{{'spl.result.limit.weight' | translate}}" [ngStyle]="{background: 'url(' + settings.backendUrl + '/assets/limitationsIcons.png)'}" class="iconed weight"></div>
                                                <div class="value oneline">
                                                    <span>{{roundToZero(_servicePoint.servicePointCapabilities.pieceWeightLimit)}}
                                                        {{_servicePoint.servicePointCapabilities.pieceWeightLimitUnit.toLowerCase()}}</span>
                                                </div>
                                            </div>
                                            <div class="limitations-item-spacer"></div>
                                        </div>
                                        <div>
                                            <h4>{{'spl.result.shipment_shipment_limitations' | translate}}</h4>
                                        </div>
                                        <div id="shipmentLimitations" class="limitations-wrap">
                                            <div class="limitations-item">
                                                <div title="{{'spl.result.limit.pieces' | translate}}" [ngStyle]="{background: 'url(' + settings.backendUrl + '/assets/limitationsIcons.png)'}" class="iconed pieces"></div>
                                                <div class="value oneline">
                                                    <span>{{_servicePoint.servicePointCapabilities.pieceCountLimit}}
                                                        {{'spl.result.limit.pieces.uom' | translate}}</span>
                                                </div>
                                            </div>
                                            <div class="limitations-item">
                                                <div title="{{'spl.result.limit.weight'|translate}}" [ngStyle]="{background: 'url(' + settings.backendUrl + '/assets/limitationsIcons.png)'}" class="iconed weight"></div>
                                                <div class="value oneline">
                                                    <span>{{roundToZero(_servicePoint.servicePointCapabilities.pieceWeightLimit)}}
                                                        {{_servicePoint.servicePointCapabilities.pieceWeightLimitUnit.toLowerCase()}}</span>
                                                </div>
                                            </div>
                                            <div class="limitations-item-spacer"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div [ngStyle]="{opacity : (displayStatus)?'0.3':'1'}" [style.pointerEvents]="displayStatus ? 'none' : 'auto'">
                            <div id="add-info-wrapper" class="dropping" *ngIf="_servicePoint.locatedAt" [style.pointerEvents]="displayStatus ? 'none' : 'auto'">
                                <h4 tabindex="0" (click)="onAdditionalInformationExpand()" [class]="showAdditionalInformation ? 'up' : ''">
                                    <span class="caret caret-right" [ngStyle]="{background: 'url(' + settings.backendUrl + '/assets/sprite2.png) no-repeat 0px -22px'}"></span>
                                    {{'spl.result.facility_additional_info' | translate}}
                                </h4>
                                <div *ngIf="showAdditionalInformation" class="limitations caret-drop">
                                <!--div style="flex: 0 0 14px; max-width: 14px;">&nbsp;</div-->
                                    <div [ngStyle]="{'flex-grow': '1'}">{{_servicePoint.locatedAt}}</div>
                                </div>
                            </div>
                            <span id="ship-anchor"></span>
                        </div>
                    </div>

                    <div tabindex="0" *ngIf="infoTabType === 'holidays'">
                        <div id="holidayOpeningHoursHtml" class="holidays">
                            <div>
                                <div id="bubble_3lt" class="bubble_headline2">{{'spl.details.opening_holidays_hours' | translate}}</div>
                                <div>
                                    <div id="bubble_table">
                                        <table class="bubble_timetable" summary="">
                                            <tbody>
                                                <tr *ngIf="_servicePoint.openingHours.holidays.open.length === 0">
                                                    <td class="bubble_timetable_tr_full_width">-</td>
                                                </tr>
                                                <tr *ngFor="let day of openingHolidayHours">
                                                    <td class="text-nowrap">{{getDate(day)}}</td>
                                                    <td class="tr_open_holidays_month_label">
                                                        <span *ngFor="let interval of day.intervals"
                                                            class="text-nowrap">
                                                            {{formatHour(interval.openingTime)}}
                                                            - {{formatHour(interval.closingTime)}}<br />
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div id="bubble_3lt" class="bubble_headline2">{{'spl.details.closed_holidays_hours' |
                                    translate}}</div>
                                <div *ngIf="!displayStatus">
                                    <div id="bubble_table">
                                        <table class="bubble_timetable" summary="">
                                            <tbody>
                                                <tr *ngIf="_servicePoint.openingHours.holidays.closed.length === 0" class="tr_holidays_with_line">
                                                    <td class="bubble_timetable_tr_full_width">-
                                                    </td>
                                                </tr>
                                                <tr *ngFor="let interval of _servicePoint.openingHours.holidays.closed">
                                                    <td class="tr_open_holidays_month_label">
                                                        <span *ngIf="interval.from !== interval.to" [ngStyle]="{'white-space': 'nowrap'}">{{interval.from}} - {{interval.to}}</span>
                                                        <span *ngIf="interval.from === interval.to" [ngStyle]="{'white-space': 'nowrap'}">{{interval.from}}</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div *ngIf="displayStatus">
                                    <app-temp-close></app-temp-close>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> <!-- overview -->
            </div><!-- class info -->
        </div>
    </div>
</div>
<div id="navigModalContainer"><template ngbModalContainer></template></div>