export const MAPS = {
  GOOGLE_MAP: {
    DIRECTION_STATUS: {
      NO_RESULTS: 'ZERO_RESULTS'
    }
  },
  BING_MAP: {
    DIRECTION_STATUS: {
      NO_RESULTS: 'NoNearbyStops',
    },
    SUPPORTED_TRAVEL_MODE: ['DRIVING', 'TRANSIT', 'WALKING']
  }
};
