import { Component, OnInit, Optional, NgZone, AfterViewInit, Renderer2, OnDestroy, Inject } from '@angular/core';
import { BingMapsLoader } from '../bing-maps-loader';
import { PrintData } from '../common/PrintData';
import { Utils } from '../common/utils';
import { DOCUMENT, Location } from '@angular/common';
import { SettingsService } from '../settings.service';
import { EncodeDecode } from '../common/encode-decode';
import { JsApiComponentBase } from '../common/jsapi-component-base';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendService, Countries} from '../backend.service';
import { TranslateService } from '@ngx-translate/core';
import { MAPS } from '../app.constant';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { MatSnackBar as MatSnackBar } from '@angular/material/snack-bar';
import { AddressEvent, AddressEventType } from '../address-input/address-input.component';
import { environment } from '../../environments/environment';
//import uuid from 'uuid/v4';
import { v4 as uuidv4 } from 'uuid';
import { GoogleDirectionMapsLoader } from '../google-direction-maps-loader';
import { ConsentService } from '../consent.service.ts.service';
import { Subscription } from 'rxjs';

declare const Microsoft: any;
declare const window: any;
declare const $: any;
declare const OneTrust;

@Component({
  selector: 'app-directions-page',
  templateUrl: './directions-page.component.html',
  styleUrls: ['./directions-page.component.scss']
})
export class DirectionsPageComponent extends JsApiComponentBase implements OnInit, AfterViewInit, OnDestroy {

  data = new PrintData();
  map: any;
  directionsManager: any;
  directionsErrorEventObj: any;
  directionsUpdatedEventObj: any;
  isJsApi = Utils.isJsApi;
  travelModes: Array<{ code: string; labelKey: string; selected: boolean; selectImage: string, unSelectImage: string, mapType: string }>;
  currentTravelMode: any;
  directionsRendererGlobal: any;
  directionsServiceGlobal: any;
  isNotSupportedTravelMode: boolean;
 
  selectedCountry = new UntypedFormControl();
  address = new UntypedFormControl('');
  countries: Countries;
  geoLoading = false;

  displayID:boolean = false;
  searchInProgress = false;
  displayPrint: boolean = false;
  directionPlaceId: string = '';
  directionProviderId: string = '';
  directionLang: string = '';
  directionToken: string = '';

  isDirection: boolean = false;
  inputAddress: string;

  startLat: number = 0.0;
  startLong: number = 0.0;

  isMapConsentGiven: boolean = false;
  isInitMapCalled: boolean = false;
  //isLoadMapCalled: boolean = false;
  geoCodeCountry: string = null;
  isCrossBorderMsgDisplayed: boolean = false;
  newGeoLoading: boolean = false;

  private subscriptions: Array<Subscription> = [];
  
  constructor(private route: ActivatedRoute, private location: Location, @Optional() public settings: SettingsService, 
  public ngZone: NgZone, private router: Router, private backend: BackendService, private translateService: TranslateService, 
  private utils: Utils, @Optional() private snackBar: MatSnackBar, private dialog: MatDialog, private renderer: Renderer2, 
  private consentService: ConsentService, @Inject(DOCUMENT) private document: Document) {
    super();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnInit() {
    this.isMapConsentGiven = this.settings.selectedCountry.mapType.toLowerCase() == 'google' ? this.settings.checkShowMapConsent('google') : this.settings.checkShowMapConsent('bing');
    if (this._isOpen) {
      this.initialize();
    }
  }


  onOpen() {
    this.initialize();
  }

  private initialize() {
    
    this.travelModes = [
      { code: 'DRIVING', labelKey: 'DRIVING', selected: false, selectImage: 'car_red', unSelectImage: 'car_gray', mapType: 'all' },
      { code: 'TRANSIT', labelKey: 'TRANSIT', selected: false, selectImage: 'train_red', unSelectImage: 'train_gray', mapType: 'all' },
      { code: 'WALKING', labelKey: 'WALKING', selected: false, selectImage: 'walk_red', unSelectImage: 'walk_gray', mapType: 'all' },
      { code: 'BICYCLING', labelKey: 'BICYCLING', selected: false, selectImage: 'bicycle_red', unSelectImage: 'bicycle_gray', mapType: 'GOOGLE' }
    ];

    /*if (this.settings.isBannerSuppresedClient(this.route.snapshot.queryParams)) {
      this.renderer.addClass(document.body, 'hide-onetrust-elements');
    }*/

    if (Utils.isJsApi()) {    
        this.loadScript(this.settings.appConfig.cookiesDataDomainUrl).then(() => {
            // Do something after the script is loaded
        }).catch(error => {
            console.error('Failed to load script:', error);
        });
    }

    if (Utils.isJsApi()) {
      if (window.gspl.params.directionsEncodeData) {
        if (window.gspl.directionsData) {
          this.data = JSON.parse(EncodeDecode.decode(window.gspl.directionsData));
        }
      } else {
        if (this.settings.printData) {
          this.data = this.settings.printData;
        }
      }
    } else {
      this.data = JSON.parse(EncodeDecode.decode(this.route.snapshot.queryParams.data));
    }
  
    //var newLang = this.data && this.data.lang ? this.data.lang : 'en';
    this.document.documentElement.lang = this.data.otCode;
    try {
      OneTrust.changeLanguage(this.data.otCode);
    } catch (Error) {
      //console.log("error in catch", Error);
    }//cookie settings changes

    if (this.data.yourLocation == null) {
      this.displayID = true;
      this.isDirection = true;
      this.displayPrint = false;
    } else {
      this.displayID = false;
      this.isDirection = false;
      this.displayPrint = true;
    }
    this.settings.directionCountryCode = this.data.countryCode;
    this.settings.isParentPageDirections = true;
    this.translateService.use(this.data.langTrans ? this.data.langTrans : 'en-GB$Latn');
     setTimeout(() => {
      this.isMapConsentGiven = this.settings.checkShowMapConsent(this.data.mapType);
      if(this.data.yourLocation && this.isMapConsentGiven){
        this.mapLoader();
      }
     }, 500);
  }

  mapLoader() {
    if (!this.data.mapType || this.data.mapType.toLowerCase() === 'bing') {
      BingMapsLoader.load(this.data.lang, this.data.langCtry, this.settings.appConfig.bingLoadUrl).then(result => {
        if (typeof Microsoft !== 'undefined') {
            this.loadMap();
        }
      }
      );
    } else {
      this.loadGoogleDirectionsMap();
    }
  }

  loadGoogleDirectionsMap() {
    var googleMapKey: string = '';
    if (Utils.isJsApi() && !this.settings.appConfig.jsapiv3Usegsplwsbkey) {
      googleMapKey = this.backend.googleKey;
    } else {
      googleMapKey = this.settings.appConfig.googleMapsKey;
    }//573005
    GoogleDirectionMapsLoader.loadgoogleDirectionsScript(this.data.lang, this.data.langCtry, this.settings.appConfig.googleDirectionMapLoadUrl, googleMapKey).then(() => {
        this.initMap();
    });
  }

  onSubmit() {
    this.doSearch();
  }

  doSearch() {
    if(this.newGeoLoading && this.geoCodeCountry && this.settings.directionCountryCode && (this.geoCodeCountry !== this.settings.directionCountryCode)){
      this.isCrossBorderMsgDisplayed = true;
      if (this.data.mapType && this.data.mapType.toUpperCase() === 'GOOGLE') {
        this.isInitMapCalled = false;
      }else{
        //this.isLoadMapCalled = false;
      }
    }else{
      this.isCrossBorderMsgDisplayed = false;
      if(!this.data.yourLocation){
        this.mapLoader();
        this.data.yourLocation = this.address.value;
      }
      this.searchInProgress = true;
      if(!this.directionToken){
        this.directionToken = uuidv4();
      }
      this.getLatLongforAddress(this.address.value);
    }
  }

  loadMap() {
    //const centerLocation = new Microsoft.Maps.Location(this.data.location.latitude, this.data.location.longitude);
    this.map = new Microsoft.Maps.Map(document.getElementById('map-direction'), {
      credentials: (Utils.isJsApi() && !this.settings.appConfig.jsapiv3Usegsplwsbkey) ? this.backend.bingKey : this.settings.appConfig.bingMapsKey,
      navigationBarMode: Microsoft.Maps.NavigationBarMode.compact
    });
    //this.isLoadMapCalled = true;
    if (!this.directionsManager) {
      Microsoft.Maps.loadModule('Microsoft.Maps.Directions', { callback: this.createDrivingRoute.bind(this) });
    } else {
      this.createDrivingRoute();
    }
  }

  createDirectionsManager() {
    if (!this.directionsManager) {
      this.directionsManager = new Microsoft.Maps.Directions.DirectionsManager(this.map);
    }
    this.directionsManager.clearAll();
    this.directionsErrorEventObj = Microsoft.Maps.Events.addHandler(this.directionsManager, 'directionsError', (arg) => {
      this.isNotSupportedTravelMode = (arg && arg.responseCode === MAPS.BING_MAP.DIRECTION_STATUS.NO_RESULTS) ? true : false;
    });
    this.directionsUpdatedEventObj = Microsoft.Maps.Events.addHandler(this.directionsManager, 'directionsUpdated', (arg) => {
      this.isNotSupportedTravelMode = false
    });
  }

  createDrivingRoute() {
    if (!this.directionsManager) {
      this.createDirectionsManager();
    }

    let defaultTravelMode = this.getDefaultTravelMode();
    defaultTravelMode = MAPS.BING_MAP.SUPPORTED_TRAVEL_MODE.indexOf(defaultTravelMode) !== -1 ? defaultTravelMode : this.data.defDriveMode;
    if (defaultTravelMode) {
      this.selectTravelMode(defaultTravelMode);
      this.calculateAndDisplayBingRoute();
    }
  }

  /**
   * Use of this method is to calculate direction route for Bing map
   */
  calculateAndDisplayBingRoute() {
    this.directionsManager.clearAll();  
  
    const unitOfLength = 'km'; // TODO pass this from main page
    let distanceUnit;

    if (unitOfLength === 'km') {
      distanceUnit = Microsoft.Maps.Directions.DistanceUnit.kilometers;
    } else {
      distanceUnit = Microsoft.Maps.Directions.DistanceUnit.miles;
    }


    // Set Route Mode to driving
    if (this.currentTravelMode == 'WALKING') {
      this.directionsManager.setRequestOptions({
        routeMode: Microsoft.Maps.Directions.RouteMode.walking,
        distanceUnit
      });
    } else if (this.currentTravelMode == 'TRANSIT') {
      this.directionsManager.setRequestOptions({
        routeMode: Microsoft.Maps.Directions.RouteMode.transit,
        distanceUnit
      });
    } else {
      this.directionsManager.setRequestOptions({
        routeMode: Microsoft.Maps.Directions.RouteMode.driving,
        distanceUnit
      });
    }

    var startWaypoint = startWaypoint = new Microsoft.Maps.Directions.Waypoint({
      //location: new Microsoft.Maps.Location(this.data.location.latitude - 0.0000001, this.data.location.longitude - 0.0000001)
      location: new Microsoft.Maps.Location(this.startLat, this.startLong)//GSPL-199
    });

    // if (this.data.userSearchLocation) {
    //   startWaypoint = new Microsoft.Maps.Directions.Waypoint({
    //     location: new Microsoft.Maps.Location(this.data.userSearchLocation.latitude, this.data.userSearchLocation.longitude)
    //   });
    // } else
     if (this.data.searchLocation  && this.data.searchLocation.suggestion) { // RTC - 555425
      startWaypoint = new Microsoft.Maps.Directions.Waypoint({
        location: new Microsoft.Maps.Location(this.data.searchLocation.suggestion.latitude, this.data.searchLocation.suggestion.longitude)
      });
    }

    this.directionsManager.addWaypoint(startWaypoint);

    const endWaypoint = new Microsoft.Maps.Directions.Waypoint({
      location: new Microsoft.Maps.Location(this.data.location.latitude, this.data.location.longitude)
    });
    
    this.directionsManager.addWaypoint(endWaypoint);
    // Set the element in which the itinerary will be rendered
    this.directionsManager.setRenderOptions({ itineraryContainer: document.getElementById('map-itinerary') });
    this.directionsManager.calculateDirections();
  }

  print() {
    if(this.displayPrint){
      window.print();
    }
  }

  back() {
    if (!Utils.isJsApi()) {
      //this.location.back();
      window.close();
    }
  }

  initMap() {
    this.isInitMapCalled = true;
    const directionsRenderer = new google.maps.DirectionsRenderer();
    const directionsService = new google.maps.DirectionsService();
    const map = new google.maps.Map(
      document.getElementById("map-direction") as HTMLElement,
      {
        zoom: 7,
        center: { lat: 41.85, lng: -87.65 },
        disableDefaultUI: true,
      }
    );
    directionsRenderer.setMap(map);

    directionsRenderer.setPanel(
      document.getElementById("map-itinerary") as HTMLElement
    );
    this.directionsServiceGlobal = directionsService;
    this.directionsRendererGlobal = directionsRenderer;
    // this.calculateAndDisplayRoute(directionsService, directionsRenderer);
    /*(document.getElementById("mode") as HTMLInputElement).addEventListener(
      "change",
      () => {
        console.log("this.current",this.currentTravelMode);
        this.calculateAndDisplayRoute(directionsService, directionsRenderer);
      }
    );*/

    const defaultTravelMode = this.getDefaultTravelMode();
    if (defaultTravelMode) {
      this.selectTravelMode(defaultTravelMode);
      this.calculateAndDisplayRoute(directionsService, directionsRenderer);
    }
  }

  calculateAndDisplayRoute(directionsService: google.maps.DirectionsService, directionsRenderer: google.maps.DirectionsRenderer) {
    const selectedMode = this.currentTravelMode;
    var start = { lat: this.startLat, lng: this.startLong};
    const end = { lat: this.data.location.latitude, lng: this.data.location.longitude };
    // if (this.data.userSearchLocation) {
    //   start = { lat: this.data.userSearchLocation.latitude, lng: this.data.userSearchLocation.longitude };
    // } else 
    if (this.data.searchLocation && this.data.searchLocation.suggestion) {
      start = { lat: this.data.searchLocation.suggestion.latitude, lng: this.data.searchLocation.suggestion.longitude };
    }

    var unitSystem: any = google.maps.UnitSystem.METRIC;
    if (this.data && this.data.uom && this.data.uom === 'mi') {
      unitSystem = google.maps.UnitSystem.IMPERIAL
    }
    directionsService
      .route({
        origin: start,
        destination: end,
        travelMode: selectedMode,
        unitSystem: unitSystem
      }, (result, status) => {
        this.isNotSupportedTravelMode = (status === MAPS.GOOGLE_MAP.DIRECTION_STATUS.NO_RESULTS) ? true : false;
        directionsRenderer.setDirections(result);
      })

  }

  ngAfterViewInit() {
    if (Utils.isJsApi() && window.gspl.directionsAfterViewInit) {
      window.gspl.directionsAfterViewInit();
    }
    this.consentService.initialize();
    this.subscriptions.push(this.consentService.consentChange$.subscribe(() => {
      this.isMapConsentGiven = this.settings.checkShowMapConsent(this.data.mapType);
      if (this.isMapConsentGiven && this.data.yourLocation) {
        if (this.data.mapType == 'BING') {
          this.directionsManager = undefined;
        }
        setTimeout(() => {
          this.mapLoader();
        }, 100);

      }
    }));

  }

  onClickOfTravelMode(mode: any) {
    this.selectTravelMode(mode.code);
    if (this.data.mapType === 'GOOGLE') {
      this.calculateAndDisplayRoute(this.directionsServiceGlobal, this.directionsRendererGlobal);
    } else {
      this.calculateAndDisplayBingRoute();
    }
  }

  /**
   * Use of this method is to get travel mode from cookie or country default driving mode
   * @returns {string}
   */
  getDefaultTravelMode(): string {
    return this.settings.getCookie('travelMode') || this.data.defDriveMode;
  }

  /**
   * Use to highlight selected travel mode
   * @param option 
   */
  selectTravelMode(option) {
    this.travelModes.forEach(value => {
      if (value.code === option) {
        value.selected = true;
        this.currentTravelMode = option;
      } else {
        value.selected = false;
      }
    });
    if (this.data.cookieConsent && this.settings.isCookiesConsentGiven()) {
      this.settings.setCookie('travelMode', option);
    }
  }

  private getLatLongforAddress(address) {
    this.backend.getLatLongForAddress(address, this.directionPlaceId, this.directionProviderId, this.settings.directionCountryCode, this.directionLang, this.backend.clientAppCode, this.directionToken).then(result => {
          if (result) {
            this.ngZone.run(() => {
              //if(!this.geoLoading){ GSPL-245
                this.startLat = result.latitude;
                this.startLong = result.longitude;
                // if(this.data.userSearchLocation){
                //   this.data.userSearchLocation.latitude = result.latitude;
                //   this.data.userSearchLocation.longitude = result.longitude;
                // }
                if(this.data.searchLocation && this.data.searchLocation.suggestion){
                  this.data.searchLocation.suggestion.latitude = result.latitude;
                  this.data.searchLocation.suggestion.longitude = result.longitude;
                }
              //}
              this.displayPrint = true;
              this.searchInProgress = false;

              setTimeout(() => {
                if (this.data.mapType && this.data.mapType.toUpperCase() === 'GOOGLE') {
                  if (this.isInitMapCalled) {
                    this.calculateAndDisplayRoute(this.directionsServiceGlobal, this.directionsRendererGlobal);
                  } else {
                    this.initMap();
                  }
                } else {
                  /*if (this.isLoadMapCalled) {
                    this.calculateAndDisplayBingRoute();
                  } else {*/
                    this.directionsManager = undefined;
                    this.loadMap();
                  //}
                }
              }, 2000);
                
                this.geoLoading = false;
            });
          }
        });
  }

  isAddressLongEnough() {
    return this.address.value && this.address.value.length >= environment.minCharsToActivateUi;
  }

  getLocation() {
    const error = (err) => {
        // location fix
        if (err && err.code === 1) {
            this.utils.showTranslatedMsg(this.snackBar, 'spl.geoapi.no.permission');
        } else {
            this.utils.showTranslatedMsg(this.snackBar, 'spl.geoapi.no.geolocation');
        }
    };
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) =>
            this.showPosition(position.coords.latitude, position.coords.longitude),
            error, { maximumAge: 600000, timeout: 10000, enableHighAccuracy: true });
    } else {
        this.utils.showTranslatedMsg(this.snackBar, 'spl.geoapi.not.supported');
    }
  }

  private showPosition(latitude, longitude) {
    this.backend.getAddressSuggestions(latitude + ', ' + longitude,
        this.settings ? this.settings.language.iso2Language : 'eng',
        this.settings ? this.settings.getCurrentLangCode() : 'en-US', null, null,
        true).then(result => {
            if (result && result[0]) {
                this.ngZone.run(() => {
                  //this.startLat = latitude; GSPL-245
                  //this.startLong = longitude; GSPL-245
                  this.directionPlaceId = result[0].placeId;
                  this.directionProviderId = result[0].providerId;
                  this.directionLang = this.settings ? this.settings.language.iso2Language : 'eng';
                  this.address['geoLocated'] = true;
                    this.address.setValue(result[0].label);
                    const country = this.settings.countries.countryList.find(country => country.value === result[0].countryCode);
                    // if(this.data.userSearchLocation){
                    //   this.data.userSearchLocation.latitude = latitude;
                    //   this.data.userSearchLocation.longitude = longitude;
                    // }
                    if(this.data.searchLocation && this.data.searchLocation.suggestion){
                      this.data.searchLocation.suggestion.latitude = result.latitude;
                      this.data.searchLocation.suggestion.longitude = result.longitude;
                    }
                    this.geoLoading = true;
                    this.geoCodeCountry = country && country.value ? country.value : null;
                    this.newGeoLoading = true;
                });
            }
        });
  }

  onAddressEvent(event: AddressEvent) {
    switch (event.type) {
        case AddressEventType.SUGGESTION_PICKED:
            //if (!this.selectedCountry.value) {
                if (event.placeId) {
                  this.directionPlaceId = event.placeId;
                  this.directionProviderId = event.providerId;
                  this.directionLang = this.settings ? this.settings.language.iso2Language : 'eng';
                  this.directionToken = event.sessionToken;
                }
            //}
          case AddressEventType.SUGGESTION_LOADED:
            if(this.geoLoading){
              this.newGeoLoading = false;
            }
        default: { 
          this.directionPlaceId = '';
          this.directionProviderId = '';
          break; 
        } 
    }
  }
  
}
