import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SettingsService} from '../settings.service';
import { NavigateService } from '../common/navigate.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LangListComponent } from '../lang-list/lang-list.component';
import { Utils } from '../common/utils';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

    @Input() showCenterLogo = false;
    @Input() toLandingPageWithParams = true;
    @Input() showLangSelector = true;

    @ViewChild('langList', {static: false}) langList: LangListComponent;

    isJsApi =  Utils.isJsApi;

    constructor(public settings: SettingsService, private navigate: NavigateService, private route: ActivatedRoute, private router: Router) {
    }

    ngOnDestroy(): void {
        // do nothing
    }

    ngOnInit() {
    }

    toLandingPage() {
        if (['results', 'advanced-search'].includes(this.route.snapshot.routeConfig.path)) {
            this.navigate.notifyHeaderIconClicked();
        } else if ([/*'directions', */'not-found'].includes(this.route.snapshot.routeConfig.path)) {
            this.router.navigate(['/']);
        } else {
            // 'error' -> isLandingWithParams = false.
            // 'print' -> not possible, no header.
        }
    }

    //BR11 Self descriptive
    isMobile(){
        return Utils.isMobile();
    }

    /*checkIFrame() {
        if (window.self !== window.top || window.frameElement) {
            return true;
        }
        return false;
    }*/

    isDirectionPage(){
        if (['directions'].includes(this.route.snapshot.routeConfig.path)) {
          return true;
        }
        return false;
    }
}
