<div class="gspl-container allow-cookie-mobile scroll4" [ngStyle]="page=='directions'?{'height':'60vh'}:{}">
    <div class="content px-3" >
      <p>{{translationKeyConsent | translate}}</p>
      <button id="allowMapConsent" [ngClass]="'rounded-lg'" mat-button
                                (click)="allowMapConsent()" [title]="">
                                <!--span>{{"spl.search.btn_text" | translate}}</span-->
                                <span>{{translationKeyButton | translate}}</span>
                    </button>
    </div>
  </div>
