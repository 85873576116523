import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ServicePoint } from '../backend.service';
import { SettingsService } from '../settings.service';

@Component({
    selector: 'app-result-item',
    templateUrl: './result-item.component.html',
    styleUrls: ['./result-item.component.scss']
})
export class ResultItemComponent implements OnInit {

    @Input() servicePoint: ServicePoint;
    @Input() lightVersion = false;
    @Input() lightVersionOrder = 1;
    @Input() rtl: boolean;
    @Input() locationButton: string;

    @Output() onSelected = new EventEmitter<ServicePoint>();
    @Output() onSelectedLocation = new EventEmitter<ServicePoint>();

    constructor(public settings: SettingsService) { }

    ngOnInit() {
    }

}
