import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import Mutex from 'await-mutex';
import { LocalCacheService } from './local-cache.service';
import { environment } from '../environments/environment';
import { Params } from '@angular/router';
import { Utils } from './common/utils';
import { SettingsService } from './settings.service';
import { LanguageInfo } from './model/LanguageInfo';
import { EncodeDecode } from './common/encode-decode';
import { CapabilityDetailComponent } from './capability-detail/capability-detail.component';
//import uuid from 'uuid/v4';
import { v4 as uuidv4 } from 'uuid';
import { CookiesBannerLoader } from './cookies-banner-loader';
import { ConsentService } from './consent.service.ts.service';


declare const window: any;

/*const fromPairs = (pairs) => {
    let index = -1;
    const length = pairs == null ? 0 : pairs.length;
    const result = {};

    while (++index < length) {
        const pair = pairs[index];
        result[pair[0]] = pair[1];
    }
    return result;
};
export const javaMapToObject = (javaMap) => fromPairs(javaMap.entry.map(objPair => ([objPair.key, objPair.value])));*/

const deleteEmptyProperties = (obj) => {
    Object.keys(obj).forEach((k) => {
        if (!obj[k]) {
            delete obj[k];
        }
    });
};

const sanitizeCapabilities = (capabilities) => capabilities ? capabilities.split(',').filter(capability => capability).join(',') : null;

export interface Countries {
    countryList: Array<Country>;
}

export interface Country {
    id: string;
    value: string;
    label: string;
    secondLevel: boolean;
    qrCheckCode: boolean;
    capabilityException: string;
    capabilityCustom: Capabilities;
    mapType: string;
    maxLimitWeightKg: number;
    maxLimitLengthCm: number;
    maxLimitWidthCm: number;
    maxLimitHeightCm: number;
    autocompleteMinLength: number;
    unitOfLength: string; //RTC - 567851
    directionType: string;
    showMessage: boolean;
    express: boolean;
    defDriveMode: string;
    customValue: string;
    dirExtRoute: boolean;
    customCountryCode: string;
}

export interface MessageDetails {
    messageDetails: Array<MessageDetail>;
}

export interface MessageDetail {
    messageType: string;
    countryId: Number;
    languageId: Number;
    message: string;
    url: string;
    clientCode: string;
    ppcCombination: string;
    priority: string;
}

export interface PageParameter {
    countryCode?: string;
    address?: string;
    capability?: string;
    weight?: string;
    weightUom?: string;
    length?: string;
    width?: string;
    height?: string;
    dimensionsUom?: string;
    openBefore?: string;
    openAfter?: string;
    openDay?: string;
    svpStatus?: string;//709541
    userCountry?: string;//791167
}

export interface ResultsParameter extends PageParameter {
    resultUom?: string;
    languageCountryCode?: string;
    language?: string;
    light?: string;
    doSearch?: string;
    languageScriptCode?: string;
    latitude?: string;
    longitude?: string;
    idf?: string;
    servicePointID?: string;
    servicePointResults?: number;
    maxDistance?: string;
    servicePointTypes?: string;
}

export const PERMANENT_URL_PARAMS: string[] = [
    "data", "mobile", "eventIdentifier", "hide", "freeze", "doSearch", "servicePointTypes", "servicePointResults", "maxDistance", "optanon"
];

export const PAGE_URL_PARAMS: string[] = [
    "address", "capability",
    "weight", "weightUom", "length", "width", "height", "dimensionsUom", "openBefore", "openAfter", "openDay", "svpStatus"//709541
    ,"userCountry"//791167
    , "showTravelMode"
];
//"placeId", "providerId", "sessionToken", "servicePointID"
//"languageScriptCode", "latitude", "longitude", "idf", "servicePointResults", "maxDistance", "servicePointTypes", "doSearch"

export class ContactDetails {
    phoneNumber: string;
    email: string;
    linkUri: string;
    servicePointId: string;
}

export interface ServicePoint extends ServicePointUiProperties {
    facilityId: string;
    facilityTypeCode: string;
    serviceAreaCode: string;
    servicePointName: string;
    servicePointNameFormatted: string;
    localName: string;
    locatedAt: string;
    servicePointType: string;
    address: any;
    geoLocation: any;
    distance: string;
    shippingCutOffTime: string;
    openingHours: any;
    servicePointCapabilities: ServicePointCapabilities;
    contactDetails: ContactDetails;
    shippingCutoffTimeHtml: any;
    distanceValue: string;
    distanceMetric: string;
    language: string;
    shipmentLimitations: any;
    shipmentLimitationsByPiece: any;
    chargeCode: string;
    timeZone: any;
    workWeekStart: number;
    partner: Partner;
    promotion: Promotion;
    svpStatus: string;
}

export interface ServicePointUiProperties {
    selectType: string;
    isSelected: boolean;
    isSelectedLocation: boolean;
}

export interface ServicePointCapabilities {
    accountShippers: boolean;
    prepaidShippers: boolean;
    payInStoreShippers: boolean;
    accountPrepaidShippers: boolean;
    accountPayInStoreShippers: boolean;
    prepaidPayInStoreShippers: boolean;
    accountPrepaidPayInStoreShippers : boolean;

    shipmentDropOff: boolean;
    shipmentCollection: boolean;
    shipmentDropOffAndCollection: boolean;

    labelFree: boolean;
    accountPrepaidLabelFree: boolean;
    accountLabelFree: boolean;
    prepaidLabelFree: boolean;

    prePrintReturnLabel: boolean;
    accountPrePrintReturnLabel: boolean;
    prepaidPrePrintReturnLabel: boolean;

    labelPrinting: boolean;
    accountLabelPrinting: boolean;
    prepaidLabelPrinting: boolean;

    disabledAccess: boolean;
    disabledAccessTitle: string;

    packaging: boolean;
    insurance: boolean;
    importCharges: boolean;

    payWithCash: boolean;
    payWithCheque: boolean;
    payWithCreditCard: boolean;
    payWithMobile: boolean;
    payWithPayPal: boolean;
    
    parkingAvailable: boolean;
    parkingTitle: string;
    pieceCountLimit: number;
    pieceDimensionsLimit: Dimensions;
    pieceDimensionsLimitUnit: string;
    pieceWeightLimit: number;
    pieceWeightLimitUnit: string;
    receiverPaid: boolean;
    visaProgram: boolean;
    capabilityCodes?: string;
    html: string;

    internationalShipping: boolean;
    domesticShipping: boolean;
    internationalDomesticShipping: boolean;
    europeanUnionDomesticShipping: boolean;


}

export interface Dimensions {
    H: number;
    L: number;
    W: number;
}

export enum ResultLoadType {
    DEFAULT,
    MAP_PAN_OR_ZOOM,
    FILTER_SELECTED_ALREADY
    //UOM_CHANGE
}

export class SearchServicePointsResultUiProperties {
    selectedCountry: Country;
    mapType: string;
    isShowInfoBox: boolean;
    resultLoadType: ResultLoadType;
    resultsParam: ResultsParameter;
    hasQrCode: boolean;
    hasSpPromotion: boolean;
    triggeredByFilter: boolean;
    selectedLocationServicePoint: ServicePoint;
}

export class SearchServicePointsResult extends SearchServicePointsResultUiProperties {
    status: any;
    searchAddress: string;
    searchLocation: {
        latitude,
        longitude,
        suggestion: {
            countryCode,
            googlePlaceId,
            latitude,
            longitude
        }
    };
    mapCulture: string;
    mapLanguage: string;
    servicePoints: Array<ServicePoint>;
    plainAddress: boolean;
    promotion: Promotion;
}

export interface Partner {
    partnerId: string;
    partnerName: string;
    partnerTypeCode: string;
    promotion: Promotion;
}

export interface Promotion extends PromotionUiProperties {
    text: string;
    buttonText: string;
    hyperlink: string;
}

export interface PromotionUiProperties {
    isClose: boolean;
    isHidden: boolean;
}

export interface CloseAtSession {
    isClose: string;
    countryPromotionIsClose: string;
    svpPromotionIsClose: string;
}

export interface AppConfig {
    supportedBrowsers: Array<SupportedBrowser>;
    navigateMap: Array<NavigationMap>;
    bingMapsKey: string;
    googleMapsKey: string;
    wsbEndpointUrl: string;
    wsbKey: string;
    capabilities: Capabilities;
    googleAnalyticsTrackingId: string;
    cookiesBannerId: string;
    grefQRCodePPCCodes: string;
    clientIdKeys: string;
    jsapiv3Usegsplwsbkey: boolean;
    clientAppCodeValidation: boolean;
    activeSvpStatusGref: string;
    messageTypes: string[];
    bingLoadUrl: string;//573005
    googleDirectionMapLoadUrl: string;
    enableLogs: boolean;
    maxResults: number;
    countryNameROWTranslation:string[];
    svcAvlDisplayOrder: string;
    gsplCookieCategory: string[];
    cookiesBannerRestrictedClients: String[];
    svpdetailBlinkingText: string;
    cookiesDataDomainUrl: string;
    googleMapsApiVersion: string;
    googleMapId: string;
}

export interface SupportedBrowser {
    id: number;
    device: string;
    browserNameToDisplay: string;
    browser: string;
    version: string;
    iconFile: string;
}
export interface NavigationMap {
    id: number;
    title: string;
    link: string;
    country: string;
    imageName: string;
    platform: string;
    language: string;
}

export interface Capabilities {
    payments: Array<Capability>;
    collects: Array<Capability>;
}

export interface Capability {
    code: string;
    labelKey?: string;
    label?: string;
    downstreams?: Array<Capability>;
}

export class RedirectActiveData {
    language: string;
    languageCountryCode: string;
    countryCode: string;
    address: string;
    svpStatus: string;
    clientAppCode: string;
}

export interface LatLongDetails {
    label: string,
    value: string,
    latitude: any,
    longitude: any,
    countryCode: string,
    placeId: string,
    providerId: string
}

@Injectable({
    providedIn: 'root'
})
export class BackendService {

    backendUrl: string;
    wsbKey: string;
    clientAppCode: string;
    googleKey: string;
    bingKey: string;
    validationWsbKey: string;
    enableLogs: boolean;
    public static restV3PathFragment = '/ServicePointLocator/restV3';

    // direct calls (not passing WSB)
    private appConfigUrl: string;
    private detectCountryUrl: string;
    private statusPromotionUrl: string;
    private closePromotionUrl: string;
    private statusSupportedBrowserUrl: string;
    private closeUnSupportedBrowserUrl: string;
    private latLongByAddressUrl: string;
    private messageDetailsUrl: string;
    private detailsByLatLongUrl: string;
    private logsUrl: string;

    // WSB calls
    private googleSuggestionUrl: string;
    private countriesUrl: string;
    private languagesUrl: string;
    private servicepointsUrl: string;

    private cacheMutex = new Mutex();
    public transcationId: any;

    constructor(private http: HttpClient, private cache: LocalCacheService, private consentService: ConsentService, private ngZone:NgZone) {
        this.transcationId = uuidv4();
    }

    initialize() {
        if (!Utils.isJsApi()) {
            this.initializeNotJsApi();
        } else {
            this.initializeJsApi();
        }
        this.initializeBackendUrlsNoWsb();
    }

    private initializeNotJsApi() {
        this.backendUrl = window.location.protocol + '//' + window.location.host;
        const wsbKeyMap = location.search.split('&').filter(s => s.includes('wsbKey')).map(s => s.split('='));
        if (wsbKeyMap && wsbKeyMap[0] && wsbKeyMap[0][1]) {
            this.wsbKey = wsbKeyMap[0][1];
        } else {
            //this.wsbKey = environment.wsbKey;
        }

        const clientAppCodeMap = location.search.split('&').filter(s => s && s.toLowerCase().includes('clientappcode')).map(s => s.split('='));
        if (clientAppCodeMap && clientAppCodeMap[0] && clientAppCodeMap[0][1]) {
            this.clientAppCode = clientAppCodeMap[0][1];
        } else {
            if (location.search.indexOf('?') < 0) {
                this.clientAppCode = 'gspl';
            }
            //this.wsbKey = environment.wsbKey;
        }
        
        /* INC58861749 */
        /*let lParam = this.getSplitParamValueFromSrc(location.search, 'l');
        if(lParam) {
            document.documentElement.lang = lParam;
        } else {
            let languageParam = this.getSplitParamValueFromSrc(location.search, 'language');
            if(languageParam) {
                document.documentElement.lang = languageParam;
            } else {
                document.documentElement.lang = 'en';
            }
        }*/
        //console.log("wsbKey = = ",this.wsbKey);
        //console.log("clientAppCode = = ",this.clientAppCode);
        // OneTrust Banner reload issue
        if(window.location.href.indexOf('/results') > 0) {
            var bodyElement = document.getElementById('bodyHtml');
            bodyElement.classList.add('hide-ot-banner');
        }
        
    }

    private initializeJsApi() {
        const scripts = document.getElementsByTagName("script");
        if (scripts) {
            for (var i = 0; i < scripts.length; i++) {
                const src = scripts.item(i).src;
                if (src.includes('/js-api-v3/gspl-js-api-v3.js')) {
                    this.backendUrl = src.substring(0, src.indexOf('/js-api-v3'));
                    //this.backendUrl = "https://locator-dev.dhl.com";
                    this.wsbKey = this.getParamValueFromSrc(src, 'wsbKey');
                    window.gspl.params.isLabelless = this.getParamValueFromSrc(src, 'labelless') === 'true' ? true : false;
                    window.gspl.params.isLabelFreeSrc = this.getParamValueFromSrc(src, 'labelFree') === 'true' ? true : false;
                    window.gspl.params.isLabelFree = window.gspl.params.isLabelless || window.gspl.params.isLabelFreeSrc;
                    window.gspl.backendUrl = this.backendUrl;
                    this.clientAppCode = this.getSplitParamValueFromSrc(src, 'clientAppCode');
                    this.googleKey = this.getSplitParamValueFromSrc(src, 'gKey');
                    this.bingKey = this.getSplitParamValueFromSrc(src, 'bKey');
                }
            }
        }
    }

    private getParamValueFromSrc(src: string, paramName: string): string {
        const index1 = src.indexOf(paramName.concat('='));
        if (index1 >= 0) {
            let index2 = src.indexOf('&', index1);
            if (index2 < 0) {
                index2 = src.length;
            }
            return src.substring(index1 + paramName.length + 1, index2);
        }
        return null;
    }

    private getSplitParamValueFromSrc(src: string, paramName: string): string {
        var srcArray: string[] = src.split("?");
        var output: string = null
        if (srcArray[1]) {
            srcArray[1].split("&").forEach(elem => {
                var valueArray: string[] = elem.split("=");
                if (valueArray[0].toLowerCase() === paramName.toLowerCase()) {
                    output = valueArray[1];
                }
            });
        }
        return output;
    }

    static getBackendUrlForTranslateHttpLoader(): string {
        let backendUrl = '';
        const scripts = document.getElementsByTagName("script");
        if (scripts) {
            for (var i = 0; i < scripts.length; i++) {
                const src = scripts.item(i).src;
                if (src.includes('/js-api-v3/gspl-js-api-v3.js')) {
                    backendUrl = src.substring(0, src.indexOf('/js-api-v3'));
                }
            }
        }
        //backendUrl = "https://locator-dev.dhl.com";
        return backendUrl;
    }

    private initializeBackendUrlsNoWsb() {
        const restV3BaseUrlNoWsb = this.backendUrl + BackendService.restV3PathFragment;
        this.appConfigUrl = restV3BaseUrlNoWsb + '/appConfig';
        this.detectCountryUrl = restV3BaseUrlNoWsb + '/detectCountry';
        this.statusPromotionUrl = restV3BaseUrlNoWsb + '/statusPromotion';
        this.closePromotionUrl = restV3BaseUrlNoWsb + '/closePromotion';
        this.statusSupportedBrowserUrl = restV3BaseUrlNoWsb + '/statusSupportedBrowser';
        this.closeUnSupportedBrowserUrl = restV3BaseUrlNoWsb + '/closeUnSupportedBrowser';
        this.messageDetailsUrl = restV3BaseUrlNoWsb + '/messages';
        this.latLongByAddressUrl = restV3BaseUrlNoWsb + '/getLatLongByAddress';
        this.detailsByLatLongUrl = restV3BaseUrlNoWsb + '/getDetailsByLatLong';
        this.logsUrl = restV3BaseUrlNoWsb + '/logs';
    }

    initializeBackendUrlsWithWsb(wsbEndpointUrl: string) {
        const restV3BaseUrl = wsbEndpointUrl + BackendService.restV3PathFragment;
        this.googleSuggestionUrl = restV3BaseUrl + '/googleSuggestion';
        this.countriesUrl = restV3BaseUrl + '/countries';
        this.languagesUrl = restV3BaseUrl + '/languages';
        this.servicepointsUrl = restV3BaseUrl + '/servicepoints';

    }

    addWsbKey(paramObj = {}): any {
        return Object.assign(paramObj, { key: this.wsbKey });
    }

    addWsbKeyForCountryLanguageKeyCheck(paramObj = {}): any {
        var wsbKeyTemp = this.wsbKey;
        if (!this.wsbKey) {
            wsbKeyTemp = this.validationWsbKey;
        }
        return Object.assign(paramObj, { key: wsbKeyTemp });
    }

    getAddressSuggestions(address, languageCode = null, bingculture = null, countryCode = null, zipcode = null, geocode: boolean = false, sessionToken = null)
        : Promise<any> {
        return this.http
            .get<any[]>(this.googleSuggestionUrl, {
                params: this.addWsbKey({
                    address,
                    languageCode,
                    bingculture,
                    countryCode,
                    zipcode,
                    sessionToken,
                    geocode: geocode.toString()
                })
            }).toPromise().catch(err => {
                console.error('Failed to fetch suggestions.', err);
                return [];
            });
    }

    getCountryByPlaceId(googlePlaceId, providerId, language = null, bingculture = null,
        countryCode, sessionToken): Promise<any> {
        return this.http.get<any>(this.googleSuggestionUrl, {
            params: this.addWsbKey({
                googlePlaceId,
                providerId,
                countryCode,
                sessionToken
            })
        }).toPromise().catch(err => {
            console.error('Failed to fetch place details.', err);
            return [];
        }).then(result => result[0].countryCode);
    }

    detectCountry(): Promise<any> {
        return this.http.get<any>(this.detectCountryUrl, { params: this.addWsbKey() }).toPromise()
            .catch(err => {
                console.error(err);
                return { country: '00' };
            });
    }

    getCountries(): Promise<Countries> {
        return this.getCached('countries', () =>
            new Promise<Countries>((resolve, reject) => {
                this.http.get<Countries>(this.countriesUrl, { params: this.addWsbKeyForCountryLanguageKeyCheck() }).toPromise().then(countries => resolve(countries))
                    .catch(error => {
                        console.error('Failed to fetch country list.', error);
                        reject(error);
                    })
            })
        );
    }

    getLatLongForAddress(address, placeId, providerId, countryCode = null, language, clientApp, sessionToken = null)
        : Promise<any> {
        return this.http.get<any[]>(this.latLongByAddressUrl, {
                params: {
                    address,
                    placeId,
                    providerId,
                    countryCode,
                    language,
                    clientApp,
                    sessionToken,
                }
            }).toPromise().catch(err => {
                console.error('Failed to fetch data for given address.', err);
                return [];
            });
    }

    getLanguages(): Promise<any> {
        return this.getCached('langs', () =>
            new Promise<any>((resolve, reject) => {
                //this.http.get<any>(this.languagesUrl, {params: this.addWsbKey()}).toPromise().then(response => resolve(javaMapToObject(response.languageMap)))
                this.http.get<any>(this.languagesUrl, { params: this.addWsbKeyForCountryLanguageKeyCheck() }).toPromise().then(response => resolve(response.languageMap))
                    .catch(error => {
                        console.error('Failed to fetch language list.', error);
                        reject(error);
                    })
            })
        );
    }

    checkWsbKey(): Promise<SearchServicePointsResult> {
        if (!window.gspl.wsbSearch && Utils.isJsApi()) {
            return new Promise((resolve, reject) => {
                let searchResult: SearchServicePointsResult = new SearchServicePointsResult();
                resolve(searchResult);
            });
            //resolve({});
        }
        return new Promise<SearchServicePointsResult>((resolve, reject) => {
            const paramObj: Params = {};
            this.http.get<SearchServicePointsResult>(this.servicepointsUrl, { params: this.addWsbKeyForCountryLanguageKeyCheck(paramObj) }).toPromise()
                .then(searchResult => resolve(searchResult))
                .catch(error => {
                    console.error('Failed to fetch servicepoints.', error);
                    reject(error);
                });
        });
    }

    queryParamsToSearchParams(queryParams: Params, settings: SettingsService): ResultsParameter {
        const resultsParam: ResultsParameter = {};
        resultsParam.servicePointResults = Utils.SERVICE_POINT_RESULTS;
        if (queryParams.servicePointID) {
            resultsParam.servicePointID = queryParams.servicePointID;
            //555425
            if (queryParams.address) {
                resultsParam.address = queryParams.address;
            }
        } else if (queryParams.idf) {
            resultsParam.idf = queryParams.idf;
        } else if (queryParams.zipcode) {
            resultsParam.address = queryParams.zipcode;
        } else {
            resultsParam.address = queryParams.address;
        }

        if (resultsParam.address && Utils.isAddressAnID(resultsParam.address)) {
            if (resultsParam.address.toUpperCase().startsWith('ID:')) {
                resultsParam.servicePointID = resultsParam.address.toUpperCase().replace('ID:', '').trim();
            } else if (resultsParam.address.toUpperCase().startsWith('IDF:')) {
                resultsParam.idf = resultsParam.address.toUpperCase().replace('IDF:', '').trim();
            }
            delete resultsParam['address'];
        } else {
            resultsParam.countryCode = queryParams.countryCode;
            resultsParam.capability = queryParams.capability;
            resultsParam.width = queryParams.width;
            resultsParam.height = queryParams.height;
            resultsParam.length = queryParams.length;
            resultsParam.weight = queryParams.weight;
            resultsParam.openDay = queryParams.openDay;
            resultsParam.openAfter = queryParams.openAfter;
            resultsParam.openBefore = queryParams.openBefore;
            resultsParam.weightUom = queryParams.weightUom;
            resultsParam.dimensionsUom = queryParams.dimensionsUom;
            resultsParam.longitude = queryParams.longitude;
            resultsParam.latitude = queryParams.latitude;
            resultsParam.servicePointTypes = queryParams.servicePointTypes;
            if (queryParams.servicePointResults) {
                resultsParam.servicePointResults = queryParams.servicePointResults;
            }
            if (queryParams.defaultQrCode) {
                resultsParam['defaultQrCode'] = queryParams.defaultQrCode;
            }
        }

        resultsParam.maxDistance = queryParams.maxDistance;

        if (queryParams.language) {
            const language: LanguageInfo = settings.languages[queryParams.language]; // UI parameter accepts 2digit language code
            if (language) {
                resultsParam.languageScriptCode = language.languageScript;
                resultsParam.language = language.iso2Language; // UI parameter accepts 2digit language code, however backend accepts 3digit language code
            } else { //RTC - 502831
                resultsParam.languageScriptCode = settings.language.languageScript;
                resultsParam.language = settings.language.iso2Language; // 3digit language code
                settings.queryParamChanged = true;
            }
        } else {
            resultsParam.languageScriptCode = settings.language.languageScript;
            resultsParam.language = settings.language.iso2Language; // 3digit language code
        }

        if(queryParams.lc){
            resultsParam.languageCountryCode = queryParams.lc;      
        }
        else if (queryParams.languageCountryCode) {
            resultsParam.languageCountryCode = queryParams.languageCountryCode;
        } else {
            resultsParam.languageCountryCode = settings.languageCountry.countryCode2;
        }

        if (queryParams.resultUom) {
            resultsParam.resultUom = queryParams.resultUom;
        } else {
            resultsParam.resultUom = settings.uom.code;
        }
    
        if(queryParams.svpStatus){
            resultsParam.svpStatus = queryParams.svpStatus;
        }
        
        if(queryParams.userCountry){
            resultsParam.userCountry = queryParams.userCountry;
        }
        
        resultsParam.doSearch = queryParams.doSearch;
        return resultsParam;
    }

    searchServicePoints(resultsParam: ResultsParameter, settings: SettingsService): Promise<SearchServicePointsResult> {
        return new Promise<SearchServicePointsResult>((resolve, reject) => {
            const paramObj: Params = Object.assign<Params, ResultsParameter>({}, resultsParam);
            paramObj.physicalProcessCapabilities = sanitizeCapabilities(paramObj.physicalProcessCapabilities); // this is not at all used
            if (paramObj.address) {
                paramObj.address = EncodeDecode.encode(paramObj.address);
                paramObj.b64 = true;
            }

            deleteEmptyProperties(paramObj);
            this.setSettingsParams(paramObj, settings);

            this.http.get<SearchServicePointsResult>(this.servicepointsUrl, { params: this.addWsbKey(paramObj) }).toPromise()
                .then(searchResult => {
                    this.searchServicePointsUiProperties(searchResult, resultsParam, settings)
                        .then(searchResult => resolve(searchResult))
                        .catch(error => reject());
                })
                .catch(error => {
                    // This block ensures validation error message is shown on the result page
                    // Reject when there is a error and wsbKey is not null
                    // Resolve when there is a error and wskKey is undefined/null
                    if (this.wsbKey) {
                        reject(error);
                    } else {
                        resolve(undefined);
                    }
                    console.error('Failed to fetch servicepoints.', error);
                });
        });
    }

    private setSettingsParams(paramObj: Params, settings: SettingsService) {
        if (settings.placeId) {
            paramObj['placeId'] = settings.placeId;
            settings.placeId = null;
        }
        if (settings.providerId) {
            paramObj['providerId'] = settings.providerId;
            settings.providerId = null;
        }
        if (settings.sessionToken) {
            paramObj['sessionToken'] = settings.sessionToken;
            settings.sessionToken = null;
        }
    }

    searchServicePointsUiProperties(searchResult: SearchServicePointsResult, resultsParam: ResultsParameter, settings: SettingsService): Promise<SearchServicePointsResult> {
        return new Promise<SearchServicePointsResult>((resolve, reject) => {
            return this.statusPromotion()
                .then(closeAtSession => {
                    if (searchResult.searchAddress && EncodeDecode.isBase64Unicode(searchResult.searchAddress)) {
                        try {
                            searchResult.searchAddress = EncodeDecode.decode(searchResult.searchAddress);
                        } catch (error) {
                            //console.warn('Exception in decoding searchResult.searchAddress = \'' + searchResult.searchAddress + '\'');
                            //console.warn('Most of the cases it can be ignored as the received is not encoded');
                            //console.log(error);
                            // do nothing as it can be ignored in this case...
                        }
                    }
                    this.initShowInfoBox(searchResult, resultsParam);
                    this.initializePromotionUiProperties(searchResult, closeAtSession.countryPromotionIsClose === 'true', closeAtSession.svpPromotionIsClose === 'true');
                    this.initializeSelectedCountryAndMapType(searchResult, settings);
                    searchResult.resultLoadType = ResultLoadType.DEFAULT;
                    searchResult.resultsParam = resultsParam;
                    searchResult.hasQrCode = false;
                    searchResult.hasSpPromotion = false;
                    searchResult.triggeredByFilter = false;
                    if(settings.isUserInputLocationNeeded && searchResult && searchResult.searchLocation){
                        settings.userSearchLocation = {latitude: searchResult.searchLocation.latitude, longitude: searchResult.searchLocation.longitude};
                    }
                    resolve(searchResult);
                })
                .catch(error => {
                    console.error('Failed searchServicePointsUiProperties ', error);
                    reject();
                });
        });
    }

    private initShowInfoBox(searchResult: SearchServicePointsResult, resultsParam: ResultsParameter) {
        let isShowInfoBox = false;
        if (!Utils.isJsApi()) {
            if (resultsParam.servicePointID || resultsParam.idf ||
                (resultsParam.doSearch && ['detail', 'detailContacts', 'detailLimits', 'detailHolidays'].includes(resultsParam.doSearch))
            ) {
                isShowInfoBox = true;
            }
            isShowInfoBox = (isShowInfoBox && searchResult.servicePoints && searchResult.servicePoints.length == 1);
        } else {
            isShowInfoBox = (searchResult.servicePoints && searchResult.servicePoints.length == 1);
        }
        searchResult.isShowInfoBox = isShowInfoBox;
    }

    private initializePromotionUiProperties(searchResult: SearchServicePointsResult, isCountryPromotionClose: boolean, isSvpPromotionClose: boolean) {
        if (searchResult.promotion) {
            searchResult.promotion.isHidden = false;
            searchResult.promotion.isClose = isCountryPromotionClose;
        }
        if (searchResult.servicePoints) {
            searchResult.servicePoints.forEach(servicePoint => {
                if (servicePoint.partner && servicePoint.partner.promotion) {
                    servicePoint.partner.promotion.isHidden = false;
                    servicePoint.partner.promotion.isClose = isSvpPromotionClose;
                }
                if (servicePoint.promotion) {
                    servicePoint.promotion.isHidden = false;
                    servicePoint.promotion.isClose = isSvpPromotionClose;
                }
                servicePoint.isSelected = false;
                servicePoint.isSelectedLocation = false;
            });
        }
    }

    private initializeSelectedCountryAndMapType(searchResult: SearchServicePointsResult, settings: SettingsService) {
        let country = settings.selectedCountry;
        let mapType = 'Google'; // Google Maps is default
        /*if (searchResult.servicePoints && searchResult.servicePoints.length > 0) {
            country = settings.countries.countryList.find(country => (country.value === searchResult.servicePoints[0].address.country 
                || country.value === searchResult.servicePoints[0].address.dhlCountry));
            settings.selectedCountry = country;
        }*/
        if (searchResult.servicePoints && searchResult.servicePoints.length > 0) {
            country = settings.countries.countryList.find(country => (country.value === searchResult.servicePoints[0].address.country));
            if (searchResult.servicePoints[0].address.dhlCountry) {
                country = settings.countries.countryList.find(country => (country.value === searchResult.servicePoints[0].address.dhlCountry));
            }
            settings.selectedCountry = country;
        }
        if (country) {
            mapType = country.mapType;
        }
        searchResult.selectedCountry = country;
        searchResult.mapType = mapType;
    }

    private getCached(key, computeFunc): Promise<any> {

        return this.cacheMutex.lock().then(unlock => {
            const cached = this.cache.get(key);
            if (cached) {
                unlock();
                return new Promise(resolve => resolve(cached));
            } else {
                return computeFunc().then((result) => {
                    this.cache.set(key, result, 480); // 8 hours
                    unlock();
                    return result;
                });
            }
        });
    }

    statusPromotion(): Promise<CloseAtSession> {
        return this.http.get<CloseAtSession>(this.statusPromotionUrl).toPromise().then(closeAtSession => closeAtSession
            , err => {
                console.error('Failed to fetch statusPromotion.', err);
                return null;
            });
    }

    closePromotion(type: string): Promise<CloseAtSession> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('type', type);
        return this.http.get<CloseAtSession>(this.closePromotionUrl, { params: httpParams }).toPromise().then(closeAtSession => closeAtSession
            , err => {
                console.error('Failed to fetch closePromotion.', err);
                return null;
            });
    }

    getAppConfig(): Promise<AppConfig> {
        return new Promise<AppConfig>((resolve, reject) => {
            let options = {};
            if (window.gspl.params.isLabelFree) {
                let httpParams = new HttpParams();
                httpParams = httpParams.append('isLabelFree', 'true');
                options = { params: httpParams };
            }
            this.http.get<AppConfig>(this.appConfigUrl, options).toPromise().then(data => {
                this.enableLogs = data.enableLogs;
                resolve(data);
            //    setTimeout(() => {
            //         this.loadCookiesBannerDisplay(data);
            //    }, 2000);
            }).catch(error => {
                    console.error('Failed to fetch appConfig.', error);
                    reject(error);
            });
        });
    }

    loadCookiesBannerDisplay(data:any){
        if(data){
            /*if(this.isBannerSuppresedClientBackend(data,this.clientAppCode)){
                var bodyElement = document.querySelector('body');
                bodyElement.classList.add('hide-onetrust-elements');
            }*/
            var startDate = new Date().getTime();
                CookiesBannerLoader.loadScript(data.cookiesBannerId, data.cookiesDataDomainUrl).then(() => {
                    console.log("Time taken to download One Trust script = "+(new Date().getTime() - startDate));
                    if(this.isBannerSuppresedClientBackend(data,this.clientAppCode)){
                        var bodyElement = document.querySelector('body');
                        bodyElement.classList.add('hide-onetrust-elements');
                    }
                });
        }
    }

    isBannerSuppresedClientBackend(appConfig: AppConfig, clientAppCode: String) {
        try {
            clientAppCode = clientAppCode ? clientAppCode.toLowerCase() : clientAppCode;
            const optanon = location.search.split('&').filter(s => s && s.toLowerCase().includes('optanon')).map(s => s.split('='));
            if (!Utils.isJsApi() && appConfig.cookiesBannerRestrictedClients && appConfig.cookiesBannerRestrictedClients.includes('allclients')){
                return true;
            }
            if (!Utils.isJsApi() && appConfig.cookiesBannerRestrictedClients && appConfig.cookiesBannerRestrictedClients.includes(clientAppCode)
                && (optanon && optanon[0], (optanon[0][1].toLowerCase() == 'n' || optanon[0][1].toLowerCase() == 'no'))) {
                return true;
            }
        } catch (error) {
            return false;
        }
        return false;
    }

    getMessageDetails(countryId: string, languageId: string, clientAppCode: string, messageType: string): Promise<MessageDetails> {
        return new Promise<MessageDetails>((resolve, reject) => {
            let options = {};
            let httpParams = new HttpParams();
            httpParams = httpParams.append('countryId', countryId);
            httpParams = httpParams.append('languageId', languageId);
            httpParams = httpParams.append('clientAppCode', clientAppCode);
            httpParams = httpParams.append('messageType', messageType);
            options = { params: httpParams };
            this.http.get<MessageDetails>(this.messageDetailsUrl, options).toPromise().then(data => resolve(data))
                .catch(error => {
                    console.error('Failed to fetch appConfig.', error);
                    reject(error);
                });
        });
    }

    statusSupportedBrowser(): Promise<CloseAtSession> {
        return this.http.get<CloseAtSession>(this.statusSupportedBrowserUrl).toPromise().then(closeAtSession => closeAtSession
            , err => {
                console.error('Failed to fetch statusSupportedBrowser.', err);
                return null;
            });
    }

    closeUnSupportedBrowser(): Promise<CloseAtSession> {
        return this.http.get<CloseAtSession>(this.closeUnSupportedBrowserUrl).toPromise().then(closeAtSession => closeAtSession
            , err => {
                console.error('Failed to fetch closeUnSupportedBrowser.', err);
                return null;
            });
    }

    getDetailsByLatLong(resultsParam: any, settings: SettingsService): Promise<LatLongDetails> {
        return this.http.get<LatLongDetails>(this.detailsByLatLongUrl, { params: resultsParam }).toPromise().then(data => data
            , err => {
                console.error('Failed to fetch DetailsByLatLong.', err);
                return null;
            });
        }


    saveLogs(data) {
        return this.http.post(this.logsUrl, data).toPromise().then(data => data
            , err => {
                //console.error('Failed to fetch logs.', err);
                return null;
            });
    }
}
