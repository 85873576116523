// consent.service.ts
import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { interval, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
declare const OneTrust: any;
@Injectable({
  providedIn: 'root'
})
export class ConsentService implements OnDestroy {
  private consentChangeSource = new Subject<boolean>();
  consentChange$ = this.consentChangeSource.asObservable();
  private checkFlag: boolean = true;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(private ngZone: NgZone) {

    this.intializeUntilOneTrustIsDefined();
  }

  ngOnDestroy(): void {
    if (this.destroy$) {
      this.destroy$.next();
      this.destroy$.complete();
    }
  }

  public initialize() {
    if (typeof OneTrust !== 'undefined' && this.checkFlag) {
      OneTrust.OnConsentChanged((consent: boolean) => {
        this.ngZone.run(() => {
          this.consentChangeSource.next(consent);
        });
      });
      this.checkFlag = false;
      if (this.destroy$) {
        this.destroy$.next();
        this.destroy$.complete();
      }
      //console.log("Initialized successfully");
    } else {
      //console.log("OneTrust is undefined", this.checkFlag);
    }
  }

  intializeUntilOneTrustIsDefined() {
    interval(1000).pipe(
      takeUntil(this.destroy$),
      takeUntil(interval(10000)),
      switchMap(() => {
        this.initialize();
        return interval(1);
      })
    ).subscribe();
  }
}
