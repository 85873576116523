import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { SettingsService } from '../settings.service';

@Component({
  selector: 'app-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss']
})
export class AlertMessageComponent implements OnInit {

  constructor(public settings: SettingsService) { }


  @Output() heightChange = new EventEmitter<number>();

  ngOnInit() {
  }

  close() {
    this.settings.isShowAlertMsg = false;
  }

}
