import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable, defer } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { LoggerService } from '../logger/logger.service';
//import uuid from 'uuid/v4';
import { Utils } from './utils';

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
  public tracker = {};
  constructor(
    private readonly deviceService: DeviceDetectorService,
    private readonly loggerService: LoggerService,
  ) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const authReq = req.clone({
      headers: req.headers
    });
    /*let key: uuid;
    if(this.loggerService.isEnableLogs()) {
      key = uuid();
      this.tracker[key] = Date.now();
    }*/
    if (!req.url.endsWith('/logs') && this.loggerService.isEnableLogs() && !Utils.isJsApi()) {
      this.loggerService.log(
        {
          req, startDate: Date.now(),
          device: this.deviceService.getDeviceInfo().browser + " - " +
            this.deviceService.getDeviceInfo().browser_version + " - " +
            this.deviceService.getDeviceInfo().device + " - " +
            this.deviceService.getDeviceInfo().os + " - " +
            this.deviceService.getDeviceInfo().os_version + " - "
        });
    }
    return next.handle(authReq).pipe(
      tap(
        {
          next: (event) => {
            if (!req.url.endsWith('/logs') && !Utils.isJsApi() && this.loggerService.isEnableLogs() && event instanceof HttpResponse) {
              //const timeTaken = this.calculatetimeTaken(this.tracker[key], Date.now());
              //var origReqdateValue = new Date(this.tracker[key]).toISOString();
              //this.loggerService.log({ req, timeTaken, status: event.status, device: this.deviceService.getDeviceInfo(), origReqdate: origReqdateValue});
              this.loggerService.log(
                {
                  req, endDate: Date.now(),
                  device: this.deviceService.getDeviceInfo().browser + " - " +
                    this.deviceService.getDeviceInfo().browser_version + " - " +
                    this.deviceService.getDeviceInfo().device + " - " +
                    this.deviceService.getDeviceInfo().os + " - " +
                    this.deviceService.getDeviceInfo().os_version + " - "
                });

            }
            return event;
          },
          error: (error) => {
            if (!Utils.isJsApi() && this.loggerService.isEnableLogs() && !req.url.endsWith('/logs')) {
              //const timeTaken = this.calculatetimeTaken(this.tracker[key], Date.now());
              //var origReqdateValue = new Date(this.tracker[key]).toISOString();
              //this.loggerService.log({ req, timeTaken, status: error.status, device: this.deviceService.getDeviceInfo(), origReqdate: origReqdateValue });
              this.loggerService.log(
                {
                  req, errorDate: Date.now(),
                  device: this.deviceService.getDeviceInfo().browser + " - " +
                    this.deviceService.getDeviceInfo().browser_version + " - " +
                    this.deviceService.getDeviceInfo().device + " - " +
                    this.deviceService.getDeviceInfo().os + " - " +
                    this.deviceService.getDeviceInfo().os_version + " - "
                });

            }
          }
        }
      ));
  }

  calculatetimeTaken(from, to) {
    const diff = to - from;
    return diff / 1000;
  }
}
