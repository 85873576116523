
import { Component, OnInit, Input, ViewChild, Output, EventEmitter, ElementRef, OnDestroy, ChangeDetectorRef, AfterViewInit, NgZone, ViewContainerRef, ComponentRef, Renderer2 } from '@angular/core';
import { Observable } from 'rxjs';
import { SearchServicePointsResult, ServicePoint, ResultLoadType, BackendService } from '../backend.service';
import { SettingsService } from '../settings.service';
import { Utils } from '../common/utils';
import { SpDetail2Component } from '../sp-detail2/sp-detail2.component';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConsentService } from '../consent.service.ts.service';
import { Loader } from "@googlemaps/js-api-loader";
import { SpDetailTooltipComponent } from '../sp-detail-tooltip/sp-detail-tooltip.component';
import { GridAlgorithm, MarkerClusterer, SuperClusterAlgorithm } from "@googlemaps/markerclusterer";

declare const window: any;
declare const OnetrustActiveGroups: any;
declare const OneTrust: any;

@Component({
    selector: 'app-google-map',
    templateUrl: './google-map.component.html',
    styleUrls: ['./google-map.component.scss']
})
export class GoogleMapComponent implements OnInit, OnDestroy, AfterViewInit {

    private readonly SINGLE_SVP_ZOOM: number = 15;
    private readonly NO_RESULTS_ZOOM: number = 8;
    public isOnStreetView: boolean = false;
    @Input() mobile: boolean;
    @Input() lightVersion = false;
    @Input()
    set servicePoints(servicePoints: SearchServicePointsResult) {
        // servicePoints will & should not be null, and no need to check
        if (servicePoints.mapType === 'Google') {
            this.isLoading = false; // maybe no need for this as already done by this.settings.resultListSearched.subscribe(...) ???
            this.setFitBounds(servicePoints);
            if (servicePoints.resultLoadType === ResultLoadType.MAP_PAN_OR_ZOOM && this._selectedServicePoint) { // not applicable to mobile
                const servicePoint = servicePoints.servicePoints.find(servicePoint => servicePoint.facilityId === this._selectedServicePoint.facilityId);
                if (servicePoint) {
                    setTimeout(() => { // delay isSelected=true to prevent Error: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. Previous value: 'ngClass: result-item'. Current value: 'ngClass: result-item-selected'.
                        this._selectedServicePoint = servicePoint;
                        this._selectedServicePoint.selectType = Utils.PIN_SELECT_TYPE;
                        this._selectedServicePoint.isSelected = true;
                        this.servicePointSelected.emit(this._selectedServicePoint);
                    }, 100);
                } else {
                    this._selectedServicePoint = null;
                }
            } else if (servicePoints.resultLoadType === ResultLoadType.MAP_PAN_OR_ZOOM && servicePoints.searchLocation) {
                this._selectedServicePoint = null;
                this.searchLocation = {
                    lat: servicePoints.searchLocation.latitude,
                    lng: servicePoints.searchLocation.longitude
                };
            } else {
                this._selectedServicePoint = null;

            }
            if (servicePoints.resultLoadType !== ResultLoadType.MAP_PAN_OR_ZOOM) {
                this.is1stLoad = true;
            }
            this._servicePoints = servicePoints;
            if (this.isMapReady) {
                this.renderGoogleMaps(this.map);
                this.resolveServicePoints();
            }
        } else {
            //this._servicePoints = null;
        }
    }
    _servicePoints: SearchServicePointsResult;

    _selectedServicePoint: ServicePoint = null;
    get selectedServicePoint() { return this._selectedServicePoint; }
    @Input() set selectedServicePoint(selectedServicePoint: ServicePoint) {
        if (selectedServicePoint && selectedServicePoint.selectType === Utils.LIST_SELECT_TYPE) {
            this.selectServicePoint(selectedServicePoint, false);
        }
    }

    private selectServicePoint(selectedServicePoint: ServicePoint, notify: boolean) {
        if (this._selectedServicePoint) {
            if (notify) {
                this._selectedServicePoint.isSelected = false;
            }
        }
        var facilityId  =  selectedServicePoint ? selectedServicePoint.facilityId : this.facilityID;
        var servicePointType = selectedServicePoint ? selectedServicePoint.servicePointType : this.servicePoinType;
        this._selectedServicePoint = selectedServicePoint;
        if (this._selectedServicePoint && this._servicePoints.searchAddress) {
            this.originPoint = this._servicePoints.searchAddress;
        } else {
            this.originPoint = null;
        }
        if (notify) {
            setTimeout(() => { // delay isSelected=true to prevent Error: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. Previous value: 'ngClass: result-item'. Current value: 'ngClass: result-item-selected'.
                if (this._selectedServicePoint) {
                    //this._selectedServicePoint.selectType = (!this.mobile) ? Utils.PIN_SELECT_TYPE : Utils.LIST_SELECT_TYPE;
                    this._selectedServicePoint.selectType = Utils.PIN_SELECT_TYPE;
                    this._selectedServicePoint.isSelected = true;
                }
                this.servicePointSelected.emit(this._selectedServicePoint);
            }, 100);
        }

        if (this.isMapReady && this._selectedServicePoint && !this.mobile && !(this._servicePoints.servicePoints.length > 1)
            && !(this._servicePoints &&
                this._servicePoints.servicePoints &&
                this._servicePoints.servicePoints.length == 1)) { //added the second last condition for BR11 - UXRedesign S9 - Last condition for 793795 // 832772
            this.isServicePointSelected = true;
            this.centerAndZoom(this.selectedServicePoint);
        } else {
            if (this.isMapReady && this.is1stLoad && this.selectedServicePoint && !this.lightVersion) { // added this for sea is shown during the ID search,
                this.isServicePointSelected = true;
                this.centerAndZoom(this.selectedServicePoint);
            }
        }

        this.markersImageChanges(this._selectedServicePoint);
    }

    @Output() servicePointSelected = new EventEmitter<ServicePoint>();
    @Output() mapMoveOrZoomEvent = new EventEmitter<any>();

    @ViewChild('spDetail2', { static: false }) spDetail2: SpDetail2Component;

    originPoint: string;
    sharedData: any;

    private isMapReady = false;

    latitude = 0;
    longitude = 0;
    fitBounds = true;
    searchLocation: any; // Attention
    hoverServicePoint: ServicePoint;
    centreZoomlatitude = 0;
    centreZoomlongitude = 0;

    //private googleMapsAPIWrapper: GoogleMapsAPIWrapper; // Attention
    private is1stLoad = true;
    private isServicePointSelected = false;
    private isPanning = false;
    private hasMapMoveOrZoom = false;
    private isLoading = false;
    private subscriptions: Array<Subscription> = [];
    private filterSelected: boolean = false;
    private isMapMoved: boolean = false;
    private isMapZoomed: boolean = false;
    private googleMap: google.maps.Map;
    private isStreetViewChanged: boolean = false;

    isMapConsentGiven: boolean = false;
    restrictMapExtendOnAllowConsent: boolean = false;
    facilityID: string = '';
    servicePoinType : string = '';
    private loader: Loader;
    private Map: any;
    private AdvancedMarkerElement: any;
    private map: any;
    markers: any[] = [];
    markerCluster: any; 
    centerPoint: any;
    @ViewChild('infoWindowContainer', { read: ViewContainerRef }) container!: ViewContainerRef;
    componentRef!: ComponentRef<SpDetailTooltipComponent>;

    private centerChangeListener:google.maps.MapsEventListener;
    private zoomChangeListener:google.maps.MapsEventListener;
    private idleListener:google.maps.MapsEventListener;
    private domReadyListener:google.maps.MapsEventListener;
    private clusterClickListener: google.maps.MapsEventListener;
    private tilesLoadListener: google.maps.MapsEventListener;

    constructor(public settings: SettingsService, private router: Router,
        private route: ActivatedRoute, private backend: BackendService, private ref: ChangeDetectorRef,
        private ngZone: NgZone, private consentService: ConsentService, private elementRef: ElementRef,
        private renderer: Renderer2) {  
        var apiKey: string = this.settings.appConfig.googleMapsKey;
        if (Utils.isJsApi() && !this.settings.appConfig.jsapiv3Usegsplwsbkey) {
            apiKey = this.backend.googleKey;
        }
        this.loader = new Loader({
            apiKey: apiKey,
            version: this.settings.appConfig.googleMapsApiVersion,//"weekly",
            language: this.settings.language.isoLanguage
        });

        this.isMapConsentGiven = this.settings.checkShowMapConsent('google');
        this.restrictMapExtendOnAllowConsent = this.isMapConsentGiven;
        if (this.isMapConsentGiven && !this.googleMap) {
            this.loadGoogleMap();
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.consentService.initialize();
        }, 100);
    }

    async loadGoogleMap() {
        // Load the required libraries
        const mapsLibraryPromise = this.loader.importLibrary("maps") as Promise<google.maps.MapsLibrary>;
        const markerLibraryPromise = this.loader.importLibrary("marker") as Promise<google.maps.MarkerLibrary>;

        // Wait for both library promises to resolve
        const [mapsLibrary, markerLibrary] = await Promise.all([mapsLibraryPromise, markerLibraryPromise]);

        // Assign the imported library objects to the class level variables
        this.Map = mapsLibrary.Map;
        this.AdvancedMarkerElement = markerLibrary.AdvancedMarkerElement;

        //const { Map, InfoWindow } = await this.loader.importLibrary("maps") as google.maps.MapsLibrary;
        //const { AdvancedMarkerElement, PinElement } = await this.loader.importLibrary("marker") as google.maps.MarkerLibrary;

        var gestureHandling: string = this.lightVersion ? 'none' : 'greedy';
        var zoomControl: boolean = this.lightVersion ? false : true;
        var panControl: boolean = this.lightVersion ? false : true;
        var disableDefaultUI: boolean = true;
        var mapTypeControl: boolean = this.lightVersion ? false : true;
        var rotateControl: boolean = this.lightVersion ? false : true;
        var streetViewControl: boolean = this.lightVersion ? false : true;
        const mapDiv = document.getElementById("map") as HTMLElement;
        if(mapDiv) {
            this.map = new this.Map(mapDiv, {
                center: { lat: 34.84555, lng: -111.8035 },
                mapId: (this.settings.appConfig && this.settings.appConfig.googleMapId) ? this.settings.appConfig.googleMapId : ' ',
                gestureHandling: gestureHandling,
                latitude: this.latitude,
                longitude: this.longitude,
                zoomControl: zoomControl,
                panControl: panControl,
                disableDefaultUI: disableDefaultUI,
                mapTypeControl: mapTypeControl,
                rotateControl: rotateControl,
                streetViewControl: streetViewControl,
                mapTypeId: google.maps.MapTypeId.ROADMAP
            });

            this.mapReady(this.map);

            // Add listener for center change
            this.centerChangeListener = google.maps.event.addListener(this.map, 'center_changed', () => {
                this.centerChange();
            });

            // Add listener for zoom change
            this.zoomChangeListener = google.maps.event.addListener(this.map, 'zoom_changed', () => {
                this.zoomChange();
            });

            this.idleListener = google.maps.event.addListener(this.map, 'idle', () => {
                this.idle();
            });

            this.tilesLoadListener = google.maps.event.addListener(this.map, 'tilesloaded', () => {
                this.removeTitleFromMarkers();
            });

            this.renderGoogleMaps(this.map);
        }
    }

    removeTitleFromMarkers(){
        const divs = document.querySelectorAll('div.yNHHyP-marker-view');
        if(divs) {
            divs.forEach(div => {
                this.renderer.removeAttribute(div, 'title');
            });
        }
    }

    async renderGoogleMaps(map: any) {
        const customIconRenderer = {
            render: ({ count, position }) => {
                return new google.maps.Marker({
                    position,
                    icon: {
                        url: this.settings.backendUrl + Utils.getIconForPins('cluster_marker'),
                        scaledSize: new google.maps.Size(32, 36),
                    },
                    label: {
                        text: String(count),
                        color: "white",
                        fontSize: "10px",
                        fontWeight: "800"
                    },
                    zIndex: 10000001+count,
                });
            }
        };

        if(this.markerCluster) {
            this.markerCluster.clearMarkers();
        }

        this.removeExistingMarkers();

        const { Map, InfoWindow } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
        const infoWindow = new InfoWindow({
            disableAutoPan: true
        });
        if(this._servicePoints && this._servicePoints.servicePoints) {
            const markers = await Promise.all(this._servicePoints.servicePoints.map(async (servicePoint, i) => {
                //this.hoverServicePoint = servicePoint;
                const normalPin = document.createElement('img');
                normalPin.id = servicePoint.facilityId;
                var pinImage ;
                if(!this.lightVersion){
                    if(servicePoint && servicePoint.servicePointType === 'TWENTYFOURSEVEN') {
                        pinImage = this.settings.backendUrl + (this.isServicePointSelected ? Utils.getIconForPins('locker_marker_selected') : Utils.getIconForPins('locker_marker_not_selected'));
                    }else {
                        pinImage = this.settings.backendUrl + (this.isServicePointSelected ? Utils.getIconForPins('marker_selected') : Utils.getIconForPins('marker_not_selected'));
                    }
                }
                normalPin.src = pinImage;
                normalPin.alt = 'DHL ServicePoint Icon - ' + servicePoint.facilityId;
                var position = { lat: servicePoint.geoLocation.latitude, lng: servicePoint.geoLocation.longitude };
                const advancedMarker = new this.AdvancedMarkerElement({
                    position,
                    map,
                    content: this.lightVersion? this.constructLightVersionPin(i) : normalPin,
                    gmpClickable: true,
                    title: "Marker Pin",
                });
    
                advancedMarker.jsonData = servicePoint;
                this.hoverServicePoint = servicePoint;
    
                advancedMarker.addListener('click', ({ domEvent, latLng }) => {
                    if(!this.lightVersion){
                        this.markersImageChanges(null);
                        const { target } = domEvent;
                        this.markers.forEach((element: any) => {
                            if(servicePoint && element.content.id === servicePoint.facilityId){
                                element.content.src = this.settings.backendUrl + (servicePoint && servicePoint.servicePointType === 'TWENTYFOURSEVEN' ? Utils.getIconForPins('locker_marker_not_selected') : Utils.getIconForPins('marker_not_selected'));
                                element.content.alt = 'DHL ServicePoint Icon - ' + servicePoint.facilityId;
                            }
                        });
                        target.src = this.settings.backendUrl + (servicePoint && servicePoint.servicePointType === 'TWENTYFOURSEVEN' ? Utils.getIconForPins('locker_marker_selected') : Utils.getIconForPins('marker_selected'));
                        target.alt = 'DHL ServicePoint Icon - ' + servicePoint.facilityId;
                        const foundServicePoint = this._servicePoints.servicePoints.find(
                            (servicePoint) => servicePoint.facilityId === target.id
                        );
                        this.markerClick(foundServicePoint);
                    }
                });
    
                advancedMarker.content.addEventListener('mouseenter', (event) => {
                    this.markerMouseOver(advancedMarker, event, map, infoWindow);
                });
    
                advancedMarker.content.addEventListener('mouseleave', () => {
                    this.markerMouseOut(infoWindow);
                });
                
                this.markers.push(advancedMarker);
                
                return advancedMarker;
            }));

            if(!this.lightVersion){
                this.markerCluster = new MarkerClusterer({
                    markers,
                    map,
                    //algorithm: new GridAlgorithm({ gridSize: 30 }),
                    algorithm: new SuperClusterAlgorithm({radius: 150, maxZoom: 20}),
                    renderer: customIconRenderer,
                });
    
                this.clusterClickListener = google.maps.event.addListener(this.markerCluster, 'click', (cluster) => {
                    this.onMapClusterClick();
                });

            }
            if (this.fitBounds) {
                this.fitBoundsMarker();
            }
        } 
        this.plotCenterPoint(map)
    }

    plotCenterPoint(map:any): boolean {
        if (this.searchLocation && this.searchLocation.lat && this.searchLocation.lng) {
            var position = { lat: this.searchLocation.lat, lng: this.searchLocation.lng };
            const centerPin = document.createElement('img');
            centerPin.src = this.settings.backendUrl + Utils.getIconForPins('icon_location');
            centerPin.alt = 'Center point pin';
            this.centerPoint = new this.AdvancedMarkerElement({
                position,
                map,
                content: centerPin,
                gmpClickable: false,
                zIndex: -1,
                title: "Center Pin",
            });
            if(!this._servicePoints || !this._servicePoints.servicePoints) {
                const bounds = new google.maps.LatLngBounds();
                bounds.extend(this.centerPoint.position);
                this.map.fitBounds(bounds);
                google.maps.event.addListenerOnce(this.map, 'bounds_changed', () => {
                    this.map.setZoom(this.NO_RESULTS_ZOOM);
                });
            }
            return true;
        }
        return false;
    }

    fitBoundsMarker() {
        if (this.markers.length > 0 && this.map) { // Check for markers and map
            const bounds = new google.maps.LatLngBounds();
            this.markers.forEach((marker) => {
                bounds.extend(marker.position); // Extend bounds for each marker position
            });
            this.map.fitBounds(bounds); // Fit bounds with padding
        }
    }


    ngOnInit() {
        this.initializeSubscriptions();
    }

    private initializeSubscriptions() {
        this.subscriptions.push(this.settings.resultListSearched.subscribe(() => {
            this.isLoading = false;
        }));
        this.subscriptions.push(this.settings.mapMovedOrZoomed.subscribe(status => {
            if (!status) {
                this.isLoading = false;
            }
        }));
        this.subscriptions.push(this.settings.servicePointsFilterTriggered.subscribe(servicePoints => {
            if (!this.mobile) {
                if (servicePoints.length > 1) {
                    this._servicePoints.servicePoints = servicePoints;
                    //this.fitBoundsMarker();
                    this.fitBounds = true;
                    if(this.isMapConsentGiven){
                        this.renderGoogleMaps(this.map);
                    }    
                } else if (servicePoints.length === 1) {
                    this.fitBounds = false;
                    this._servicePoints.servicePoints = servicePoints;
                    this.centerAndZoom(this._servicePoints.servicePoints[0]);
                } else {
                    this._servicePoints.servicePoints = null;
                    this.markerCluster.clearMarkers();//GSPL-369
                    // TBD... this.centerAndZoom(null); ???
                }
            }
        }));
        // RTC 565997 && 566002 - Listens to the event to extend to 50 on selection of filters
        this.subscriptions.push(this.settings.qrCodePromotionChanged.subscribe(() => {
            this.filterSelected = true;
            this.isMapMoved = false;
            if(!this.lightVersion){
                this.extendToMaximumResults(); 
            }else{
                this.settings.filterServicePoints();
            }//these condition is fix for GSPL-358 only for bing
        }));

        this.subscriptions.push(this.settings.servicePointSelected.subscribe(servicePointSelected => {
            if ((this._selectedServicePoint && servicePointSelected && this._selectedServicePoint.facilityId != servicePointSelected.facilityId) || (this.mobile && this._selectedServicePoint && !servicePointSelected)) {
                this.markersImageChanges(null);
            }
            if (!servicePointSelected) {
                this._selectedServicePoint = null;
                this.markersImageChanges(null);
            } else {
                if (this.googleMap && this.googleMap.getStreetView() && this.googleMap.getStreetView().getVisible()) {
                    this.googleMap.getStreetView().setVisible(false);
                    this.isStreetViewChanged = true;
                }
                this._selectedServicePoint = servicePointSelected;
                this.servicePoinType = this._selectedServicePoint.servicePointType;
                this.facilityID = this._selectedServicePoint.facilityId;
                this.markersImageChanges(this._selectedServicePoint);
            }
        }));
        this.ngZone.run(() => {
            this.subscriptions.push(this.consentService.consentChange$.subscribe(consent => {
                if (this._servicePoints && this._servicePoints.mapType == 'Google') {
                    this.isMapConsentGiven = this.settings.checkShowMapConsent('google');
                    this.restrictMapExtendOnAllowConsent = this.isMapConsentGiven;
                    this._selectedServicePoint = null;
                    if(this.isMapConsentGiven){
                        this.loadGoogleMap();
                    } 
                }
                // Update UI based on consent change
            }));
        });
    }

    markersImageChanges(servicePointSelected: ServicePoint){
        this.markers.forEach((element: any) => {
            if(servicePointSelected && element.content.id === servicePointSelected.facilityId){
                if (this._selectedServicePoint.servicePointType === 'TWENTYFOURSEVEN') {
                    element.content.src = this.settings.backendUrl + (this._selectedServicePoint ? Utils.getIconForPins('locker_marker_selected') : Utils.getIconForPins('locker_marker_not_selected'));
                } else {
                    element.content.src = this.settings.backendUrl +(this._selectedServicePoint ? Utils.getIconForPins('marker_selected') : Utils.getIconForPins('marker_not_selected'));
                }
                element.content.alt = 'DHL ServicePoint Icon - ' + this._selectedServicePoint.facilityId;
            }else if(!servicePointSelected){
                this._servicePoints.servicePoints.forEach(svp => {
                if(svp && element.content.id === svp.facilityId){
                    if (svp.servicePointType === 'TWENTYFOURSEVEN') {
                        element.content.src = this.settings.backendUrl + Utils.getIconForPins('locker_marker_not_selected');
                    } else {
                        element.content.src = this.settings.backendUrl + Utils.getIconForPins('marker_not_selected');
                    }
                    element.content.alt = 'DHL ServicePoint Icon - ' + svp.facilityId;
                }
                });
            }
        });
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        if(this.isMapConsentGiven){
            if(this.centerChangeListener){
                google.maps.event.removeListener(this.centerChangeListener);
            }
            if(this.zoomChangeListener){
                google.maps.event.removeListener(this.zoomChangeListener);
            }
            if(this.idleListener){
                google.maps.event.removeListener(this.idleListener);
            }
            if(this.domReadyListener){
                google.maps.event.removeListener(this.domReadyListener);
            }
            if(this.clusterClickListener){
                google.maps.event.removeListener(this.clusterClickListener);
            }
            if(this.tilesLoadListener){
                google.maps.event.removeListener(this.tilesLoadListener);
            }
            /*this.markers.forEach((advancedMarker) => {
                advancedMarker.removeEventListener('click');
                advancedMarker.content.removeEventListener('mouseenter');
                advancedMarker.content.removeEventListener('mouseleave');
            });*/
            this.removeExistingMarkers();
        } 
    }

    mapReady(map: google.maps.Map) {
        this.googleMap = map;
        this.isMapReady = true;
        this.setFitBounds(this._servicePoints);
        //this.googleMapsAPIWrapper = (<any>this.agmMap)._mapsWrapper; // Attention
        this.resolveServicePoints();
        map.getStreetView().addListener('visible_changed', () => {
            const isStreetViewVisible = map.getStreetView().getVisible();
            if (this.isOnStreetView || isStreetViewVisible) {
                this.isOnStreetView = isStreetViewVisible;
                if (this.isOnStreetView && this.selectedServicePoint) {
                    this.closed();
                }
                this.isStreetViewChanged = true;
                this.ref.detectChanges();
            }
        });
    }

    private resolveServicePoints() { // map should be ready before calling this function
        if (this._servicePoints.servicePoints) {
            if (this._servicePoints.isShowInfoBox && !this.mobile) {
                this.selectServicePoint(this._servicePoints.servicePoints[0], true);
            } else if (this._servicePoints.servicePoints.length === 1) {
                this.centerAndZoom(this._servicePoints.servicePoints[0]);
            }
            //if (!this.mobile) {
            //    //
            //} else {
            //    this.centerAndZoom(this._selectedServicePoint);
            //}
        } else if (this._servicePoints.searchLocation && !this.settings.isMapMovedToSea) {
            this.latitude = this._servicePoints.searchLocation.latitude;
            this.longitude = this._servicePoints.searchLocation.longitude;
            this.searchLocation = {//GSPL-198
                lat: this.latitude,
                lng: this.longitude
            };
        } else {
            //setTimeout(() => { // for some reason this is having issue without delaying
                this.centerAndZoom(null);
                this.searchLocation = {
                    lat: this.map.getCenter().lat(), // Attention
                    lng: this.map.getCenter().lng() // Attention
                };
            //}, 500);
        }
    }

    private setFitBounds(servicePoints: SearchServicePointsResult) {
        //this.fitBounds = !this.mobile && !servicePoints.isShowInfoBox && servicePoints.resultLoadType === ResultLoadType.DEFAULT;
        /*if(servicePoints.resultLoadType === ResultLoadType.MAP_PAN_OR_ZOOM && !this.isMapZoomed){
            this.restrictMapExtendOnAllowConsent = true;//fitbounds to show all svp results in map
        }*/ //- Yves
        this.fitBounds = (!servicePoints.isShowInfoBox && (servicePoints.resultLoadType === ResultLoadType.DEFAULT || this.restrictMapExtendOnAllowConsent) && (servicePoints.servicePoints && servicePoints.servicePoints.length > 1)) || this.lightVersion;
        if (this.fitBounds) {
            if (servicePoints.searchLocation) {
                this.searchLocation = {
                    lat: servicePoints.searchLocation.latitude,
                    lng: servicePoints.searchLocation.longitude
                };
            }
        } else {
            if (this.mobile || servicePoints.isShowInfoBox) {
                this.searchLocation = null;
            }
        }
    }

    private centerAndZoom(servicePoint: ServicePoint) {
        let latitude: number;
        let longitude: number;
        let zoom: number;
        if (servicePoint) {
            latitude = servicePoint.geoLocation.latitude;
            longitude = servicePoint.geoLocation.longitude;
            zoom = this.SINGLE_SVP_ZOOM;
        } else {
            latitude = this.map.getCenter().lat(); // Attention
            longitude = this.map.getCenter().lng(); // Attention
            zoom = this.NO_RESULTS_ZOOM;
        }
        this.map.setCenter({
            lat: latitude,
            lng: longitude
        });
        if (servicePoint) {
            this.map.setZoom(zoom);
        } // Attention

    }

    centerChange() { // Attention latLngLiteral: LatLngLiteral
        this.isMapMoved = true;
        this.isMapZoomed = false;
        if (!this.is1stLoad) {
            this.hasMapMoveOrZoom = true;
            this.settings.hasMapMovedOrZoomed = true;
        }
    }

    zoomChange() { // zoom: number
        if(this._selectedServicePoint && !this.lightVersion && this._servicePoints.servicePoints.length > 1){
            this.selectServicePoint(null, true);//1st condition- GSPL-315 && 2nd condition- GSPL-346 && 3rd condition- GSPL-347
        }
        this.isMapMoved = true;
        if (!this.is1stLoad) {
            this.hasMapMoveOrZoom = true;
            this.settings.hasMapMovedOrZoomed = true;
        } // 821558
        this.isMapZoomed = true;
        if (!this.isStreetViewChanged) {
            if (!(this._servicePoints && this._servicePoints.resultsParam && (this._servicePoints.resultsParam.servicePointID || this._servicePoints.resultsParam.idf))) {
                this._selectedServicePoint = null;//492398
                this.isServicePointSelected = false;
            }
        }
    }

    removeExistingMarkers() {
        this.markers.forEach(marker => {
            marker.setMap(null);
        });
        this.markers = [];
        if (this.centerPoint) {
            this.centerPoint.setMap(null);
        }
        this.centerPoint = undefined;
    }

    idle(){
        if (!this.lightVersion) {
            //Adjusted the infoHeight in this block for BR11 - UXRedesign S9
            if (this.isStreetViewChanged) {
                setTimeout(() => { this.isStreetViewChanged = false }, 50);
            } else if (this.isServicePointSelected) {
                //const elementRef: ElementRef = (<any>this.agmMap)._elem; // Attention
                //const mapHeight: number = elementRef.nativeElement.firstChild.offsetHeight; // Attention
                const mapHeight: number = document.getElementById('map')?.offsetHeight || 0; // Attention
                const infoHeight = 200;
                const topPadding = 30;
                this.map.panBy(0, -(infoHeight - (mapHeight / 2) + topPadding)); // Attention
                this.isServicePointSelected = false;
                this.isPanning = true;
            } else if (this.isPanning) {
                //this.spDetail2.resolveInfoSectionHeight(); //Commented this block for BR11 - UXRedesign S9-->
                this.isPanning = false;
                //this.is1stLoad = false;
            }
            else if (this.is1stLoad && this.map.getCenter().lat() !== 0 && this.map.getCenter().lng() !== 0) {
                this.is1stLoad = false;
                this.restrictMapExtendOnAllowConsent = false;//209 (comment)
            } // Attention
            else if (this._servicePoints && this._servicePoints.triggeredByFilter) {
                this._servicePoints.triggeredByFilter = false;
            } else if (this.settings.isFilterSelected) {
                this.settings.isFilterSelected = false;
            } else if (this.settings.isSearchClicked) { //GSPL-189
                this.settings.isSearchClicked = false;
            } else if (this.restrictMapExtendOnAllowConsent) {
                this.restrictMapExtendOnAllowConsent = false;
            }
            else if((this.centreZoomlatitude === this.map.getCenter().lat() && this.centreZoomlongitude === this.map.getCenter().lng())) {
            //console.log("centreZoomlatitude");
            //do nothing
            //console.log("same lat long");
                this.isStreetViewChanged = false;
            } // Attention
            else if (this.hasMapMoveOrZoom && !this.isLoading && !this._servicePoints.isShowInfoBox) {//&& this._servicePoints.servicePoints && this._servicePoints.searchLocation
                //this._selectedServicePoint = null;
                //this.isServicePointSelected = false;
                this.hasMapMoveOrZoom = false;
                this.isLoading = true;
                //this.settings.notifyServicePointSelected(this._selectedServicePoint);
                if(!this.mobile){
                    // this.servicePointSelected.emit(null);
                    // this.selectServicePoint(null, true);
                    this.markersImageChanges(null);
                }
                this.extendToMaximumResults();
            }
        }
        this.hasMapMoveOrZoom = false;
        setTimeout(() => {
            this.removeTitleFromMarkers();
        }, 200);
    }

    // RTC 565997 && 566002
    extendToMaximumResults() {
        if(this.isMapConsentGiven){
            this.centreZoomlatitude = this.map.getCenter().lat(); // Attention
            this.centreZoomlongitude = this.map.getCenter().lng(); // Attention
        }
        if (!Utils.isJsApi()) {
            const queryParams = this.settings.getParams(this.route.snapshot.queryParams);
            const params = Object.assign({}, queryParams);

            if (params['defaultQrCode'] && params['defaultQrCode'].toLowerCase() == 'y' && this.settings.isQrCodeAccessed) {
                params['capability'] = this.settings.ppcCodesInUrl;
                //params['defaultQrCode'] = 'N';
            } //else {
              if(this.filterSelected){
                  params['defaultQrCode'] = 'N';
              }
            //}

            // Not sure why this condition is added. Need to figure out during testing >> IMPORTANT
            //if(params['servicePointResults']==='50' && this.filterSelected){
            //    this.settings.filterServicePoints();
            //}
            if (!params['servicePointResults']) {
                params['servicePointResults'] = this.settings.appConfig.maxResults;
                if (!this.isMapZoomed && this.isMapConsentGiven) {
                    params['latitude'] = this.map.getCenter().lat(); // Attention
                    params['longitude'] = this.map.getCenter().lng(); // Attention
                }
            } else {
                if (this.filterSelected) {
                    this.settings.filterServicePoints();
                }

                if (this.isMapMoved && !this.isMapZoomed && this.isMapConsentGiven) {
                    params['latitude'] = this.map.getCenter().lat(); // Attention
                    params['longitude'] = this.map.getCenter().lng(); // Attention
                }
            }

            this.mapMoveOrZoomEvent.emit(params);
        } else {
            if (window.gspl.wsbSearch) {
                if (this._servicePoints.resultsParam) {
                    if (!window.gspl.params.servicePointResults) {
                        this._servicePoints.resultsParam.servicePointResults = this.settings.appConfig.maxResults;
                    } else {
                        this._servicePoints.resultsParam.servicePointResults = window.gspl.params.servicePointResults;
                    }
                    if (this.isMapMoved && !this.isMapZoomed) {
                        this._servicePoints.resultsParam.latitude = this.map.getCenter().lat().toString(); //Attention
                        this._servicePoints.resultsParam.longitude = this.map.getCenter().lng().toString(); //Attention
                    }
                    this.mapMoveOrZoomEvent.emit(this._servicePoints.resultsParam);
                }
            }
            else {
                if (this.settings.jsApiPageParam) {
                    //const params = Object.assign({}, this.settings.jsApiPageParam);
                    if (!window.gspl.params.servicePointResults) {
                        this.settings.jsApiPageParam['servicePointResults'] = this.settings.appConfig.maxResults;
                    } else {
                        this.settings.jsApiPageParam['servicePointResults'] = window.gspl.params.servicePointResults;
                    }
                    if(window.gspl.params.capability){
                        this.settings.jsApiPageParam['capability'] = window.gspl.params.capability;  
                    }
                    if (this.isMapMoved && !this.isMapZoomed) {
                        this.settings.jsApiPageParam['latitude'] = this.map.getCenter().lat().toString(); // Attention
                        this.settings.jsApiPageParam['longitude'] = this.map.getCenter().lng().toString(); // Attention
                    }
                    if (this.settings.jsApiPageParam.latitude === '0') {
                        this.settings.jsApiPageParam['latitude'] = undefined;
                    }
                    if (this.settings.jsApiPageParam.longitude === '0') {
                        this.settings.jsApiPageParam['longitude'] = undefined;
                    }
                    this.mapMoveOrZoomEvent.emit(this.settings.jsApiPageParam);
                }
            }
        }
    }

    //covered
    markerClick(servicePoint: ServicePoint) {
        if (!this.lightVersion) { //// Removed && !servicePoint.isSelected 793779
            this.hoverServicePoint = null;
            this.selectServicePoint(servicePoint, true);
        }
    }

    markerMouseOver(advancedMarker: any, event: any, map: any, infoWindow: any) {
        var servicePoint: ServicePoint = advancedMarker.jsonData;
        if (!servicePoint.isSelected && !this._selectedServicePoint && !Utils.isMobile()) {
            this.hoverServicePoint = servicePoint;
            if (this.componentRef) {
                this.componentRef.destroy();
            }

            this.container.clear();
            this.componentRef = this.container.createComponent(SpDetailTooltipComponent);
            this.componentRef.instance.servicePoint = servicePoint;
            const htmlContent = (this.componentRef.hostView as any)
                .rootNodes[0] as HTMLElement;
            infoWindow.setContent(htmlContent);
            infoWindow.open(map, advancedMarker);
            google.maps.event.addListenerOnce(infoWindow, 'domready', () => {
                setTimeout(() => {
                    this.calculatePopoverPosition(event); 
                }, 100); 
            });
        }
    }

    calculatePopoverPosition(event: any) {
        const parent = document.getElementById('map') as HTMLElement;
        const wrapperElement = document.getElementById('tooltipouter') as HTMLElement;
        //const popover = this.findSixthLevelParent(wrapperElement);
        const popover = document.querySelector('.gm-style-iw-a') as HTMLElement;
        const mtcElement = document.querySelector('.gm-style-mtc-bbw') as HTMLElement;
        if (parent && event && event.target && popover && wrapperElement) {
            //setTimeout(() => {
                const parentRect = parent.getBoundingClientRect();
                const markerRect = event.target.getBoundingClientRect();
                const wrapperRect = wrapperElement.getBoundingClientRect();
                const mtcElementRect = mtcElement ? mtcElement.getBoundingClientRect() : undefined;
                const spaceAvailableOnTop = markerRect.top - (mtcElementRect ? mtcElementRect.bottom : parentRect.top);
                const currTop = popover.style.top.replace("px", "");

                if (spaceAvailableOnTop < wrapperRect.height) {
                    //const newTop = (+currTop) + markerRect.bottom - spaceAvailableOnTop;
                    const newTop = (+currTop) + wrapperRect.height + markerRect.height;
                    popover.style.top = newTop + 25 + `px`;
                }
                const currLeft = popover.style.left.replace("px", "");
                if (markerRect.left < parentRect.left) {
                    popover.style.left = ((+currLeft) + (wrapperRect.width / 2) + (markerRect.width / 2)) + `px`;
                } else if ((markerRect.left - (wrapperRect.width / 2)) < parentRect.left) {
                    //popover.style.left = ((+currLeft)+(markerRect.left - 175)-(markerRect.left - parentRect.left))+ `px`;
                    popover.style.left = ((+currLeft) + (wrapperRect.width / 2)) + `px`;
                } else if (markerRect.right > parentRect.right) {
                    popover.style.left = (-20) + ((+currLeft) - Math.abs(parentRect.right - markerRect.right) - (wrapperRect.width / 2)) + `px`;
                } else if (markerRect.right + (wrapperRect.width / 2) > parentRect.right) {
                    popover.style.left = (-20) + ((+currLeft) + Math.abs(parentRect.right - markerRect.right) - (wrapperRect.width / 2)) + `px`;
                }
                setTimeout(() => {
                    wrapperElement.style.opacity = 'unset';
                }, 200);
            //}, 50);

        }
    }

    /*findSixthLevelParent(element: HTMLElement): HTMLElement | null {
        let currentElement: HTMLElement | null = element;
        for (let i = 0; i <= 4; i++) {
            if (currentElement && currentElement.parentNode instanceof HTMLElement) {
                currentElement = currentElement.parentNode;
                
            } else {
                // If there are less than 6 levels, return null or handle accordingly
                return null;
            }
        }
        if(currentElement && currentElement.classList && currentElement.classList[0] == 'gm-style-iw-a') {
            return currentElement;
        } else {
            return null;
        }        
    }*/

    markerMouseOut(infoWindow: google.maps.InfoWindow) {
        this.hoverServicePoint = null;
        if (this.componentRef) {
            this.componentRef.destroy();
        }
        infoWindow.close();
    }

    closed() {
        this.settings.isSpDetail2Active = false;
        this.servicePoinType = this._selectedServicePoint.servicePointType;
        this.facilityID = this._selectedServicePoint.facilityId;
        this.selectServicePoint(null, true);
    }

    onMapClusterClick() {
        this.isPanning = true;
        if(!this.mobile){
            //this.selectServicePoint(null, true);
            //this.servicePointSelected.emit(null);
            this.markersImageChanges(null);
        }
    }

    constructLightVersionPin(number: number): HTMLElement {
        var container = document.createElement('div');
        container.className = 'custom-marker';
        container.style.position = 'relative';
        container.style.top = '3px';
        container.style.display = 'inline-block';

        var img = document.createElement('img');
        img.src = this.settings.backendUrl + Utils.getIconForPins('light_version_marker');
        img.alt = 'Light version pins';
        img.style.display = 'block';
        container.appendChild(img);

        var numberDiv = document.createElement('div');
        numberDiv.className = 'marker-number';
        numberDiv.innerText = (number + 1).toString();
        numberDiv.style.position = 'absolute';
        numberDiv.style.top = '50%';
        numberDiv.style.left = '50%';
        numberDiv.style.color = 'black';
        numberDiv.style.fontSize = '12px';
        numberDiv.style.fontWeight = 'bolder';
        numberDiv.style.transform = 'translate(-50%, -50%)';
        container.appendChild(numberDiv);
        return container;
    }

    // JsApi
    @Input('show_share_svp_detail') showShareButtonSvpDetail = 'Y';
    @Output() onDirections = new EventEmitter<any>();
    @Output() onPrint = new EventEmitter<any>();
    @Output() onWebLink = new EventEmitter<any>();
    @Output() onFindActiveServicePoints = new EventEmitter<any>();
}
