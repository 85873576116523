import { Utils } from "./common/utils";

declare const window:any;
export class CookiesBannerLoader {

    //private static promise;
    //static newUrl: string;

    /*public static loadCookiesBannerScript(cookiesBannerId: string, url: string) {
        if (!this.promise) {

            this.promise = new Promise((resolve, reject) => {
                const node = document.createElement('script');
                node.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
                node.async = true;
                node.type = "text/javascript";
                node.setAttribute('data-document-language', 'true');
                node.setAttribute('charset', 'UTF-8');
                node.setAttribute('data-domain-script', cookiesBannerId);
                document.body.appendChild(node);
                    node.onload = resolve;
                    node.onerror = reject;
            });
        }

        return this.promise;
    }*/

    public static loadScript(scriptId: string, url: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            //if (document.querySelector(`script[src="${url}"]`)) {
            if (document.querySelector(`script[src="${url}"]`) || typeof window.OneTrust != 'undefined') {
                // Script already loaded, resolve immediately
                resolve();
            } else {
                let nonce = (document.querySelector('meta[name="CSP_NONCE"]') as HTMLMetaElement)?.content;
                if(Utils.isJsApi()){
                    nonce = window.gsplNonce;
                }
                const scriptElement = document.createElement('script');
                scriptElement.src = url;
                scriptElement.async = true;
                scriptElement.type = "text/javascript";
                scriptElement.setAttribute('data-document-language', 'true');
                scriptElement.setAttribute('charset', 'UTF-8');
                scriptElement.setAttribute('data-domain-script', scriptId);
                scriptElement.setAttribute('nonce', nonce);
                scriptElement.onload = () => resolve();
                scriptElement.onerror = () => reject(new Error(`Failed to load script at ${url}`));
                //document.addEventListener("DOMContentLoaded", function() {
                if(document.body){
                    document.body.appendChild(scriptElement);
                }
                //});    
            }
        });
    }

}
