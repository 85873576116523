import { Directive, Input, Self } from '@angular/core';
import { NgControl, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Utils } from '../common/utils';
import { SettingsService } from '../settings.service';

@Directive({
    selector: '[canFreeze]'
})
export class CanFreezeDirective {

    @Input()
    set canFreeze(value: string) {
        const params = this.settings.getParams(Utils.isJsApi() ? this.settings.jsApiPageAttributes : this.route.snapshot.queryParams);
        const freeze: string = params['freeze'];
        if (freeze && freeze.split(',').some(freezeParam => value.split(',').includes(freezeParam))) {
            setTimeout(() => {
                const control: UntypedFormControl = (<any>this.ngControl).form;
                control.disable({ onlySelf: true });
            }, 500);
            
        }
    }

    constructor(@Self() private ngControl: NgControl, private route: ActivatedRoute, private settings: SettingsService) { }
}
