import { SearchServicePointsResult } from '../backend.service';
import { Renderer2, ElementRef } from '@angular/core';
import { CountryPromotionComponent } from '../country-promotion/country-promotion.component';
import { JsApiComponentBase } from '../common/jsapi-component-base';
import { AlertMessageComponent } from '../alert-message/alert-message.component';

export abstract class SpMapBase extends JsApiComponentBase {

    abstract servicePoints: SearchServicePointsResult;
    abstract mapType: string;
    abstract renderer: Renderer2;
    abstract countryPromotionComponent: CountryPromotionComponent;
    abstract alertMsgComponent: AlertMessageComponent;
    abstract mapDivElementRef: ElementRef;
    abstract isAfterViewInit: boolean;
    abstract loadBingMaps();

    setMapType() {
        if (this.servicePoints) {
            if (this.servicePoints.mapType === 'Bing') {
                setTimeout(() => {
                    this.loadBingMaps();
                }, 100);
            } else {
                this.mapType = this.servicePoints.mapType; // Google
            }
        }
    }

    promotionHeightChanged(height: number) {
    }
}
