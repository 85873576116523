import { AfterViewInit, Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { ConsentService } from '../consent.service.ts.service';
import { SettingsService } from '../settings.service';

declare var OnetrustActiveGroups: any;
declare const window: any;


@Component({
  selector: 'app-cookie-map-consent',
  templateUrl: './cookie-map-consent.component.html',
  styleUrls: ['./cookie-map-consent.component.scss']
})
export class CookieMapConsentComponent implements OnInit, AfterViewInit {

  //@Output() dataEvent = new EventEmitter<any>();
  @Input() page:string = '';
  @Input() mapType:string = '';
  translationKeyConsent: string;
  translationKeyButton: string;
  constructor(public settings: SettingsService, private ngZone:NgZone, private consentService: ConsentService) { 

  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.consentService.initialize();
    }, 700);
  }

  ngOnInit() {
    this.translationKeyConsent = this.mapType.toLowerCase() == 'bing' ? 'spl.cookies.consent.txt_bing_map' : 'spl.cookies.consent.txt_google_map';
    this.translationKeyButton = this.mapType.toLowerCase() == 'bing' ? 'spl.cookies.consent.btn_bing_map' : 'spl.cookies.consent.btn_google_map';
  }

  allowMapConsent() {
    var mapConsentCategoryId = this.settings.appConfig.gsplCookieCategory.filter(val => val.indexOf('googlemap') > -1)[0].split(":")[1];
    if(this.mapType && this.mapType.toLowerCase() == 'bing'){
      mapConsentCategoryId = this.settings.appConfig.gsplCookieCategory.filter(val => val.indexOf('bingmap') > -1)[0].split(":")[1];
    }
    this.ngZone.run(() => {

      if (typeof window.OneTrust !== 'undefined') {
        this.consentService.initialize();
        window.OneTrust.UpdateConsent("Category", mapConsentCategoryId+":1");
      } else {
        console.log("One Trust is undefined in Allow Map Consent");
      }
    });
    
    
  }

}
