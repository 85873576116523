import { Component, Input, OnInit, Optional, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '../settings.service';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { AdvSearchTipComponent } from '../adv-search-tip/adv-search-tip.component';
import { Utils } from '../common/utils';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CapabilitySelection, CapabilityCategory } from '../common/capability-selection';
import { AdvanceSearchCriteria } from '../common/advance-search-criteria';

@Component({
    selector: 'app-adv-search-criteria',
    templateUrl: './adv-search-criteria.component.html',
    styleUrls: ['./adv-search-criteria.component.scss']
})
export class AdvSearchCriteriaComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() capability: CapabilitySelection;
    @Input() searchCriteria: AdvanceSearchCriteria;
    @Input() compact: boolean = false;
    
    mobile: boolean; // ???
    sendOptionsTranslated: Array<any>;
    collectOptionsTranslated: Array<any>;
    //sendOptions: Array<any>;
    //collectOptions: Array<any>;
    wUoms = ['kg', 'lb'];
    dUoms = ['cm', 'in'];
    hours: Array<{ code: string; value: string; }> = [];
    days: Array<{ code: string; labelKey: string; displayValue: string;}>;
    daysTranslated: Array<{ code: string; value: string; }> = [];

    @Input() isaddressAnID : boolean; //677488
    @Input() disabled : boolean = false;
    @Input() disablePPC : boolean = false;

    @ViewChild('weightInput', {static: false}) weightInput: ElementRef;
    @ViewChild('lengthInput', {static: false}) lengthInput: ElementRef;
    @ViewChild('widthInput', {static: false}) widthInput: ElementRef;
    @ViewChild('heightInput', {static: false}) heightInput: ElementRef;

    private subscriptions: Array<Subscription> = [];

    isScriptLanguage: boolean = false;

    constructor(private translate: TranslateService, @Optional() public settings: SettingsService,
                public dialog: MatDialog, private route: ActivatedRoute) {
        
        /*this.sendOptions = [{
            code: '0',
            label: 'spl.details.rs.drop_off'
        }].concat(sendSecondLevel.concat([allSend]));

        this.collectOptions = [{
            code: '0',
            label: 'spl.details.rs.collection'
        }].concat(collectSecondLevel.concat([allCollect]));

        this.days = [
            {code: '1', value: 'spl.search.weekday_monday'},
            {code: '2', value: 'spl.search.weekday_tuesday'},
            {code: '3', value: 'spl.search.weekday_wednesday'},
            {code: '4', value: 'spl.search.weekday_thursday'},
            {code: '5', value: 'spl.search.weekday_friday'},
            {code: '6', value: 'spl.search.weekday_saturday'},
            {code: '0', value: 'spl.search.weekday_sunday'}
        ];*/
    }

    ngOnInit() {
        this.initializeSubscriptions();
        this.initialize();
        this.isScriptLanguage = Utils.checkScriptLanguage(this.settings.languageCountry.language2.toUpperCase());
    }

    private initializeSubscriptions() {
        this.subscriptions.push(this.settings.languageChanged.subscribe(() => {
            //this.runNgSelectTranslations();
            this.isScriptLanguage = Utils.checkScriptLanguage(this.settings.languageCountry.language2.toUpperCase());
            this.generateHours();
        }));
        this.subscriptions.push(this.settings.uomChanged.subscribe(uom => {
            this.searchCriteria.changeUom(uom);
        }));
    }

    private initialize() {
        this.mobile = Utils.isMobile();
        this.generateHours();
        //this.runNgSelectTranslations();
        this.days = [
            { code: '1', labelKey: 'spl.result.weekday_short_monday', displayValue: 'Monday'},
            { code: '2', labelKey: 'spl.result.weekday_short_tuesday', displayValue: 'Tuesday' },
            { code: '3', labelKey: 'spl.result.weekday_short_wednesday', displayValue: 'Wednesday' },
            { code: '4', labelKey: 'spl.result.weekday_short_thursday', displayValue: 'Thursday'},
            { code: '5', labelKey: 'spl.result.weekday_short_friday', displayValue: 'Friday'},
            { code: '6', labelKey: 'spl.result.weekday_short_saturday', displayValue: 'Saturday' },
            { code: '0', labelKey: 'spl.result.weekday_short_sunday', displayValue: 'Sunday' }
        ];
    }

    private generateHours() {
        const intelligentTimeFormat = this.settings && this.settings.languageCountry.timeFormat !== 'AMPM';

        const shiftTime = (h => h > 12 ? h - 12 : h);

        const formatHours = (h, m, am = false) =>
            `${am ? shiftTime(h < 10 ? '0' + h : h) : h}:${m === 0 ? '00' : m * 15}${am ? (h < 12 ? ' AM' : ' PM') : ''}`;

        this.hours = [];

        for (let h = 0; h < 24; h++) {
            for (let m = 0; m < 4; m++) {
                this.hours.push({
                    code: formatHours(h, m), value: formatHours(h, m, !intelligentTimeFormat)
                });
            }
        }
    }

    /*private runNgSelectTranslations = () => {
        if (!this.sendOptions || !this.collectOptions || !this.days) {
            return;
        }
        Promise.all([
            Promise.all(this.sendOptions.map(opt => this.translate.get(opt.label).toPromise())),
            Promise.all(this.collectOptions.map(opt => this.translate.get(opt.label).toPromise())),
            Promise.all(this.days.map(day => this.translate.get(day.value).toPromise().then()))
        ]).then(translations => {

            this.sendOptionsTranslated = this.sendOptions.map(obj => Object.assign({}, obj));
            this.collectOptionsTranslated = this.collectOptions.map(obj => Object.assign({}, obj));

            translations[0].forEach((val, i) => this.sendOptionsTranslated[i].label = (i === 0) ? `--- ${val} ---` : val);
            translations[1].forEach((val, i) => this.collectOptionsTranslated[i].label = (i === 0) ? `--- ${val} ---` : val);


            this.daysTranslated = this.days.map((day, i) => ({code: day.code, value: translations[2][i]}));
        });

    };*/

    ngAfterViewInit() {
        this.searchCriteria.weightInput = this.weightInput;
        this.searchCriteria.lengthInput = this.lengthInput;
        this.searchCriteria.widthInput = this.widthInput;
        this.searchCriteria.heightInput = this.heightInput;
        this.handleAccessibilityForNGSelect();
    }

    handleAccessibilityForNGSelect(){
        let arrayVal: string[] = ['service_select','payment_select','handling_select','collect_select',
                                    'length_select','weight_select','openbefore_select',
                                    'openafter_select'];
        for(const item of arrayVal){
            const selectElement:HTMLElement = document.getElementById(item);
            if(selectElement){
                selectElement.removeAttribute('role');
            }
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    onServiceChange() {
        if (this.capability.service.value === CapabilityCategory.SEND) {
            this.capability.category = CapabilityCategory.SEND;
            this.capability.payment.setValue(this.capability.allPaymentCode.toString());
            this.capability.collect.setValue('0');
        } else if (this.capability.service.value === CapabilityCategory.COLLECT) {
            this.capability.category = CapabilityCategory.COLLECT;
            this.capability.payment.setValue('0');
            this.capability.collect.setValue(this.capability.allCollectCode.toString());
        } else if (this.capability.service.value === CapabilityCategory.SEND_COLLECT) {
            this.capability.category = CapabilityCategory.SEND_COLLECT;
            this.capability.payment.setValue(this.capability.allPaymentCode.toString());
            this.capability.collect.setValue(this.capability.allCollectCode.toString());
        } else {
            this.capability.category = CapabilityCategory.NONE;
            this.capability.payment.setValue('0');
            this.capability.collect.setValue('0');
        }
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(AdvSearchTipComponent, {});
    }

    onAnyChange(key: string = '') {
        if (key !== '') {
            this.searchCriteria[key]['isfocus'] = true;
        }

    }

    onWeightUomChange() {
        this.searchCriteria.weight.updateValueAndValidity();
    }

    onDimensionsUomChange() {
        this.searchCriteria.length.updateValueAndValidity();
        this.searchCriteria.width.updateValueAndValidity();
        this.searchCriteria.height.updateValueAndValidity();
    }

    onResize(event: any) {
        // Allow actively change mode for layouting, only for advance-search.
        this.mobile = Utils.isMobile();
    }

    checkCodeForValidationTickMark(input: string){
        if(input && (input==='0' || input.startsWith("0,"))){
            return false;
        }
        return true;
    }

    // JsApi
    
    @Input() mandatoryIndicator = 'default';
    @Input() isCapabilitySelected: boolean;
    @Input() infoIcon: string;
}
