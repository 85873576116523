import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendService, RedirectActiveData, ServicePoint } from '../backend.service';
import { Utils } from '../common/utils';
import { SettingsService } from '../settings.service';
declare const window: any;

@Component({
  selector: 'app-redirect-to-active',
  templateUrl: './redirect-to-active.component.html',
  styleUrls: ['./redirect-to-active.component.scss']
})
export class RedirectToActiveComponent implements OnInit {

  @Input() set servicePoint(servicePoint: ServicePoint) {
    if (servicePoint.svpStatus && servicePoint.svpStatus != 'A') {
      this.displayStatus = true;
      if (!Utils.isJsApi()) {
        this.constructURL(servicePoint);
      } else {
        this.constructDataForJsApi(servicePoint);
      }
    } else {
      this.displayStatus = false;
    }
  }

  displayStatus: boolean = false;
  newUrl: string;
  redirectActiveData: RedirectActiveData = new RedirectActiveData();

  constructor(public backend: BackendService, public settings: SettingsService, private utils: Utils, private route: ActivatedRoute) {
    if (!window.gsplInternal) {
      window.gsplInternal = {};
    }
    this.findServicePointButton();
  }

  ngOnInit() {
  }

  private findServicePointButton() {
    //if (!window.gsplInternal.openFindActiveServicePoint) {
    window.gsplInternal.openFindActiveServicePoint = (url) => {
      if (!Utils.isJsApi()) {
        this.utils.openExternalLinks(url, this.route);
      } else {

        this.onFindActiveServicePoints.emit(this.redirectActiveData);
      }
    };
    //}
  }

  private constructDataForJsApi(servicePoint: ServicePoint) {
    this.redirectActiveData.address = this.constructAddressForUrl(servicePoint);
    this.redirectActiveData.countryCode = (this.settings.searchResult.selectedCountry && this.settings.searchResult.selectedCountry.value) ? this.settings.searchResult.selectedCountry.value : (servicePoint.address.country ? servicePoint.address.country : "");
    let iso2Language = (this.settings.searchResult.resultsParam && this.settings.searchResult.resultsParam.language) ? this.settings.searchResult.resultsParam.language : 'eng';
    let array = Object.entries<any>(this.settings.languages).find(([key, value]) => (value.iso2Language == iso2Language));
    this.redirectActiveData.language = array ? array[0] : 'en';
    this.redirectActiveData.languageCountryCode = (this.settings.searchResult.resultsParam && this.settings.searchResult.resultsParam.languageCountryCode) ? this.settings.searchResult.resultsParam.languageCountryCode : 'GB';
    this.redirectActiveData.svpStatus = this.settings.appConfig.activeSvpStatusGref;
    this.redirectActiveData.clientAppCode = this.backend.clientAppCode;
  }

  private constructURL(servicePoint: ServicePoint) {
    var url = window.location.href;
    var newAddress = this.constructAddressForUrl(servicePoint);
    const seperatedValues: string[][] = url.split(/[?&]+/).map(value => value.split("="));
    const keyValuePairs = new Map();
    seperatedValues.forEach(el => keyValuePairs.set(el[0], el[1]));
    if (keyValuePairs.has("svpStatus") == false) {
      this.newUrl = url.replace("address=" + keyValuePairs.get("address"), "address=" + newAddress) + "&svpStatus=" + this.settings.appConfig.activeSvpStatusGref;
    } else {
      this.newUrl = url.replace("svpStatus=" + keyValuePairs.get("svpStatus"), "svpStatus=" + this.settings.appConfig.activeSvpStatusGref).replace("address=" + keyValuePairs.get("address"), "address=" + newAddress);
    }

    if (keyValuePairs.has("countryCode") == false && servicePoint.address.country) {
      this.newUrl = this.newUrl + "&countryCode=" + servicePoint.address.country;
    }
  }

  private constructAddressForUrl(servicePoint: ServicePoint) {
    var newAddress = "";
    if (servicePoint && servicePoint.address) {
      if (servicePoint.address.addressLine1) {
        newAddress = newAddress + servicePoint.address.addressLine1 + ", ";
      }
      if (servicePoint.address.addressLine2) {
        newAddress = newAddress + servicePoint.address.addressLine2 + ", ";
      }
      if (servicePoint.address.zipCode) {
        newAddress = newAddress + servicePoint.address.zipCode + ", ";
      }
      if (servicePoint.address.city) {
        newAddress = newAddress + servicePoint.address.city
      }
      if (newAddress.endsWith(", ")) {
        newAddress = newAddress.substring(0, newAddress.length - 2);
      }
      newAddress = newAddress.trim();
    }
    return newAddress;
  }

  
  handleRedirectButton(): void {
    let event;
    if(typeof(Event) === 'function') {
        event = new Event('close-redirect-button');
    }else{
        event = document.createEvent('Event');
        event.initEvent('close-redirect-button', true, true);
    }
    window.dispatchEvent(event);
  }

  openRedirectToActive() {
    window.gsplInternal.openFindActiveServicePoint(this.newUrl);
  }

  @Output() tempButtonClosed = new EventEmitter<string>();
  @Output() onFindActiveServicePoints = new EventEmitter<any>();

  @HostListener('window:close-redirect-button', ['$event'])
  closeRedirectButton(event: any) {
    this.displayStatus = false;
    this.tempButtonClosed.emit();
  }

}
