import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Utils} from './common/utils';
import { SettingsService } from './settings.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    router: Router = null;

    constructor(private injector: Injector, private settings: SettingsService) {
    }

    handleError(error) {
        console.error(error);
        if (this.isIgnorableError(error)) {
            return;
        }

        if (!Utils.isJsApi() && !(this.injector.get(ActivatedRoute).snapshot.url[0] &&
            this.injector.get(ActivatedRoute).snapshot.url[0].path === 'error')) {
            this.settings.isError = true;
            this.getRouter().navigate(['/error']);
        } else {
            throw error;
        }
    }

    private isIgnorableError(error) {
        if (error) {
            if (error.message && (error.message.toString().includes('ExpressionChangedAfterItHasBeenCheckedError')
            || error.message.toString().includes('ViewDestroyedError'))) {
                return true;
            }
            if (error.toString().includes('HttpErrorResponse') || error.toString().includes('isInAnimation')) {
                return true;
            }
        }
        return false;
    }

    private getRouter() {
        if (!this.router) {
            this.router = this.injector.get(Router);
        }
        return this.router;
    }
}