import { Component, OnInit, Input } from '@angular/core';
import { SettingsService } from '../settings.service';
import {CountryInfo} from '../model/CountryInfo';

@Component({
  selector: 'app-lang-country-mobile',
  templateUrl: './lang-country-mobile.component.html',
  styleUrls: ['./lang-country-mobile.component.scss']
})
export class LangCountryMobileComponent implements OnInit {

  @Input() lang;
  @Input() expandUp = false;
  @Input() toggleLanguage;
  showCountryList = false;
  countryList: Array<CountryInfo>;

  constructor(public settings: SettingsService) { }

  ngOnInit() {
  }

  onLangSelect() {
    this.showCountryList = false;
    this.toggleLanguage = false;
    this.settings.language = this.lang;
    this.lang.togglelanguage
    this.settings.toReloadResultsPage = true;
  }

  showSelectedCountryList(){
    this.countryList = this.lang.countryInfos;
    this.showCountryList = !this.showCountryList
  }

  onCountrySelect(countryInfo: CountryInfo) {
    this.showCountryList = false;
    this.toggleLanguage = false;
    this.settings.language = this.lang;
    this.settings.languageCountry = countryInfo;
    this.settings.toReloadResultsPage = true;
  }

  modalBackdropClick() {
    this.showCountryList = false;
  }


}
