<nav class="navbar navbar-expand-md navbar-dark fixed-top">
    <button class="navbar-toggler hidden-sm-up" type="button" (click)="onClick()" data-target="#navbarsDefault" aria-controls="navbarsDefault" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon" [ngStyle]="{'height': '35px','background-image': 'url('+settings.backendUrl+'/assets/menu-burger-smaller.svg)'}"></span>
    </button>
    <div [ngbCollapse]="isNavbarCollapsed" class="collapse" id="navbarsDefault">
      <ul [ngStyle]="onClickDisplay ? {} : {'display': 'none'}" [ngClass]="'navbar-nav mr-auto dropdown-menu ' + (settings?.isRTL()?' dropdown-menu-rtl':'') + ' menu-footer menu-ul'">
        <li [ngStyle]="{'margin-right': '0px', 'position':'relative'}">
            <div class="dropdown sel-language">
              <a id="lanLabelMobile" class="dropdown-selected" data-target="#" data-toggle="dropdown"
                 role="button" aria-haspopup="true" aria-expanded="false"
                 (click)="onLangClick()">{{selectedLanguage ? selectedLanguage.languageNative : 'english'}}</a>
              <span (click)="onLangPlateClick()" [ngClass]="toggleLanguage?'caret-down':'caret-right'" [ngStyle]="{background: 'url(' + settings.backendUrl + '/assets/sprite2.png) no-repeat'}"></span>
              <ul class="ul-language-uom" *ngIf="toggleLanguage">
                <li *ngFor="let lang of settings.languages | keyvalue" [ngStyle]="{'float':'left','width':'100%'}">
                    <app-lang-country-mobile [lang]="lang.value" [toggleLanguage]="toggleLanguage"></app-lang-country-mobile> <!-- BR11-->
                </li>
              </ul>    
            </div>
        </li>
        <li [ngStyle]="{'margin-right': '0px', 'position':'relative'}" class="main-list-li"> <!-- ngIf is added for BR11 - UXRedesign S1-->
            <div class="dropdown sel-country">
              <a id="lenUnitMobile" data-target="#" data-toggle="dropdown" role="button" aria-haspopup="true"
                 aria-expanded="false">{{'spl.search.'+ selectedUom.code | translate}}</a>
              <span (click)="onUomClick()" [ngClass]="toggleUom?'caret-down':'caret-right'" [ngStyle]="{background: 'url(' + settings.backendUrl + '/assets/sprite2.png) no-repeat'}"></span>
              <ul [ngClass]="" aria-labelledby="lenUnit" class="dropdown-language ul-language-uom" *ngIf="toggleUom">
                <li *ngFor="let uom of uoms">
                  <a (click)="onUomSelect(uom)">{{'spl.search.'+ uom.code | translate}}</a>
                </li>
              </ul>
            </div>
        </li>
        <li [ngStyle]="{'margin-right': '0px', 'margin-left': '-10px'}">
            <app-light-selector class="matcheckboxmobile"></app-light-selector>
        </li>
        <li [ngStyle]="{'margin-right': '0px'}">
            <!-- *ngIf="!isJsApi() && !settings.mobileHiding"-->
            <div>
                <span><a href="http://www.dhl.com" [href]="'https://' + mobileLink" target="_blank" class="text-red-14-bold-link" [ngStyle]="{'color':'dhlred'}">DHL.com</a></span>
            </div>
        </li>
        <li [ngStyle]="{'margin-right': '0px'}">
          <!-- *ngIf="!isJsApi() && !settings.mobileHiding"-->
          <div>
              <span><a id="ot-sdk-btn-footer" tabindex="0" class="text-red-14-bold-link ot-sdk-show-settings" [ngStyle]="{'color':'dhlred'}">Consent Settings</a></span>
          </div>
      </li>
        <div class="copyright-container">
          <span class="copyright-span">2021 © DHL International GmbH. <br> All rights reserved.</span>
        </div>
      </ul>
      
    </div>
    
  </nav>
  <div class="modal-backdrop" *ngIf="showCountryList || toggleUom || toggleLanguage || !isNavbarCollapsed" (click)="modalBackdropClick()"></div>