
<!--div class="col mx-0 px-0 h-100" style="display:flex; flex-direction:row">
    <div class="col px-0 svp-detail-min-max-width" *ngIf="!mobile && lightVersion && selectedServicePoint">
        <app-sp-detail2 #spDetail2 [servicePoint]="selectedServicePoint" [yourLocation]="originPoint"
            [searchLocation]="_servicePoints.searchLocation"
            [hrFormat24]="settings?.languageCountry.timeFormat !== 'AMPM'"
            [show_share_svp_detail]="showShareButtonSvpDetail" [hasPromotion]="_servicePoints.hasSpPromotion"
            (closed)="closed()">
        </app-sp-detail2>
    </div>
    <div class="col px-0 svp-detail-min-max-width" *ngIf="!mobile && !lightVersion && selectedServicePoint">
        <app-sp-detail2 #spDetail2 [servicePoint]="selectedServicePoint" [yourLocation]="originPoint"
            [searchLocation]="_servicePoints.searchLocation"
            [hrFormat24]="settings?.languageCountry.timeFormat !== 'AMPM'"
            [hasPromotion]="_servicePoints.hasSpPromotion" [show_share_svp_detail]="showShareButtonSvpDetail"
            (closed)="closed()" (onDirections)="onDirections.emit($event)" (onPrint)="onPrint.emit($event)"
            (onWebLink)="onWebLink.emit($event)" (onFindActiveServicePoints)="onFindActiveServicePoints.emit($event)">
        </app-sp-detail2>
    </div>
    <div [ngClass]="selectedServicePoint?(mobile?('col-12 px-0'):('col px-0')):'col-12 px-0'">
        <app-cookie-map-consent *ngIf="!isMapConsentGiven" [mapType]="'Google'"></app-cookie-map-consent> 
    <div>
</div-->
<div class="col mx-0 px-0 h-100" [ngStyle]="{'display':'flex', 'flex-direction':'row'}">
        <div class="col px-0 svp-detail-min-max-width" *ngIf="!mobile && lightVersion && selectedServicePoint">
            <app-sp-detail2
                #spDetail2 
                [servicePoint]="selectedServicePoint"
                [yourLocation]="originPoint" 
                [searchLocation]="_servicePoints.searchLocation" 
                [hrFormat24]="settings?.languageCountry.timeFormat !== 'AMPM'"
                [show_share_svp_detail]="showShareButtonSvpDetail"
                [hasPromotion]="_servicePoints.hasSpPromotion"
                (closed)="closed()">
            </app-sp-detail2>
        </div>
    <div class="col px-0 svp-detail-min-max-width" *ngIf="!mobile && !lightVersion && selectedServicePoint">
        <app-sp-detail2
            #spDetail2
            [servicePoint]="selectedServicePoint"
            [yourLocation]="originPoint" 
            [searchLocation]="_servicePoints.searchLocation" 
            [hrFormat24]="settings?.languageCountry.timeFormat !== 'AMPM'"
            [hasPromotion]="_servicePoints.hasSpPromotion"
            [show_share_svp_detail]="showShareButtonSvpDetail"
            (closed)="closed()"
            (onDirections)="onDirections.emit($event)"
            (onPrint)="onPrint.emit($event)"
            (onWebLink)="onWebLink.emit($event)"
            (onFindActiveServicePoints)="onFindActiveServicePoints.emit($event)">
        </app-sp-detail2>
    </div>
    <div [ngClass]="selectedServicePoint?(mobile?('col-12 px-0'):('col px-0')):'col-12 px-0'">
        <!--google-map *ngIf="_servicePoints && isMapConsentGiven" 
            #googleMap [style.width.%]="100" [style.height.%]="100"
            [disableDefaultUI]="true" [zoomControl]="lightVersion ? false : true" [mapTypeControl]="lightVersion ? false : true" [streetViewControl]="lightVersion ? false : true" [rotateControl]="lightVersion ? false : true" 
            [fitBounds]="fitBounds" 
            [latitude]="latitude" [longitude]="longitude" 
            [gestureHandling]="lightVersion ? 'none' : 'greedy'"
            (mapReady)="mapReady($event)"
            (centerChange)="centerChange($event)"
            (zoomChange)="zoomChange($event)"
            (idle)="idle()">
            <map-marker-cluster *ngIf="!lightVersion" [minimumClusterSize]= "2"  [styles]="clusterStyles" [zoomOnClick]="true" (clusterClick)="onMapClusterClick()">
                    <map-marker *ngFor="let servicePoint of _servicePoints.servicePoints; let i = index"
                        [visible]="!isOnStreetView"
                        [latitude]="servicePoint.geoLocation.latitude" 
                        [longitude]="servicePoint.geoLocation.longitude" 
                        [markerDraggable]="false" 
                        [openInfoWindow]="false" 
                        [agmFitBounds]="true" 
                        [title]="'DHL ServicePoint location'"
                        [iconUrl]="lightVersion ? 
                                settings.backendUrl + '/assets/lightVersion/marker_small_push_pin.png' 
                            : 
                                servicePoint.isSelected ? 
                                    servicePoint.servicePointType === 'TWENTYFOURSEVEN' ? 
                                        settings.backendUrl + '/assets/icon-marker-locker-selected.svg' : settings.backendUrl + '/assets/icon-marker-selected.svg' 
                                : 
                                    servicePoint.servicePointType === 'TWENTYFOURSEVEN' ? 
                                        settings.backendUrl + '/assets/icon-marker-locker.png' : settings.backendUrl + '/assets/icon-marker.png'"
                        [label]="lightVersion ? { color: 'black', fontFamily: 'Delivery, sans-serif', fontSize: '12px', fontWeight: 'bolder', text: (i+1).toString() } : ''">
                        
                        <map-info-window [disableAutoPan]="true" [isOpen]="true">
                            <div style="border-radius: 50%; width: 53px; height: 62px; position: absolute; left: -13px; cursor: pointer;"
                                (mouseenter)="markerMouseOver(servicePoint, i, $event)" 
                                (mouseleave)="markerMouseOut()"
                                (click)="markerClick(servicePoint)">
                            </div>
                        </map-info-window>
                    
                    </map-marker>
            </map-marker-cluster>


            <ng-container 
                *ngIf="lightVersion">
                <map-marker *ngFor="let servicePoint of _servicePoints.servicePoints; let i = index"
                    [latitude]="servicePoint.geoLocation.latitude" 
                    [longitude]="servicePoint.geoLocation.longitude" 
                    [markerDraggable]="false" 
                    [openInfoWindow]="false" 
                    [agmFitBounds]="true" 
                    [iconUrl]="lightVersion ? 
                            settings.backendUrl + '/assets/lightVersion/marker_small_push_pin.png' 
                        : 
                            servicePoint.isSelected ? 
                                servicePoint.servicePointType === 'TWENTYFOURSEVEN' ? 
                                    settings.backendUrl + '/assets/icon-marker-locker-selected.svg' : settings.backendUrl + '/assets/icon-marker-selected.svg' 
                            : 
                                servicePoint.servicePointType === 'TWENTYFOURSEVEN' ? 
                                    settings.backendUrl + '/assets/icon-marker-locker.png' : settings.backendUrl + '/assets/icon-marker.png'"
                    [label]="lightVersion ? { color: 'black', fontFamily: 'Delivery, sans-serif', fontSize: '12px', fontWeight: 'bolder', text: (i+1).toString() } : ''">
                    
                    <map-info-window [disableAutoPan]="true" [isOpen]="true">
                        <div style="border-radius: 50%; width: 53px; height: 62px; position: absolute; top: 35px; left: -13px; cursor: pointer;"
                            (mouseenter)="markerMouseOver(servicePoint, i, $event)" 
                            (mouseleave)="markerMouseOut()"
                            (click)="markerClick(servicePoint)">
                        </div>
                    </map-info-window>
                
                </map-marker>
            </ng-container>
            
            <agm-marker
                [visible]="searchLocation && !isOnStreetView"
                [latitude]="searchLocation?.lat" 
                [longitude]="searchLocation?.lng" 
                [markerDraggable]="false" 
                [openInfoWindow]="false" 
                [iconUrl]="settings.backendUrl + '/assets/icon-location.png'"
                [title]="'Center Pointer'">
            </agm-marker>

           
            <map-info-window *ngIf="hoverServicePoint"
                [latitude]="hoverServicePoint.geoLocation.latitude" 
                [longitude]="hoverServicePoint.geoLocation.longitude" 
                [disableAutoPan]="true" [isOpen]="true">
                <div id="toolTipWrapper">
                    <app-sp-detail-tooltip [servicePoint]="hoverServicePoint"></app-sp-detail-tooltip>
                </div>
            </map-info-window>
        </google-map-->
        <div id="map" *ngIf="_servicePoints && isMapConsentGiven" [ngStyle]="{'height': '100%', 'width': '100%'}"></div>
        <div #infoWindowContainer id="toolTipWrapper" [ngStyle]="{'display':'none'}"></div>
        <app-cookie-map-consent *ngIf="!isMapConsentGiven" [mapType]="'Google'"></app-cookie-map-consent> 
    <div>
</div>

