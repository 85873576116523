import {ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Optional, Output, ViewChild} from '@angular/core';
import {BackendService, Country} from '../backend.service';
import {UntypedFormControl} from '@angular/forms';
import {Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, tap} from 'rxjs/operators';
import {SettingsService} from '../settings.service';
//import uuid from 'uuid/v4';
import { v4 as uuidv4 } from 'uuid';
import {Utils} from '../common/utils';
import {MatAutocompleteTrigger as MatAutocompleteTrigger} from '@angular/material/autocomplete';

declare const window: any;

export enum AddressEventType {
    INPUT_FILLED_ENOUGH,
    SUGGESTION_LOADED,
    SUGGESTION_PICKED,
    SUGGESTIONS_FAILED,
    INPUT_INSUFFICIENT,
    ID_USED
}

export class AddressEvent {
    type: AddressEventType;
    address: string;
    placeId: string;
    providerId: string;
    countryCode: String;
    sessionToken: string;
}

@Component({
    selector: 'app-address-input',
    templateUrl: './address-input.component.html',
    styleUrls: ['./address-input.component.scss']
})
export class AddressInputComponent implements OnInit, OnDestroy {

    @ViewChild(MatAutocompleteTrigger, {static: false})
    autoComplete: MatAutocompleteTrigger;

    @Input() selectedCountry: UntypedFormControl;

    @Output() events = new EventEmitter<AddressEvent>();

    @Output() submit = new EventEmitter<void>();

    @Input()
    loading = false;

    @Input()
    address: UntypedFormControl;

    @Input()
    customAutocomplete;

    @Input()
    isBasic: boolean = false;

    @Input() 
    isAdvanced: boolean = false;

    @Input()
    abbreviated: boolean = false;

    @Input()
    isDirection: boolean = false;

    @Input()
    inputAddress: string; 

    addresses: Array<any>;
    private addressSubscription: Subscription;
    private selectedCountrySubscription: Subscription;
    justSelected: string;
    sessionToken: string;
    isFloating = false;
    isValueSelected: boolean = false;

    MIN_CHARS = 3;
    DELAY = 1000;

    constructor(private backend: BackendService, @Optional() public settingService: SettingsService, private cdr: ChangeDetectorRef,public ngZone: NgZone) {
        this.addresses = [];
    }

    ngOnInit() {
        if(this.address.value != ""){
            this.isValueSelected = true;
        }
        if (this.settingService.selectedCountry &&
            this.settingService.selectedCountry.autocompleteMinLength !== undefined
        ) {
            this.MIN_CHARS = this.settingService.selectedCountry.autocompleteMinLength;
        }
        if(this.isDirection){
            this.address.setValue(this.inputAddress);
        }
        this.isFloating = this.address.value ? true : false;
        if(!this.settingService.isSelectCountryFreezed()){
            this.selectedCountrySubscription = this.selectedCountry.valueChanges.subscribe (country =>  {
                if (this.checkInput(this.address.value)) {
                    this.loadSuggestions(this.address.value, () => this.autoComplete.openPanel());
                }
                this.isFloating = false;
                if (country) {
                    this.MIN_CHARS = country.autocompleteMinLength;
                    this.DELAY = country.autocompleteDelay;
                } else {
                    this.MIN_CHARS = 3;
                    this.DELAY = 1000;
                }
                this.subscribeToAddress();
            });
            this.subscribeToAddress();
        }
    }

    private subscribeToAddress() {
        if (this.addressSubscription) {
            this.addressSubscription.unsubscribe();
        }
        this.addressSubscription = this.address.valueChanges.pipe(
            tap((item) => {
                if (item && item.length >= this.MIN_CHARS) {
                    //this.isValueSelected = true;
                    const event = new AddressEvent();
                    event.address = item;
                    event.type = AddressEventType.INPUT_FILLED_ENOUGH;
                    this.events.emit(event);
                } else {
                    this.isValueSelected = false;
                    const event = new AddressEvent();
                    event.type = AddressEventType.INPUT_INSUFFICIENT;
                    this.events.emit(event);
                }
                if (!item || item <= this.MIN_CHARS) {
                    this.addresses = [];
                }
            }),
            debounceTime(this.DELAY),
            distinctUntilChanged(),
            filter((item) => this.checkInput(item))
        ).subscribe((value) => this.loadSuggestions(value));
    }

    onFocus() {
        this.isFloating = true;
    }
    
    onBlur() {
        this.isFloating = this.address.value ? true : false;
    }

    loadSuggestions(address, callback = null) {
        if(this.settingService.isApiAddressPreSelected && Utils.isJsApi() && !window.gspl.wsbSearch){
            this.settingService.isApiAddressPreSelected = false;
            return;
        }

        if (!this.sessionToken) {
            this.sessionToken = uuidv4();
        }
        let countryCode = null;
        if (this.selectedCountry.value) {
            countryCode = (<Country>this.selectedCountry.value).value;
        }
        if( this.settingService.directionCountryCode && this.isDirection){
            countryCode = this.settingService.directionCountryCode;
        }
        this.backend.getAddressSuggestions(address,
            this.settingService ? this.settingService.language.iso2Language : 'eng',
            this.settingService ? this.settingService.languageCountry.bingCulture2 : 'en-US',
            countryCode, null, false, this.sessionToken)
            .then((data) => {
                this.ngZone.run(()=>{
                    this.addresses = data;
                    const event = new AddressEvent();
                    event.type = AddressEventType.SUGGESTION_LOADED;
                    event.sessionToken = this.sessionToken;
                    this.events.emit(event);
                });

                //this.cdr.detectChanges();
                if (callback) {
                    callback();
                }
            });
    }

    addressSelected(value): void {
        this.justSelected = value;
        const event = new AddressEvent();
        event.address = value;
        event.type = AddressEventType.SUGGESTION_PICKED;
        event.placeId = this.addresses.find(adr => adr.label === value).placeId;
        event.providerId = this.addresses.find(adr => adr.label === value).providerId;
        event.countryCode = this.addresses.find(adr => adr.label === value).countryCode;
        event.sessionToken = this.sessionToken;
        this.sessionToken = null;
        this.events.emit(event);
        //this.isValueSelected = true;
    }

    ngOnDestroy(): void {
        if(this.addressSubscription){
            this.addressSubscription.unsubscribe();
        }
        if(this.selectedCountrySubscription){
            this.selectedCountrySubscription.unsubscribe();
        }
    }

    clearInput() {
        setTimeout(()=>{
            this.settingService.messageDetails = null;  
            this.settingService.expressMessageWithLink = null;
            this.settingService.expressMessage = null;   
            this.address.setValue('');                   
            this.isValueSelected = false;
       });
    }

    containsValue() {
        return this.address.value && this.address.value.length > 0;
    }

    enterPressed(event) {
        if (this.addresses.length === 0) {
            event.preventDefault();
        }
        if (Utils.isAddressAnID(this.address.value)) {
            this.submit.emit();
        }
    }

    checkInput(input: string) {
        this.isValueSelected = Utils.isAddressAValidValue(input);
        if (input) {
            if (this.address['geoLocated']) {
                //this.isValueSelected = true;
                delete this.address['geoLocated'];
                return false;
            }
            if (this.justSelected === input) {
                this.justSelected = null;
            } else if (Utils.isAddressAnID(input) || Utils.isAddressCoordinates(input)) {
                //this.isValueSelected = Utils.isAddressAValidID(input);
                return false;
            } else if (input.length >= this.MIN_CHARS) {
                return true;
            }
        }
        return false;
    }

    isJSApi(){
        return Utils.isJsApi();
    }

    isMobile(){
        return Utils.isMobile();
    }
}
