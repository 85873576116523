<!-- This div handles the send, collect, send and collect for the first level countries. In this case, no dropdown has to be displayed.-->
<div class="row" aria-disabled="true">
    <div class="col mx-auto px-0 d-none d-md-flex"></div>
    <div class="col col-md-auto px-md-0">
        <div class="row justify-content-center service-icons-md">
            <div *canHide="'capability,send'" class="col-4" 
            [ngStyle]="{'padding-left': '0px', 'padding-right': '6px'}">
                <div [attr.tabindex]="_disabled ? '-1' : '0'" [ngClass]="'w-100 h-100 service ' + (_disabled ? 'disabled' : 'enabled') + ((selectedCapability.category === categories.SEND)?' selected':' not-selected')" 
                    [title]="((_disabled && selectedCapability.category === categories.NONE) ? (isaddressAnID ? ('spl.search.service_not_required' | translate) : ('spl.search.insert_location' | translate)) : '')" 
                    (click)="selectCapability(categories.SEND)" data-test_id="click_send">
                    <img aria-label="Click here to select a service: send shipment option, 1 of 3 option" class="service-img" [ngStyle]="{'width': '32px'}" [src]="settings.backendUrl + '/assets/capability/' + ((selectedCapability.category === categories.SEND)?'send2.png':'send.png') ">
                    <label class="px-1 px-md-2">{{'spl.search.send_shipment' | translate}}</label>
                </div>
            </div>
            <div *canHide="'capability,collect'" class="col-4" 
            [ngStyle]="{'padding-left': '3px', 'padding-right': '3px'}">
                <div [attr.tabindex]="_disabled ? '-1' : '0'" [ngClass]="'w-100 h-100 service ' + (_disabled ? 'disabled' : 'enabled') + ((selectedCapability.category === categories.COLLECT)?' selected':' not-selected')" 
                    [title]="((_disabled && selectedCapability.category === categories.NONE) ? (isaddressAnID ? ('spl.search.service_not_required' | translate) : ('spl.search.insert_location' | translate)) : '')" 
                    (click)="selectCapability(categories.COLLECT)" data-test_id="click_collect">
                    <img aria-label="Click here to select a service: collect shipment option, 2 of 3 option" class="service-img" [ngStyle]="{'width': '32px'}" [src]="settings.backendUrl + '/assets/capability/' + ((selectedCapability.category === categories.COLLECT)?'collect2.png':'collect.png') ">
                    <label class="px-1 px-md-2">{{'spl.search.collect_shipemnt' | translate}}</label>
                </div>
            </div>
            <div *canHide="'!send&collect,capability,*send,*collect,sendAndCollect'" class="col-4" 
            [ngStyle]="{'padding-left': '6px', 'padding-right': '0px'}">
                <div [attr.tabindex]="_disabled ? '-1' : '0'" [ngClass]="'w-100 h-100 service ' + (_disabled ? 'disabled' : 'enabled') + ((selectedCapability.category === categories.SEND_COLLECT)?' selected':' not-selected')" 
                    [title]="((_disabled && selectedCapability.category === categories.NONE) ? (isaddressAnID ? ('spl.search.service_not_required' | translate) : ('spl.search.insert_location' | translate)) : '')" 
                    (click)="selectCapability(categories.SEND_COLLECT)" data-test_id="click_send_collect">
                    <img aria-label="Click here to select a service: send and collect shipment option, 3 of 3 option" class="service-img" [ngStyle]="{'width': '58px'}" [src]="settings.backendUrl + '/assets/capability/' + ((selectedCapability.category === categories.SEND_COLLECT)?'sc2.png':'sc.png') ">
                    <label class="px-1 px-md-2">{{'spl.search.collect_send_shipment' | translate}}</label>
                </div>
            </div>
        </div>
    </div>
    <div class="col mx-auto px-0 d-none d-md-flex"></div>
</div>

<!--div class="row">
    <div class="col mx-auto px-0 d-none d-md-flex"></div>
    <div *canHide="'capability,send'"
        [ngStyle]="{ 'background-image': 'url(' + settings.backendUrl + '/assets/capability/' + ((selectedCapability.category === categories.SEND)?'send_red.svg':'send.svg') + ')' }" 
        [ngClass]="'col-auto capability-button ' + (_disabled ? 'disabled' : 'enabled')" 
        style="width: 120px; height: 110px;"
        (click)="selectCapability(categories.SEND)" 
        [title]="_disabled? ('spl.search.insert_location' | translate) : ''">
    </div>
    <div *canHide="'capability,collect'"
        [ngStyle]="{ 'background-image': 'url(' + settings.backendUrl + '/assets/capability/' + ((selectedCapability.category === categories.COLLECT)?'collect_red.svg':'collect.svg') + ')' }" 
        [ngClass]="'col-auto mx-2 capability-button ' + (_disabled ? 'disabled' : 'enabled')" 
        style="width: 120px; height: 110px;"
        (click)="selectCapability(categories.COLLECT)" 
        [title]="_disabled? ('spl.search.insert_location' | translate) : ''">
    </div>
    <div *canHide="'!send&collect,capability,*send,*collect,sendAndCollect'"
        [ngStyle]="{ 'background-image': 'url(' + settings.backendUrl + '/assets/capability/' + ((selectedCapability.category === categories.SEND_COLLECT)?'sc_red.svg':'sc.svg') + ')' }" 
        [ngClass]="'col-auto capability-button ' + (_disabled ? 'disabled' : 'enabled')" 
        style="width: 120px; height: 110px;"
        (click)="selectCapability(categories.SEND_COLLECT)" 
        [title]="_disabled? ('spl.search.insert_location' | translate) : ''">
    </div>
    <div class="col mx-auto px-0 d-none d-md-flex"></div>
</div-->

<!--div class="flex-stuff" style="align-items: flex-start">
    <div *canHide="'capability,send'" class="capability-container">
        <div class="labeled-button">
            
        </div>
        <app-capability-detail [name]="'spl.search.send_shipment' | translate"
                               [items]="sendSecondLevel"
                               [selectedSecondLevel]="selectedCapability.sendCode"
                               [disabled]="disabled"
                               *ngIf="selectedCapability.secondLevelEnabled && !isMobile() && selectedCapability.category == categories.SEND"
                               (onSelected)="selectSecondLevelSend($event)"
        ></app-capability-detail>
    </div>

    <div *canHide="'capability,collect'" class="capability-container">
        <div class="labeled-button">
            
            <label *ngIf="selectedCapability.showCollectLabel && !isMobile()">
                <span class="ie-fix">{{'spl.search.collect_shipemnt' | translate}}</span>
            </label>
        </div>
        <app-capability-detail [name]="'spl.search.collect_shipemnt' | translate"
                               [items]="collectSecondLevel"
                               [selectedSecondLevel]="selectedCapability.collectCode"
                               [disabled]="disabled"
                               *ngIf="selectedCapability.secondLevelEnabled && !isMobile() && selectedCapability.category == categories.COLLECT"
                               (onSelected)="selectSecondLevelCollect($event)"
        ></app-capability-detail>

    </div>

    <div *canHide="'!send&collect,capability,*send,*collect,sendAndCollect'" class="capability-container">
        <div class="labeled-button">
            
            <label *ngIf="selectedCapability.showSendAndCollectLabel && !isMobile()">
                <span class="ie-fix">{{'spl.search.collect_send_shipment' | translate}}</span>
            </label>
        </div>
        <div style="display: inline-block" class="capability-detail-container"
             *ngIf="!isMobile() && selectedCapability.secondLevelEnabled && selectedCapability.category == categories.SEND_COLLECT">
            <app-capability-detail [name]="'spl.search.send_shipment' | translate"
                                   [items]="sendSecondLevel"
                                   [selectedSecondLevel]="selectedCapability.sendCode"
                                   [disabled]="disabled"
                                   (onSelected)="selectSecondLevelSend($event)"></app-capability-detail>
            <br/>
            <app-capability-detail [name]="'spl.search.collect_shipemnt' | translate"
                                   [items]="collectSecondLevel"
                                   [selectedSecondLevel]="selectedCapability.collectCode"
                                   [disabled]="disabled"
                                   (onSelected)="selectSecondLevelCollect($event)"></app-capability-detail>
        </div>

    </div>

</div-->

<!-- This div handles the send, collect, send and collect for the second level countries. In this case, dropdown has to be displayed.-->
<div aria-disabled="true" class="row mt-3 d-none d-md-flex" *ngIf="selectedCapability.secondLevelEnabled && selectedCapability.category !== categories.NONE">
    <div class="col-3"></div>
    <div class="col-6">
        <div class="row">
            <!-- This div handles the send or send & collect for the second level countries. Label Free -->
            <div class="col-6 pl-0 pr-3" *ngIf="(selectedCapability.isLabelFree && selectedCapability.category == categories.SEND) || selectedCapability.category == categories.SEND_COLLECT">
                <!--[tabIndex]="selectedCapability.payment.value!='0' ? '-1' : '0'"-->
                <ng-select id="dropdown_payment_select" [tabIndex]="-1" [inputAttrs]="{'aria-labelledby':'paymentValue1'}" [formControl]="selectedCapability.payment" [items]="selectedCapability.paymentOptions" aria-label="payment option"
                    [searchable]="false" bindValue="code" bindLabel="label" [clearable]="false"
                    (change)="selectedCapability.onPaymentChange($event)" labelForId="dropdown_payment">
                    <!--ng-template ng-label-tmp let-item="item">
                        <span style="width:0px">{{ item.code === '0' ? ('--- ' + (item.labelKey | translate) + ' ---') : (item.labelKey | translate) }}</span>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                        <span [ngClass]="selectedCapability.service.value === item.code ? 'font-weight-bold' : ''" style="width:0px">{{ item.code === '0' ? ('--- ' + (item.labelKey | translate) + ' ---') : (item.labelKey | translate) }}</span>
                    </ng-template-->
                    <ng-template ng-label-tmp let-item="item">
                        <div class="capability">
                            <div tabindex="0" class="ng-value capab">
                                <div [ngClass]="'capabplaceholder'" *ngIf="item.code!='0'">
                                    {{"spl.search.choose_payment_option" | translate}}
                                </div>
                                <div id="paymentValue1" [ngClass]="item.code!='0'?'capabvalue':'capabvaluedefault'">
                                    {{item.labelKey | translate}}
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                        <span [ngClass]="selectedCapability.service.value === item.code ? 'font-weight-bold' : ''" [ngStyle]="{'width':'0px'}">{{ item.code === '0' ? ('--- ' + (item.labelKey | translate) + ' ---') : (item.labelKey | translate) }}</span>
                    </ng-template>
                </ng-select>
            </div>
            <!-- This div handles the send for the second level countries. Non Label Free -->
            <div class="col" *ngIf="!selectedCapability.isLabelFree && selectedCapability.category == categories.SEND">
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-6 px-4">
                        <ng-select id="dropdown_payment_SEND_select" [tabIndex]="-1" [formControl]="selectedCapability.payment" [items]="selectedCapability.paymentOptions" aria-label="payment option"
                            [searchable]="false" bindValue="code" bindLabel="label" [clearable]="false" [inputAttrs]="{'aria-labelledby':'paymentValue2'}"
                            (change)="selectedCapability.onPaymentChange($event)" labelForId="dropdown_payment_SEND">
                            <!--ng-template ng-label-tmp let-item="item">
                                <span style="width:0px">{{ item.code === '0' ? ('--- ' + (item.labelKey | translate) + ' ---') : (item.labelKey | translate) }}</span>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item">
                                <span [ngClass]="selectedCapability.service.value === item.code ? 'font-weight-bold' : ''" style="width:0px">{{ item.code === '0' ? ('--- ' + (item.labelKey | translate) + ' ---') : (item.labelKey | translate) }}</span>
                            </ng-template-->
                            <ng-template ng-label-tmp let-item="item">
                                <div class="capability">
                                    <div tabindex=0 class="ng-value capab">
                                        <div [ngClass]="'capabplaceholder'" *ngIf="item.code!='0'">
                                            {{"spl.search.choose_payment_option" | translate}}
                                        </div>
                                        <div id="paymentValue2" [ngClass]="item.code!='0'?'capabvalue':'capabvaluedefault'">
                                            {{item.labelKey | translate}}
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item">
                                <span [ngClass]="selectedCapability.service.value === item.code ? 'font-weight-bold' : ''" [ngStyle]="{'width':'0px'}">{{ item.code === '0' ? ('--- ' + (item.labelKey | translate) + ' ---') : (item.labelKey | translate) }}</span>
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="col-3"></div>
                </div>
            </div>
            <!-- This div handles the send for the second level countries. Label Free -->
            <div class="col-6 pl-3 pr-0" *ngIf="selectedCapability.isLabelFree && selectedCapability.category == categories.SEND">
                <ng-select id="dropdown_handling_SEND_select" [tabIndex]="-1" [formControl]="selectedCapability.handling" [items]="selectedCapability.handlingOptions" aria-label=" handling option"
                    [searchable]="false" bindValue="code" bindLabel="label" [clearable]="false" labelForId="dropdown_handling_SEND"
                    (change)="selectedCapability.onHandlingChange($event)" [inputAttrs]="{'aria-labelledby':'handlingValue1'}">
                    <!--ng-template ng-label-tmp let-item="item">
                        <span style="width:0px">{{ item.code === '0' ? ('--- ' + (item.labelKey | translate) + ' ---') : (item.labelKey | translate) }}</span>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                        <span [ngClass]="selectedCapability.service.value === item.code ? 'font-weight-bold' : ''" style="width:0px">{{ item.code === '0' ? ('--- ' + (item.labelKey | translate) + ' ---') : (item.labelKey | translate) }}</span>
                    </ng-template-->
                    <ng-template ng-label-tmp let-item="item">
                        <div class="capability">
                            <div tabindex=0 class="ng-value capab">
                                <div [ngClass]="'capabplaceholder'" *ngIf="item.code!='0'">
                                    {{"spl.search.choose_handling_option" | translate}}
                                </div>
                                <div id="handlingValue1" [ngClass]="item.code!='0'?'capabvalue':'capabvaluedefault'">
                                    {{item.labelKey | translate}}
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                        <span [ngClass]="selectedCapability.service.value === item.code ? 'font-weight-bold' : ''" [ngStyle]="{'width':'0px'}">{{ item.code === '0' ? ('--- ' + (item.labelKey | translate) + ' ---') : (item.labelKey | translate) }}</span>
                    </ng-template>
                </ng-select>
            </div>
            <!-- This div handles the collect for the second level countries. Label Free -->
            <div class="col" *ngIf="selectedCapability.category == categories.COLLECT">
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-6 px-4">
                        <ng-select id="dropdown_collect_COLLECT_select" [tabIndex]="-1" [formControl]="selectedCapability.collect" [items]="selectedCapability.collectOptions" [inputAttrs]="{'aria-labelledby':'collectValue1'}" aria-label="collect option"
                            [searchable]="false" bindValue="code" bindLabel="label" [clearable]="false" labelForId="dropdown_collect_COLLECT" (change)="selectedCapability.onCollectChange($event)">
                            <!--ng-template ng-label-tmp let-item="item">
                                <span style="width:0px">{{ item.code === '0' ? ('--- ' + (item.labelKey | translate) + ' ---') : (item.labelKey | translate) }}</span>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item">
                                <span [ngClass]="selectedCapability.service.value === item.code ? 'font-weight-bold' : ''" style="width:0px">{{ item.code === '0' ? ('--- ' + (item.labelKey | translate) + ' ---') : (item.labelKey | translate) }}</span>
                            </ng-template-->
                            <ng-template ng-label-tmp let-item="item">
                                <div class="capability">
                                    <div tabindex="0" class="ng-value capab">
                                        <div [ngClass]="'capabplaceholder'" *ngIf="item.code!='0'">
                                            {{"spl.search.choose_collection_option" | translate}}
                                        </div>
                                        <div id="collectValue1" [ngClass]="item.code!='0'?'capabvalue':'capabvaluedefault'">
                                            {{item.labelKey | translate}}
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item">
                                <span [ngClass]="selectedCapability.service.value === item.code ? 'font-weight-bold' : ''" [ngStyle]="{'width':'0px'}">{{ item.code === '0' ? ('--- ' + (item.labelKey | translate) + ' ---') : (item.labelKey | translate) }}</span>
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="col-3"></div>
                </div>
            </div>
            <!-- This div handles the send and collect for the second level countries. Label Free and Non Label Free-->
            <div class="col-6 pl-3 pr-0" *ngIf="selectedCapability.category == categories.SEND_COLLECT">
                <ng-select id="dropdown_collect_SEND_COLLECT_select" [tabIndex]="-1" [formControl]="selectedCapability.collect" [items]="selectedCapability.collectOptions"
                    [searchable]="false" [inputAttrs]="{'aria-labelledby':'collectValue2'}" aria-label="collect option"
                    bindValue="code" bindLabel="label" [clearable]="false" labelForId="dropdown_collect_SEND_COLLECT" (change)="selectedCapability.onCollectChange($event)">
                    <!--ng-template ng-label-tmp let-item="item">
                        <span style="width:0px">{{ item.code === '0' ? ('--- ' + (item.labelKey | translate) + ' ---') : (item.labelKey | translate) }}</span>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                        <span [ngClass]="selectedCapability.service.value === item.code ? 'font-weight-bold' : ''" style="width:0px">{{ item.code === '0' ? ('--- ' + (item.labelKey | translate) + ' ---') : (item.labelKey | translate) }}</span>
                    </ng-template-->
                    <ng-template ng-label-tmp let-item="item">
                        <div class="capability">
                            <div tabindex=0 class="ng-value capab">
                                <div [ngClass]="'capabplaceholder'" *ngIf="item.code!='0'">
                                    {{"spl.search.choose_collection_option" | translate}}
                                </div>
                                <div id="collectValue2" [ngClass]="item.code!='0'?'capabvalue':'capabvaluedefault'">
                                    {{item.labelKey | translate}}
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                        <span [ngClass]="selectedCapability.service.value === item.code ? 'font-weight-bold' : ''" [ngStyle]="{'width':'0px'}">{{ item.code === '0' ? ('--- ' + (item.labelKey | translate) + ' ---') : (item.labelKey | translate) }}</span>
                    </ng-template>
                </ng-select>
            </div>
        </div>
        <!-- This div handles the send and collect for the second level countries. Label Free-->
        <div class="row mt-3" *ngIf="selectedCapability.isLabelFree && selectedCapability.category == categories.SEND_COLLECT">
            <div class="col-6 pl-0 pr-3">
                <ng-select id="dropdown_handling_SEND_COLLECT_select" [tabIndex]="-1" [formControl]="selectedCapability.handling" [items]="selectedCapability.handlingOptions" aria-label="handling option"
                    [searchable]="false" bindValue="code" bindLabel="label" [clearable]="false" labelForId="dropdown_handling_SEND_COLLECT" 
                    (change)="selectedCapability.onHandlingChange($event)" [inputAttrs]="{'aria-labelledby':'handlingValue2'}">
                    <!--ng-template ng-label-tmp let-item="item">
                        <span style="width:0px">{{ item.code === '0' ? ('--- ' + (item.labelKey | translate) + ' ---') : (item.labelKey | translate) }}</span>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                        <span [ngClass]="selectedCapability.service.value === item.code ? 'font-weight-bold' : ''" style="width:0px">{{ item.code === '0' ? ('--- ' + (item.labelKey | translate) + ' ---') : (item.labelKey | translate) }}</span>
                    </ng-template-->
                    <ng-template ng-label-tmp let-item="item">
                        <div class="capability">
                            <div tabindex=0 class="ng-value capab">
                                <div [ngClass]="'capabplaceholder'" *ngIf="item.code!='0'">
                                    {{"spl.search.choose_handling_option" | translate}}
                                </div>
                                <div id="handlingValue2" [ngClass]="item.code!='0'?'capabvalue':'capabvaluedefault'">
                                    {{item.labelKey | translate}}
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                        <span [ngClass]="selectedCapability.service.value === item.code ? 'font-weight-bold' : ''" [ngStyle]="{'width':'0px'}">{{ item.code === '0' ? ('--- ' + (item.labelKey | translate) + ' ---') : (item.labelKey | translate) }}</span>
                    </ng-template>
                </ng-select>
            </div>
            <div class="col-6"></div>
        </div>
    </div>
    <div class="col-3"></div>
</div>

<!-- This div handles the send, collect, send and collect for the second level countries. In this case, dropdown has to be displayed.-->
<div aria-disabled="true" class="row mt-3 d-md-none" *ngIf="selectedCapability.secondLevelEnabled && selectedCapability.category !== categories.NONE">
    <div class="col">
        <div class="row">
            <div class="col px-0 mb-3" [ngStyle]="{'width': '1px'}" *ngIf="selectedCapability.category == categories.SEND || selectedCapability.category == categories.SEND_COLLECT">
                <ng-select id="dropdown_payment_SEND_COLLECT_select" [tabIndex]="-1" [formControl]="selectedCapability.payment" [items]="selectedCapability.paymentOptions" aria-label="payment option"
                    [searchable]="false" bindValue="code" bindLabel="label" [clearable]="false" [inputAttrs]="{'aria-labelledby':'paymentValue3'}"
                    (change)="selectedCapability.onPaymentChange($event)" labelForId="dropdown_payment_SEND_COLLECT">
                    <!--ng-template ng-label-tmp let-item="item">
                        <span style="width:0px">{{ item.code === '0' ? ('--- ' + (item.labelKey | translate) + ' ---') : (item.labelKey | translate) }}</span>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                        <span [ngClass]="selectedCapability.service.value === item.code ? 'font-weight-bold' : ''" style="width:0px">{{ item.code === '0' ? ('--- ' + (item.labelKey | translate) + ' ---') : (item.labelKey | translate) }}</span>
                    </ng-template-->
                    <ng-template ng-label-tmp let-item="item">
                        <div class="capability">
                            <div tabindex=0 class="ng-value capab">
                                <div [ngClass]="'capabplaceholder'" *ngIf="item.code!='0'">
                                    {{"spl.search.choose_payment_option" | translate}}
                                </div>
                                <div id="paymentValue3" [ngClass]="item.code!='0'?'capabvalue':'capabvaluedefault'">
                                    {{item.labelKey | translate}}
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                        <span [ngClass]="selectedCapability.service.value === item.code ? 'font-weight-bold' : ''" [ngStyle]="{'width':'0px'}">{{ item.code === '0' ? ('--- ' + (item.labelKey | translate) + ' ---') : (item.labelKey | translate) }}</span>
                    </ng-template>
                </ng-select>
            </div>
        </div>
        <div *ngIf="selectedCapability.isLabelFree" class="row">
            <div class="col px-0 mb-3" [ngStyle]="{'width': '1px'}" *ngIf="selectedCapability.category == categories.SEND || selectedCapability.category == categories.SEND_COLLECT">
                <ng-select id="dropdown_handling_select" [tabIndex]="-1" [formControl]="selectedCapability.handling" [items]="selectedCapability.handlingOptions" [inputAttrs]="{'aria-labelledby':'handlingValue3'}"
                    [searchable]="false" bindValue="code" bindLabel="label" [clearable]="false" labelForId="dropdown_handling" aria-label="handling option">
                    <!--ng-template ng-label-tmp let-item="item">
                        <span style="width:0px">{{ item.code === '0' ? ('--- ' + (item.labelKey | translate) + ' ---') : (item.labelKey | translate) }}</span>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                        <span [ngClass]="selectedCapability.service.value === item.code ? 'font-weight-bold' : ''" style="width:0px">{{ item.code === '0' ? ('--- ' + (item.labelKey | translate) + ' ---') : (item.labelKey | translate) }}</span>
                    </ng-template-->
                    <ng-template ng-label-tmp let-item="item">
                        <div class="capability">
                            <div tabindex=0 class="ng-value capab">
                                <div [ngClass]="'capabplaceholder'" *ngIf="item.code!='0'">
                                    {{"spl.search.choose_handling_option" | translate}}
                                </div>
                                <div id="handlingValue3" [ngClass]="item.code!='0'?'capabvalue':'capabvaluedefault'">
                                    {{item.labelKey | translate}}
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                        <span [ngClass]="selectedCapability.service.value === item.code ? 'font-weight-bold' : ''" [ngStyle]="{'width':'0px'}">{{ item.code === '0' ? ('--- ' + (item.labelKey | translate) + ' ---') : (item.labelKey | translate) }}</span>
                    </ng-template>
                </ng-select>
            </div>
        </div>
        <div class="row">
            <div class="col px-0" [ngStyle]="{'width': '1px'}" *ngIf="selectedCapability.category == categories.COLLECT || selectedCapability.category == categories.SEND_COLLECT">
                <ng-select id="dropdown_collect_select" [tabIndex]="-1" [formControl]="selectedCapability.collect" [items]="selectedCapability.collectOptions"
                    [searchable]="false" [inputAttrs]="{'aria-labelledby':'collectValue3'}" aria-label="collect option"
                    bindValue="code" bindLabel="label" [clearable]="false" labelForId="dropdown_collect" >
                    <!--ng-template ng-label-tmp let-item="item">
                        <span style="width:0px">{{ item.code === '0' ? ('--- ' + (item.labelKey | translate) + ' ---') : (item.labelKey | translate) }}</span>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                        <span [ngClass]="selectedCapability.service.value === item.code ? 'font-weight-bold' : ''" style="width:0px">{{ item.code === '0' ? ('--- ' + (item.labelKey | translate) + ' ---') : (item.labelKey | translate) }}</span>
                    </ng-template-->
                    <ng-template ng-label-tmp let-item="item">
                        <div class="capability">
                            <div tabindex=0 class="ng-value capab">
                                <div [ngClass]="'capabplaceholder'" *ngIf="item.code!='0'">
                                    {{"spl.search.choose_collection_option" | translate}}
                                </div>
                                <div id="collectValue3" [ngClass]="item.code!='0'?'capabvalue':'capabvaluedefault'">
                                    {{item.labelKey | translate}}
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                        <span [ngClass]="selectedCapability.service.value === item.code ? 'font-weight-bold' : ''" [ngStyle]="{'width':'0px'}">{{ item.code === '0' ? ('--- ' + (item.labelKey | translate) + ' ---') : (item.labelKey | translate) }}</span>
                    </ng-template>
                </ng-select>
            </div>
        </div>
    </div>
</div>


<!--div *ngIf="isMobile()" [ngClass]="isMobile() ? 'flex-stuff' : ''">
    <div *canHide="'capability,send'">
        <div *ngIf="selectedCapability.secondLevelEnabled" class="capability-detail-container">
            <app-capability-detail [name]="'spl.search.send_shipment' | translate"
                                   [items]="sendSecondLevel"
                                   [selectedSecondLevel]="selectedCapability.sendCode"
                                   [disabled]="disabled"
                                   *ngIf="selectedCapability.category == categories.SEND"
                                   (onSelected)="selectSecondLevelSend($event)"
                                   [hidden]="sendSecondLevel.length === 0"
            ></app-capability-detail>
        </div>
        <div class="spacer1" [hidden]="!selectedCapability.secondLevelEnabled || selectedCapability.category != categories.SEND"></div>
    </div>
    <div *canHide="'capability,sendAndCollect'">
        <div style="display: inline-block" class="capability-detail-container"
             *ngIf="selectedCapability.secondLevelEnabled && selectedCapability.category == categories.SEND_COLLECT">
            <app-capability-detail [name]="'spl.search.send_shipment' | translate"
                                   [items]="sendSecondLevel"
                                   [selectedSecondLevel]="selectedCapability.sendCode"
                                   [disabled]="disabled"
                                   (onSelected)="selectSecondLevelSend($event)"></app-capability-detail>
            <br/>
            <app-capability-detail [name]="'spl.search.collect_shipemnt' | translate"
                                   [items]="collectSecondLevel"
                                   [selectedSecondLevel]="selectedCapability.collectCode"
                                   [disabled]="disabled"
                                   (onSelected)="selectSecondLevelCollect($event)"></app-capability-detail>
        </div>
        <div class="spacer2" [hidden]="!selectedCapability.secondLevelEnabled || selectedCapability.category != categories.SEND_COLLECT"></div>
    </div>
    <div *canHide="'capability,collect'">
        <div *ngIf="selectedCapability.secondLevelEnabled" class="capability-detail-container">
            <app-capability-detail [name]="'spl.search.collect_shipemnt' | translate"
                                   [items]="collectSecondLevel"
                                   [selectedSecondLevel]="selectedCapability.collectCode"
                                   [disabled]="disabled"
                                   *ngIf="selectedCapability.category == categories.COLLECT"
                                   (onSelected)="selectSecondLevelCollect($event)"
                                   [hidden]="collectSecondLevel.length === 0"
            ></app-capability-detail>
        </div>
        <div class="spacer1" [hidden]="!selectedCapability.secondLevelEnabled || selectedCapability.category != categories.COLLECT"></div>
    </div>
</div-->