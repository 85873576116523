import {Component, OnDestroy, OnInit} from '@angular/core';
import {SettingsService} from '../settings.service';
import {Utils} from '../common/utils';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-cookie-consent',
    templateUrl: './cookie-consent.component.html',
    styleUrls: ['./cookie-consent.component.scss']
})
export class CookieConsentComponent implements OnInit, OnDestroy {

    isMobile = Utils.isMobile;
    defaultLink = 'https://www.logistics.dhl/global-en/home/footer/global-privacy-notice.html';
    link: string;
    subscriptions = [];

    constructor(public settings: SettingsService, private route: ActivatedRoute) {
        if (settings.cookieCheckDisabled || location.href.includes('directions')) {
            this.hidden = true;
        }
        this.settings.cookiesAllowed = true;
        this.subscriptions.push(settings.languageCountryChanged.subscribe((lc) => {
            this.link = lc.cookiesLink || this.defaultLink;
        }));
    }

    hidden = false;

    ngOnInit() {
        this.link = this.settings.languageCountry.cookiesLink || this.defaultLink;
    }

    onAgree() {
        this.settings.cookiesAllowed = true;
    }

    onHide() {
        this.hidden = true;
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
