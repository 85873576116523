import { Params } from '@angular/router';

export class Hide {

    capability: boolean = false;
    dropOff: boolean = false;
    collection: boolean = false;
    mandatoryCapability: MandatoryCapability;
    pieceLimitation: boolean = false;
    openingHours: boolean = false;
    selectCountry: boolean = false;
    yourLocation: boolean = false;
    back: boolean = false;//523702

    constructor() {
    }

    initialize(queryParams: Params) {
        const hide: string = queryParams ? queryParams['hide'] : null;
        if (hide) {
            const hideArray = hide.split(',');
            if (hideArray.includes('capability')) {
                this.capability = true;
                this.dropOff = true;
                this.collection = true;
                this.mandatoryCapability = MandatoryCapability.NONE;
            } else if (hideArray.includes('send') && hideArray.includes('collect')) {
                this.mandatoryCapability = MandatoryCapability.SEND_AND_COLLECT;
            } else if (hideArray.includes('sendAndCollect')) {
                this.mandatoryCapability = MandatoryCapability.EITHER_SEND_OR_COLLECT;
            } else if (hideArray.includes('send')) {
                this.dropOff = true;
            } else if (hideArray.includes('collect')) {
                this.collection = true;
            }
            if (hideArray.includes('pieceLimitation')) {
                this.pieceLimitation = true;
            }
            if (hideArray.includes('openingHours')) {
                this.openingHours = true;
            }
            if (hideArray.includes('selectCountry')) {
                this.selectCountry = true;
            }
            if (hideArray.includes('yourLocation')) {
                this.yourLocation = true;
            }
            if(hideArray.includes('back')){
                this.back = true;
            }
        }
        if (!this.mandatoryCapability) {
            this.mandatoryCapability = MandatoryCapability.DEFAULT_SEND_OR_COLLECT;
        }
    }
}

export enum MandatoryCapability {
    DEFAULT_SEND_OR_COLLECT = 0,
    NONE = 1,
    SEND_AND_COLLECT = 2,
    EITHER_SEND_OR_COLLECT = 3
}
