<div class="container-fluid">
    <div class="row supported-browser-header">
        <div class="col-2">&nbsp;</div>
        <div class="col-8 text-center"><i class="material-icons-outlined">report_problem</i>&nbsp;Browser not supported</div>
        <div class="col-2 supported-browser-header-close"><i class="material-icons" [ngStyle]="{'float': 'right'}" (click)="closed.emit()">cancel</i></div>
    </div>
    <hr/>
    <div class="row justify-content-center">
        <div class="col-auto text-center">{{'spl.unsupported.browsers.text' | translate}}</div>
    </div>
    <div class="row supported-browser-list justify-content-center">
        <div class="col-auto" *ngFor="let supportedBrowser of supportedBrowsers">
            <div class="row justify-content-center"><img [src]="'assets/browser-icons/' + supportedBrowser.iconFile"></div>
            <div class="row justify-content-center">{{supportedBrowser.browserNameToDisplay}}</div>
            <div class="row justify-content-center">version&nbsp;{{supportedBrowser.version.split(',')[0]}}</div>
        </div>
    </div>
</div>