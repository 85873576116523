import { UntypedFormControl } from '@angular/forms';
import { Capability, Country } from '../backend.service';
import { SettingsService } from '../settings.service';
import { Params } from '@angular/router';
import { Utils } from './utils';
import { Hide, MandatoryCapability } from './hide';

declare const window: any;

export class CapabilitySelection {

    secondLevelEnabled: boolean;
    defaultSecondLevelEnabled: boolean;
    category: CapabilityCategory;
    service: UntypedFormControl;
    serviceOptions: Array<Capability> = [];
    payment: UntypedFormControl;
    paymentOptions: Array<Capability>;
    allPaymentCode: string[];
    handling: UntypedFormControl;
    handlingOptions: Array<Capability> = [];
    collect: UntypedFormControl;
    collectOptions: Array<Capability>;
    allCollectCode: string[];
    isFreeze: boolean = false;
    isLabelFree: boolean;
    isPpcValidationFailed: boolean = false; // Introduced to add PPC validation 566028
    //isPaymentSelected: boolean = false; Can be removed
    //isCollectSelected: boolean = false; Can be removed

    queryParams: Params;

    //sendCode: string;
    //collectCode: string;
    //showSendLabel = false;
    //showSendAndCollectLabel = false;
    //showCollectLabel = false;

    constructor(private settings: SettingsService, private utils: Utils, public hide: Hide, defaultSecondLevelEnabled: boolean) {
        this.defaultSecondLevelEnabled = defaultSecondLevelEnabled;
        this.category = CapabilityCategory.NONE;

        this.serviceOptions.push({ code: '0', labelKey: 'spl.search.service' });
        this.serviceOptions.push({ code: 'send', labelKey: 'spl.search.send_shipment' });
        this.serviceOptions.push({ code: 'collect', labelKey: 'spl.search.collect_shipemnt' });
        this.serviceOptions.push({ code: 'send_collect', labelKey: 'spl.search.collect_send_shipment' });

        this.service = new UntypedFormControl('0');
        this.payment = new UntypedFormControl('0');
        this.handling = new UntypedFormControl('0');
        this.collect = new UntypedFormControl('0');
    }

    initialize(queryParams: Params) {
        this.isLabelFree = window.gspl.params.isLabelFree;
        this.queryParams = queryParams;
    }

    // This code might needs to be enhanced to perform better.
    /*private applyCustomCapabilities() {
        let commonPayment: Array<Capability> = [];
        let commonCollect: Array<Capability> = []; 
        this.settings.paymentOptions.forEach(item=>commonPayment.push(Object.assign({}, item)));
        this.settings.collectOptions.forEach(item=>commonCollect.push(Object.assign({}, item)));
        this.paymentOptions = this.compareAndPopulateValues(commonPayment, this.settings.selectedCountry.capabilityCustom.payments);
        this.collectOptions = this.compareAndPopulateValues(commonCollect, this.settings.selectedCountry.capabilityCustom.collects);
    }*/

    // This code might needs to be enhanced to perform better.
    /*private compareAndPopulateValues(collectOrPayment:Array<Capability>, collectOrPaymentCustom:Array<Capability>): Array<Capability>{
        let customPaymentOrCollectOptions: Array<Capability> = [];
        let handlingDefaultCapab;
        customPaymentOrCollectOptions.push(collectOrPayment.filter(item => '0' === item.code)[0]);
        collectOrPaymentCustom.forEach(customItem => {
            let filteredCapability = collectOrPayment.filter(item => customItem.code === item.code)[0];
            if (customItem.downstreams) {
                if(filteredCapability){
                    let customHandlingOptions: Array<Capability> = [];
                    handlingDefaultCapab = filteredCapability.downstreams.filter(item => '0' === item.code)[0];
                    customHandlingOptions.push(handlingDefaultCapab);
                    customItem.downstreams.forEach(handling => {
                        let filteredhandling = filteredCapability.downstreams.filter(item => handling.code === item.code)[0];
                        if(filteredhandling){
                            customHandlingOptions.push(filteredhandling);
                        } else {
                            this.settings.getTranslatedValue(handling.labelKey).subscribe (val => {
                                handling.label = val;
                                customHandlingOptions.push(handling);
                            });
                        }
                    });
                    filteredCapability.downstreams = customHandlingOptions;
                    customPaymentOrCollectOptions.push(filteredCapability);
                } else {*/
                    /*let customHandlingOptions: Array<Capability> = [];
                    this.settings.getTranslatedValue(customItem.labelKey).subscribe (val => {
                        customItem.label = val;
                    });
                    customItem.downstreams.forEach(handling => {
                        this.settings.getTranslatedValue(handling.labelKey).subscribe (val => {
                            handling.label = val;
                            customHandlingOptions.push(handling);
                        });
                    });
                    customItem.downstreams = customHandlingOptions;
                    customPaymentOrCollectOptions.push(customItem);
                    console.log("customPaymentOrCollectOptions",customPaymentOrCollectOptions);*/
                /*}
            } else {
                if(filteredCapability){
                    customPaymentOrCollectOptions.push(filteredCapability);
                } else {
                    this.settings.getTranslatedValue(customItem.labelKey).subscribe (val => {
                        customItem.label = val;
                        customPaymentOrCollectOptions.push(customItem);
                    });
                }
            }
        });
        return customPaymentOrCollectOptions;
    }*/

    setCapabilityOptions() {
        this.collectOptions = this.settings.collectOptions;
        this.paymentOptions = this.settings.paymentOptions;
        if (this.settings.selectedCountry) {
            this.secondLevelEnabled = this.settings.selectedCountry.secondLevel ? true : false;
            //if (!Utils.isJsApi()) {
                if (window.gspl.params.isLabelFree){
                    if (this.settings.selectedCountry.capabilityCustom && this.settings.selectedCountry.capabilityCustom.payments && 
                        this.settings.selectedCountry.capabilityCustom.collects &&
                        this.settings.paymentOptionsCustom && this.settings.paymentOptionsCustom.length>0
                        && this.settings.collectOptionsCustom.length>0) {
                        //this.applyCustomCapabilities();
                        this.paymentOptions = this.settings.paymentOptionsCustom;
                        this.collectOptions = this.settings.collectOptionsCustom;
                    }
                }
            //}
        } else {
            this.secondLevelEnabled = this.defaultSecondLevelEnabled;
        }
        //const capabilityException = this.settings.selectedCountry ? this.settings.selectedCountry.capabilityException : null;
        //this.paymentOptions = this.getCapabilitiesWithException(this.settings.paymentOptions, capabilityException);
        let paymentCapability: Capability;
        if (this.payment.value !== '0') {
            paymentCapability = this.paymentOptions.find(capability => capability.code === this.payment.value);
            if (paymentCapability) {
                this.payment.setValue(paymentCapability.code);
                this.handling.enable({ onlySelf: true });
            } else {
                this.payment.setValue('0');
                this.handling.disable({ onlySelf: true });
            }
        }

        if (window.gspl.params.isLabelFree && this.secondLevelEnabled) {
            if (paymentCapability) {
                this.handlingOptions = paymentCapability.downstreams;
                if (this.handling.value !== '0') {
                    const handlingCapability = this.handlingOptions.find(capability => capability.code === this.handling.value);
                    if (handlingCapability) {
                        this.handling.setValue(handlingCapability.code);
                    } else {
                        this.handling.setValue('0');
                    }
                }
            } else {
                if (!(this.handlingOptions.find(element => element.code == this.paymentOptions[1].downstreams[0].code))) {
                    this.handlingOptions.push(this.paymentOptions[1].downstreams[0]);
                }
                this.handling.setValue('0');
            }
        }

        //this.collectOptions = this.getCapabilitiesWithException(this.settings.collectOptions, capabilityException);
        
        if (this.collect.value !== '0') {
            const collectCapability = this.collectOptions.find(capability => capability.code.includes(this.collect.value));
            if (collectCapability) {
                this.collect.setValue(collectCapability.code);
                
            } else {
                this.collect.setValue('0');
            }
        }
    }

    /*private getCapabilitiesWithException(capabilities: Array<Capability>, capabilityException: string): Array<Capability> {
        if (capabilityException) {
            const codeExceptions = capabilityException.split(',');
            if (codeExceptions && codeExceptions.length > 0) {
                const newCapabilities: Array<Capability> = [];
                capabilities.forEach((capability, i) => {
                    if (i === 0) {
                        newCapabilities.push(capability);
                    } else if (!capability.code.split(',').some(code => codeExceptions.includes(code))) {
                        if (capability.downstreams) {
                            const newCapability = Object.assign<Capability, Capability>({ code: capability.code }, { code: capability.code, labelKey: capability.labelKey, label: capability.label });
                            newCapability.downstreams = this.getCapabilitiesWithException(capability.downstreams, capabilityException);
                            newCapabilities.push(newCapability);
                        } else {
                            newCapabilities.push(capability);
                        }
                    }
                });
                return newCapabilities;
            }
        }
        return capabilities;
    }*/

    resolve(fromInitialize: boolean,defaultStatus:boolean = false) {
        this.isPpcValidationFailed = false; // Introduced to add PPC validation 566028
        this.allPaymentCode = this.concatCapabilityCode(this.paymentOptions);
        this.allCollectCode = this.concatCapabilityCode(this.collectOptions);
        let allCodes: string[] = this.allPaymentCode.concat(this.allCollectCode); // Introduced to add PPC validation 566028
        const allPaymentCodeStr = this.allPaymentCode.toString();
        const allCollectCodeStr = this.allCollectCode.toString();
        const allCodesStr = allCodes.toString();
        let paymentCode = '0';
        let handlingCode = '0';
        let collectCode = '0';
        let isPrevSecondLevelEnabled = this.secondLevelEnabled;

        const capability: string = this.queryParams ? this.queryParams['capability'] : null;
        if (capability) {
            const capabilities: string[] = capability.split(',');
            if (this.secondLevelEnabled) {
                const payment = this.paymentOptions.find(payment => capabilities.includes(payment.code));
                if (payment) {
                    paymentCode = payment.code;
                    this.payment.setValue(paymentCode);
                    if (window.gspl.params.isLabelFree && payment.downstreams) {
                        var handling = undefined;
                        payment.downstreams.forEach(element => element.code.split(",").forEach(code => {
                            if(capabilities.includes(code)){
                                handling = element;
                            }
                        }));
                        //const handling = payment.downstreams.find(handling => handling.code.split(',').every(code => capabilities.includes(code)));
                        if (handling) {
                            handlingCode = handling.code;
                            this.handling.setValue(handlingCode);
                        }
                        else if (payment.downstreams && payment.downstreams.length == 2 && payment.downstreams.find(handling => handling.code === '0')) {
                            const handling = payment.downstreams.find(handling => handling.code != '0');
                            handlingCode = handling ? handling.code : '0';
                        }
                        /*if (payment && handling === undefined) { // Introduced to add PPC validation 566028
                            this.isPpcValidationFailed = true;
                        }*/
                    }
                }
                var collect = undefined;

                this.collectOptions.forEach(element => element.code.split(",").forEach(code => {
                    if(capabilities.includes(code)){
                        collect = element;
                    }
                }));

                /*const collect = this.collectOptions.find(collect => collect.code.split(',').every(code => {
                    console.log("code = ",code);
                    capabilities.includes(code)
                }));*/
                if (collect) {
                    collectCode = collect.code;
                }
                // Introduced below block to add PPC validation 566028
                if (payment === undefined && collect === undefined && capabilities && capabilities.length > 0) {
                    this.isPpcValidationFailed = true;
                }

                // Introduced below block to add PPC validation 566028
                // commented out for production issue - 80,89,88
                /*if (capabilities.filter(paramCapab => !allCodes.includes(paramCapab)) && capabilities.filter(paramCapab => !allCodes.includes(paramCapab)).length != 0) {
                    this.isPpcValidationFailed = true;
                }*/

            } else {

                if(capabilities.every(code => allCodes.includes(code))){
                    paymentCode = this.allPaymentCode.filter(pred => capabilities.includes(pred)).toString();
                    collectCode = this.allCollectCode.filter(pred => capabilities.includes(pred)).toString();
                }

                if(!paymentCode){
                    paymentCode = '0';
                }
                if(!collectCode){
                    collectCode = '0';
                }

                /*if (capabilities.every(code => this.allPaymentCode.includes(code))) {
                    paymentCode = capability;
                }
                if (capabilities.every(code => this.allCollectCode.includes(code))) {
                    collectCode = capability;
                }*/
                /*if(paymentCode==='0' && collectCode==='0'){
                    this.isPpcValidationFailed = true;
                }*/
            }
        }

        const freeze: string = this.queryParams ? this.queryParams['freeze'] : null;
        let freezeArray: string[];
        if (freeze) {
            freezeArray = freeze.split(',');
        }
        if (freezeArray && freezeArray.find(freeze => ['capability', 'sendAndCollect', 'send', 'collect'].includes(freeze))) {
            if (freezeArray.includes('capability')) {
                if (this.secondLevelEnabled) {
                    //if ((window.gspl.params.isLabelFree && paymentCode !== '0' && handlingCode !== '0' && collectCode !== '0') || 
                    if ((window.gspl.params.isLabelFree && paymentCode !== '0' && collectCode !== '0') ||
                        (!window.gspl.params.isLabelFree && paymentCode !== '0' && collectCode !== '0')) {
                        this.setValues(CapabilityCategory.SEND_COLLECT, paymentCode, handlingCode, collectCode, true);
                        //} else if ((window.gspl.params.isLabelFree && paymentCode !== '0' && handlingCode !== '0') || 
                    } else if ((window.gspl.params.isLabelFree && paymentCode !== '0') ||
                        (!window.gspl.params.isLabelFree && paymentCode !== '0')) {
                        this.setValues(CapabilityCategory.SEND, paymentCode, handlingCode, null, true);
                    } else if (collectCode !== '0') {
                        this.setValues(CapabilityCategory.COLLECT, null, null, collectCode, true);
                    }
                } else if (!this.secondLevelEnabled) {
                    if (paymentCode !== '0' && collectCode !== '0') {
                        this.setValues(CapabilityCategory.SEND_COLLECT, paymentCode, handlingCode, collectCode, true);
                    } else if (paymentCode !== '0') {
                        this.setValues(CapabilityCategory.SEND, paymentCode, handlingCode, null, true);
                    } else if (collectCode !== '0') {
                        this.setValues(CapabilityCategory.COLLECT, null, null, collectCode, true);
                    }
                }
                //this.resolveCapabilityQueryParams(paymentCode, handlingCode, collectCode, true);
            } else if (freezeArray.includes('sendAndCollect')) {
                if (this.secondLevelEnabled &&
                    //((window.gspl.params.isLabelFree && paymentCode !== '0' && handlingCode !== '0' && collectCode !== '0') || 
                    ((window.gspl.params.isLabelFree && paymentCode !== '0' && collectCode !== '0') ||
                        (!window.gspl.params.isLabelFree && paymentCode !== '0' && collectCode !== '0'))
                ) {
                    this.setValues(CapabilityCategory.SEND_COLLECT, paymentCode, handlingCode, collectCode, true);
                } else if (!this.secondLevelEnabled) {
                    this.setValues(CapabilityCategory.SEND_COLLECT, allPaymentCodeStr, null, allCollectCodeStr, true);
                }
            } else if (freezeArray.includes('send')) {
                if (this.secondLevelEnabled &&
                    //((window.gspl.params.isLabelFree && paymentCode !== '0' && handlingCode !== '0') || 
                    ((window.gspl.params.isLabelFree && paymentCode !== '0') ||
                        (!window.gspl.params.isLabelFree && paymentCode !== '0'))
                ) {
                    this.setValues(CapabilityCategory.SEND, paymentCode, handlingCode, null, true);
                } else if (!this.secondLevelEnabled) {
                    this.setValues(CapabilityCategory.SEND, allPaymentCodeStr, null, null, true);
                }
            } else if (freezeArray.includes('collect')) {
                if (this.secondLevelEnabled && collectCode !== '0') {
                    this.setValues(CapabilityCategory.COLLECT, null, null, collectCode, true);
                } else if (!this.secondLevelEnabled) {
                    this.setValues(CapabilityCategory.COLLECT, null, null, allCollectCodeStr, true);
                }
            }
        }

        //this.resolveCapabilityQueryParams(paymentCode, handlingCode, collectCode);
        if (!this.isFreeze) {
            if (this.category === CapabilityCategory.NONE) {
                 if ((!Utils.isJsApi() && !fromInitialize) || defaultStatus) {
                    paymentCode = '0';
                    handlingCode = '0';
                    collectCode = '0';
                }
                if ((paymentCode !== '0' && collectCode !== '0') || this.hide.mandatoryCapability === MandatoryCapability.SEND_AND_COLLECT) {
                    if (this.hide.dropOff) {
                        this.setValues(CapabilityCategory.COLLECT, null, null, collectCode);
                    } else if (this.hide.collection) {
                        this.setValues(CapabilityCategory.SEND, paymentCode, handlingCode, null);
                    } else {
                        this.setValues(CapabilityCategory.SEND_COLLECT, paymentCode, handlingCode, collectCode);
                    }
                } else if (paymentCode !== '0' || this.hide.collection) {
                    this.setValues(CapabilityCategory.SEND, paymentCode, handlingCode, null);
                } else if (collectCode !== '0' || this.hide.dropOff) {
                    this.setValues(CapabilityCategory.COLLECT, null, null, collectCode);
                } else {
                    this.payment.setValue('0');
                    if (window.gspl.params.isLabelFree) {
                        this.handling.setValue('0');
                        this.handling.disable({ onlySelf: true });
                    }
                    this.collect.setValue('0');
                }
            } else {
                if (this.secondLevelEnabled) {
                    if (isPrevSecondLevelEnabled && !defaultStatus) {
                            paymentCode = this.payment.value;
                            handlingCode = this.handling.value;
                            collectCode = this.collect.value;
                    } else {
                        paymentCode = '0';
                        handlingCode = '0';
                        collectCode = '0';
                    }
                } else {
                    if(defaultStatus){
                        paymentCode = '0';
                        handlingCode = '0';
                        collectCode = '0';
                        this.category = CapabilityCategory.NONE;
                    } else {
                        if (this.category === CapabilityCategory.SEND) {
                            paymentCode = capability;
                        } else if (this.category === CapabilityCategory.SEND_COLLECT) {
                            paymentCode = capability;
                            collectCode = capability;
                        } else if (this.category === CapabilityCategory.COLLECT) {
                            collectCode = capability;
                        }
                    }
                }
            }

            /*if (this.category === CapabilityCategory.NONE) {
                if (paymentCode !== '0' && collectCode !== '0') {
                    this.setValues(CapabilityCategory.SEND_COLLECT, paymentCode, handlingCode, collectCode);
                } else if (paymentCode !== '0') {
                    this.setValues(CapabilityCategory.SEND, paymentCode, handlingCode, null);
                } else if (collectCode !== '0') {
                    this.setValues(CapabilityCategory.COLLECT, null, null, collectCode);
                }
            } else {*/
                if (this.secondLevelEnabled) {
                    var qrcodeInUrlStatus : boolean = false;
                    if(this.settings.ppcCodesInUrl){
                        this.settings.ppcCodesInUrl.split(",").forEach(element => {
                            if(this.settings.appConfig.grefQRCodePPCCodes.includes(element)){
                                qrcodeInUrlStatus = true;
                            }
                        });
                    }
                    if(this.queryParams && this.queryParams['defaultQrCode'] 
                    && this.queryParams['defaultQrCode'].toLowerCase() =='y' 
                    && this.settings.isResultPage()
                    && qrcodeInUrlStatus){
                        this.setValues(null, paymentCode, this.settings.appConfig.grefQRCodePPCCodes, collectCode);
                    } else {
                        this.setValues(null, paymentCode, handlingCode, collectCode);
                    }
                } else {
                    if (this.category === CapabilityCategory.SEND) {
                        this.setValues(null, allPaymentCodeStr, null, null);
                    } else if (this.category === CapabilityCategory.SEND_COLLECT) {
                        this.setValues(null, allPaymentCodeStr, null, allCollectCodeStr);
                    } else if (this.category === CapabilityCategory.COLLECT) {
                        this.setValues(null, null, null, allCollectCodeStr);
                    } else {
                        this.setValues(this.category, null, null, '0');
                    }
                }
            //}
        }
    }

    private concatCapabilityCode(capabilities: Array<Capability>): string[] {
        let codes: string[] = [];
        if(!capabilities){
            return codes;
        }
        capabilities.forEach(capability => {
            codes = codes.concat(capability.code.split(',').filter(code => code !== '0'));
            if (capability.downstreams) {
                this.concatCapabilityCode(capability.downstreams).forEach(code1 => {
                    if (!codes.includes(code1)) {
                        codes.push(code1);
                    }
                });
            }
        });
        return codes;
    }

    private setValues(category: CapabilityCategory, paymentCode: string, handlingCode: string, collectCode: string, isFreeze: boolean = false) {
        if (category) {
            this.category = category;
            if (category === CapabilityCategory.NONE) {
                this.service.setValue('0');
            } else {
                this.service.setValue(category.toString());
            }
        }
        if (paymentCode && paymentCode !== '0') {
            this.payment.setValue(paymentCode);
            if (window.gspl.params.isLabelFree && this.secondLevelEnabled) {
                const paymentCapability = this.paymentOptions.find(capability => capability.code === paymentCode);
                if (paymentCapability.downstreams) {
                    this.handlingOptions = paymentCapability.downstreams;
                }
                this.handling.enable({ onlySelf: true });
            }
        } else {
            this.payment.setValue('0');
            if (window.gspl.params.isLabelFree && this.secondLevelEnabled) {
                this.handling.disable({ onlySelf: true });
            }
        }
        if (window.gspl.params.isLabelFree) {
            if (handlingCode && handlingCode !== '0') {
                this.handling.setValue(handlingCode);
            } else {
                this.handling.setValue('0');
            }
        }
        if (collectCode && collectCode !== '0') {
            this.collect.setValue(collectCode);
        } else {
            this.collect.setValue('0');
        }
        this.isFreeze = isFreeze;
        if (this.isFreeze) {
            this.payment.disable({ onlySelf: true });
            if (window.gspl.params.isLabelFree && this.handling.value !== '0') {
                this.handling.disable({ onlySelf: true });
            }
            this.collect.disable({ onlySelf: true });
            if(!this.secondLevelEnabled){
                this.service.disable({ onlySelf: true });
            }
        }
    }

    reset() {
        this.category = CapabilityCategory.NONE;
        this.payment.setValue('0');
        if (window.gspl.params.isLabelFree) {
            this.handling.setValue('0');
            this.handling.disable({ onlySelf: true });
        }
        this.collect.setValue('0');
        this.isFreeze = false;
    }

    clear() {
        this.utils.clearFormControl(this.payment, '0');
        if (window.gspl.params.isLabelFree) {
            this.utils.clearFormControl(this.handling, '0');
            this.handling.disable({ onlySelf: true });
        }
        this.utils.clearFormControl(this.collect, '0');
        this.utils.clearFormControl(this.service, '0');
    }

    getSelectedCode(): string {
        let capability = null;
        if (!this.hide.capability) {
            if (this.secondLevelEnabled) {
                if (!this.hide.dropOff && this.payment.value !== '0') {
                    capability = this.payment.value;
                }
                if (!this.hide.dropOff && this.handling.value !== '0') {
                    capability = capability ? capability.concat(',').concat(this.handling.value) : this.handling.value;
                }
                if (!this.hide.collection && this.collect.value !== '0') {
                    capability = capability ? capability.concat(',').concat(this.collect.value) : this.collect.value;
                }
            } else {
                capability = this.getSelectedCodeFirstLevel();
            }
        } else {
            capability = this.queryParams['capability'];
        }
        return capability;
    }

    getSelectedCodeValid(): string {
        let capability = null;
        if (!this.hide.capability) {
            if (this.secondLevelEnabled) {
                capability = this.getSelectedCodePayment();
                const collectCode = this.getSelectedCodeCollect();
                if (collectCode) {
                    if (capability) {
                        capability = capability.concat(',').concat(collectCode);
                    } else {
                        capability = collectCode;
                    }
                }
            } else {
                capability = this.getSelectedCodeFirstLevel();
            }
        } else {
            capability = this.queryParams['capability'];
        }
        return capability;
    }

    getSelectedCodeValid1(): string {
        let capability = null;
        if (!this.hide.capability) {
            if (this.secondLevelEnabled) {
                if (this.category === CapabilityCategory.SEND) {
                    capability = this.getSelectedCodePayment();
                } else if (this.category === CapabilityCategory.COLLECT) {
                    capability = this.getSelectedCodeCollect();
                } else if (this.category === CapabilityCategory.SEND_COLLECT) {
                    const paymentCode = this.getSelectedCodePayment();
                    const collectCode = this.getSelectedCodeCollect();
                    if (paymentCode && collectCode) {
                        capability = paymentCode.concat(',').concat(collectCode);
                    }
                }
            } else {
                capability = this.getSelectedCodeFirstLevel();
            }
        } else {
            capability = this.queryParams['capability'];
        }
        return capability;
    }

    private getSelectedCodeFirstLevel(): string {
        if (this.category === CapabilityCategory.SEND) {
            return this.getSelectedCodePayment();
        } else if (this.category === CapabilityCategory.COLLECT) {
            return this.getSelectedCodeCollect();
        } else if (this.category === CapabilityCategory.SEND_COLLECT) {
            const paymentCode = this.getSelectedCodePayment();
            const collectCode = this.getSelectedCodeCollect();
            if (paymentCode && collectCode) {
                return paymentCode.concat(',').concat(collectCode);
            }
        }
        return null;
    }

    /*private getSelectedCodeHasNoCategory(): string {
        const paymentCode = this.getSelectedCodePayment();
        const collectCode = this.getSelectedCodeCollect();

        //let capabilities: string[] = [];
        //if (!this.hide.dropOff && this.dropOff.value !== '0') {
        let code = null;
        if (!this.hide.dropOff && paymentCode) {
            //capabilities = capabilities.concat(this.dropOff.value.split(','));
            code = paymentCode;
        }
        if (!this.hide.collection && collectCode) {
            //capabilities = capabilities.concat(this.collection.value.split(','));
            code = code ? code.concat(',').concat(collectCode) : collectCode;
        }
        //params.capability = capabilities.join(',');
        return code;
    }*/

    private getSelectedCodePayment(): string {
        if (!this.hide.dropOff) {
            if (this.secondLevelEnabled) {
                if (window.gspl.params.isLabelFree) {
                    if (this.payment.value !== '0' && this.handling.value !== '0') {
                        return this.payment.value.concat(',').concat(this.handling.value);
                    }
                } else {
                    if (this.payment.value !== '0') {
                        return this.payment.value;
                    }
                }
            } else {
                if (this.payment.value !== '0') {
                    return this.payment.value;
                }
            }
        }
        return null;
    }

    private getSelectedCodeCollect(): string {
        if (!this.hide.collection) {
            if (this.collect.value !== '0') {
                return this.collect.value;
            }
        }
        return null;
    }

    isCapabilitySelectedWithHide(): boolean {
        if (this.hide.mandatoryCapability === MandatoryCapability.DEFAULT_SEND_OR_COLLECT) {
            return this.isSelectedCodePayment() || (this.isSelectedCodeCollect() && this.isValidSelectedCodePayment());
        } else if (this.hide.mandatoryCapability === MandatoryCapability.NONE) {
            return true;
        } else if (this.hide.mandatoryCapability === MandatoryCapability.SEND_AND_COLLECT) {
            return this.isSelectedCodePayment() && this.isSelectedCodeCollect();
        } else if (this.hide.mandatoryCapability === MandatoryCapability.EITHER_SEND_OR_COLLECT) {
            return (!(this.isSelectedCodePayment() && this.isSelectedCodeCollect()) &&
                (this.isSelectedCodePayment() || (this.isSelectedCodeCollect() && this.isValidSelectedCodePayment())));
        } else {
            if((Utils.isJsApi() && window.gspl.wsbSearch) || (!Utils.isJsApi())){
                console.error('hide.mandatoryCapability should not be null');
            }
            return false;
        }
    }

    private isSelectedCodePayment(): boolean {
        return this.getSelectedCodePayment() !== null;
    }

    private isValidSelectedCodePayment(): boolean {
        if (!this.hide.dropOff) {
            if (this.secondLevelEnabled) {
                if (window.gspl.params.isLabelFree) {
                    if (this.payment.value !== '0' && this.handling.value === '0') {
                        return false;
                    }
                }
            }
        }
        return true;
    }

    private isSelectedCodeCollect(): boolean {
        return this.getSelectedCodeCollect() !== null;
    }

    /*isCapabilitySelectedWithHide1(): boolean {
        if (window.gspl.params.isLabelless) {
            if (this.hide.mandatoryCapability === MandatoryCapability.DEFAULT_SEND_OR_COLLECT) {
                return (this.payment.value !== '0' && this.handling.value !== '0') || (this.collect.value !== '0' && this.payment.value === '0' && this.handling.value === '0');
            } else if (this.hide.mandatoryCapability === MandatoryCapability.NONE) {
                return true;
            } else if (this.hide.mandatoryCapability === MandatoryCapability.SEND_AND_COLLECT) {
                return (this.payment.value !== '0' && this.handling.value !== '0') && this.collect.value !== '0';
            } else if (this.hide.mandatoryCapability === MandatoryCapability.EITHER_SEND_OR_COLLECT) {
                return (!( (this.payment.value !== '0' && this.handling.value !== '0') && this.collect.value !== '0') && 
                    ((this.payment.value !== '0' && this.handling.value !== '0') || (this.collect.value !== '0' && this.payment.value === '0' && this.handling.value === '0')));
            } else {
                console.error('hide.mandatoryCapability should not be null');
                return false;
            }
        } else {
            if (this.hide.mandatoryCapability === MandatoryCapability.DEFAULT_SEND_OR_COLLECT) {
                return this.payment.value !== '0' || this.collect.value !== '0';
            } else if (this.hide.mandatoryCapability === MandatoryCapability.NONE) {
                return true;
            } else if (this.hide.mandatoryCapability === MandatoryCapability.SEND_AND_COLLECT) {
                return this.payment.value !== '0' && this.collect.value !== '0';
            } else if (this.hide.mandatoryCapability === MandatoryCapability.EITHER_SEND_OR_COLLECT) {
                return (!( this.payment.value !== '0' && this.collect.value !== '0') && 
                    (this.payment.value !== '0' || this.collect.value !== '0'));
            } else {
                console.error('hide.mandatoryCapability should not be null');
                return false;
            }
        }
    }*/

    /*getCode(): string {
        if (this.sendCode && this.collectCode) {
            return this.sendCode.concat(',').concat(this.collectCode);
        } else if (this.sendCode) {
            return this.sendCode;
        } else if (this.collectCode) {
            return this.collectCode;
        } else {
            return null;
        }
    }*/

    onPaymentChange(capability: Capability) {
        if (capability && window.gspl.params.isLabelFree) {
            this.handling.setValue('0');
            if (this.payment.value === '0') {
                this.settings.expressMessage = null;
                this.settings.expressMessageWithLink = null;
                this.handling.disable({ onlySelf: true });
            } else {
                this.handlingOptions = capability.downstreams;
                if (capability.downstreams && capability.downstreams.length == 2 && capability.downstreams.find(handling => handling.code === '0')) {
                    const handling = capability.downstreams.find(handling => handling.code != '0');
                    this.handling.setValue(handling ? handling.code : '0');                    
                    //this.settings.getMessageDetailsByPriorityForExpressLink(capability.code+','+this.handling.value);
                }
                if(this.settings.messageDetails){
                    var ppcArr: string[] = [capability.code+','+this.handling.value];
                    if(this.collect.value){
                        ppcArr.push(this.collect.value);
                    }
                    this.settings.ppcsArray = ppcArr;
                    this.settings.getMessageDetailsByPriorityForExpressMessage(ppcArr);
                }
                this.handling.enable({ onlySelf: true });
            }
            //this.setCapabilityCategory();
            /*if(capability.code != '0'){ Can be removed
                this.isPaymentSelected = true;
            } else {
                this.isPaymentSelected = false;
            }*/
        }
    }

    onHandlingChange(capability: Capability){
        if(this.settings.messageDetails){
            var ppcArr: string[] = [this.payment.value+','+capability.code];
            if(this.collect.value){
                ppcArr.push(this.collect.value);
            }
            this.settings.ppcsArray = ppcArr;
            this.settings.getMessageDetailsByPriorityForExpressMessage(ppcArr);
            
        }
        //this.settings.getMessageDetailsByPriorityForExpressLink(capability.code);
    }

    onCollectChange(capability: Capability){ //can be removed
        /*if(capability.code != '0'){
            this.isCollectSelected = true;
        } else {
            this.isCollectSelected = false;
        }   
        if (capability && window.gspl.params.isLabelless) {
            //this.setCapabilityCategory();
        }*/
        if(capability.code!=='0' && this.settings.messageDetails){
            var ppcArr: string[] = [capability.code];
            if(this.handling.value && this.payment.value){
                ppcArr.push(this.payment.value+','+this.handling.value);
            }
            this.settings.ppcsArray = ppcArr;
            this.settings.getMessageDetailsByPriorityForExpressMessage(ppcArr);
        } else if (capability.code ==='0' && this.settings.messageDetails){
            var ppcArr: string[] = [];
            if(this.handling.value && this.payment.value){
                ppcArr.push(this.payment.value+','+this.handling.value);
            }
            this.settings.ppcsArray = ppcArr;
            this.settings.getMessageDetailsByPriorityForExpressMessage(ppcArr);
        } else {
            this.settings.expressMessageWithLink = null;
            this.settings.expressMessage = null;
        }
        //this.settings.getMessageDetailsByPriorityForExpressLink(capability.code);
    }

    setCapabilityCategory(){
        if(this.payment.value!=0 && this.collect.value ==0){
            this.category = CapabilityCategory.SEND;
            //if(!this.secondLevelEnabled){
                this.service.setValue(CapabilityCategory.SEND);
            //}
        } else if (this.payment.value==0 && this.collect.value !=0){
            this.category = CapabilityCategory.COLLECT;
            //if(!this.secondLevelEnabled){
                this.service.setValue(CapabilityCategory.COLLECT);
            //}
        } else if (this.payment.value!=0 && this.collect.value !=0){
            this.category = CapabilityCategory.SEND_COLLECT;
            //if(!this.secondLevelEnabled){
                this.service.setValue(CapabilityCategory.SEND_COLLECT);
            //}
        } else {
            this.category = CapabilityCategory.NONE;
            this.service.setValue('0');
        }
    }


}

export enum CapabilityCategory {
    SEND = 'send',
    COLLECT = 'collect',
    SEND_COLLECT = 'send_collect',
    NONE = 'none'
}
