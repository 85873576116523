export default function percentToByte(p) {
    return String.fromCharCode(parseInt(p.slice(1), 16));
}
export class EncodeDecode {
    static b64EncodeUnicode(str: any) {
       return btoa(encodeURIComponent(str).replace(/%[0-9A-F]{2}/g, percentToByte));
    }

    static b64DecodeUnicode(str) {
        // Going backwards: from bytestream, to percent-encoding, to original string.
        return decodeURIComponent(atob(str).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    }
    static encode(str) {
        return EncodeDecode.b64EncodeUnicode(str)
            .replace(/\//g, '_')
            .replace(/\+/g, '-')
            .replace(/=+$/, '');
    }
    static decode(str) {
        return EncodeDecode.b64DecodeUnicode(str.replace(/\-/g, '+').replace(/_/g, '/'));
    }
    static isBase64(str) {
        try {
            return btoa(atob(str))
                .replace(/\//g, '_')
                .replace(/\+/g, '-')
                .replace(/=+$/, '') == str;
        } catch (err) {
            return false;
        }
    }

    static isBase64Unicode(str) {
        try {
            return EncodeDecode.b64DecodeUnicode(EncodeDecode.b64EncodeUnicode(str))
                .replace(/\//g, '_')
                .replace(/\+/g, '-')
                .replace(/=+$/, '') == str;
        } catch (err) {
            return false;
        }
    }
}
