<app-header *ngIf="!data.hideMobile" [showLangSelector]="false"></app-header>
<div *ngIf="_isOpen" [dir]="dir" [ngClass]="textAlignRtl" class="container d-block">
    <div class="back no-print d-md-none d-lg-none d-xl-none" (click)="back()">{{"spl.result.back" | translate}}</div>
    <!--div class="logo-line"><img src="../../assets/logo.png" class="logo"></div-->
    <div class="head-loc">
        <div class="row">
            <div class="col-12 col-sm-5 d-print-none">
                <h2>A: {{'spl.search.your_location' | translate}}</h2>

                <div class="py-2 row mb-3 mb-md-2" [ngClass]="'search-page-customization' + (settings?.isRTL() ? ' search-page-customization-rtl' : '')">
                    <div [ngClass]="'col col-md-10'">
                        <div class="row">
                            <app-address-input [ngClass]="'col pl-0 pl-md-3 pr-2 pr-md-2'" (events)="onAddressEvent($event)"
                                [selectedCountry]="selectedCountry" (submit)="onSubmit()" [address]="address" [isBasic]="true"
                                [isDirection]="true" [inputAddress]="data.yourLocation" data-test_id="textBox_address_basic"></app-address-input>
                
                            <div [ngClass]="'col-auto px-0 ' + (settings?.isRTL()?'pl-md-3':'pr-md-3')"
                            class="custom-width" tabindex="0">
                                <a class="geolocation" (click)="getLocation()" title="{{'spl.search.location.click.title' | translate}}">
                                    <img class="mx-auto" [src]="settings.backendUrl + '/assets/locationIcon2.svg'" alt="Shows the icon depicting the location.">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row" class="custom-search-button">
                    <button class="rounded-lg" mat-button id="searchButton" class="custom-searchButton"
                            [disabled]="searchInProgress ? true : (
                                    (  isAddressLongEnough() && isMapConsentGiven) ? false : true )"
                            (click)="doSearch()" aria-label="search"
                            data-test_id="button_search_basic">
                        <span>{{"spl.search.btn_text" | translate}}</span>
                    </button>
                </div>
            </div>
            <div id="printOnly" class="col-12 col-sm-5">
                <h2>A: {{'spl.search.your_location' | translate}}</h2>
                <div>{{address.value}}</div>
            </div>
            <div class="col-12 col-sm-5">
                <h2>B: {{data.spName}}</h2>
                <h3>{{data.localName}}</h3>
                <div>
                    <span *ngIf="data.address.addressLine1">{{data.address.addressLine1}}</span>
                    <span *ngIf="data.address.addressLine2"> {{data.address.addressLine2}}</span>
                    <span *ngIf="data.address.addressLine3"> {{data.address.addressLine3}}</span>
                    <br />
                    <span *ngIf="data.address.zipCode">{{data.address.zipCode}}</span>
                    <span *ngIf="data.address.countryDivisionCode"> {{data.address.countryDivisionCode}},</span>
                    <span *ngIf="data.address.city"> {{data.address.city}}</span>
                </div>
            </div>
            <div class="col-sm-2 d-none d-sm-block no-print">
                <div *ngIf="displayPrint && isMapConsentGiven">
                    <a class="text-button-l" (click)="print()" tabindex="0">
                        <span>
                            <div class="iconed-big print"></div>
                        </span>
                        <span>{{'spl.result.button.print' | translate}}</span>
                    </a>
                </div>
            </div>
        </div>
    </div>


    <div class="direct">
        <div class="row" *ngIf="data.yourLocation && isMapConsentGiven && !isCrossBorderMsgDisplayed">
            <div class="col-xs-12 col-12 col-md-4 col-sm-12 col-lg-4 col-xl-4">
                <div id="travel_mode" *ngIf="data.showTravelMode" class="row">
                    <div class="col-3 pr-2 pl-2" *ngFor="let mode of travelModes;index as i" tabindex="0">
                        <a *ngIf="!mode.selected && (mode.mapType === 'all' || data.mapType === mode.mapType)"
                            class="mode-image unselected"
                            (click)="onClickOfTravelMode(mode)" title="{{mode.label | translate}}" [attr.aria-label]="mode.labelKey + ' MODE'" role="link">
                            <img src="{{settings.backendUrl + '/assets/directions/' + mode.unSelectImage + '.svg'}}" alt="{{mode.label | translate}}"></a>
                        <a *ngIf="mode.selected && (mode.mapType === 'all' || data.mapType === mode.mapType)"
                            class="mode-image selected"
                            (click)="onClickOfTravelMode(mode)" title="{{mode.label | translate}}" [attr.aria-label]="mode.labelKey + ' MODE'" role="link">
                            <img src="{{settings.backendUrl + '/assets/directions/' + mode.selectImage + '.svg'}}" alt="{{mode.label | translate}}"></a>
                    </div>
                </div>
                <div class="row warning-msg" *ngIf="!currentTravelMode">{{'spl.result.select_travel_mode' | translate}}</div>
                <div class="row warning-msg" *ngIf="isNotSupportedTravelMode">{{'spl.result.travel_mode_not_supported' | translate}}</div>
                <div [ngClass]="{'opacity-print': displayPrint}" class="row" id="map-itinerary">                    
                </div>
            </div>
            <div class="col-xs-12 col-12 cold-md-8 col-sm-12 col-lg-8 col-xl-8">
                <div aria-labelledby="Loads and initiates the map directions" id="map-direction"></div>
            </div>
        </div>
        <div class="custom-class" *ngIf="!data.yourLocation && !isCrossBorderMsgDisplayed">
           {{"spl.direction.error.txt_id_search" | translate}}
        </div>
        <div class="custom-class padding-class" *ngIf="isCrossBorderMsgDisplayed">
            {{'spl.direction.error.cross_border_service' | translate}}
        </div>
        <div class="custom-class" *ngIf="!isMapConsentGiven && data.yourLocation">
            <app-cookie-map-consent [page]="'directions'" [mapType]="data.mapType"></app-cookie-map-consent>
        </div>
    </div>
    
</div>

<app-footer class="no-print row margin-top" [hasBackground]="true"></app-footer>