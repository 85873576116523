import { Utils } from "./common/utils";

declare const window: any;


export class BingMapsLoader {

    private static promise;

    public static load(lang = 'en', ctry = 'US', url) {//573005

        if (!this.promise) {

            this.promise = new Promise(resolve => {

                window['__onBingLoaded'] = (ev) => {
                    resolve('Bing Maps API loaded');
                };
                let nonce = (document.querySelector('meta[name="CSP_NONCE"]') as HTMLMetaElement)?.content;
                if(Utils.isJsApi()){
                    nonce = window.gsplNonce;
                }
                const node = document.createElement('script');
                node.src = window.location.protocol + url + '&setLang=' + lang + '&setMkt=' + lang + '-' + ctry;
                node.type = 'text/javascript';
                node.setAttribute('nonce', nonce);
                node.async = true;
                node.defer = true;
                document.getElementsByTagName('head')[0].appendChild(node);
            });
        }

        return this.promise;
    }
}
