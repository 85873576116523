<div *ngIf="!isJsApi() && !settings.mobileHiding" class="col py-3" [style.background-color]="hasBackground ? '#DCDCDC' : 'unset'">
    <div class="row" [ngStyle]="{'height': '1rem'}">
        <div class="col">DHL © {{brandingYear}} | <a [href]="'https://' + link" target="_blank">www.dhl.com</a> | <a id="ot-sdk-btn-footer" tabindex="0" class="ot-sdk-show-settings">Consent Settings</a></div> <!--*ngIf="settings.cookieLinkDisplayStatus" //694365-->
        <!--div *ngIf="!settings.cookieLinkDisplayStatus" class="col">DHL © {{brandingYear}} | <a [href]="'https://' + link" target="_blank">www.dhl.com</a></div //694365-->
        <div class="col-auto">Locator {{version}}</div>
    </div>
</div>
<div *ngIf="!isJsApi() && settings.mobileHiding && isBasic" class="row mt-1" [ngStyle]="{'height':'30px', 'background-color': '#EBEBEB',  'width': '100%'}"> <!-- && settings.cookieLinkDisplayStatus //694365-->
    <span class="col-auto"><a [ngStyle]="{'float': 'right', 'margin-left': settings.isRTL() ? '' : '5px', 'margin-right': settings.isRTL() ? '10px' : '', 'margin-top': '4px' }" id="ot-sdk-btn" class="ot-sdk-show-settings">Consent Settings</a></span>
</div>
<div *ngIf="!isJsApi() && settings.mobileHiding && isAdvanced" class="row mt-1" [ngStyle]="{'height':'30px', 'background-color': '#EBEBEB',  'width': '100%'}"> <!-- && settings.cookieLinkDisplayStatus //694365-->
    <span class="col-auto"><a [ngStyle]="{'float': 'right',  'margin-left': settings.isRTL() ? '' : '5px', 'margin-right': settings.isRTL() ? '10px' : '', 'margin-top': '4px' }" id="ot-sdk-btn" class="ot-sdk-show-settings">Consent Settings</a></span>
</div>
