<div class="customized">
        <ng-select id="country_select" [tabIndex]="selectedCountry.value ? '-1' : '0'" [inputAttrs]="{'aria-label':'select a country', 'aria-labelledby':'valueElement','aria-autocomplete':'list'}" aria-label="country value" canFreeze="selectCountry" labelForId="countrySelect"
            [items]="countries?.countryList" [selectOnTab]="true" [searchFn]="searchFn" [formControl]="selectedCountry"
            [clearable]="clearable" placeholder="{{
                            abbreviated ? '&nbsp;--' : ('spl.search.your_country' | translate)
                    }}" [ngClass]="(enableRtl && settings?.isRTL()) ? 'country-select-rtl' : 'country-select-ltr'">
            <ng-template ng-label-tmp let-item="item">
                <div class="countryvalue">
                    <div class="ng-value countryimage" >
                        <img aria-label="Diplays flag image" [src]="settings.backendUrl + '/assets/blank.gif'" [ngStyle]="{background: 'url(' + settings.backendUrl + '/assets/flags.png) no-repeat'}" [class]="'flag flag-'+item.value.toLowerCase()">
                    </div>
                    <div tabindex="0" aria-label="Select a country value option" class="ng-value country">
                        <div [ngClass]="'parentplaceholder'" *ngIf="!abbreviated">
                            {{"spl.search.your_country" | translate}}
                        </div>
                        <div id="valueElement" [ngClass]="'parentcountry'">
                            {{item.value === '00' || item.label === '00' ? '' :
                            (abbreviated ? item.value : displayCountry(item) | translate)}}
                        </div>
                    </div>
                    <div *ngIf="!isBasic" [ngClass]="'ng-value greencheck'+((abbreviated?' results'+(settings?.isRTL()?' right-margin-0':''):''))">
                        <img alt="Country selected" *ngIf="!abbreviated" [src]="settings.backendUrl + '/assets/blank.gif'" [ngStyle]="{'background-image': 'url('+settings.backendUrl+'/assets/green-checked.svg)'}" [ngClass]="'greenchecklarge' +(settings?.isRTL()?' right-margin-0':'')">
                        <img alt="Country selected" *ngIf="abbreviated" [src]="settings.backendUrl + '/assets/blank.gif'" [ngStyle]="{'background-image': 'url('+settings.backendUrl+'/assets/green-checked.svg)'}" [ngClass]="'greencheckmedium' +(settings?.isRTL()?' right-margin-0':'')">
                    </div>
                </div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index">
                <img [src]="settings.backendUrl + '/assets/blank.gif'" [ngStyle]="{background: 'url(' + settings.backendUrl + '/assets/flags.png) no-repeat'}" [class]="'flag flag-'+item.value.toLowerCase()">
                <span>{{displayCountry(item) | translate}}</span>
            </ng-template>
        </ng-select>
        <img alt="Country is not Selected" *ngIf="!isBasic && !selectedCountry.value && !abbreviated" [ngClass]="'default-tick'" [src]="settings.backendUrl + '/assets/blank.gif'" [ngStyle]="{'margin-top': '-33px', 'margin-right':'20px', 'float':'right', 'height':'20px', 'width':'20px', 'position':'relative', 'background': 'url(' + settings.backendUrl + '/assets/grey-flag.svg) no-repeat'}">
        <img alt="Country is not Selected" *ngIf="!isBasic && !selectedCountry.value && abbreviated" [ngClass]="'default-tick'" [src]="settings.backendUrl + '/assets/blank.gif'" [ngStyle]="{'margin-top': '-25px', 'margin-right':'15px', 'float':'right', 'height':'20px', 'width':'20px', 'position':'relative', 'background': 'url(' + settings.backendUrl + '/assets/grey-flag.svg) no-repeat'}">
</div>

<!--div class="row customized">
    <div class="col-12">
    </div>
</div-->
